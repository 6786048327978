import React from 'react';
import Button from '../Button';
import _ from 'lodash';

class NewEmployeeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  updateField(field, value) {
    this.setState({
      [field]: value,
    });
  }

  validateForm() {
    let isValid = true;
    let requiredInputs = document.getElementsByClassName('form-container')[0].querySelectorAll('[required]');
    let requiredElements = document.getElementsByClassName('selected-text required');

    requiredInputs.forEach((element) => {
      if (_.isEmpty(element.value)) {
        element.classList.add('input--invalid');
        isValid = false;
      } else {
        element.classList.remove('input--invalid');
      }
    });

    _.forEach(requiredElements, (element) => {
      if (_.isEmpty(element.getElementsByTagName('input')[0].value)) {
        element.classList.add('input--invalid');
        isValid = false;
      }
    });

    return isValid;
  }

  submitForm() {
    this.setState({
      isLoading: true,
      errMsg: null,
    });

    if (!this.validateForm()) {
      return;
    }

    this.props
      .onClick(this.state)
      .then((res) => {
        this.props.showModalFn(false);
      })
      .catch((err) => {
        // Add error message
        this.setState({
          errMsg: err,
        });
      })
      .finally((res) => {
        this.setState({
          isLoading: false,
        });
      });
  }

  render() {
    return (
      <div id={'new-employee-modal'} className='modal-content'>
        <div className='heading'>Add New Employee to {this.props.data.contract.name}</div>
        <div className='form-container'>
          <div className='form-row'>
            <div className='form-element'>
              <label>First Name</label>
              <input
                id={`input-firstName`}
                type='text'
                autoComplete='off'
                required={true}
                value={this.state.firstName ? this.state.firstName : ''}
                onChange={(e) => this.updateField('firstName', e.target.value)}
              />
            </div>
            <div className='form-element'>
              <label>Last Name</label>
              <input
                id={`input-lastName`}
                type='text'
                autoComplete='off'
                required={true}
                value={this.state.lastName ? this.state.lastName : ''}
                onChange={(e) => this.updateField('lastName', e.target.value)}
              />
            </div>
          </div>
          <div className='form-element'>
            <label>SAP Reference #</label>
            <input
              id={`input-sap-reference-number`}
              type='text'
              autoComplete='off'
              required={true}
              value={this.state.sapReferenceNumber ? this.state.sapReferenceNumber : ''}
              onChange={(e) => this.updateField('sapReferenceNumber', e.target.value)}
            />
          </div>

          <div className='f--warning error-message'>
            <span>{this.state.errMsg}</span>
          </div>
        </div>

        <div className='button-group t--align-right'>
          <Button classes={`button button--plain`} onClick={() => this.props.showModalFn(false)} text='Cancel' />
          <Button classes={`button button--med button--blue`} onClick={() => this.submitForm()} text='Submit' />
        </div>
      </div>
    );
  }
}

export default NewEmployeeModal;
