import React from 'react';
import Icon from '../Icon';

class EmployeeResult extends React.Component {
  render() {
    const data = this.props.data;
    const dataType = this.props.type;

    return (
      <div className={`result-item ${dataType}`} onClick={() => this.props.onClick()}>
        <div id={'employee-' + data.id} className={`item-card`}>
          <div className='item-row'>
            {/* Name */}
            <div className='item-detail' title={data.name}>
              <Icon name='person' />
              <span className={'u--ellipsis'}>{data.name}</span>
            </div>
            {/* SAP Reference Number */}
            <div id='sapRef' className='item-detail' title='SAP Reference Number'>
              <label>SAP #</label>
              <span>{data.sapReferenceNumber}</span>
            </div>
            {/* Mobile Phone */}
            <div className='item-detail' title='Mobile Phone Number'>
              <Icon name='phone' />
              <span>{data.mobileNumber}</span>
            </div>
            {/* Pay Type */}
            <div className='item-detail' title={`Pay Type: ${data.payType}`}>
              <Icon name='coin' />
              <span className={'u--ellipsis'}>{data.payType}</span>
            </div>
            {/* Areas */}
            <div className='item-detail' title={`Areas: ${data.areas}`}>
              <Icon name='map' />
              <span className={'u--multiline-ellipsis'}>{data.areas}</span>
            </div>
            {/* Other Contracts */}
            <div className='item-detail' title={`Other Contracts: ${data.otherContracts.join(', ')}`}>
              <Icon name='clipboard' />
              <span className={'u--multiline-ellipsis'}>{data.otherContracts.join(', ')}</span>
            </div>
          </div>
          <div className='item-row'>
            {/* Alias */}
            <div className='item-detail' title='Alias'>
              <Icon name='label' />
              <span>{data.alias}</span>
            </div>
            {/* Email Address */}
            <div className='item-detail' title='Email Address'>
              <Icon name='envelope' />
              <span className={'u--ellipsis'} title={data.emailAddress}>
                {data.emailAddress}
              </span>
            </div>
            {/* Office Phone */}
            <div className='item-detail' title='Office Phone Number'>
              <Icon name='briefcase' />
              <span>{data.officeNumber}</span>
            </div>
            {/* Agreement Type */}
            <div className='item-detail' title={`Agreement Type: ${data.agreementType}`}>
              <Icon name='badge' />
              <span className={'u--ellipsis'}>{data.agreementType}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EmployeeResult;
