import React from 'react';
import './sass/App.scss';
import _ from 'lodash';
import Assignments from './components/assignments/Assignments';
import CustomSelect from './components/CustomSelect';
import Approvals from './components/approvals/Approvals';
import Search from './components/search/Search';
import Utils from './utils/Utils';
import Dashboard from './components/dashboard/Dashboard';
import Export from './components/export/Export';
import Icon from './components/Icon';
import { BrowserRouter as Router, NavLink, Route, Switch } from 'react-router-dom';
import LogIn from './components/log-in/LogIn';
import Remerge from './components/remerge/Remerge';
import MatrixParts from './components/matrix-parts/MatrixParts';
import LogTicket from './components/support/LogTicket';
import Timesheet from './components/timesheets/Timesheet';
import Audits from './components/audits/Audits';
import Quintiq from './components/quintiq/Quintiq';
import Employees from './components/employees/Employees';
import EmployeeDetails from './components/employees/EmployeeDetails';
import { ROLE } from './constants/UserRole';
import UpdateUserLogon from './components/temp/UpdateUserLogon';
import { PublicClientApplication } from '@azure/msal-browser';
import RouteTemplates from './components/route-templates/RouteTemplates';
import ResequenceRouteTemplate from './components/route-templates/ResequenceRouteTemplate';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userData: {},
      selectedContract: {},
      selectedAreas: [],
      selectedTab: 'dashboard',
      selectedSubpage: '',
      isLoading: true,
      username: null,
      isAuthenticated: false,
    };

    this.publicClientApplication = new PublicClientApplication({
      auth: {
        clientId: process.env.REACT_APP_AUTH_APP_ID,
        redirectUri: 'https://mimtrdev.spotless.com.au/hybrid/',
        authority: process.env.REACT_APP_AUTH_APP_AUTHORITY,
      },
      cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: true,
      },
    });
  }

  async componentDidMount() {
    // Set tab title
    document.title = `${process.env.REACT_APP_ENV ? `[${process.env.REACT_APP_ENV}] ` : ''}MiMtr Hybrid`;

    // Retrieve user data from localstorage
    let defaultUserData = { navTabs: [], securityRoles: [] };

    let userData = localStorage.getItem('userData');
    userData = userData ? JSON.parse(userData) : defaultUserData;

    let username = localStorage.getItem('username');
    let selectedContract = JSON.parse(localStorage.getItem('selectedContract'));
    let selectedAreas = JSON.parse(localStorage.getItem('selectedAreas'));

    // Check for valid auth token - log out if unauthorised
    let checkTokenApiUrl = `${process.env.REACT_APP_API_ENDPOINT}/check`;
    fetch(checkTokenApiUrl).then((res) => {
      if (res.status === 401) {
        this.logout(true);
      } else {
        document.title = `${process.env.REACT_APP_ENV ? `[${process.env.REACT_APP_ENV}] ` : ''}MiMtr Hybrid | Login`;

        // Redirect to 'My Timesheet' if user only has timesheet only roles (Meter Reader only)
        if (selectedContract && selectedContract.roles.includes(ROLE.TIMESHEET_ONLY) && window.location.href === '/hybrid/timesheet/employee') {
          window.location.href = '/hybrid/timesheet/employee';
        }

        this.setState({
          userData: userData,
          username: username,
          selectedContract: selectedContract,
          selectedAreas: selectedAreas,
          isLoading: false,
        });
      }
    });

    document.addEventListener('mousedown', this.handleClickOutsideNav);
  }

  handleClickOutsideNav = (e) => {
    var navSidebar = document.getElementById('nav-sidebar');

    if (navSidebar && navSidebar.style.display === 'block' && !navSidebar.contains(e.target)) {
      let closeNav = true;

      if (closeNav) {
        this.toggleNavSidebar(false);
      }
    }
  };

  toggleNavSidebar(show) {
    let navSidebar = document.getElementById('nav-sidebar');

    if (!show) {
      navSidebar.style.display = 'none';
    } else if (navSidebar.style.display === 'block') {
      navSidebar.style.display = 'none';
    } else {
      navSidebar.style.display = 'block';
    }
  }

  /**
   * Function to submit Log In form, then retrieves and locally stores user data.
   *
   * @param {String} username
   * @param {String} password
   */
  async submitLogIn(username, password) {
    if (!username || !password) {
      let errMsg = 'Username and Password are required.';

      this.setState({
        loginErrorMsg: errMsg,
      });

      return;
    }

    this.setState({
      isLoading: true,
    });

    await this.getUserData(username, password)
      .then((userData) => {
        // Retrieve selected contract from localstorage if possible, otherwise use first contract
        let prevStoredContract = JSON.parse(localStorage.getItem('selectedContract'));
        let prevStoredUsername = localStorage.getItem('username');
        let selectedContract = userData.contracts[0];
        let selectedAreas = [];

        userData.username = username;

        // Check if the current user is the same as the previous user
        if (prevStoredUsername === username) {
          selectedContract = prevStoredContract ? userData.contracts.find((contract) => contract.id === prevStoredContract.id) : selectedContract;
          if (selectedContract) {
            userData.areas.forEach((area) => {
              if (area.contractId === selectedContract.id) {
                selectedAreas.push(area);
              }
            });
          } else {
            selectedContract = { id: null, name: '' };
          }
        } else {
          localStorage.removeItem('manageTimesheetSession');
          localStorage.removeItem('username');
        }

        localStorage.removeItem('selectedContract');

        userData.securityRoles.canReallocateInProgressRoutes = selectedContract.roles.includes(ROLE.SUPERVISOR) || selectedContract.roles.includes(ROLE.CONTRACT_MANAGER);

        if (selectedContract.roles.includes(ROLE.TIMESHEET_ONLY) || (selectedContract.roles.length === 1 && selectedContract.roles[0] === ROLE.METER_READER)) {
          userData.navTabs = [];

          localStorage.removeItem('selectedEmployeeTimesheet');
          window.location.href = '/hybrid/timesheet/employee';
        }

        localStorage.setItem('username', username);
        localStorage.setItem('userData', JSON.stringify(userData));
        localStorage.setItem('selectedContract', JSON.stringify(selectedContract));
        localStorage.setItem('selectedAreas', JSON.stringify(selectedAreas));

        this.setState({
          userData: userData,
          selectedContract: selectedContract,
          selectedAreas: selectedAreas,
          isLoading: false,
          username: username,
        });
      })
      .catch((err) => {
        let errMsg = typeof err.message === 'string' ? err.message : 'System Error. Please try again later.';

        this.setState({
          isLoading: false,
          loginErrorMsg: errMsg,
        });
      });
  }

  /**
   * Function to retrieve User Data from database.
   *
   * @param {String} username
   * @param {String} password
   * @return Promise
   */
  async getUserData(username, password) {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          username: username,
          password: password,
        }),
      };

      fetch(`${process.env.REACT_APP_API_ENDPOINT}/login`, requestOptions)
        .then((userRes) => {
          return userRes.json();
        })
        .then((res) => {
          // On successful login, set initial app data
          if (res.success) {
            let userData = res.data;
            let userId = userData[0][0].UserId;

            // Format user contracts
            let contracts = [];
            userData[1].forEach((contract) => {
              contracts.push({
                id: contract.ContractId,
                name: contract.Contract,
                dbServer: contract.DatabaseServer,
                roles: contract.RoleIds.split(',').map((roleId) => parseInt(roleId)),
              });
            });

            contracts = _.sortBy(contracts, [(contract) => contract.name.toLowerCase()]);

            // Set user security roles
            let securityRoles = {
              //    canSeeAssignments: userData[0][0].SecurityRoles.includes("1"),
              //    canSeeApprovals: userData[0][0].SecurityRoles.includes("2"),
              //    canSeeSearch: userData[0][0].SecurityRoles.includes("3"),
              canEditJobDetails: userData[0][0].SecurityRoles.includes('4'),
              canEditExportConfig: userData[0][0].SecurityRoles.includes('5'),
              canManageTimesheets: userData[0][0].SecurityRoles.includes('6'),
              canApprovePolePhotos: userData[0][0].SecurityRoles.includes('7'),
              canSkipRoutes: true,
              canSeeDashboard: userData[0][0].SecurityRoles.includes('20'),
              canSeeExport: userData[0][0].SecurityRoles.includes('21'),
              canSeeEmployees: userData[0][0].SecurityRoles.includes('22'),
              canSeeReports: userData[0][0].SecurityRoles.includes('23'),
              canSeeRouteTemplates: userData[0][0].SecurityRoles.includes('24'),
              canSeeAssignments: userData[0][0].SecurityRoles.includes('25'),
              canSeeApprovals: userData[0][0].SecurityRoles.includes('26'),
              canSeeAudits: userData[0][0].SecurityRoles.includes('27'),
              canSeeSearch: userData[0][0].SecurityRoles.includes('28'),
              canSeeQuintiq: userData[0][0].SecurityRoles.includes('29'),
              canSeeLogout: userData[0][0].SecurityRoles.includes('30'),
            };

            // Set up accessible nav tabs
            let tabs = this.state.userData.navTabs;
            // let tabsToAdd = ["dashboard", "export", "employees", "reports"];
            let tabsToAdd = [];
            //TODO: remove this (18 = temp role to hide route templates)
            // if (_.some(contracts, contract => { return contract.roles.includes(18)})) {
            //     tabsToAdd.push("routeTemplates");
            // }

            if (securityRoles.canSeeDashboard) {
              tabsToAdd.push('dashboard');
            }
            if (securityRoles.canSeeExport) {
              tabsToAdd.push('export');
            }
            if (securityRoles.canSeeEmployees) {
              tabsToAdd.push('employees');
            }
            if (securityRoles.canSeeReports) {
              tabsToAdd.push('reports');
            }
            if (securityRoles.canSeeRouteTemplates) {
              tabsToAdd.push('routeTemplates');
            }
            if (securityRoles.canSeeAssignments) {
              tabsToAdd.push('assignments');
            }
            if (securityRoles.canSeeApprovals) {
              tabsToAdd.push('approvals');
            }
            if (securityRoles.canSeeAudits) {
              tabsToAdd.push('audits');
            }
            if (securityRoles.canSeeSearch) {
              tabsToAdd.push('search');
            }
            if (securityRoles.canSeeQuintiq) {
              tabsToAdd.push('quintiq');
            }
            //  if (_.some(contracts, contract => { return contract.roles.includes(ROLE.TIME_AND_ATTENDANCE_ADMIN)})) {
            //      tabsToAdd.push("quintiq");
            //  }

            tabs.splice(3, 0, ...tabsToAdd);

            // Format user area groups
            let areaGroups = [];
            userData[2].forEach((areaGroup) => {
              areaGroups.push({ id: areaGroup.AreaGroupId, contractId: areaGroup.ContractId, name: areaGroup.Name });
            });

            // Format user areas
            let areas = [];
            userData[3].forEach((area) => {
              areas.push({
                id: area.AreaId,
                name: area.Name,
                contractId: area.ContractId,
                areaGroupId: area.AreaGroupId,
              });
            });

            resolve({
              userId: userId,
              securityRoles: securityRoles,
              contracts: contracts,
              areaGroups: areaGroups,
              areas: areas,
              navTabs: tabs,
            });
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  async adLogin() {
    await this.publicClientApplication
      .handleRedirectPromise()
      .then((authResult) => {
        // Check if user signed in
        const account = this.publicClientApplication.getActiveAccount();
        if (!account) {
          // redirect anonymous user to login page
          this.publicClientApplication.loginRedirect();
        } else {
          this.setState({
            isAuthenticated: true,
          });
        }
      })
      .catch((err) => {
        // TODO: Handle errors
        console.log(err);
      });
  }

  async adLogout() {
    await this.publicClientApplication
      .handleRedirectPromise()
      .then((authResult) => {
        this.publicClientApplication.logoutRedirect();
        this.setState({
          isAuthenticated: false,
        });
      })
      .catch((err) => {
        // TODO: Handle errors
        console.log(err);
      });
  }

  /**
   * Function to update selected contract from Navigation bar.
   *
   * @param {Object} updatedContract
   */
  updateSelectedContract(updatedContract) {
    let userData = this.state.userData;
    let selectedContract = userData.contracts.find((contract) => contract.id === updatedContract.id);
    let selectedAreas = [];
    userData.areas.forEach((area) => {
      if (area.contractId === selectedContract.id) {
        selectedAreas.push(area);
      }
    });

    userData.securityRoles.canReallocateInProgressRoutes = selectedContract.roles.includes(ROLE.SUPERVISOR) || selectedContract.roles.includes(ROLE.CONTRACT_MANAGER);

    this.setState({
      selectedContract: selectedContract,
      selectedAreas: selectedAreas,
      userData: userData,
    });

    localStorage.setItem('userData', JSON.stringify(userData));
    localStorage.setItem('selectedContract', JSON.stringify(selectedContract));
    localStorage.setItem('selectedAreas', JSON.stringify(selectedAreas));
  }

  updateSelectedNavTab(tab) {
    if (tab === 'dashboard') {
      this.setState({ selectedSubpage: null });
    }

    this.setState({
      selectedTab: tab,
    });

    this.toggleNavSidebar();
  }

  updateSelectedSubpage(page) {
    this.toggleNavSidebar(false);
    this.setState({ selectedSubpage: page });
  }

  /**
   * Function to log out of MiMtr app, clears user information
   */
  logout(noRedirect) {
    this.setState({
      userData: {
        navTabs: [],
      },
      selectedContract: {},
      selectedAreas: [],
      selectedTab: 'dashboard',
      selectedSubpage: '',
      isLoading: false,
      username: null,
    });

    if (!noRedirect) {
      window.location.href = `https://${window.location.host}/hybrid`;
    }

    localStorage.removeItem('selectedEmployeeTimesheet');
    localStorage.removeItem('userData');
    localStorage.removeItem('selectedAreas');
  }

  render() {
    const tabs = this.state.userData.navTabs;
    let currentUrlPath = window.location.pathname.replace('/hybrid/', '');
    const showNav = !['remerge', 'matrix-parts'].includes(currentUrlPath);
    const needLogin = !this.state.isLoading && !this.state.userData.userId;

    const timesheetOnly =
      this.state.selectedContract &&
      this.state.selectedContract.roles &&
      (_.isEqual(this.state.selectedContract.roles, [ROLE.METER_READER]) || this.state.selectedContract.roles.includes(ROLE.TIMESHEET_ONLY));

    if (timesheetOnly && window.location.pathname !== `/hybrid/timesheet/employee`) {
      window.location.href = `https://${window.location.host}/hybrid/timesheet/employee`;
    }

    return (
      <div className='App'>
        {needLogin && <LogIn submitFn={(username, password) => this.submitLogIn(username, password)} errMsg={this.state.loginErrorMsg} />}

        {!needLogin && (
          <Router>
            <div className={`nav-bar ${this.state.selectedSubpage ? 'mob-hide--small' : ''}`}>
              <div className='tabs'>
                <a className={`nav-tab logo ${showNav ? '' : 'disabled'}`} href={'/hybrid'} />

                {/* Desktop Nav Bar */}
                {showNav &&
                  tabs &&
                  tabs.map((tab) => {
                    return (
                      <NavLink
                        to={`/hybrid/${_.kebabCase(tab)}`}
                        className={`nav-tab mob-hide--small mob-hide--mid`}
                        exact={tab === 'dashboard'}
                        activeClassName={'selected-tab'}
                        onClick={() => this.updateSelectedNavTab(tab)}
                        key={tab}>
                        {Utils.humanize(tab)}
                      </NavLink>
                    );
                  })}
                <NavLink to={`/hybrid`} className={`nav-tab ${showNav ? 'mob-hide--small mob-hide--mid' : 'logout'} f--bold`} onClick={() => this.logout()}>
                  Logout
                </NavLink>
              </div>
              <div>
                {!this.state.isLoading && showNav && (
                  <div className='nav-select mob-hide--small'>
                    <label>Contract: </label>
                    <CustomSelect defaultText={this.state.selectedContract.name} optionsList={this.state.userData.contracts} onClick={(id) => this.updateSelectedContract(id)} id='selectedContract' />
                  </div>
                )}

                {/* Mobile Nav Menu */}
                {showNav && (
                  <div className='nav-menu mob-hide--large' onClick={() => this.toggleNavSidebar(true)}>
                    <Icon name='menu' />
                  </div>
                )}
                <div id='nav-sidebar' className='mob-hide--large'>
                  {!this.state.isLoading && showNav && (
                    <div className='nav-select mob-hide--mid'>
                      <label>Contract: </label>
                      <CustomSelect
                        defaultText={this.state.selectedContract.name}
                        optionsList={this.state.userData.contracts}
                        onClick={(id) => this.updateSelectedContract(id)}
                        id='selectedContract-mob'
                      />
                    </div>
                  )}
                  {tabs &&
                    tabs.map((tab) => {
                      return (
                        <NavLink
                          to={`/hybrid/${_.kebabCase(tab)}`}
                          className={`sidebar-tab`}
                          exact={tab === 'dashboard'}
                          activeClassName={'selected-tab'}
                          onClick={(tab) => this.updateSelectedNavTab(tab)}
                          key={tab}>
                          {Utils.humanize(tab)}
                        </NavLink>
                      );
                    })}
                  <NavLink to={`/hybrid`} className={`sidebar-tab f--bold`} onClick={() => this.logout()}>
                    Logout
                  </NavLink>
                </div>
              </div>
            </div>
            <div className='content-body'>
              {!this.state.isLoading && (
                <Switch>
                  <Route path='/hybrid/assignments'>
                    {this.state.userData.securityRoles.canSeeAssignments && (
                      <Assignments userData={this.state.userData} selectedContract={this.state.selectedContract} selectedAreas={this.state.selectedAreas} logoutFn={() => this.logout()} />
                    )}
                  </Route>
                  <Route path='/hybrid/approvals'>
                    {this.state.userData.securityRoles.canSeeApprovals && (
                      <Approvals userData={this.state.userData} selectedContract={this.state.selectedContract} selectedAreas={this.state.selectedAreas} logoutFn={() => this.logout()} />
                    )}
                  </Route>
                  <Route path='/hybrid/audits'>
                    {this.state.userData.securityRoles.canSeeApprovals && (
                      <Audits userData={this.state.userData} selectedContract={this.state.selectedContract} selectedAreas={this.state.selectedAreas} logoutFn={() => this.logout()} />
                    )}
                  </Route>
                  <Route path='/hybrid/export'>
                    <Export userData={this.state.userData} selectedContract={this.state.selectedContract} selectedAreas={this.state.selectedAreas} logoutFn={() => this.logout()} />
                  </Route>
                  <Route path='/hybrid/search'>
                    {this.state.userData.securityRoles.canSeeSearch && (
                      <Search userData={this.state.userData} selectedContract={this.state.selectedContract} selectedAreas={this.state.selectedAreas} logoutFn={() => this.logout()} />
                    )}
                  </Route>
                  <Route path='/hybrid/timesheet/'>
                    <Timesheet userData={this.state.userData} selectedContract={this.state.selectedContract} backFn={() => this.props.updateSelectedSubpage()} logoutFn={() => this.logout()} />
                  </Route>
                  <Route path='/hybrid/quintiq/'>
                    <Quintiq userData={this.state.userData} selectedContract={this.state.selectedContract} logoutFn={() => this.logout()} />
                  </Route>
                  <Route path='/hybrid/employees/'>
                    <Employees userData={this.state.userData} selectedContract={this.state.selectedContract} logoutFn={() => this.logout()} />
                  </Route>
                  <Route path='/hybrid/employee-details/'>
                    <EmployeeDetails userData={this.state.userData} selectedContract={this.state.selectedContract} logoutFn={() => this.logout()} />
                  </Route>
                  <Route path='/hybrid/route-templates/resequence'>
                    <ResequenceRouteTemplate userData={this.state.userData} selectedContract={this.state.selectedContract} logoutFn={() => this.state.logout()} />
                  </Route>
                  <Route path='/hybrid/route-templates/'>
                    <RouteTemplates userData={this.state.userData} selectedContract={this.state.selectedContract} selectedAreas={this.state.selectedAreas} logoutFn={() => this.logout()} />
                  </Route>
                  <Route path='/hybrid/remerge'>
                    <Remerge updateSelectedSubpage={(page) => this.updateSelectedSubpage(page)} logoutFn={() => this.logout()} />
                  </Route>
                  <Route path='/hybrid/matrix-parts'>
                    <MatrixParts updateSelectedSubpage={(page) => this.updateSelectedSubpage(page)} logoutFn={() => this.logout()} />
                  </Route>
                  <Route path='/hybrid/support'>
                    <LogTicket backFn={() => this.props.updateSelectedSubpage()} logoutFn={() => this.logout()} />
                  </Route>
                  <Route path='/hybrid/tempUserUpdate'>
                    <UpdateUserLogon logoutFn={() => this.logout()} />
                  </Route>
                  <Route path='/hybrid/'>
                    <Dashboard
                      userData={this.state.userData}
                      selectedContract={this.state.selectedContract}
                      selectedAreas={this.state.selectedAreas}
                      selectedSubpage={this.state.selectedSubpage}
                      updateSelectedSubpage={(page) => this.updateSelectedSubpage(page)}
                      logoutFn={() => this.logout()}
                    />
                  </Route>
                </Switch>
              )}
            </div>
          </Router>
        )}
      </div>
    );
  }
}

export default App;
