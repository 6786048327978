import React from 'react';
import wave from '../../images/wave2.svg';
import CustomSelect from '../CustomSelect';
import Button from '../Button';
import logo from '../../images/skilltech-logo.png';
import uat_logo from '../../images/skilltech-mimtr-logo-uat-dark.png';

class LogTicket extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      application: null,
      attemptedActions: [],
      hasOccurredBefore: false,
      hasInitialTroubleshooting: false,
    };
  }

  componentDidMount() {
    document.title = `${process.env.REACT_APP_ENV ? `[${process.env.REACT_APP_ENV}] ` : ''}MiMtr Hybrid | Log A Ticket`;

    let checkTokenApiUrl = `${process.env.REACT_APP_API_ENDPOINT}/check`;
    fetch(checkTokenApiUrl).then((res) => {
      if (res.status === 401) {
        this.props.logoutFn();
      } else {
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
      }
    });
  }

  onChangeValue(event) {
    this.setState({
      [event.target.name]: event.target.value === 'true',
    });
  }

  updateField(field, value) {
    this.setState({
      [field]: value,
    });
  }

  updateApplication(value) {
    this.updateField('application', value);

    let checklistItems = [];

    switch (value) {
      case 'MiMtr':
        checklistItems = [
          { id: 1, name: 'Checked files (Only for missing routes)' },
          { id: 2, name: 'Checked client status report (Only for missing routes)' },
        ];
        break;
      case 'MiMtr Cyclic Handheld':
        checklistItems = [
          { id: 1, name: 'Reboot' },
          { id: 2, name: 'Check for reception' },
          { id: 3, name: 'Connect to Wi-Fi' },
          { id: 4, name: 'Uninstall/reinstall MiMtr App (Only for unsynced work)' },
        ];
        break;

      case 'Matrix (Upvise) Handheld':
        checklistItems = [
          { id: 1, name: 'Reboot' },
          { id: 2, name: 'Uninstall/reinstall Upvise' },
          { id: 3, name: 'Clear data/cache' },
        ];
        break;
      default:
        break;
    }

    this.setState({ attemptedActions: checklistItems });
  }

  toggleCheckbox(name, value) {
    let initialValue = this.state[name];
    this.setState({
      [name]: value ? value : !initialValue,
    });
  }

  renderChecklist() {
    let checklistItems = this.state.attemptedActions;

    return (
      <>
        {checklistItems.length > 0 && <label>Please check any of the following actions already attempted:</label>}
        {checklistItems.map((item) => {
          return (
            <div className='checklist-item' key={`checklist-item-${item.id}`}>
              <div className='checkbox-container'>
                <input type='checkbox' id={'checkbox-' + item.id} checked={item.selected} onClick={() => this.selectChecklistItem(item.id)} />
                <label className='checkbox-label' htmlFor={'checkbox-' + item.id} />
              </div>
              <div onClick={() => this.selectChecklistItem(item.id)}>{item.name}</div>
            </div>
          );
        })}
      </>
    );
  }

  selectChecklistItem(id) {
    let checklist = this.state.attemptedActions;
    let selectedItemIndex = checklist.findIndex((item) => item.id === id);
    let selectedItem = checklist[selectedItemIndex];
    selectedItem.selected = !selectedItem.selected;

    this.setState({ attemptedActions: checklist });
  }

  render() {
    return (
      <div className='main-content log-ticket-page'>
        <div className='logo-container' />
        <header>
          <div>
            <h1>Log An Application Issue</h1>
          </div>
          <img className='logo-container' src={process.env.REACT_APP_ENV ? uat_logo : logo} alt='' />
        </header>
        <div className='content-container'>
          <div className='form-container'>
            <section className='sidebar'>
              <div className='form-element'>
                <label>Application</label>
                <CustomSelect
                  defaultText='Select'
                  optionsList={[
                    { id: 1, name: 'MiMtr' },
                    { id: 2, name: 'MiMtr Cyclic Handheld' },
                    { id: 3, name: 'MiMtr Offcycle (Droid)' },
                    { id: 4, name: 'Matrix' },
                    { id: 5, name: 'Matrix (Upvise) Handheld' },
                    { id: 6, name: 'Matrix MRP Handheld' },
                    { id: 7, name: 'MIS' },
                    { id: 0, name: 'Other (specify in Description)' },
                  ]}
                  onClick={(selection) => this.updateApplication(selection.name)}
                  id='application'
                />
              </div>
              {this.state.application && <div className='form-element'>{this.renderChecklist()}</div>}
              <div className='form-element'>
                <label>Has this issue occurred before?</label>
                <div className='input-container' onChange={(e) => this.onChangeValue(e)}>
                  <label>
                    <input type='radio' name='hasOccurredBefore' value={true} />
                    <span>Yes</span>
                  </label>
                  <label>
                    <input type='radio' name='hasOccurredBefore' value={false} />
                    <span>No</span>
                  </label>
                </div>
              </div>
              {this.state.hasOccurredBefore && (
                <>
                  <div className='form-element'>
                    <label>Previous Ticket No.</label>
                    <input type='text' onChange={(e) => {}} />
                  </div>
                  <div className='form-element'>
                    <label>Has initial troubleshooting been completed?</label>
                    <div className='input-container' onChange={(e) => this.onChangeValue(e)}>
                      <label>
                        <input type='radio' name='hasInitialTroubleshooting' value={true} />
                        <span>Yes</span>
                      </label>
                      <label>
                        <input type='radio' name='hasInitialTroubleshooting' value={false} />
                        <span>No</span>
                      </label>
                    </div>
                  </div>
                  {this.state.hasInitialTroubleshooting && (
                    <div className='form-element'>
                      <label>Troubleshooting Result</label>
                      <textarea onChange={(e) => {}} />
                    </div>
                  )}
                </>
              )}
            </section>
            <section className='main-section'>
              <div className='form-row'>
                <div className='form-element'>
                  <label>Contract</label>
                  <CustomSelect
                    defaultText='Select'
                    optionsList={[
                      { id: 1, name: 'ATC' },
                      { id: 2, name: 'Essential Energy' },
                      { id: 3, name: 'Logan City Council' },
                    ]}
                    onClick={() => {}}
                    id='contract'
                  />
                </div>
                <div className='form-element'>
                  <div className='checkbox-container'>
                    <input type='checkbox' id={'urgentAction'} checked={this.state.urgentAction} />
                    <label htmlFor={'urgentAction'} onClick={() => this.toggleCheckbox('urgentAction')} />
                    <span onClick={() => this.toggleCheckbox('urgentAction')}>Urgent Action required</span>
                  </div>
                </div>
              </div>
              <div className='form-element'>
                <label>Description of Problem</label>
                <textarea onChange={(e) => {}} />
              </div>
              <div className='form-element'>
                <label>No. of Users Affected</label>
                <CustomSelect
                  defaultText='Select'
                  optionsList={[
                    { id: 1, name: '1' },
                    { id: 2, name: '< 5' },
                    { id: 3, name: '> 5' },
                    { id: 4, name: '> 25' },
                    { id: 5, name: '> 50' },
                    { id: 6, name: '> 100' },
                  ]}
                  onClick={() => {}}
                  id='users-affected'
                />
              </div>
              <div className='form-element'>
                <label>Screenshots Attached</label>
                <label>
                  <input type='file' />
                </label>
              </div>

              <div className='button-group'>
                <Button classes='button button--blue' text='Submit Ticket' />
              </div>
            </section>
          </div>
        </div>
        <img className='wave' src={wave} alt={''} />
      </div>
    );
  }
}

export default LogTicket;
