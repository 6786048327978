import React from 'react';

class Zoom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundImage: `url(${props.src})`,
      backgroundPosition: '0% 0%',
    };
  }

  handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;
    this.setState({ backgroundPosition: `${x}% ${y}%` });
  };

  render() {
    return (
      <figure onMouseMove={this.handleMouseMove} style={this.state}>
        <div />
        <img src={this.props.src} alt={''} />
      </figure>
    );
  }
}

export default Zoom;
