import React from 'react';
import Icon from './Icon';

class FloatingActionButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showList: false,
    };
  }

  toggleListDisplay(show) {
    let showList = show !== undefined ? show : !this.state.showList;

    this.setState({
      showList: showList,
    });
  }

  clickOption(option) {
    option.onClick();
    this.toggleListDisplay(false);
  }

  render() {
    return (
      <div className={`floating-action-button-container ${!this.state.showList ? 'hidden' : ''}`}>
        <div className={`floating-action-button-overlay`} />
        {this.state.showList && (
          <div className='floating-action-button-list'>
            {this.props.options.map((option) => {
              return (
                <div className='floating-action-button' onClick={() => this.clickOption(option)} key={option.text}>
                  <Icon name={option.icon} />
                  <label>{option.text}</label>
                </div>
              );
            })}
          </div>
        )}
        {
          <div className='floating-action-button floating-action-button--main' onClick={() => this.toggleListDisplay()}>
            <Icon name={this.state.showList ? 'full-cross' : 'full-plus'} />
          </div>
        }
      </div>
    );
  }
}

export default FloatingActionButton;
