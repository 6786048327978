import React from 'react';
import Icon from '../../Icon';

class WorkRouteResult extends React.Component {
  handleClick = (e) => {
    const id = this.props.data.id;
    const readingElem = document.getElementById('workRoute-' + id);

    if (readingElem.contains(e.target)) {
      this.props.selectFn(this.props.data);
    }
  };

  componentDidMount() {
    const element = document.getElementById('workRoute-' + this.props.data.id);
    element.addEventListener('mousedown', this.handleClick);
  }

  componentWillUnmount() {
    // Remove the event listener on component unmounting
    const element = document.getElementById('workRoute-' + this.props.data.id);
    element.removeEventListener('mousedown', this.handleClick);
  }

  render() {
    const data = this.props.data;
    const bulkSelecting = this.props.bulkSelecting;

    return (
      <div className={`result-item work-route-item`}>
        {bulkSelecting && (
          <div className='checkbox-container'>
            <input type='checkbox' id={'checkbox-' + data.id} checked={data.selected} onClick={() => this.props.selectFn()} />
            <label className='checkbox-label' htmlFor={'checkbox-' + data.id} />
          </div>
        )}
        <div id={'workRoute-' + data.id} className={`item-card`}>
          <div className='item-row'>
            {/* Work Route Number */}
            <div className='item-detail f--bold' title='Work Route Number'>
              <span>{data.workRouteNumber}</span>
            </div>
            {/* Route Description */}
            <div className='item-detail' title='Route Description'>
              <Icon name='pin' />
              <span className='u--ellipsis' title={data.name}>
                {data.name}
              </span>
            </div>

            {/* Scheduled Date */}
            <div className='item-detail' title='Scheduled Date'>
              <Icon name='date' />
              <span>{data.scheduledDate.format('DD/MM/YYYY')}</span>
            </div>
            {/* Meters */}
            <div className='item-detail' title='Meters'>
              <Icon name='meter' />
              <span>
                {data.totalMeters} {data.totalMeters === 1 ? 'meter' : 'meters'}
              </span>
            </div>
            {/* Completed */}
            <div className='item-detail' title='Completed Meters'>
              <Icon name='complete' />
              <span>{data.completed} completed</span>
            </div>
            {/* Incomplete */}
            <div className='item-detail' title='Incomplete Meters'>
              <Icon name='incomplete' />
              <span>{data.incomplete} incomplete</span>
            </div>
            {/* Marked For Audit */}
            {data.markedForAudit && (
              <div className='item-detail' title='Marked For Audit'>
                <Icon name='audit' />
                <span className='u--ellipsis' title={'Meters For Audit'}>
                  For Audit
                </span>
              </div>
            )}
            {/* Meters Marked For Audit */}
            {data.metersForAudit && (
              <div className='item-detail' title='Meters Marked For Audit'>
                <Icon name='audit' />
                <span className='u--ellipsis' title={'Marked For Audit'}>
                  {data.metersForAudit} meters for audit
                </span>
              </div>
            )}
          </div>
          <div className='item-row'>
            {/* Status */}
            <div className='item-detail' title='Status'>
              <Icon name='status' />
              <span>{data.status}</span>
            </div>
            {<div />}
            {/* Assigned Reader */}
            <div className='item-detail' title='Assigned Reader'>
              <Icon name='person' />
              <span className='u--ellipsis' title={data.readerName ? data.readerName : 'Unassigned'}>
                {data.readerName ? data.readerName : 'Unassigned'}
              </span>
            </div>
            <div />
            <div />
            {/* Skipped */}
            <div className='item-detail' title='Skipped Meters'>
              <Icon name='skip' />
              <span>{data.skipped} skipped</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WorkRouteResult;
