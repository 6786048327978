import React from 'react';
import logo from '../../images/skilltech-mimtr-logo-dark.png';
import uat_logo from '../../images/skilltech-mimtr-logo-uat-dark.png';
import wave from '../../images/wave.svg';
import DatePicker from '../DatePicker';
import moment from 'moment';
import Icon from '../Icon';
import Modal from '../Modal';
import TimesheetEntryModal from './TimesheetEntryModal';
import TimesheetApproverDetailsModal from './TimesheetApproverDetailsModal';
import _ from 'lodash';
import { ROLE } from '../../constants/UserRole';
import Button from '../Button';
import MobileTimesheet from './MobileTimesheet';
import Utils from '../../utils/Utils';
import { TIMESHEET_STATUS } from '../../constants/TimesheetStatus';

class EmployeeTimesheet extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedContract: props.selectedContract,
      selectedDate: moment().subtract(1, 'days'),
      selectedEmployee: { id: props.userData.userId },
      timesheetData: [],
      rejectedTimesheetData: [],
      returnedTimesheetData: [],
      showModal: false,
      modalType: '',
      popupMessage: null,
      timesheetErrorInfo: '',
      entryType: '',
      selectedEntry: null,
      allowanceCategoryData: [],
      timeEntryCategoryData: [],
      isApproverView: false,
      showHideAddNewTimeEntry: false,
      showHideAddNewAllowance: false,
      showHideSubmit: false,
      isAdminUserView: props.selectedContract.roles.includes(ROLE.TIME_AND_ATTENDANCE_ADMIN),
    };
    this.hideComponent = this.hideComponent.bind(this);
  }

  hideComponent(name, hidden) {
    //    console.log(name);
    switch (name) {
      case 'showHideAddNewTimeEntry':
        this.setState({ showHideAddNewTimeEntry: hidden });
        break;
      case 'showHideAddNewAllowance':
        this.setState({ showHideAddNewAllowance: hidden });
        break;
      case 'showHideSubmit':
        this.setState({ showHideSubmit: hidden });
        break;
      default:
        this.setState({ showHideAddNewTimeEntry: true });
        this.setState({ showHideAddNewAllowance: true });
        this.setState({ showHideSubmit: true });
        break;
    }
  }

  async componentDidMount() {
    let selectedEmployeeTimesheet = JSON.parse(localStorage.getItem('selectedEmployeeTimesheet'));

    let isApproverView = selectedEmployeeTimesheet && parseInt(selectedEmployeeTimesheet.id) !== this.props.userData.userId;
    if (selectedEmployeeTimesheet && isApproverView) {
      document.title = `${process.env.REACT_APP_ENV ? `[${process.env.REACT_APP_ENV}] ` : ''}MiMtr Hybrid | ${Utils.titleize(selectedEmployeeTimesheet.name.replace('.', ' '))}'s Timesheet`;

      this.setState(
        {
          selectedEmployee: {
            id: selectedEmployeeTimesheet.id,
            name: selectedEmployeeTimesheet.name,
          },
          selectedDate: moment(selectedEmployeeTimesheet.date),
          isApproverView: isApproverView,
        },
        () => {
          this.getData();
        }
      );
    } else {
      document.title = `${process.env.REACT_APP_ENV ? `[${process.env.REACT_APP_ENV}] ` : ''}MiMtr Hybrid | My Timesheet`;
      this.getData();
    }
  }

  componentDidUpdate() {
    let timeblocksElem = document.getElementById('timesheet-week-timeblocks');

    if (timeblocksElem) {
      timeblocksElem.scrollTop = 300;
    }
  }

  async getData(date) {
    this.setState({ isLoading: true });
    await this.getTimesheetCodesets('timesheetStatus').then((statusData) => {
      this.getTimesheetCodesets('timeEntryCategory').then((timeEntryCategoryData) => {
        this.getTimesheetCodesets('allowanceCategory').then((allowanceCategoryData) => {
          this.setState({
            statusData: statusData,
            timeEntryCategoryData: timeEntryCategoryData,
            allowanceCategoryData: allowanceCategoryData,
          });

          this.getTimesheet(date).then((timesheetData) => {
            this.setState({
              isLoading: false,
              timesheetData: timesheetData,
            });
          });
        });
      });
    });
  }

  async getTimesheetCodesets(type, employeeId) {
    return new Promise((resolve) => {
      let apiUrl, idStr, nameStr;

      switch (type) {
        case 'timesheetStatus':
          apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getTimesheetStatuses`;
          idStr = 'timesheetStatusId';
          nameStr = 'timesheetStatusName';
          break;
        case 'timeEntryCategory':
          apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getTimeEntryCategories?userId=${employeeId}`;
          idStr = 'timeCategoryId';
          nameStr = 'timeCategoryName';
          break;
        case 'allowanceCategory':
          apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getAllowanceCategories?userId=${employeeId}`;
          idStr = 'allowanceTypeId';
          nameStr = 'allowanceName';
          break;
        default:
          apiUrl = '';
          break;
      }

      fetch(apiUrl)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, {
              method: 'POST',
            });
            return res.json();
          }
        })
        .then((data) => {
          let codesetData = {};
          data.results.forEach((code) => {
            if (type === 'allowanceCategory') {
              codesetData[code[idStr]] = {
                name: code[nameStr],
                unit: code.unitsName,
              };
            } else {
              codesetData[code[idStr]] = code[nameStr];
            }
          });

          resolve(codesetData);
        })
        .catch(() => {});
    });
  }

  getTimesheet(date) {
    date = date ? date : this.state.selectedDate;
    let selectedWeekStart = moment(date).startOf('week');
    let selectedWeekEnd = moment(date).endOf('week');
    let userId = this.state.selectedEmployee.id ? this.state.selectedEmployee.id : this.props.userData.userId;
    let apiUrl = this.state.isApproverView ? `${process.env.REACT_APP_API_ENDPOINT}/getTimesheetApproverView` : `${process.env.REACT_APP_API_ENDPOINT}/getTimesheetEmployeeView`;

    return new Promise((resolve) => {
      fetch(`${apiUrl}?userId=${userId}&dateFrom=${selectedWeekStart.format('YYYY-MM-DD')}&dateTo=${selectedWeekEnd.format('YYYY-MM-DD')}`)
        .then((timesheetRes) => {
          return timesheetRes.json();
        })
        .then((data) => {
          let dayData = _.groupBy(data.results[0], 'readDate');

          let systemDayData;

          if (this.state.isApproverView) {
            systemDayData = _.groupBy(data.results[1], 'readDate');
          } else {
            let returnedTimesheets = data.results[1][0].returned ? data.results[1][0].returned.split('|') : [];
            let rejectedTimesheets = data.results[2][0].rejected ? data.results[2][0].rejected.split('|') : [];

            this.setState({
              returnedTimesheetData: returnedTimesheets,
              rejectedTimesheetData: rejectedTimesheets,
            });
          }

          let timesheetData = [];

          for (let i = 0; i < 7; i++) {
            let timesheetDate = moment(selectedWeekStart).add(i, 'day');
            let timesheet = _.find(dayData, (day, dateString) => {
              return moment(dateString).isSame(timesheetDate, 'day');
            });

            let timesheetByCategory = timesheet ? _.groupBy(timesheet, 'summaryType') : {};
            let routeTime = timesheetByCategory.RouteTimeEvent ? timesheetByCategory.RouteTimeEvent : [];
            let timePeriods = timesheetByCategory.TimePeriod ? timesheetByCategory.TimePeriod : [];
            let timeEntries = routeTime.concat(timePeriods);
            timeEntries = timeEntries.map((timeEntry) => {
              let timeCategory = this.state.timeEntryCategoryData[timeEntry.timeCategoryId];

              return {
                id: timeEntry.sourceId,
                date: moment(timeEntry.readDate),
                category: timeCategory,
                categoryId: timeEntry.timeCategoryId,
                summaryType: timeEntry.summaryType,
                startTime: timeEntry.startTime,
                endTime: timeEntry.endTime,
                comment: timeEntry.comments ? timeEntry.comments.trim() : timeEntry.comments,
                timesheetId: timeEntry.timesheetId,
                totalHours: timeEntry.totalHours,
                totalMinutes: moment.duration(timeEntry.totalHours, 'hours').asMinutes(),
                isReadOnly:
                  (!this.state.isApproverView && [TIMESHEET_STATUS.AMENDED, TIMESHEET_STATUS.AMENDED_BY_PROVISIONAL_APPROVER].includes(timeEntry.timesheetStatusId)) ||
                  [TIMESHEET_STATUS.POSTED].includes(timeEntry.timesheetStatusId),
              };
            });

            let allowances = timesheetByCategory.Allowance ? timesheetByCategory.Allowance : [];

            allowances = allowances.map((allowance) => {
              let category = this.state.allowanceCategoryData[allowance.allowanceTypeId];

              return {
                id: allowance.sourceId,
                categoryId: allowance.allowanceTypeId,
                category: category.name,
                qty: allowance.qty,
                unit: category.unit,
                comment: allowance.comments,
                date: moment(allowance.readDate),
                isReadOnly:
                  (category.editable !== undefined && !category.editable) ||
                  (!this.state.isApproverView && [TIMESHEET_STATUS.AMENDED, TIMESHEET_STATUS.AMENDED_BY_PROVISIONAL_APPROVER].includes(allowance.timesheetStatusId)) ||
                  [TIMESHEET_STATUS.POSTED].includes(allowance.timesheetStatusId),
              };
            });

            const totalMinutes =
              Math.round(
                (_.sumBy(
                  timeEntries.filter((entry) => !['Meal Break', 'Break', 'Automatic Break', 'HH Break'].includes(entry.category)),
                  (entry) => {
                    return entry.totalMinutes;
                  }
                ) +
                  Number.EPSILON) *
                  100000
              ) / 100000;

            const totalHours = Utils.toHoursAndMinutes(totalMinutes);

            let systemTimesheet = _.find(systemDayData, (day, dateString) => {
              return moment(dateString).isSame(timesheetDate, 'day');
            });

            let systemTimeEntries = [];
            let systemTotalMinutes, systemTotalHours;

            if (systemTimesheet) {
              systemTimeEntries = systemTimesheet.map((systemTimeEntry) => {
                let timeCategory = this.state.timeEntryCategoryData[systemTimeEntry.timeCategoryId];

                return {
                  id: systemTimeEntry.sourceId,
                  date: moment(systemTimeEntry.readDate),
                  category: timeCategory,
                  categoryId: systemTimeEntry.timeCategoryId,
                  startTime: systemTimeEntry.startTime,
                  endTime: systemTimeEntry.endTime,
                  comment: systemTimeEntry.comments ? systemTimeEntries.comments.trim() : systemTimeEntries.comments,
                  timesheetId: systemTimeEntry.timesheetId,
                  totalHours: systemTimeEntry.totalHours,
                  totalMinutes: moment.duration(systemTimeEntry.totalHours, 'hours').asMinutes(),
                  isReadOnly: true,
                };
              });

              systemTotalMinutes =
                Math.round(
                  (_.sumBy(
                    systemTimeEntries.filter((entry) => entry.category === 'Productive (System)'),
                    (entry) => {
                      return entry.totalMinutes;
                    }
                  ) +
                    Number.EPSILON) *
                    100000
                ) / 100000;

              systemTotalHours = Utils.toHoursAndMinutes(systemTotalMinutes);
            }

            let timesheetStatusEntry = timesheet.filter((entry) => {
              return entry.timesheetStatusId !== null;
            })[0];
            let status = timesheet && timesheetStatusEntry ? this.state.statusData[timesheetStatusEntry.timesheetStatusId] : '';
            let displayDraft = !this.state.isApproverView || status !== 'Draft';

            timesheetData.push({
              id: timesheet ? timesheet[0].timesheetId : '',
              date: timesheetDate,
              totalHours: displayDraft && timeEntries.length > 0 ? totalHours : null,
              entries: displayDraft ? timeEntries : [],
              allowances: displayDraft ? allowances : [],
              systemEntries: systemTimeEntries,
              systemTotalHours: systemTotalHours,
              status: displayDraft ? status : '',
              statusId: timesheet && timesheetStatusEntry ? timesheetStatusEntry.timesheetStatusId : null,
              approverName: timesheet && timesheetStatusEntry && timesheetStatusEntry.approverUsername ? Utils.titleize(timesheetStatusEntry.approverUsername.replace('.', ' ')) : null,
              rejectionReason: timesheet && timesheetStatusEntry ? timesheetStatusEntry.rejectionReason : null,
            });
          }

          resolve(timesheetData);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  changeDate(date) {
    if (moment().isSameOrBefore(date, 'day')) return;

    let startOfWeek = moment(date).startOf('week');
    var startdate = moment();
    startdate = startdate.subtract(1, 'days');
    if (startdate.isAfter(startOfWeek)) {
      this.setState({
        selectedDate: date,
      });

      let selectedTimesheetStartIndex = this.state.timesheetData.findIndex((timesheet) => timesheet.date.isSame(moment(date).startOf('week'), 'day'));

      if (selectedTimesheetStartIndex < 0) {
        this.setState({
          isLoading: true,
        });

        this.getTimesheet(date).then((timesheetData) => {
          this.setState({
            isLoading: false,
            timesheetData: timesheetData,
          });
        });
      }
      this.hideComponent('showHideAddNewTimeEntry', true);
      this.hideComponent('showHideAddNewAllowance', true);
      this.hideComponent('showHideSubmit', true);
    } else {
      this.hideComponent('showHideAddNewTimeEntry', false);
      this.hideComponent('showHideAddNewAllowance', false);
      this.hideComponent('showHideSubmit', false);
    }

    this.setState({ timesheetErrorInfo: '' });
  }

  selectEntry(type, entry) {
    this.setState({
      selectedEntry: entry,
      selectedDate: entry.date,
    });

    this.clearTimesheetErrorInfo();
    this.showModal(type, true);
  }

  selectDateFromNotification(date) {
    this.getTimesheet(moment(date)).then((timesheetData) => {
      this.setState({
        selectedDate: moment(date),
        timesheetData: timesheetData,
      });
    });

    this.showModal('approverDetails', true);
  }

  showModal(type, show) {
    const showModal = show !== undefined ? show : !this.state.showModal;

    this.setState({
      showModal: showModal,
      modalType: type === 'popup' ? 'popup' : null,
      entryType: type !== 'popup' ? type : null,
    });

    if (!showModal) {
      this.setState({
        selectedEntry: null,
        newStatusId: null,
      });
    }
  }

  calculateTotalHours(startTime, endTime) {
    if (startTime && endTime) {
      startTime = moment(startTime, 'hh:mm:ss');
      endTime = moment(endTime, 'HH:mm:ss');

      let totalHours = moment.duration(endTime.diff(startTime));

      var hours = parseInt(totalHours.asHours());
      var minutes = parseInt(totalHours.asMinutes()) % 60;

      hours = hours.toString().length < 2 ? '0' + hours : hours;

      if (minutes < 0) {
        minutes = Math.abs(minutes);
        hours = parseInt(totalHours.asHours()) < 0 ? hours : '-' + hours;
      }

      minutes = minutes.toString().length < 2 ? '0' + minutes : minutes;

      return hours + ':' + minutes;
    } else {
      return '—';
    }
  }

  saveTimesheetEntry(type, operation, data) {
    return new Promise((resolve, reject) => {
      this.getTimesheet().then((timesheetData) => {
        let timesheet = timesheetData.find((timesheet) => moment(data.date).isSame(timesheet.date, 'day'));

        if (
          (this.state.isApproverView && [TIMESHEET_STATUS.APPROVED, TIMESHEET_STATUS.RETURNED].includes(timesheet.statusId)) ||
          (!this.state.isApproverView &&
            [
              TIMESHEET_STATUS.PENDING_APPROVAL,
              TIMESHEET_STATUS.APPROVED,
              TIMESHEET_STATUS.AMENDED,
              TIMESHEET_STATUS.PROVISIONALLY_APPROVED,
              TIMESHEET_STATUS.AMENDED_BY_PROVISIONAL_APPROVER,
            ].includes(timesheet.statusId))
        ) {
          this.showModal('popup', true);
          let popupMessage = 'Cannot save new entry. The timesheet has already been submitted for approval.';

          this.setState({
            popupMessage: popupMessage,
            timesheetData: timesheetData,
          });

          resolve(popupMessage);
          return;
        }

        data.timesheetId = timesheet ? timesheet.id : null;
        data.userId = this.state.selectedEmployee.id;
        data.date = moment(data.date).format('YYYY-MM-DD');

        const inputDataFormat = 'HH:mm:ss';

        // If deleting or not a time entry, no need to do time logic
        if (operation === 'delete' || type === 'allowance') {
          this.sendTimesheetEntryData(data, operation, type, timesheet).then((data) => {
            if (data.message === 'PASS') {
              this.showModal(null, false);

              this.getTimesheet().then((timesheetData) => {
                this.setState({
                  timesheetData: timesheetData,
                });
              });
            }
          });

          resolve();
        } else {
          const promises = [];

          let newStartTime = moment(data.startTime, inputDataFormat);
          let newEndTime = moment(data.endTime, inputDataFormat);
          let cont = true;

          timesheet.entries.forEach((timeEntry) => {
            if (timeEntry.summaryType !== 'RouteTimeEvent' && timeEntry.id !== data.id) {
              let startTime = moment(timeEntry.startTime, inputDataFormat);
              let endTime = moment(timeEntry.endTime, inputDataFormat);
              let ttype = timeEntry.categoryId;

              console.log(timeEntry.category); //Productive (System)

              if (newStartTime.isSameOrBefore(startTime) && newEndTime.isSameOrAfter(endTime)) {
                // If new entry overlaps an existing entry,
                // Delete existing time entry
                if ((+data.categoryId === 5 && ttype === 19) || (ttype !== 19 && ttype !== 28)) {
                  //1066: Allow Productive time to be overwritten with Meal Break ONLY
                  console.log('adding to database here in overlap');
                  promises.push(this.sendTimesheetEntryData(timeEntry, 'delete', type));
                } else {
                  console.log('Cannot Edit System Generated Productive Time! Overlap before or after');
                  cont = false;
                  this.setState({
                    timesheetErrorInfo: 'Cannot overlap an existing system timeblock.',
                  });
                }
              } else if ((newStartTime.isSameOrBefore(startTime) && newEndTime.isSameOrBefore(startTime)) || newStartTime.isSameOrAfter(endTime)) {
                // If new entry does not clash with an existing entry,
                // Do nothing
              } else if (newStartTime.isAfter(startTime) && newEndTime.isBefore(endTime)) {
                let splitEntry = { ...timeEntry };
                // If new entry is in between an existing entry, split the existing entry
                // Edit existing entry endTime
                timeEntry.endTime = newStartTime.format('HH:mm');
                if ((+data.categoryId === 5 && ttype === 19) || (ttype !== 19 && ttype !== 28)) {
                  //1066: Allow Productive time to be overwritten with Meal Break ONLY
                  console.log('adding to database here in split');
                  promises.push(this.sendTimesheetEntryData(timeEntry, 'update', type));

                  // Add new entry, copy of existing entry, with new start time
                  splitEntry.startTime = newEndTime.format('HH:mm');
                  promises.push(this.sendTimesheetEntryData(splitEntry, 'add', type));
                } else {
                  console.log('Cannot Edit System Generated Productive Time! split entries');
                  cont = false;
                  this.setState({
                    timesheetErrorInfo: 'Cannot overlap an existing system timeblock.',
                  });
                }
              } else if (newStartTime.isSameOrBefore(startTime) && newEndTime.isBefore(endTime)) {
                // If new entry ends in the middle of an existing entry,
                // Edit existing entry startTime
                timeEntry.startTime = newEndTime.format('HH:mm');
                if ((+data.categoryId === 5 && ttype === 19) || (ttype !== 19 && ttype !== 28)) {
                  //1066: Allow Productive time to be overwritten with Meal Break ONLY
                  console.log('adding to database here ends in middle');
                  promises.push(this.sendTimesheetEntryData(timeEntry, 'update', type));
                } else {
                  console.log('Cannot Edit System Generated Productive Time! end in middle');
                  cont = false;
                  this.setState({
                    timesheetErrorInfo: 'Cannot overlap an existing system timeblock.',
                  });
                }
              } else if (newStartTime.isAfter(startTime) && newStartTime.isBefore(endTime)) {
                // If new entry starts in the middle of an existing entry,
                // Edit existing entry endTime
                timeEntry.endTime = newStartTime.format('HH:mm');
                if ((+data.categoryId === 5 && ttype === 19) || (ttype !== 19 && ttype !== 28)) {
                  //1066: Allow Productive time to be overwritten with Meal Break ONLY
                  console.log('adding to database here in starts in middle');
                  promises.push(this.sendTimesheetEntryData(timeEntry, 'update', type));
                } else {
                  console.log('Cannot Edit System Generated Productive Time! starts in middle of current');

                  cont = false;
                  this.setState({
                    timesheetErrorInfo: 'Cannot overlap an existing system timeblock.',
                  });
                }
              }
            }
          });

          Promise.all(promises).then(() => {
            // Submit new entry
            if (cont === false) {
              this.getTimesheet().then((timesheetData) => {
                this.setState({
                  timesheetData: timesheetData,
                });
              });

              reject('cannot submit an new time entry');
            } else {
              this.sendTimesheetEntryData(data, operation, type, timesheet).then((data) => {
                if (data.message === 'PASS') {
                  this.showModal(null, false);

                  this.getTimesheet().then((timesheetData) => {
                    this.setState({
                      timesheetData: timesheetData,
                    });
                  });
                }
              });

              resolve();
            }
          });
        }
      });
    });
  }

  sendTimesheetEntryData(data, operation, type, timesheet) {
    data.userId = this.state.selectedEmployee.id;

    return new Promise((resolve, reject) => {
      let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/${operation}${type.charAt(0).toUpperCase() + type.slice(1)}`;

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      };

      fetch(apiUrl, requestOptions)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, {
              method: 'POST',
            });
            return res.json();
          }
        })
        .then((res) => {
          if (res.message === 'PASS') {
            if (timesheet && timesheet.statusId) {
              // If the timesheet has been edited by an approver, change the status to amended
              if (this.props.userData.userId !== data.userId && ![TIMESHEET_STATUS.DRAFT, TIMESHEET_STATUS.POSTED].includes(timesheet.statusId)) {
                let isProvisionalApprover = this.state.selectedContract.roles.includes(ROLE.PROVISIONAL_APPROVER);
                this.updateTimesheetStatus(isProvisionalApprover ? TIMESHEET_STATUS.AMENDED_BY_PROVISIONAL_APPROVER : TIMESHEET_STATUS.AMENDED, timesheet);
              } else if (
                this.props.userData.userId === data.userId &&
                [TIMESHEET_STATUS.REJECTED, TIMESHEET_STATUS.RETURNED, TIMESHEET_STATUS.RETURNED_BY_PROVISIONAL_APPROVER].includes(timesheet.statusId)
              ) {
                this.updateTimesheetStatus(TIMESHEET_STATUS.DRAFT, timesheet);
              }
            }
            resolve(res);
          }
        });
    });
  }

  submitTimesheet() {
    let selectedTimesheet = this.state.timesheetData.find((timesheet) => timesheet.date.isSame(this.state.selectedDate, 'day'));
    let data = { timesheetId: selectedTimesheet.id };
    let popupMessage = null;

    return new Promise((resolve, reject) => {
      if (_.isEmpty(selectedTimesheet.allowances) && _.isEmpty(selectedTimesheet.entries)) {
        popupMessage = 'The timesheet has no entries to submit.';

        this.showModal('popup', true);
        this.setState({
          popupMessage: popupMessage,
        });

        resolve(popupMessage);
        return;
      }

      let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/submitTimesheet`;

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      };

      fetch(apiUrl, requestOptions)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, {
              method: 'POST',
            });
            return res.json();
          }
        })
        .then((data) => {
          if (data.message === 'PASS') {
            this.getTimesheet().then((timesheetData) => {
              this.setState({
                timesheetData: timesheetData,
              });
              resolve(data.message);
            });
          } else {
            popupMessage = 'The timesheet has already been approved and cannot be resubmitted.';
            this.showModal('popup', true);
            this.setState({
              popupMessage: popupMessage,
            });

            resolve(popupMessage);
          }
        });
    });
  }

  rejectOrReturnTimesheet(status) {
    this.setState({
      newStatusId: status,
    });

    this.showModal('approverDetails', true);
  }

  updateTimesheetStatus(status, selectedTimesheet) {
    return new Promise((resolve) => {
      let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/updateReaderTimesheets`;
      let timesheetData = this.state.timesheetData;
      selectedTimesheet = selectedTimesheet ? selectedTimesheet : timesheetData.find((timesheet) => timesheet.date.isSame(this.state.selectedDate, 'day'));

      let body = {
        timesheetId: selectedTimesheet.id,
        timesheetIds: [selectedTimesheet.id],
        approverId: this.props.userData.userId,
        timesheetStatusId: status,
        rejectionReason: selectedTimesheet.rejectionReason,
      };

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      };

      fetch(apiUrl, requestOptions)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, {
              method: 'POST',
            });
            return res.json();
          }
        })
        .then((data) => {
          if (data.message === 'PASS') {
            this.getTimesheet(this.state.selectedDate).then((timesheetData) => {
              this.setState({
                timesheetData: timesheetData,
              });

              this.showModal(null, false);
            });
          }
          resolve(data.message);
        });
    });
  }

  checkdate(date) {
    //this.state.selectedDate.isAfter(day.date, 'day')
    if (moment().isAfter(date)) {
      this.changeDate(date);
    }
  }

  clearTimesheetErrorInfo() {
    this.setState({
      timesheetErrorInfo: '',
    });
  }

  render() {
    const { showHideAddNewTimeEntry, showHideAddNewAllowance, showHideSubmit } = this.state;
    let selectedDate = this.state.selectedDate;
    let selectedWeekStart = moment(selectedDate).startOf('week');
    let selectedWeekEnd = moment(selectedDate).endOf('week');
    let selectedTimesheetStartIndex = this.state.timesheetData.findIndex((timesheet) => timesheet.date.isSame(selectedWeekStart, 'day'));

    let selectedDateTimesheet;
    let timesheetWeek = [];
    if (selectedTimesheetStartIndex >= 0) {
      for (let i = 0; i < 7; i++) {
        timesheetWeek.push(this.state.timesheetData[selectedTimesheetStartIndex + i]);
      }
      selectedDateTimesheet = timesheetWeek.find((timesheet) => timesheet.date.isSame(selectedDate, 'day'));
    }

    let timesheetName = this.state.selectedEmployee.name ? `${Utils.titleize(this.state.selectedEmployee.name.replace('.', ' '))}'s` : 'My';

    let isDraftTimesheet = selectedDateTimesheet && (selectedDateTimesheet.statusId === TIMESHEET_STATUS.DRAFT || selectedDateTimesheet.statusId === null);
    let isPendingApproval = selectedDateTimesheet && selectedDateTimesheet.statusId === TIMESHEET_STATUS.PENDING_APPROVAL;
    let isAmended = selectedDateTimesheet && [TIMESHEET_STATUS.AMENDED, TIMESHEET_STATUS.AMENDED_BY_PROVISIONAL_APPROVER].includes(selectedDateTimesheet.statusId);
    let isReturned = selectedDateTimesheet && [TIMESHEET_STATUS.RETURNED, TIMESHEET_STATUS.RETURNED_BY_PROVISIONAL_APPROVER].includes(selectedDateTimesheet.statusId);
    let isApproved = selectedDateTimesheet && [TIMESHEET_STATUS.APPROVED, TIMESHEET_STATUS.PROVISIONALLY_APPROVED].includes(selectedDateTimesheet.statusId);
    let isProvisionallyApproved = selectedDateTimesheet && TIMESHEET_STATUS.PROVISIONALLY_APPROVED === selectedDateTimesheet.statusId;
    let isProvisionallyRejected = selectedDateTimesheet && TIMESHEET_STATUS.REJECTED_TO_PROVISIONAL_APPROVER === selectedDateTimesheet.statusId;
    let isPosted = selectedDateTimesheet && TIMESHEET_STATUS.POSTED === selectedDateTimesheet.statusId;

    let isProvisionalApprover = this.state.selectedContract.roles.includes(ROLE.PROVISIONAL_APPROVER);
    let isMasterApprover = this.state.selectedContract.roles.includes(ROLE.MASTER_APPROVER);
    //    let admin = contract.roles.includes(ROLE.TIME_AND_ATTENDANCE_ADMIN)

    let hourBlocks = [
      '12am',
      '1am',
      '2am',
      '3am',
      '4am',
      '5am',
      '6am',
      '7am',
      '8am',
      '9am',
      '10am',
      '11am',
      '12pm',
      '1pm',
      '2pm',
      '3pm',
      '4pm',
      '5pm',
      '6pm',
      '7pm',
      '8pm',
      '9pm',
      '10pm',
      '11pm',
      '12am (Next Day)',
    ];

    return (
      <div className={'main-content timesheet-page'}>
        {/* Browser View */}
        <div className='mob-hide--small'>
          <header>
            <div>
              <h1>{timesheetName} Timesheet</h1>
            </div>
            <img className='logo-container' src={process.env.REACT_APP_ENV ? uat_logo : logo} alt='' />
          </header>
          {!this.state.isLoading && (
            <div className={`content-container ${this.state.isApproverView ? `content-container--approver-view` : ''}`}>
              {/* Sidebar */}
              <div className={'content-sidebar'}>
                <DatePicker id={'timesheet'} onClick={(data) => this.changeDate(data)} selectedDate={selectedDate} selectedWeek={true} />
                <div className={'sidebar-actions'}>
                  {!isPosted && ((!this.state.isApproverView && !isAmended && !isPendingApproval) || (this.state.isApproverView && !isDraftTimesheet && !isApproved)) && (
                    <div>
                      {showHideAddNewTimeEntry && <Button classes={('showHideAddNewTimeEntry', 'button button--plain')} onClick={() => this.showModal('timeEntry')} text={'+ Add New Time Entry'} />}
                      {showHideAddNewAllowance && <Button classes={('showHideAddNewAllowance', 'button button--plain')} onClick={() => this.showModal('allowance')} text={'+ Add New Allowance'} />}
                    </div>
                  )}
                  {!this.state.isApproverView && (
                    <div>
                      {isDraftTimesheet && showHideAddNewAllowance && (
                        <Button classes={('showHideSubmit', 'button button--blue button--med')} text={`Submit Timesheet for ${selectedDate.format('DD/MM')}`} onClick={() => this.submitTimesheet()} />
                      )}
                      {isReturned && (
                        <Button
                          classes={'button button--green button--med'}
                          text={`Accept Timesheet for ${selectedDate.format('DD/MM')}`}
                          onClick={() => this.updateTimesheetStatus(TIMESHEET_STATUS.APPROVED)}
                        />
                      )}
                    </div>
                  )}
                  {this.state.isApproverView && (
                    <div>
                      {((isPendingApproval && !isProvisionalApprover) || (isProvisionallyApproved && isMasterApprover)) && (
                        <Button
                          classes={'button button--green button--med'}
                          text={`Approve Timesheet for ${selectedDate.format('DD/MM')}`}
                          onClick={() => this.updateTimesheetStatus(TIMESHEET_STATUS.APPROVED)}
                        />
                      )}
                      {isPendingApproval && isProvisionalApprover && (
                        <Button
                          classes={'button button--green button--med'}
                          text={`Provisionally Approve Timesheet for ${selectedDate.format('DD/MM')}`}
                          onClick={() => this.updateTimesheetStatus(TIMESHEET_STATUS.PROVISIONALLY_APPROVED)}
                        />
                      )}
                      {((isPendingApproval && !isProvisionalApprover) || (isProvisionallyRejected && isMasterApprover)) && (
                        <Button
                          classes={'button button--orange button--med'}
                          text={`Reject Timesheet for ${selectedDate.format('DD/MM')}`}
                          onClick={() => this.rejectOrReturnTimesheet(TIMESHEET_STATUS.REJECTED, selectedDateTimesheet)}
                        />
                      )}

                      {((isApproved && this.state.isAdminUserView) ||
                        (isProvisionallyApproved && this.state.isAdminUserView) ||
                        (isApproved && isMasterApprover) ||
                        (isProvisionallyApproved && isMasterApprover)) && (
                        <Button
                          classes={'button button--orange button--med'}
                          text={`UnApprove Timesheet for ${selectedDate.format('DD/MM')}`}
                          onClick={() => this.updateTimesheetStatus(TIMESHEET_STATUS.PENDING_APPROVAL, selectedDateTimesheet)}
                        />
                      )}

                      {isPendingApproval && isProvisionalApprover && (
                        <Button
                          classes={'button button--orange button--med'}
                          text={`Provisionally Reject Timesheet for ${selectedDate.format('DD/MM')}`}
                          onClick={() => this.rejectOrReturnTimesheet(TIMESHEET_STATUS.REJECTED_TO_PROVISIONAL_APPROVER, selectedDateTimesheet)}
                        />
                      )}
                      {isAmended && !isProvisionalApprover && (
                        <Button
                          classes={'button button--blue button--med'}
                          text={`Return Timesheet for ${selectedDate.format('DD/MM')}`}
                          onClick={() => this.rejectOrReturnTimesheet(TIMESHEET_STATUS.RETURNED)}
                        />
                      )}
                      {isAmended && isProvisionalApprover && (
                        <Button
                          classes={'button button--blue button--med'}
                          text={`Return Timesheet for ${selectedDate.format('DD/MM')}`}
                          onClick={() => this.rejectOrReturnTimesheet(TIMESHEET_STATUS.RETURNED_BY_PROVISIONAL_APPROVER)}
                        />
                      )}
                    </div>
                  )}
                </div>

                {/* Sidebar Notification */}
                {!this.state.isApproverView && (this.state.rejectedTimesheetData.length > 0 || this.state.returnedTimesheetData.length > 0) && (
                  <div className={'sidebar-notification notification--warning'}>
                    <h4>
                      <Icon name={'bell'} /> Alert:
                    </h4>
                    {this.state.rejectedTimesheetData.length > 0 && (
                      <section>
                        <p>Your timesheets have been rejected for the following dates:</p>
                        {this.state.rejectedTimesheetData.map((timesheetDate) => {
                          return (
                            <div className={'f--link'} key={timesheetDate} onClick={() => this.selectDateFromNotification(timesheetDate)}>
                              {moment(timesheetDate).format('DD/MM/YY')}
                            </div>
                          );
                        })}
                      </section>
                    )}

                    {this.state.returnedTimesheetData.length > 0 && (
                      <section>
                        <p>Your timesheets have been returned with amendments for the following dates:</p>
                        {this.state.returnedTimesheetData.map((timesheetDate) => {
                          return (
                            <div className={'f--link'} key={timesheetDate} onClick={() => this.selectDateFromNotification(timesheetDate)}>
                              {moment(timesheetDate).format('DD/MM/YY')}
                            </div>
                          );
                        })}
                      </section>
                    )}
                    <p>Please review and speak to your supervisor.</p>
                  </div>
                )}
              </div>
              <div />
              {/* Week Timeblocks */}
              <div>
                <h3>{`${selectedWeekStart.format('MMMM')} ${selectedWeekStart.format('D')} - ${selectedWeekEnd.format('MMMM')} ${selectedWeekEnd.format('D')}, ${selectedDate.format('YYYY')}`}</h3>
                {/*  
        //bug here if selecting a sunday, and clicking back a few weeks, the forward button then doesnt change the whole weeks dates or data just the sunday date changes.
        <div className="timesheet-week-summary--prev-button" onClick={() => this.changeDate(selectedDate.subtract(1, 'week'))}>
                            <Icon name="chevron"/>
                        </div>
                        <div className="timesheet-week-summary--next-button" onClick={() => this.changeDate(selectedDate.add(1, 'week'))}>
                            <Icon name="chevron"/>
                        </div>
        */}
                <div className='timesheet-week-summary'>
                  {timesheetWeek.map((day) => {
                    return (
                      <div
                        className={`${this.state.selectedDate.isSame(day.date, 'day') ? 'selected' : ''} ${moment().isSameOrBefore(day.date) ? 'disabled' : ''}`}
                        // return <div className={`${this.state.selectedDate.isSame(day.date, 'day') ? 'selected' : ''} ${moment().isBefore(day.date) ? 'disabled' : ''}`}
                        // return <div className={`${this.state.selectedDate.isAfter(day.date, 'day') ? 'selected' : ''} ${moment().isSameOrBefore(day.date) ? 'disabled' : ''}`}
                        // onClick={() => this.checkdate(day.date)}
                        onClick={() => this.changeDate(day.date)}
                        //onClick={${moment().isAfter(day.date) ? this.changeDate(day.date) : null}
                        key={moment(day.date).format('dddd')}>
                        <div className='timesheet-week-summary--date'>{day.date.format('D')}</div>
                        <div className='timesheet-week-summary--day'>{moment(day.date).format('dddd')}</div>
                        <div className={`timesheet-week-summary--value`}>
                          <span className={`${day.totalHours || moment().isBefore(day.date) ? '' : 'warning'}`}>{day.totalHours || moment().isBefore(day.date) ? day.totalHours : '—'}</span>
                          {this.state.isApproverView ? '/' : ''}
                          {this.state.isApproverView && (
                            <span className={`system ${day.systemTotalHours || moment().isBefore(day.date) ? '' : 'warning'}`}>
                              {day.systemTotalHours || moment().isBefore(day.date) ? day.systemTotalHours : '—'}
                            </span>
                          )}
                          <div className={`timesheet-week-summary--status ${day.status}`} onClick={() => this.showModal('approverDetails')}>
                            {day.status}{' '}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div id='timesheet-week-timeblocks' className={`timesheet-week-timeblocks ${this.state.isApproverView ? 'timesheet-week-timeblocks--approver-view' : ''}`}>
                  <div className='timesheet-hourblocks'>
                    {hourBlocks.map((hour) => {
                      return (
                        <div className='hourblock' key={hour}>
                          <label>{hour}</label>
                          <div />
                        </div>
                      );
                    })}
                  </div>
                  <div className='timesheet-days'>
                    {timesheetWeek.map((day) => {
                      return (
                        <div className={`timesheet-day`} key={moment(day.date).format('dddd')}>
                          {day.entries.map((timeEntry, index) => {
                            // Calculate time entry top positioning based on 6am to entry start time and height based on total minutes
                            let top = parseFloat(timeEntry.startTime.replace(':', '.')).toFixed(2).toString().replace('.', ':');
                            top = moment.duration(top).asMinutes() * 0.75;
                            let style = {
                              height: `${timeEntry.totalMinutes * 0.75}px`,
                              top: `${top}px`,
                            };
                            return (
                              <div
                                className={`timesheet-entry ${timeEntry.isReadOnly ? 'read-only' : ''} ${timeEntry.category === 'Automatic Break' ? 'break' : ''}`}
                                style={style}
                                title={`${timeEntry.category} (${timeEntry.startTime} - ${timeEntry.endTime}) ${timeEntry.comment ? `- ${timeEntry.comment}` : ''}`}
                                onClick={timeEntry.category === 'Productive (System)' ? () => {} : () => this.selectEntry('timeEntry', timeEntry)}
                                //onClick={timeEntry.isReadOnly ? () => {} : () => this.selectEntry('timeEntry', timeEntry)}

                                key={`${index}-${timeEntry.id}`}>
                                <div className='timesheet-entry--content'>
                                  <div>
                                    <label className={'u--ellipsis'}>{timeEntry.category}</label>
                                    <div className='timesheet-entry--detail'>
                                      <div>
                                        {timeEntry.startTime} - {timeEntry.endTime}
                                      </div>
                                      <label>{timeEntry.totalHours}</label>
                                    </div>
                                    <div className='timesheet-entry--comment'>
                                      <div className='u--multiline-ellipsis'>{timeEntry.comment}</div>
                                    </div>
                                  </div>
                                  {timeEntry.category !== 'Productive (System)' ? <Icon name='edit' /> : <Icon name='check' />}
                                </div>
                              </div>
                            );
                          })}
                          {day.systemEntries.map((systemTimeEntry, index) => {
                            // Calculate time entry top positioning based on 6am to entry start time and height based on total minutes
                            let top = parseFloat(systemTimeEntry.startTime.replace(':', '.')).toFixed(2).toString().replace('.', ':');
                            top = moment.duration(top).asMinutes() * 0.75;
                            let style = {
                              height: `${systemTimeEntry.totalMinutes * 0.75}px`,
                              top: `${top}px`,
                            };
                            return (
                              <div
                                className={`timesheet-entry timesheet-entry--system ${systemTimeEntry.isReadOnly ? 'read-only' : ''}`}
                                style={style}
                                title={`${systemTimeEntry.category} (${systemTimeEntry.startTime} - ${systemTimeEntry.endTime}) ${systemTimeEntry.comment ? `- ${systemTimeEntry.comment}` : ''}`}
                                // onClick={systemTimeEntry.isReadOnly ? () => {} : () => this.selectEntry('systemTimeEntry', systemTimeEntry)}
                                onClick={systemTimeEntry.category === 'Productive (System)' ? () => {} : () => this.selectEntry('systemTimeEntry', systemTimeEntry)}
                                key={`${index}-${systemTimeEntry.id}`}>
                                <div className='timesheet-entry--content'>
                                  <div>
                                    <label>{systemTimeEntry.category}</label>
                                    <div className='timesheet-entry--detail'>
                                      <div>
                                        {systemTimeEntry.startTime} - {systemTimeEntry.endTime}
                                      </div>
                                      <label>{systemTimeEntry.totalHours}</label>
                                    </div>
                                    <div className='timesheet-entry--comment'>
                                      <div className='u--multiline-ellipsis'>{systemTimeEntry.comment}</div>
                                    </div>
                                  </div>
                                  {systemTimeEntry.category !== 'Productive (System)' ? <Icon name='edit' /> : <Icon name='check' />}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className='timesheet-week-allowances'>
                  <div>
                    <label>Allowances</label>
                  </div>
                  <div className='timesheet-days'>
                    {timesheetWeek.map((day) => {
                      return (
                        <div className={`timesheet-day`} key={moment(day.date).format('dddd')}>
                          {day.allowances.map((allowance, index) => {
                            return (
                              <div
                                className={`timesheet-entry ${allowance.isReadOnly ? 'read-only' : ''}`}
                                title={`${allowance.category} (${allowance.qty} ${allowance.unit}) ${allowance.comment ? `- ${allowance.comment}` : ''}`}
                                onClick={allowance.isReadOnly ? () => {} : () => this.selectEntry('allowance', allowance)}
                                key={index}>
                                <div className='timesheet-entry--content'>
                                  <div>
                                    <label>{allowance.category}</label>
                                    <div className='timesheet-entry--detail'>
                                      <div>
                                        {allowance.qty} {allowance.unit}
                                      </div>
                                    </div>
                                    <div className='timesheet-entry--comment'>
                                      <div className='u--ellipsis'>{allowance.comment}</div>
                                    </div>
                                  </div>
                                  <Icon name='edit' />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
          <img className='wave' src={wave} alt={''} />
          {!this.state.isLoading && selectedDateTimesheet && this.state.showModal && (
            <Modal showModalFn={(show) => this.showModal(show)} showModal={this.state.showModal}>
              {this.state.modalType !== 'popup' && this.state.entryType !== 'approverDetails' && (
                <>
                  <TimesheetEntryModal
                    type={this.state.entryType}
                    showModalFn={(show) => this.showModal(show)}
                    showModal={this.state.showModal}
                    data={{
                      selectedDate: selectedDate,
                      selectedEntry: this.state.selectedEntry,
                      getCategoriesFn: (type) => this.getTimesheetCodesets(type, this.state.selectedEmployee.id),
                      allowanceCategories: this.state.allowanceCategoryData,
                      calculateTotalHoursFn: (startTime, endTime) => this.calculateTotalHours(startTime, endTime),
                    }}
                    onClick={(operation, data) => this.saveTimesheetEntry(this.state.entryType, operation, data)}
                    timesheetErrorInfo={this.state.timesheetErrorInfo}
                  />
                </>
              )}
              {this.state.modalType !== 'popup' && this.state.entryType === 'approverDetails' && (
                <TimesheetApproverDetailsModal
                  showModalFn={(show) => this.showModal(show)}
                  showModal={this.state.showModal}
                  data={{
                    timesheet: selectedDateTimesheet,
                    newStatusId: this.state.newStatusId,
                  }}
                  onClick={(status, timesheet) => this.updateTimesheetStatus(status, timesheet)}
                />
              )}
              {this.state.modalType === 'popup' && (
                <div id={'popup-modal'} className='modal-content'>
                  <div className='heading f--warning'>Cannot Submit Timesheet</div>
                  <div className='form-container'>
                    <p>{this.state.popupMessage}</p>
                  </div>
                </div>
              )}
            </Modal>
          )}
        </div>

        {/* Mobile View */}
        {!this.state.isApproverView && (
          <>
            <MobileTimesheet
              data={{
                selectedDate: selectedDate,
                getCategoriesFn: (entryType) => this.getTimesheetCodesets(entryType, this.state.selectedEmployee.id),
                allowanceCategories: this.state.allowanceCategoryData,
                timesheetWeek: timesheetWeek,
                hourBlocks: hourBlocks,
                selectedDateTimesheet: selectedDateTimesheet,
                reviewableTimesheetData: {
                  rejectedTimesheets: this.state.rejectedTimesheetData,
                  returnedTimesheets: this.state.returnedTimesheetData,
                },
                calculateTotalHoursFn: (startTime, endTime) => this.calculateTotalHours(startTime, endTime),
                changeDateFn: (date) => this.changeDate(date),
                saveFn: (entryType, operation, data) => this.saveTimesheetEntry(entryType, operation, data),
                submitTimesheetFn: () => this.submitTimesheet(),
                updateTimesheetStatusFn: (status) => this.updateTimesheetStatus(status),
                backFn: this.props.backFn,
                timesheetErrorInfo: this.state.timesheetErrorInfo,
                clearTimesheetErrorInfo: () => this.clearTimesheetErrorInfo(),
              }}
            />
          </>
        )}
      </div>
    );
  }
}

export default EmployeeTimesheet;
