import React from 'react';
import ResultsContainer from '../ResultsContainer';
import _ from 'lodash';
import moment from 'moment';
import TabContainer from '../TabContainer';
import ImportFileResult from './result-item/ImportFileResult';
import ExportFileResult from './result-item/ExportFileResult';
import ClientRouteResult from './result-item/ClientRouteResult';
import MeterResult from './result-item/MeterResult';
import WorkRouteResult from './result-item/WorkRouteResult';
import SearchCriteria from './SearchCriteria';
import Utils from '../../utils/Utils';
import Modal from '../Modal';
import MeterDetailModal from '../meter-detail-modal/MeterDetailModal';
import wave from '../../images/wave.svg';
import logo from '../../images/skilltech-mimtr-logo-dark.png';
import uat_logo from '../../images/skilltech-mimtr-logo-uat-dark.png';
import './search-page.scss';
import Api from '../../api/Api';
import MarkForAuditModal from '../MarkForAuditModal';

class Search extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      masterData: [],
      resultData: {
        importFile: [],
        exportFile: [],
        clientRoute: [],
        workRoute: [],
        meter: [],
        filters: false,
      },
      clientRouteData: [],
      workRouteData: [],
      statusData: [],
      buttonData: [],
      userData: props.userData,
      isLoading: true,
      selectedGroup: 'importFile',
      searchFn: null,
      filterData: [],
      cardData: [],
      showModal: false,
      modalType: '',
      bulkSelecting: false,
      selectAll: false,
      selectedAssignReader: {},
    };
  }

  async componentDidMount() {
    document.title = `${process.env.REACT_APP_ENV ? `[${process.env.REACT_APP_ENV}] ` : ''}MiMtr Hybrid | Search`;

    this.getData();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.selectedContract.id !== this.props.selectedContract.id) {
      this.setState({
        bulkSelecting: false,
        selectAll: false,
        searchFn: null,
      });
      this.getData();
    }
  }

  async getData() {
    this.setState({ isLoading: true });
    await this.getClientRoutes().then((clientRouteData) => {
      this.getWorkRoutes().then((workRouteData) => {
        Api.getJobStatuses(this.props.selectedContract).then((statusData) => {
          Api.getReaders(this.props.selectedContract).then((readerData) => {
            Api.getCodesets(this.props.selectedContract, [1, 2, 3, 4, 5, 6]).then((codesetData) => {
              let troubleCodeData = codesetData.filter((code) => code.categoryId === 1);
              let meterTypeCodeData = codesetData.filter((code) => code.categoryId === 2);
              let instructionCodeData = codesetData.filter((code) => code.categoryId === 3);
              let skipCodeData = codesetData.filter((code) => code.categoryId === 4);
              let locationCodeData = codesetData.filter((code) => code.categoryId === 5);
              let tapTestData = codesetData.filter((code) => code.categoryId === 6);

              skipCodeData.splice(0, 0, { id: 0, name: 'All' });

              this.setState(
                {
                  clientRouteData: clientRouteData,
                  workRouteData: workRouteData,
                  statusData: statusData,
                  readerData: readerData,
                  skipCodeData: skipCodeData,
                  troubleCodeData: troubleCodeData,
                  meterTypeCodeData: meterTypeCodeData,
                  instructionCodeData: instructionCodeData,
                  locationCodeData: locationCodeData,
                  tapTestData: tapTestData,
                  isLoading: false,
                },
                this.processData
              );
            });
          });
        });
      });
    });
  }

  processData = () => {
    let params = new URLSearchParams(window.location.search);
    let type = params.get('type');
    let filterData = [];

    if (type) {
      filterData = this.getFilterData(type, params);
      this.runSearch(filterData, type);
    } else {
      type = 'importFile';
      params = null;
      filterData = this.getFilterData(type, params);
    }

    this.setState({
      selectedGroup: type ? type : 'importFile',
      buttonData: this.getButtonData(type),
      filterData: filterData,
    });
  };

  async getClientRoutes() {
    return new Promise((resolve, reject) => {
      let clientRoutesApiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getclientroutenumbersbycontractid?DatabaseServer=${this.props.selectedContract.dbServer}&contractid=${this.props.selectedContract.id}`;
      fetch(clientRoutesApiUrl)
        .then((clientRoutesRes) => {
          if (clientRoutesRes.status === 401) {
            this.props.logoutFn();
            reject();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
            return clientRoutesRes.json();
          }
        })
        .then((data) => {
          let clientRoutesData = data
            .map((clientRoute) => {
              return {
                name: clientRoute.ClientRouteNumber,
                id: clientRoute.ClientRouteNumber,
              };
            })
            .sort();
          resolve(clientRoutesData);
        })
        .catch(() => {});
    });
  }

  async getWorkRoutes() {
    return new Promise((resolve) => {
      let workRoutesApiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getworkroutenumbersbycontractid?DatabaseServer=${this.props.selectedContract.dbServer}&contractid=${this.props.selectedContract.id}`;
      fetch(workRoutesApiUrl)
        .then((workRoutesRes) => {
          return workRoutesRes.json();
        })
        .then((data) => {
          let workRoutesData = data
            .map((workRoute) => {
              return {
                name: workRoute.WorkRouteNumber,
                id: workRoute.WorkRouteNumber,
              };
            })
            .sort();
          resolve(workRoutesData);
        })
        .catch(() => {});
    });
  }

  getButtonData(selectedGroup) {
    selectedGroup = selectedGroup ? selectedGroup : this.state.selectedGroup;
    const groupHumanised = Utils.humanize(selectedGroup);
    let sortOptions = this.getSortOptions(selectedGroup);

    let buttonData = [
      {
        type: 'text',
        text: `0 ${groupHumanised} results returned`,
      },
      {
        type: 'group',
        items: [
          {
            type: 'sort-button',
            classes: 'button--active',
            text: 'Sort By',
            icon: 'sort',
            iconPosition: '2',
            popover: {
              type: 'select',
              onClick: (key) => this.sortResults(key),
              options: sortOptions,
            },
            selectedSort: sortOptions[0].value,
            sortAsc: false,
          },
          { type: 'search-input', onChange: (key) => this.searchResults(key) },
        ],
      },
    ];

    if (['meter', 'workRoute'].includes(selectedGroup)) {
      buttonData = [
        {
          type: 'line',
          id: 1,
          items: [
            {
              type: 'text',
              text: `0 ${groupHumanised} results returned`,
            },
          ],
        },
        {
          type: 'line',
          id: 2,
          items: [
            {
              type: 'bulk-select-button',
              classes: 'button--plain',
              text: 'Bulk Select',
              actionItems: [{ text: 'Mark All For Audit', onClick: () => this.showModal(true, 'markForAudit') }],
              icon: 'checkbox',
              iconPosition: '1',
              selectAll: () => this.selectResult(null, true),
              toggleBulkSelectFn: () => this.toggleBulkSelecting(),
              count: 0,
            },
            buttonData[1],
          ],
        },
      ];
    } else {
    }

    return buttonData;
  }

  getSortOptions(selectedGroup) {
    let options = [];
    switch (selectedGroup) {
      case 'importFile':
        options.push({ name: 'Imported Date', value: 'importedDate' });
        options.push({ name: 'File Name', value: 'fileName' });
        options.push({ name: 'Status', value: 'status' });
        options.push({ name: 'Total Meters', value: 'meters' });
        options.push({ name: 'Total Jobs', value: 'jobs' });
        options.push({ name: 'Total Cycles', value: 'cycles' });
        options.push({ name: 'Total Routes', value: 'clientRoutes' });
        break;

      case 'exportFile':
        options.push({ name: 'Exported Date', value: 'exportedDate' });
        options.push({ name: 'File Name', value: 'fileName' });
        options.push({ name: 'Total Meters', value: 'meters' });
        options.push({ name: 'Total Jobs', value: 'jobs' });
        options.push({ name: 'Total Routes', value: 'clientRoutes' });
        break;

      case 'clientRoute':
        options.push({ name: 'Due Date', value: 'dueDate' });
        options.push({ name: 'Client Route No.', value: 'clientRouteNumber' });
        options.push({ name: 'Status', value: 'status' });
        options.push({ name: 'Scheduled Date', value: 'scheduledDate' });
        options.push({ name: 'Total Meters', value: 'totalMeters' });
        options.push({ name: 'Completed Meters', value: 'completed' });
        options.push({ name: 'Inomplete Meters', value: 'incomplete' });
        options.push({ name: 'Skipped Meters', value: 'skipped' });
        break;

      case 'workRoute':
        options.push({ name: 'Work Route No.', value: 'workRouteNumber' });
        options.push({ name: 'Route Description', value: 'name' });
        options.push({ name: 'Status', value: 'status' });
        options.push({ name: 'Scheduled Date', value: 'scheduledDate' });
        options.push({ name: 'Assigned Reader', value: 'readerName' });
        options.push({ name: 'Total Meters', value: 'totalMeters' });
        options.push({ name: 'Completed Meters', value: 'completed' });
        options.push({ name: 'Inomplete Meters', value: 'incomplete' });
        options.push({ name: 'Skipped Meters', value: 'skipped' });
        break;

      case 'meter':
        options.push({ name: 'Work Route No.', value: 'workRouteNumber' });
        options.push({ name: 'Route Description', value: 'address' });
        options.push({ name: 'Area Group', value: 'areaGroup' });
        options.push({ name: 'Meter No.', value: 'meterNumber' });
        options.push({ name: 'Status', value: 'status' });
        options.push({ name: 'Assigned Reader', value: 'completedBy' });
        options.push({ name: 'Date Completed', value: 'dateCompleted' });
        options.push({ name: 'Skip Reason', value: 'skipReason' });
        options.push({ name: 'Total Registers', value: 'registers' });
        options.push({ name: 'Photo Count', value: 'photoCount' });
        break;
      default:
        break;
    }

    return options;
  }

  getFilterData(selectedGroup, params) {
    let clientRouteData = this.state.clientRouteData;
    let workRouteData = this.state.workRouteData;
    let statusData = this.state.statusData;
    let readerData = this.state.readerData;
    let skipCodeData = this.state.skipCodeData;
    selectedGroup = selectedGroup ? selectedGroup : this.state.selectedGroup;

    let filters = [
      {
        name: 'dateCompleted',
        type: 'dateSelectRange',
        selection: { start: null, end: null },
        displayLabel: selectedGroup === 'importFile' ? 'Date Imported' : selectedGroup === 'exportFile' ? 'Date Exported' : 'Date Completed',
      },
      {
        name: 'clientRouteNo',
        type: 'select',
        multiselect: true,
        options: clientRouteData,
        selection: [],
        displayLabel: 'Client Route No',
      },
      {
        name: 'workRouteNo',
        type: 'select',
        multiselect: true,
        options: workRouteData,
        selection: params && params.get('workRouteNumber') ? [workRouteData.find((route) => route.name === params.get('workRouteNumber')).id] : [],
        displayLabel: 'Work Route No',
      },
      {
        name: 'areaGroup',
        type: 'select',
        multiselect: true,
        options: this.props.userData.areaGroups.filter((areaGroup) => areaGroup.contractId === this.props.selectedContract.id),
        selection: [],
        displayLabel: 'Area Group',
      },
      {
        name: 'area',
        type: 'select',
        multiselect: true,
        options: this.props.userData.areas.filter((area) => area.contractId === this.props.selectedContract.id),
        selection: [],
        displayLabel: 'Area',
      },
      {
        name: 'address',
        type: 'text',
        selection: null,
        displayLabel: 'Address',
      },
      {
        name: 'meterNo',
        type: 'text',
        selection: null,
        displayLabel: 'Meter No',
      },
      {
        name: 'jobStatus',
        type: 'select',
        multiselect: true,
        options: statusData,
        selection: [],
        displayLabel: selectedGroup === 'importFile' || selectedGroup === 'exportFile' ? 'File Status' : 'Job Status',
      },
      {
        name: 'meterReader',
        type: 'select',
        multiselect: true,
        options: readerData,
        selection: [],
        displayLabel: 'Meter Reader',
      },
    ];

    if (selectedGroup === 'meter') {
      filters.push({
        name: 'skipped',
        type: 'select',
        multiselect: true,
        options: skipCodeData,
        selection: [],
        displayLabel: 'Skipped',
      });

      filters.push({
        name: 'photos',
        type: 'select',
        multiselect: true,
        options: [
          { id: 1, name: 'OOT' },
          { id: 2, name: 'Skip' },
          { id: 3, name: 'Trouble' },
          { id: 4, name: 'Survey' },
          { id: 5, name: 'Ad Hoc' },
        ],
        selection: [],
        displayLabel: 'Photos',
      });
    }

    if (['workRoute', 'meter'].includes(selectedGroup)) {
      filters.push({
        name: 'markedForAudit',
        type: 'select',
        options: [
          { id: 1, name: 'Yes' },
          { id: 0, name: 'No' },
        ],
        selection: params && params.get('audit') ? { id: 1, name: 'Yes' } : null,
        displayLabel: 'Marked For Audit',
      });
    }

    return filters;
  }

  selectGroup(tabName) {
    // Remove search bar text
    document.getElementById('search-bar').value = '';
    let filterData = this.getFilterData(tabName);

    let prevFilters = this.state.filterData;

    filterData.forEach((filter) => {
      let prevFilter = prevFilters.find((prevFilter) => prevFilter.name === filter.name);
      if (prevFilter && prevFilter.selection !== filter.selection) {
        filter.selection = prevFilter.selection;
      }
    });

    this.setState({
      selectedGroup: tabName,
      bulkSelecting: false,
      buttonData: this.getButtonData(tabName),
      filterData: filterData,
    });

    if (this.state.resultData.filters) {
      this.runSearch(filterData, tabName);
    }
  }

  async runSearch(filters, selectedGroup) {
    this.setState({ isLoading: true });

    if (!filters) {
      this.setState({
        isLoading: false,
        filterData: this.getFilterData(),
        buttonData: this.getButtonData(),
        resultData: {
          importFile: [],
          exportFile: [],
          clientRoute: [],
          workRoute: [],
          meter: [],
          filters: false,
        },
      });
      return;
    }

    selectedGroup = selectedGroup ? selectedGroup : this.state.selectedGroup;

    let searchApiUrl = `${process.env.REACT_APP_API_ENDPOINT}/jobsearch?DatabaseServer=${this.props.selectedContract.dbServer}&contractid=${this.props.selectedContract.id}&searchtype=${
      selectedGroup === 'meter' ? 'job' : selectedGroup
    }`;

    filters
      .filter((filter) => !!filter.selection)
      .forEach((filter) => {
        if (filter.multiselect && !filter.selection.length) {
          return;
        }
        let selection = filter.multiselect ? filter.selection.join(',') : null;

        switch (filter.name) {
          case 'dateCompleted':
            if (filter.selection.start && filter.selection.end) {
              let startDate = moment(filter.selection.start).utc();
              let endDate = moment(filter.selection.end).utc();
              searchApiUrl += `&datefromjobcompleted=${startDate.format('YYYY-MM-DD')}`;
              searchApiUrl += `&datetojobcompleted=${endDate.format('YYYY-MM-DD')}`;
            }
            break;
          case 'clientRouteNo':
            searchApiUrl += `&clientroutenumber=${filter.selection}`;
            break;
          case 'workRouteNo':
            searchApiUrl += `&workroutenumber=${filter.selection}`;
            break;
          case 'areaGroup':
            searchApiUrl += `&aregagroupids=${selection}`;
            break;
          case 'area':
            searchApiUrl += `&areaids=${selection}`;
            break;
          case 'address':
            searchApiUrl += `&address=${filter.selection}`;
            break;
          case 'meterNo':
            searchApiUrl += `&meternumber=${filter.selection}`;
            break;
          case 'jobStatus':
            searchApiUrl += `&statusid=${selection}`;
            break;
          case 'meterReader':
            searchApiUrl += `&readerid=${selection}`;
            break;
          case 'skipped':
            searchApiUrl += `&skipcode=${selection}`;
            break;
          case 'markedForAudit':
            searchApiUrl += `&forAudit=${filter.selection.id}`;
            break;
          case 'photos':
            searchApiUrl += `&photoType=${filter.selection}`;
            break;
          default:
            break;
        }
      });

    await new Promise((resolve) => {
      fetch(searchApiUrl)
        .then((searchRes) => {
          if (searchRes.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
            return searchRes.json();
          }
        })
        .then((data) => {
          let results = selectedGroup === 'meter' ? this.formatMeterSearchResults(data.results) : data.results.map((result) => this.formatSearchResult(result));
          resolve(results);
        })
        .catch(() => {
          resolve([this.formatSearchResult()]);
        });
    }).then((results) => {
      let buttonData = this.state.buttonData;

      if (['meter', 'workRoute'].includes(this.state.selectedGroup)) {
        const textIndex = buttonData[0].items.findIndex((button) => button.type === 'text');
        buttonData[0].items[textIndex].text = `${results.length} ${Utils.humanize(this.state.selectedGroup)} results returned`;

        const buttonGroup = buttonData[1].items.find((button) => button.type === 'group');
        const sortButton = buttonGroup.items.find((button) => button.type === 'sort-button');
        results = _.orderBy(results, sortButton.selectedSort, sortButton.sortAsc ? 'asc' : 'desc');
      } else {
        const textIndex = buttonData.findIndex((button) => button.type === 'text');
        buttonData[textIndex].text = `${results.length} ${Utils.humanize(this.state.selectedGroup)} results returned`;

        const buttonGroup = buttonData.find((button) => button.type === 'group');
        const sortButton = buttonGroup.items.find((button) => button.type === 'sort-button');
        results = _.orderBy(results, sortButton.selectedSort, sortButton.sortAsc ? 'asc' : 'desc');
      }

      let resultData = this.state.resultData;
      resultData[selectedGroup] = results.length > 0 ? this.getResultItems(results) : [];
      resultData.filters = true;

      this.setState({
        isLoading: false,
        resultData: resultData,
        masterData: results,
        filterData: filters,
      });
    });
  }

  formatSearchResult(data) {
    switch (this.state.selectedGroup) {
      case 'importFile':
        return {
          clientRoutes: data.ClientRoutes,
          contractId: data.ContractId,
          cycles: data.Cycles,
          id: data.FileId,
          fileName: data.FileName,
          importedDate: moment(data.ImportedDate),
          jobs: data.Jobs,
          meters: data.Meters,
          status: data.Status,
        };
      case 'exportFile':
        return {
          id: data.FileId,
          contractId: data.ContractId,
          fileName: data.FileName,
          exportedDate: moment(data.ExportedDate),
          clientRoutes: data.ClientRoutes,
          jobs: data.Jobs,
          meters: data.Meters,
        };

      case 'clientRoute':
        return {
          id: data.ClientRouteId,
          clientRouteNumber: data.ClientRouteNumber,
          status: data.Status,
          scheduledDate: moment(data.ScheduledDate),
          dueDate: moment(data.DueDate),
          totalMeters: data.TotalMeters,
          completed: data.Completed,
          incomplete: data.Incomplete,
          skipped: data.Skipped,
          metersPerWorkRoute: data.MetersPerWorkRoute,
        };

      case 'workRoute':
        return {
          id: data.WorkRouteId,
          workRouteNumber: data.WorkRouteNumber,
          name: data.RouteDescription,
          status: data.Status,
          readerName: Utils.titleize(data.ReaderName.replace('.', ' ')),
          scheduledDate: moment(data.ScheduledDate),
          totalMeters: data.TotalMeters,
          completed: data.Completed,
          incomplete: data.Incomplete,
          skipped: data.Skipped,
          markedForAudit: data.MetersForAudit > 0,
        };

      default:
        return {};
    }
  }

  formatMeterSearchResults(data) {
    let groupedMeters = _.groupBy(data, 'MeterID');
    return _.map(groupedMeters, (meter) => {
      return {
        workRouteNumber: meter[0].WorkRouteNumber,
        completedBy: Utils.titleize(meter[0].CompletedBy.replace('.', ' ').replace('.', ' ')),
        id: meter[0].MeterID,
        address: meter[0].Address,
        dateCompleted: meter[0].DateTimeCompleted ? moment(meter[0].DateTimeCompleted) : null,
        meterNumber: meter[0].MeterNumber.trim(),
        registers: meter[0].Registers,
        skipReason: meter[0].SkipReason,
        customerName: meter[0].CustomerName ? Utils.titleize(meter[0].CustomerName).replace('.', ' ') : null,
        status: meter[0].JobStatus,
        areaGroup: meter[0].AreaGroup,
        reading: meter[0].NewReading,
        photoCount: meter[0].PhotoCount,
        markedForAudit: meter[0].MeterForAudit,
      };
    });
  }

  getResultItems(results, bulkSelecting) {
    bulkSelecting = bulkSelecting != null ? bulkSelecting : this.state.bulkSelecting;
    if (results.length > 0) {
      switch (this.state.selectedGroup) {
        case 'importFile':
          return results.map((item, index) => {
            return <ImportFileResult data={item} key={index} />;
          });

        case 'exportFile':
          return results.map((item, index) => {
            return <ExportFileResult data={item} key={index} />;
          });

        case 'clientRoute':
          return results.map((item, index) => {
            return <ClientRouteResult data={item} key={index} selectFn={() => this.selectRoute(item)} />;
          });

        case 'workRoute':
          return results.map((item, index) => {
            return <WorkRouteResult data={item} key={index} selectFn={() => this.selectResult(item)} bulkSelecting={bulkSelecting} />;
          });

        case 'meter':
          return results.map((item, index) => {
            item.index = index;
            return <MeterResult data={item} key={index} selectFn={() => this.selectResult(item)} bulkSelecting={bulkSelecting} />;
          });

        default:
      }
    }
  }

  toggleBulkSelecting(bulkSelecting) {
    // Switch to the opposite state
    bulkSelecting = bulkSelecting != null ? bulkSelecting : !this.state.bulkSelecting;

    // Clear changes when toggling bulk select
    let buttonData = this.state.buttonData;
    const bulkButton = buttonData[1].items.find((button) => button.type === 'bulk-select-button');
    bulkButton.count = 0;

    let masterData = this.state.masterData;
    if (!bulkSelecting) {
      masterData.forEach((item) => {
        item.selected = false;
      });
    }

    let resultData = this.state.resultData;
    resultData[this.state.selectedGroup] = masterData.length > 0 ? this.getResultItems(masterData, bulkSelecting) : [];

    this.setState({
      bulkSelecting: bulkSelecting,
      selectAll: false,
      resultData: resultData,
    });
  }

  async bulkMarkForAudit() {
    let selectedItems = this.state.masterData.filter((data) => data.selected);

    const promises = selectedItems.map(async (item) => {
      return await this.markForAudit(item);
    });

    await Promise.all(promises).then(() => {
      this.toggleBulkSelecting(false);
      this.updateRouteData(selectedItems);
    });
  }

  markForAudit(item) {
    let selectedGroup = this.state.selectedGroup;
    let body = {
      DatabaseServer: this.props.selectedContract.dbServer,
      [`${selectedGroup}Id`]: item.id,
    };

    return new Promise((resolve, reject) => {
      let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/markForAudit`;

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      };

      fetch(apiUrl, requestOptions)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
            return res.json();
          }
        })
        .then((data) => {
          fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
          this.showModal(false);
        });
      resolve();
    });
  }

  updateRouteData(updatedRoutes) {
    let masterData = this.state.masterData;

    updatedRoutes.forEach((updatedRoute) => {
      let matchingRoute = masterData.find((route) => updatedRoute.id === route.id);
      matchingRoute.markedForAudit = true;
    });

    let resultData = this.state.resultData;
    resultData[this.state.selectedGroup] = masterData.length > 0 ? this.getResultItems(masterData) : [];
  }

  async selectResult(selectedItem, toggleAll) {
    let masterData = this.state.masterData;
    let selectedGroup = this.state.selectedGroup;

    const bulkSelecting = this.state.bulkSelecting;
    let selectAll = this.state.selectAll;

    // If bulk selecting and toggling all, switch according to the selectAll state
    if (bulkSelecting && toggleAll) {
      selectAll = !selectAll;
    }

    if (bulkSelecting) {
      masterData.forEach((item) => {
        if (toggleAll) {
          item.selected = selectAll;
        } else if (item.id === selectedItem.id) {
          item.selected = !item.selected;
        }
      });

      // Add to the bulk select count
      const count = _.filter(masterData, { selected: true }).length;
      const bulkButton = _.find(this.state.buttonData[1].items, { type: 'bulk-select-button' });
      bulkButton.count = count;

      let resultData = this.state.resultData;
      resultData[selectedGroup] = this.getResultItems(masterData, bulkSelecting);

      this.setState({
        resultData: resultData,
        selectAll: selectAll,
      });
    } else {
      if (selectedGroup === 'meter') {
        await this.selectMeter(selectedItem);
      } else {
        await this.selectRoute(selectedItem);
      }
    }
  }

  async selectMeter(meter) {
    await this.getMeterDetails(meter.id).then((meterDetails) => {
      let data = {};
      data.photos = meterDetails.photos;

      data.readings = { ...meterDetails.readings[0] };
      data.readings.dateCompleted = meter.dateCompleted;
      data.readings.workRouteNumber = meter.workRouteNumber;
      data.readings.address = meter.address;
      data.readings.meterNumber = meter.meterNumber;
      data.readings.skipReason = meter.skipReason;
      data.readings.customerName = meter.customerName;
      data.readings.areaGroup = meter.areaGroup;
      data.readings.status = meter.status;
      data.readings.index = meter.index;
      data.readings.registers = meterDetails.readings.map((register) => {
        return { textPrompt: register.textPrompt, newReading: register.newReading };
      });

      this.setState({ cardData: data });
      this.showModal(true, 'meterDetails');
    });
  }

  async selectRoute(route) {
    let selectedGroup = this.state.selectedGroup;
    let filters = this.state.filterData;

    let filter = filters.find((filter) => filter.name === `${selectedGroup}No`);
    filter.selection = [filter.options.find((option) => option.name === route.workRouteNumber || option.name === route.clientRouteNumber).id];

    this.setState({
      filterData: filters,
    });

    this.tabContainer.selectTab('meter');
  }

  showModal(show, modalType) {
    const showingModal = this.state.showModal;

    this.setState({
      showModal: show != null ? show : !showingModal,
      modalType: !showingModal ? modalType : '',
    });
  }

  async getMeterDetails(id) {
    let meterDetailsApi = `${process.env.REACT_APP_API_ENDPOINT}/getjobdetails?DatabaseServer=${this.props.selectedContract.dbServer}&meterid=${id}`;

    return new Promise((resolve) => {
      fetch(meterDetailsApi)
        .then((searchRes) => {
          return searchRes.json();
        })
        .then((data) => {
          fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });

          let results = {};
          results.readings = data.results[0].map((result) => {
            let instructionCodeData = this.state.instructionCodeData;
            let readInstruction1 = instructionCodeData.find((code) => code.id === result.NewReadInstruction1_Code);
            let readInstruction2 = instructionCodeData.find((code) => code.id === result.NewReadInstruction2_Code);

            let meterType = this.state.meterTypeCodeData.find((code) => code.id === result.NewMeterType);

            let troubleCodeData = this.state.troubleCodeData;
            let troubleCode1 = troubleCodeData.find((code) => code.id === result.NewTroubleCode1_Code);
            let troubleCode2 = troubleCodeData.find((code) => code.id === result.NewTroubleCode2_Code);

            let meterLocation = this.state.locationCodeData.find((code) => code.id === result.NewMeterLocation_Code);

            let tapTestResult = this.state.tapTestData.find((code) => code.id === result.TapTestResult_Code);

            return {
              meterId: result.MeterId,
              meterNumber: result.NewMeterNumber,
              troubleMessage: result.TroubleMessage,
              specialMessage: result.NewMeterSpecialMessage,
              meterInformation: result.NewMeterInformation,
              newMeterLocation: meterLocation ? meterLocation.name : '',
              newReadInstruction1: readInstruction1 ? readInstruction1.name : '',
              newReadInstruction2: readInstruction2 ? readInstruction2.name : '',
              meterType: meterType ? meterType.name : '',
              readerId: result.ReaderId,
              readTimestampUtc: result.ReadTimestampUtc,
              newReading: result.NewReading,
              longitude: result.Longitude,
              latitude: result.Latitude,
              newTroubleCode1: troubleCode1 ? troubleCode1.name : '',
              newTroubleCode2: troubleCode2 ? troubleCode2.name : '',
              readReasonCode: result.ReadReason_Code,
              tapTestResult: tapTestResult ? tapTestResult.name : '',
              textPrompt: result.TextPrompt.trim(),
              readingId: result.readingId,
            };
          });

          results.photos = data.results[1]
            .filter((result) => result.PictureType !== 22)
            .map((result) => {
              return {
                pictureId: result.PictureId,
                imageData: result.ImageData,
                pictureType: result.PictureType,
                readingId: result.ReadId,
                reviewed: result.Reviewed,
                reviewedBy: result.ReviewedBy ? result.ReviewedBy.replace('.', ' ') : 'N/A',
                reviewedDate: result.LastUpdatedAtUtc ? moment(result.LastUpdatedAtUtc) : null,
              };
            });

          results.photos = _.groupBy(results.photos, (photo) => photo.pictureType);

          resolve(results);
        })
        .catch(() => {});
    });
  }

  sortResults(sortKey) {
    let data = this.state.masterData;
    let currentSortKey, sortAsc;

    // Find the sort button and check if the sort key was already selected
    let buttonData = this.state.buttonData;
    buttonData = ['meter', 'workRoute'].includes(this.state.selectedGroup) ? buttonData[1].items : buttonData;
    const buttonGroup = buttonData.find((button) => button.type === 'group');
    buttonGroup.items.forEach((button) => {
      if (button.type === 'sort-button') {
        currentSortKey = button.selectedSort;

        // If the sort key was reselected, toggle asc/desc order
        if (currentSortKey === sortKey) {
          button.sortAsc = !button.sortAsc;
        } else {
          button.sortAsc = true;
        }

        button.selectedSort = sortKey;
        sortAsc = button.sortAsc;
      }
    });

    if (data.length > 0) {
      data = _.orderBy(data, sortKey, sortAsc ? 'asc' : 'desc');

      let resultData = this.state.resultData;
      resultData[this.state.selectedGroup] = this.getResultItems(data);

      this.setState({
        resultData: resultData,
        masterData: data,
      });
    }
  }

  searchResults(searchTerm) {
    let data = this.state.masterData;
    let searchFn;

    if (searchTerm) {
      switch (this.state.selectedGroup) {
        case 'importFile':
        case 'exportFile':
          searchFn = (result) => {
            return result.fileName.toLowerCase().includes(searchTerm.toLowerCase());
          };
          break;
        case 'clientRoute':
          searchFn = (result) => {
            return result.clientRouteNumber.includes(searchTerm);
          };
          break;
        case 'workRoute':
          searchFn = (result) => {
            return (
              result.workRouteNumber.includes(searchTerm) ||
              result.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
              (result.readerName && result.readerName.toLowerCase().includes(searchTerm.toLowerCase()))
            );
          };
          break;
        case 'meter':
          searchFn = (result) => {
            return (
              result.workRouteNumber.includes(searchTerm) ||
              result.address.toLowerCase().includes(searchTerm.toLowerCase()) ||
              result.meterNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
              (result.completedBy && result.completedBy.toLowerCase().includes(searchTerm.toLowerCase()))
            );
          };
          break;
        default:
          searchFn = (result) => {
            return result;
          };
      }
    } else {
      searchFn = (result) => {
        return result;
      };
    }

    data = data.filter(searchFn);

    if (data.length > 0) {
      let resultData = this.state.resultData;
      resultData[this.state.selectedGroup] = this.getResultItems(data);

      this.setState({
        resultData: resultData,
      });
    }

    let buttonData = this.state.buttonData;
    if (this.state.selectedGroup === 'meter') {
      const lineIndex = buttonData.findIndex((button) => button.type === 'line' && button.id === 1);
      const textIndex = buttonData[lineIndex].items.findIndex((button) => button.type === 'text');
      buttonData[lineIndex].items[textIndex].text = `${data.length} ${Utils.humanize(this.state.selectedGroup)} results returned`;
    } else {
      const textIndex = buttonData.findIndex((button) => button.type === 'text');
      buttonData[textIndex].text = `${data.length} ${Utils.humanize(this.state.selectedGroup)} results returned`;
    }
  }

  render() {
    let data = { resultData: this.state.resultData };

    // Collect data into an array of tab objects representing the data groups
    const groups = [];

    ['importFile', 'exportFile', 'clientRoute', 'workRoute', 'meter'].forEach((groupName) => {
      let group = { name: groupName };
      groups.push(group);
    });

    return (
      <div className='main-content search-page'>
        <header>
          <h1>Search</h1>
          <img className='logo-container' src={process.env.REACT_APP_ENV ? uat_logo : logo} alt='' />
        </header>
        <TabContainer
          id={'search'}
          groups={groups}
          selectedTab={this.state.selectedGroup}
          onClick={(name) => this.selectGroup(name)}
          isLoading={this.state.isLoading}
          ref={(c) => (this.tabContainer = c)}
        />
        <SearchCriteria filters={this.state.filterData} onClick={(filters) => this.runSearch(filters)} />
        <ResultsContainer
          data={data}
          buttonData={this.state.buttonData}
          selectedGroup={this.state.selectedGroup}
          emptyStateText={`There are no ${Utils.humanize(this.state.selectedGroup)}s matching the criteria.`}
          bulkSelecting={this.state.bulkSelecting}
          isLoading={this.state.isLoading}
        />
        <img className='wave' src={wave} alt={''} />
        {this.state.showModal && (
          <Modal classes={this.state.modalType === 'markForAudit' ? 'popup' : ''} showModalFn={(show) => this.showModal(show)} showModal={this.state.showModal}>
            {this.state.modalType === 'meterDetails' && <MeterDetailModal data={this.state.cardData} />}
            {this.state.modalType === 'markForAudit' && (
              <MarkForAuditModal
                showModalFn={(show) => this.showModal(show)}
                selectedReads={this.state.masterData.filter((data) => data.selected)}
                photoType={this.state.selectedGroup}
                newReader={this.state.selectedAssignReader}
                submitFn={() => this.bulkMarkForAudit()}
              />
            )}
          </Modal>
        )}
      </div>
    );
  }
}

export default Search;
