export const TIMESHEET_STATUS = {
  DRAFT: 1,
  PENDING_APPROVAL: 2,
  APPROVED: 3,
  POSTED: 4,
  AMENDED: 5,
  RETURNED: 6,
  REJECTED: 7,
  PROVISIONALLY_APPROVED: 8,
  REJECTED_TO_PROVISIONAL_APPROVER: 9,
  AMENDED_BY_PROVISIONAL_APPROVER: 10,
  RETURNED_BY_PROVISIONAL_APPROVER: 11,
};
