import React from 'react';
import ResultsContainer from '../ResultsContainer';
import _ from 'lodash';
import wave from '../../images/wave.svg';
import logo from '../../images/skilltech-mimtr-logo-dark.png';
import uat_logo from '../../images/skilltech-mimtr-logo-uat-dark.png';
import './route-templates-page.scss';
import Modal from '../Modal';
import RouteTemplateResult from './RouteTemplateResult';
import RouteTemplateModal from './RouteTemplateModal';
import Api from '../../api/Api';
import Button from '../Button';
import CustomSelect from '../CustomSelect';
import { withRouter } from 'react-router-dom';

class RouteTemplates extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      masterData: [],
      routeTemplateData: [],
      readerData: [],
      buttonData: [],
      userData: props.userData,
      sortKey: 'routeNumber',
      searchFn: null,
      filterFn: null,
      resultData: [],
      showModal: false,
      modalType: '',
      resequenceMethod: '',
    };
  }

  componentDidMount() {
    document.title = `${process.env.REACT_APP_ENV ? `[${process.env.REACT_APP_ENV}] ` : ''}MiMtr Hybrid | Route Templates`;

    this.getData();
  }

  async componentDidUpdate(prevProps) {
    //TODO: remove this (18 = temp role to hide route templates)
    if (!this.props.selectedContract.roles.includes(18)) {
      window.location.href = `https://${window.location.host}/hybrid/`;
    }
    if (prevProps.selectedContract.id !== this.props.selectedContract.id) {
      this.setState({
        selectAll: false,
        sortKey: 'name',
        searchFn: null,
        filterFn: null,
      });
      this.getData();
    }
  }

  async getData() {
    this.setState({ isLoading: true });
    Api.getReaders(this.props.selectedContract).then((readerData) => {
      Api.getReadFrequencies(this.props.selectedContract).then((frequencyData) => {
        Api.getRouteGrade(this.props.selectedContract).then((gradeData) => {
          Api.getRouteCategories().then((categoryData) => {
            let routeTemplateData = [];
            if (this.props.history.location.state && this.props.history.location.state.routeTemplateData) {
              routeTemplateData = this.props.history.location.state.routeTemplateData;
            }

            this.setState({
              readerData: readerData,
              buttonData: this.getButtonData(routeTemplateData),
              frequencyData: frequencyData,
              gradeData: gradeData,
              categoryData: categoryData,
              routeTemplateData: routeTemplateData,
              isLoading: false,
            });
          });
        });
      });
    });
  }

  async getRouteTemplates(routeNumber) {
    let areas = [
      ...new Set(
        this.props.selectedAreas.map((area) => {
          return area.id;
        })
      ),
    ];
    let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getRouteTemplates?DatabaseServer=${this.props.selectedContract.dbServer}&contractId=${this.props.selectedContract.id}&areas=${areas}&routeNumber=${routeNumber}`;

    return new Promise((resolve, reject) => {
      return fetch(apiUrl)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
            reject();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
            return res.json();
          }
        })
        .then((data) => {
          if (!data.length) {
            resolve([]);
            return;
          }

          let masterData = data.map((result) => {
            if (result.requirements.length) {
              result.requirements = result.requirements.replaceAll(' Required', '').split('|');
            } else {
              result.requirements = [];
            }

            return result;
          });

          resolve(masterData);
        });
    });
  }

  getButtonData(masterData) {
    return [
      {
        type: 'text',
        text: `${masterData.length} results returned`,
      },
      {
        type: 'group',
        items: [
          {
            type: 'filter-button',
            text: 'Filter',
            icon: 'filter',
            iconPosition: '2',
            popover: {
              type: 'filter',
              onClick: (filterData) => this.filterResults(filterData),
              filters: [
                // {
                //     name: "areaGroups",
                //     displayLabel: "Area Groups",
                //     type: "tags",
                //     selection: [],
                //     options: _.sortBy(_.uniqBy(masterData, 'areaGroupId').map(route => { return {id: route.areaGroupId, name: route.areaGroup}}), 'name'),
                //     selectCallback: (selectedAreaGroups, filters) => {
                //         let areaIndex = filters.findIndex(filter => filter.name === 'areas');
                //         let contractAreas = _.filter(this.props.userData.areas, area => area.contractId === this.props.selectedContract.id);
                //         filters[areaIndex].options = selectedAreaGroups ? _.filter(contractAreas, area => selectedAreaGroups.includes(area.areaGroupId)) : contractAreas;
                //         return filters;
                //     }
                // },
                {
                  name: 'areas',
                  displayLabel: 'Areas',
                  type: 'select',
                  multiselect: true,
                  selection: [],
                  options: _.sortBy(
                    _.uniqBy(masterData, 'areaId').map((route) => {
                      return { id: route.areaId, name: route.areaName };
                    }),
                    'name'
                  ),
                },
                {
                  name: 'category',
                  displayLabel: 'Route Category',
                  type: 'select',
                  multiselect: true,
                  selection: [],
                  options: _.sortBy(
                    _.uniqBy(masterData, 'category').map((route) => {
                      return { id: route.routeCategory, name: route.routeCategoryName };
                    }),
                    'name'
                  ),
                },
              ],
            },
          },
          {
            type: 'sort-button',
            classes: 'button--active',
            text: 'Sort By',
            icon: 'sort',
            iconPosition: '2',
            popover: {
              type: 'select',
              onClick: (key) => this.sortResults(key),
              options: [
                { name: 'Route No.', value: 'routeNumber' },
                { name: 'Route Name', value: 'routeName' },
              ],
            },
            selectedSort: 'routeNumber',
            sortAsc: true,
          },
          { type: 'search-input', onChange: (key) => this.searchResults(key) },
        ],
      },
    ];
  }

  filterResults(filterData) {
    let filterFn;
    let buttonData;

    if (!filterData) {
      filterFn = (route) => {
        return route;
      };
      buttonData = this.getButtonData(this.state.masterData);
    } else {
      const selectedArea = filterData[0].selection;
      const selectedCategory = filterData[1].selection;

      filterFn = function (route) {
        return (!selectedArea.length > 0 || selectedArea.includes(route.areaId)) && (!selectedCategory.length > 0 || selectedCategory.includes(route.category));
      };

      buttonData = this.state.buttonData;
    }

    let data = _.filter(_.cloneDeep(this.state.masterData), filterFn ? filterFn : this.state.filterFn);
    buttonData[0].text = `${data.length} results returned`;

    this.setState({
      buttonData: buttonData,
      routeTemplateData: data,
      filterFn: filterFn ? filterFn : null,
    });
  }

  sortResults(sortKey) {
    let currentSortKey, sortAsc;

    // Find the sort button and check if the sort key was already selected
    let buttonData = this.state.buttonData;
    const buttonGroup = buttonData.find((button) => button.type === 'group');
    buttonGroup.items.forEach((button) => {
      if (button.type === 'sort-button') {
        currentSortKey = button.selectedSort;

        // If the sort key was reselected, toggle asc/desc order
        if (currentSortKey === sortKey) {
          button.sortAsc = !button.sortAsc;
        } else {
          button.sortAsc = true;
        }

        button.selectedSort = sortKey;
        sortAsc = button.sortAsc;
      }
    });

    let data = _.orderBy(this.state.masterData, sortKey, sortAsc ? 'asc' : 'desc');

    this.setState({
      routeTemplateData: data,
      buttonData: buttonData,
      sortKey: sortKey,
    });
  }

  searchResults(searchTerm) {
    if (searchTerm.length < 3) {
      return;
    }

    this.getRouteTemplates(searchTerm).then((routeTemplateData) => {
      routeTemplateData = _.sortBy(routeTemplateData, this.state.sortKey);

      this.setState({
        filterFn: null,
        searchTerm: searchTerm,
        buttonData: this.getButtonData(routeTemplateData),
        routeTemplateData: routeTemplateData,
        masterData: routeTemplateData,
      });
    });
  }

  showModal(show, modalType) {
    const showingModal = this.state.showModal;

    this.setState({
      showModal: show != null ? show : !showingModal,
      modalType: modalType,
    });
  }

  openRouteTemplateModal(selectedRouteTemplate) {
    selectedRouteTemplate.DatabaseServer = this.props.selectedContract.dbServer;

    this.setState({
      selectedRouteTemplate: selectedRouteTemplate,
    });

    this.showModal(true, 'routeTemplateDetails');
  }

  updateRouteTemplates() {
    this.getRouteTemplates().then((routeTemplateData) => {
      this.showModal(false);
      this.setState({
        routeTemplateData: routeTemplateData,
      });
    });
  }

  selectRouteTemplate(selectedRouteTemplate) {
    this.setState({
      selectedRouteTemplate: selectedRouteTemplate,
    });

    this.showModal(true, 'routeTemplateManageMethod');
  }

  getResequenceMethods() {
    let resequenceMethods = [{ name: 'Current Template', id: 'currentTemplate' }];
    if (this.state.selectedRouteTemplate) {
      if (this.state.selectedRouteTemplate.lastestWorkrouteId) {
        resequenceMethods.push({ name: 'Work Route', id: 'workRoute' });
      }

      if (this.state.selectedRouteTemplate.latestClientRouteId) {
        resequenceMethods.push({ name: 'Client Route', id: 'clientRoute' });
      }
    }

    return resequenceMethods;
  }

  updateResequenceMethod(value) {
    this.setState({
      resequenceMethod: value,
    });
  }

  manageRouteTemplate() {
    let props = {
      resequenceMethod: this.state.resequenceMethod,
      routeTemplate: this.state.selectedRouteTemplate,
      routeTemplateData: this.state.routeTemplateData,
    };

    this.props.history.push('/hybrid/route-templates/resequence', props);
  }

  getResultItems() {
    return this.state.routeTemplateData.map((routeTemplate, index) => {
      return <RouteTemplateResult data={routeTemplate} key={index} editFn={() => this.openRouteTemplateModal(routeTemplate)} selectFn={() => this.selectRouteTemplate(routeTemplate)} />;
    });
  }

  render() {
    let data = { resultData: this.getResultItems() };

    return (
      <div className={'main-content route-templates-page'}>
        <header>
          <h1>Route Templates</h1>
          <img className='logo-container' src={process.env.REACT_APP_ENV ? uat_logo : logo} alt='' />
        </header>
        <ResultsContainer
          data={data}
          buttonData={this.state.buttonData}
          dataClass={'routeTemplate'}
          emptyStateText={`There are no matching route templates. Please enter a valid route number in the search field.`}
          isLoading={this.state.isLoading}
          bulkSelecting={this.state.bulkSelecting}
        />
        {this.state.showModal && (
          <Modal showModalFn={(show) => this.showModal(show)} showModal={this.state.showModal}>
            {this.state.modalType === 'routeTemplateDetails' && (
              <RouteTemplateModal
                data={this.state.selectedRouteTemplate}
                options={{
                  frequencyOptions: this.state.frequencyData,
                  gradeOptions: this.state.gradeData,
                  categoryOptions: this.state.categoryData,
                  areaOptions: this.props.selectedAreas,
                  readerOptions: this.state.readerData,
                }}
                updateFn={() => this.updateRouteTemplates()}
                showModalFn={(show) => this.showModal(show)}
                showModal={this.state.showModal}
              />
            )}
            {this.state.modalType === 'routeTemplateManageMethod' && (
              <div id={'route-template-manage-method-modal'} className='modal-content'>
                <div className='heading'>Select Manage Route Template Method</div>
                {!this.state.isLoading && (
                  <div>
                    <div className='form-container'>
                      <CustomSelect placeholder={'Select'} optionsList={this.getResequenceMethods()} onClick={(option) => this.updateResequenceMethod(option.id)} id={'manage-method'} />
                    </div>
                    <div className='button-group t--align-right'>
                      <Button classes={`button button--plain`} onClick={() => this.showModal(false)} text='Cancel' />
                      <Button classes={`button button--med button--blue`} onClick={() => this.manageRouteTemplate()} text='Manage' />
                    </div>
                  </div>
                )}
              </div>
            )}
          </Modal>
        )}
        <img className='wave' src={wave} alt={''} />
      </div>
    );
  }
}

export default withRouter(RouteTemplates);
