import React from 'react';
import Button from '../Button';
import AssignReaderPopover from '../AssignReaderPopover';
import Icon from '../Icon';
import moment from 'moment';
import Loader from 'react-loader-spinner';
import { ROLE } from '../../constants/UserRole';

class AssignmentReallocationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditState: false,
      reallocationReasonProvided: false,
      reallocationReason: '',
      newReader: props.data.newReader,
      isCheckingSyncStatus: false,
    };
  }

  toggleEditState() {
    const show = !this.state.showEditState;

    this.setState({
      showEditState: show,
    });
  }

  selectReader(reader) {
    this.setState({
      newReader: { readerName: reader.newReaderName, id: reader.newReaderId },
    });
  }

  handleReasonChange(e) {
    this.setState({
      reallocationReasonProvided: e.target.value.length > 12,
      reallocationReason: e.target.value,
    });
  }

  checkSyncStatus() {
    this.setState({
      isCheckingSyncStatus: true,
    });

    //TODO: re-get route data
    setTimeout(() => {
      this.setState({
        isCheckingSyncStatus: false,
      });
    }, 3000);
  }

  reallocateRoute() {
    this.props.updateFn(this.state.newReader, this.state.reallocationReason);
  }

  render() {
    let currentReader = this.props.data.currentReader;
    let hasOverridePerm = this.props.userData.securityRoles.canReallocateInProgressRoutes;
    let userRole = '';

    if (hasOverridePerm) {
      userRole = this.props.userRoles.includes(ROLE.CONTRACT_MANAGER) ? 'Contract Manager' : 'Supervisor';
    }

    // If the reader has not synced recently, the safety check will come into effect
    let lateSync = moment(currentReader.lastSync).isBefore(moment().subtract(1, 'hours'));

    return (
      <div id={'reallocation-modal'} className='modal-content'>
        <div className='heading'>Reallocating A Route</div>
        {!this.state.isCheckingSyncStatus && (
          <div className='form-container'>
            <p>
              {this.props.data.routeCount > 1 ? `These ${this.props.data.routeCount} routes are` : 'This route is'} currently out in the field with reader {currentReader.readerName}. We have put in
              some checks to ensure we do not end up with TWO readers completing the same work.
            </p>

            {!lateSync && (
              <p>
                Reader {currentReader.readerName}&apos;s handheld shows they have last synchronised {currentReader.lastSync.fromNow()}. Please ensure they are aware the work is about to be
                reallocated, and that the route will be removed from their device on the next sync.
              </p>
            )}
            {lateSync && (
              <p>
                Reader {currentReader.readerName}&apos;s handheld shows they have NOT synchronised since {currentReader.lastSync.fromNow()}.
              </p>
            )}

            {lateSync && hasOverridePerm && (
              <div>
                <p>
                  MiMtr has NO way of knowing what work has been completed, and what is yet to be completed. However, as a {userRole}, you have the ability to OVERRIDE this safety check and FORCE the
                  reallocation.
                </p>
                <p>Are you CERTAIN you want to continue? (You will need to provide a reason for the MiMtr LOG)</p>
              </div>
            )}

            {(!lateSync || hasOverridePerm) && (
              <div>
                <div className='form-element'>
                  <label>New Reader</label>
                  <div className='popover-container'>
                    {!this.state.showEditState && (
                      <>
                        <div id={'input-' + this.state.dataId} className={`select-container`} onClick={() => this.toggleEditState()}>
                          <span className='u--ellipsis' title={this.state.newReader.readerName}>
                            {this.state.newReader.readerName}
                          </span>
                          <Icon name='chevron' />
                        </div>
                      </>
                    )}
                    {this.state.showEditState && (
                      <>
                        <div id={'input-' + this.state.dataId} className={`select-container selected`} onClick={() => this.toggleEditState()}>
                          <span className='u--ellipsis' title={this.state.newReader.readerName}>
                            {this.state.newReader.readerName}
                          </span>
                          <Icon name='chevron' />
                        </div>
                        <AssignReaderPopover
                          data={{}}
                          toggleState={() => this.toggleEditState()}
                          showEditState={this.state.showEditState}
                          optionLoadFn={this.props.optionLoadFn}
                          updateFn={(data) => this.selectReader(data)}
                          type={'assign-reader'}
                        />
                      </>
                    )}
                  </div>
                </div>
                {lateSync && (
                  <div className='form-element'>
                    <label>Reason For Reallocation (Min 12 Chars)</label>
                    <textarea onChange={(e) => this.handleReasonChange(e)} />
                  </div>
                )}
              </div>
            )}

            {lateSync && !hasOverridePerm && (
              <div>
                <p>You have 2 options:</p>
                <ol>
                  <li>
                    Contact the reader and get them to perform a &lt;Force Sync&gt; on their device. This will update MiMtr with the CURRENT status of their work and will ensure that the incomplete
                    work ONLY will be reallocated.
                  </li>
                  <li>If you are unable to contact the reader, you can ask your Supervisor or Contract Manager to override this safety check.</li>
                </ol>
                <p>Would you like to recheck their sync status?</p>

                <Button classes='button button--blue' onClick={() => this.checkSyncStatus()} text='Yes' />
              </div>
            )}
          </div>
        )}

        <div className='button-group t--align-right'>
          <Button classes={`button button--plain`} onClick={() => this.props.showModalFn(false)} text='Cancel' />
          <Button
            classes={`button button--med button--blue ${lateSync && !this.state.reallocationReasonProvided ? 'button--disabled' : ''}`}
            onClick={() => this.reallocateRoute()}
            text='Reallocate'
          />
        </div>

        {this.state.isCheckingSyncStatus && (
          <div>
            <div className='spinner-container'>
              <h3>Checking {currentReader.readerName}&apos;s last sync...</h3>
              <Loader type='Bars' color='#008dce' height={30} width={30} visible={this.state.isCheckingSyncStatus} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default AssignmentReallocationModal;
