import React from 'react';
import _ from 'lodash';
import ButtonGroup from '../ButtonGroup';
import CustomSelect from '../CustomSelect';
import DatePickerSelect from '../DatePickerSelect';

class AssignmentsBulkUpdatePopover extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultSelectText: 'Select',
      readers: _.sortBy(props.options, 'name'),
      selectedReader: null,
      selectedDate: null,
    };
  }

  updateReader(id) {
    this.setState({
      selectedReader: id,
    });
  }

  updateDate(date) {
    this.setState({
      selectedDate: date,
    });
  }

  updateRoutes() {
    this.props.onClick(this.state.selectedReader, this.state.selectedDate);
  }

  render() {
    return (
      <div className='popover-content'>
        {this.props.selectedGroup !== 'inProgress' && (
          <div className='form-element'>
            <label>Scheduled Date</label>
            <DatePickerSelect id={'scheduled-date'} defaultText={this.state.defaultSelectText} onClick={(date) => this.updateDate(date)} />
          </div>
        )}
        <div className='form-element'>
          <label>Assigned Reader</label>
          <CustomSelect id={'assigned-reader'} placeholder={this.state.defaultSelectText} optionsList={this.state.readers} onClick={(id) => this.updateReader(id)} />
        </div>
        <div className='warning-message'>{this.props.warningMsg}</div>
        <div className='popover-footer'>
          <div></div>
          <ButtonGroup
            items={[
              { type: 'button', classes: 'button--plain', text: 'Cancel', onClick: () => this.props.cancelFn() },
              { type: 'button', classes: `button--blue ${this.props.warningMsg ? 'button--disabled' : ''}`, text: 'Update', onClick: () => this.updateRoutes() },
            ]}
          />
        </div>
      </div>
    );
  }
}

export default AssignmentsBulkUpdatePopover;
