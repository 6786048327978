import React from 'react';
import Button from './Button';
import PopoverButton from './PopoverButton';
import SearchInput from './SearchInput';
import Icon from './Icon';
import CustomSelect from './CustomSelect';
import ToggleButton from './ToggleButton';
import DatePickerSelect from './DatePickerSelect';

class ButtonGroup extends React.Component {
  render() {
    return (
      <div className='button-group'>
        {this.props.items.map(function (item, index) {
          if (item.showCondition && !item.showCondition()) {
            return null;
          }

          switch (item.type) {
            case 'button':
              return <Button classes={item.classes ? item.classes : ''} icon={item.icon} iconPosition={item.iconPosition} text={item.text} onClick={item.onClick} key={index} />;
            case 'bulk-update-button':
              if (item.popover) {
                return <PopoverButton data={item} key={index} />;
              } else {
                return <Button classes={item.classes ? item.classes : ''} icon={item.icon} iconPosition={item.iconPosition} text={item.text} onClick={item.onClick} key={index} />;
              }
            case 'filter-button':
            case 'sort-button':
              return <PopoverButton data={item} key={index} />;
            case 'search-input':
              return <SearchInput onChange={(key) => item.onChange(key)} key={index} placeholder={item.placeholder} />;
            case 'select':
              return <CustomSelect defaultText={item.defaultText} displayLabel={item.displayLabel} optionsList={item.options} onClick={(option) => item.updateFn(option)} key={index} id={item.name} />;
            case 'date-picker-select':
              let selection = { startDate: item.selection.start, endDate: item.selection.end };
              return (
                <DatePickerSelect
                  displayLabel={item.displayLabel}
                  selectionText={selection.startDate ? selection.startDate.format('DD/MM/YY') + ' - ' + selection.endDate.format('DD/MM/YY') : ''}
                  selection={selection}
                  range={item.range}
                  onClick={(startDate, endDate) => item.updateFn(item.name, { start: startDate, end: endDate })}
                  id={item.name}
                  key={item.name}
                />
              );
            case 'text':
              return (
                <div className={`input-container ${item.classes ? item.classes : ''}`} key={item.name}>
                  <div className='placeholder'>{item.defaultText}</div>
                  <input id={`input-filter-${item.name}`} type='text' onChange={(e) => item.onClick(item.name, e.target.value)} />
                </div>
              );
            case 'toggle-button':
              return <ToggleButton options={item.options} key={index} onClick={item.onClick} selection={item.selection} />;
            case 'checkbox-all':
              return (
                <div className='checkbox-container' key={index}>
                  <input type='checkbox' id={'checkbox-all'} />
                  <label htmlFor={'checkbox-all'} onClick={() => item.onClick()} />
                </div>
              );
            case 'checkbox':
              return (
                <div className='checkbox-container' key={index}>
                  <input type='checkbox' id={`checkbox-${item.name}`} checked={item.selected} />
                  <label htmlFor={`checkbox-${item.name}`} onClick={() => item.onClick()} />
                  <span onClick={() => item.onClick(`checkbox-${item.name}`)}>{item.text}</span>
                </div>
              );
            case 'close':
              return (
                <div className='button button--plain' title='Close' onClick={() => item.onClick()} key={index}>
                  <Icon name='close' />
                </div>
              );
            case 'pipe':
              return (
                <div className='button button--plain' key={index}>
                  {' '}
                  |{' '}
                </div>
              );
            default:
              return '';
          }
        })}
      </div>
    );
  }
}

export default ButtonGroup;
