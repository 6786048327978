import React from 'react';
import Loader from 'react-loader-spinner';
import wave from '../../images/wave.svg';
import logo from '../../images/skilltech-mimtr-logo-dark.png';
import uat_logo from '../../images/skilltech-mimtr-logo-uat-dark.png';
import './quintiq-page.scss';
import TabContainer from '../TabContainer';
import moment from 'moment';
import _ from 'lodash';
import ResultsContainer from '../ResultsContainer';
import QuintiqResult from './QuintiqResult';
import Utils from '../../utils/Utils';
import ExportUtils from '../../utils/ExportUtils';

class Quintiq extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      masterData: {},
      quintiqData: {},
      buttonData: [],
      searchFn: null,
      filterFn: null,
      selectedGroup: 'time',
      dateFrom: moment().startOf('week'),
      dateTo: moment().endOf('week'),
    };
  }

  componentDidMount() {
    document.title = `${process.env.REACT_APP_ENV ? `[${process.env.REACT_APP_ENV}] ` : ''}MiMtr Hybrid | Quintiq`;

    this.getData();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.selectedContract.id !== this.props.selectedContract.id) {
      this.setState({
        sortKey: 'date',
        searchFn: null,
        filterFn: null,
      });
      this.getData();
    }
  }

  async getData() {
    this.setState({ isLoading: true });
    this.getTimeData().then((timeData) => {
      this.getAllowanceData().then((allowanceData) => {
        this.getNoBreakData().then((noBreakData) => {
          let quintiqData = {
            time: timeData,
            allowances: allowanceData,
            noBreak: noBreakData,
          };

          this.setState({
            isLoading: false,
            quintiqData: quintiqData,
            masterData: quintiqData,
            buttonData: this.getButtonData(null, quintiqData),
          });
        });
      });
    });
  }

  async getTimeData() {
    return new Promise((resolve, reject) => {
      let dateFrom = this.state.dateFrom.format('YYYY-MM-DD');
      let dateTo = this.state.dateTo.format('YYYY-MM-DD');

      let timeApiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getQuintiqInputTimeWages?dateFrom=${dateFrom}&dateTo=${dateTo}&contractId=${this.props.selectedContract.id}`;
      fetch(timeApiUrl)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
            return res.json();
          }
        })
        .then((data) => {
          if (!data.results || !data.results.length || !data.results[0]) {
            resolve([]);
            return;
          }

          let timeData = data.results.map((time) => {
            return {
              id: time.TimePeriodId,
              timesheetId: time.TimesheetId,
              date: moment(time.StartDate).format('DD/MM/YYYY'),
              unformattedDate: moment(time.StartDate),
              employeeName: Utils.titleize(time.UserName.replace('.', ' ')),
              employeeId: time.employeeId,
              startTime: time.StartTime.substring(0, time.StartTime.length - 3),
              endTime: time.EndTime.substring(0, time.EndTime.length - 3),
              categoryName: time.TimeCategoryName,
              costCentreCode: time.CostCentreCode,
              sapReferenceNumber: time.SapReferenceNumber,
              sapWageType: time.SapReferenceNumber_WageType,
              completedDate: !_.isEmpty(time.CompletedUtcDatetime.trim()) ? moment(time.CompletedUtcDatetime).format('DD/MM/YYYY') : '',
              wbsCode: time.WBSCode,
              completedBy: time.CompletedBy.trim().toLowerCase(),
            };
          });

          timeData = _.sortBy(timeData, 'unformattedDate');

          resolve(timeData);
        })
        .catch((err) => {});
    });
  }

  async getAllowanceData() {
    return new Promise((resolve, reject) => {
      let dateFrom = this.state.dateFrom.format('YYYY-MM-DD');
      let dateTo = this.state.dateTo.format('YYYY-MM-DD');

      let allowanceApiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getQuintiqInputAllowances?dateFrom=${dateFrom}&dateTo=${dateTo}&contractId=${this.props.selectedContract.id}`;
      fetch(allowanceApiUrl)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
            return res.json();
          }
        })
        .then((data) => {
          if (!data.results || !data.results.length || !data.results[0]) {
            resolve([]);
            return;
          }

          let allowanceData = data.results.map((allowance) => {
            return {
              timesheetId: allowance.TimesheetId,
              id: allowance.TimesheetAllowanceId,
              date: moment(allowance.StartDate).format('DD/MM/YYYY'),
              unformattedDate: moment(allowance.StartDate),
              employeeName: Utils.titleize(allowance.UserName.replace('.', ' ')),
              employeeId: allowance.employeeId,
              quantity: allowance.Quantity.toFixed(2),
              unitsName: allowance.UnitsName,
              categoryName: allowance.Name,
              costCentreCode: allowance.CostCentreCode,
              sapReferenceNumber: allowance.SapReferenceNumber,
              sapPayCode: allowance.SapReferenceNumber_PayCode,
              wbsCode: allowance.WBSCode,
              completedDate: !_.isEmpty(allowance.CompletedUtcDatetime.trim()) ? moment(allowance.CompletedUtcDatetime).format('DD/MM/YYYY') : null,
              completedBy: allowance.CompletedBy.trim().toLowerCase(),
            };
          });

          allowanceData = _.sortBy(allowanceData, 'unformattedDate');

          resolve(allowanceData);
        })
        .catch((err) => {});
    });
  }

  async getNoBreakData() {
    return new Promise((resolve, reject) => {
      let dateFrom = this.state.dateFrom.format('YYYY-MM-DD');
      let dateTo = this.state.dateTo.format('YYYY-MM-DD');

      let noBreakApiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getQuintiqInputNoBreaks?dateFrom=${dateFrom}&dateTo=${dateTo}&contractId=${this.props.selectedContract.id}&minHours=5`;
      fetch(noBreakApiUrl)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
            return res.json();
          }
        })
        .then((data) => {
          if (!data.results || !data.results.length || !data.results[0]) {
            resolve([]);
            return;
          }

          let noBreakData = data.results.map((noBreak) => {
            return {
              timesheetId: noBreak.TimesheetId,
              id: noBreak.TimesheetId,
              employeeName: Utils.titleize(noBreak.UserName.replace('.', ' ')),
              employeeId: noBreak.employeeId,
              productive: noBreak.Productive,
              officialBreak: noBreak.OfficialBreak,
              date: moment(noBreak.StartDate).format('DD/MM/YYYY'),
              unformattedDate: moment(noBreak.StartDate),
              categoryName: noBreak.TimeCategoryName,
              costCentreCode: noBreak.CostCentreCode,
              sapReferenceNumber: noBreak.SapReferenceNumber,
              completedDate: !_.isEmpty(noBreak.CompletedUtcDatetime.trim()) ? moment(noBreak.CompletedUtcDatetime).format('DD/MM/YYYY') : null,
              completedBy: noBreak.CompletedBy.trim().toLowerCase(),
            };
          });

          noBreakData = _.sortBy(noBreakData, 'unformattedDate');

          resolve(noBreakData);
        })
        .catch((err) => {});
    });
  }

  getButtonData(selectedGroup, quintiqData) {
    let employees = [];
    let ccc = [];

    _.forEach(quintiqData, (group) => {
      employees.push(
        ..._.uniqBy(
          group.filter((data) => data.employeeName),
          'employeeName'
        ).map((data) => {
          return { id: data.employeeName, name: data.employeeName };
        })
      );
      ccc.push(
        ..._.uniqBy(
          group.filter((data) => data.costCentreCode),
          'costCentreCode'
        ).map((data) => {
          return { id: data.costCentreCode, name: data.costCentreCode };
        })
      );
    });

    employees = _.uniqBy(employees, 'name');
    ccc = _.uniqBy(ccc, 'name');

    return [
      { type: 'button', classes: 'button--plain', icon: 'checkbox', iconPosition: '1', text: 'Export Results', onClick: () => this.exportToCSV() },
      {
        type: 'group',
        items: [
          {
            type: 'date-picker-select',
            name: 'dateRange',
            displayLabel: 'Date Range',
            range: true,
            selection: { start: this.state.dateFrom, end: this.state.dateTo },
            updateFn: (name, dateRange) => this.changeDate(dateRange),
          },
          {
            type: 'filter-button',
            text: 'Filter',
            icon: 'filter',
            iconPosition: '2',
            popover: {
              type: 'filter',
              onClick: (filterData) => this.filterResults(filterData),
              filters: [
                {
                  name: 'employeeName',
                  displayLabel: 'Employee',
                  type: 'select',
                  multiselect: true,
                  selection: [],
                  options: _.sortBy(employees, 'name'),
                },
                {
                  name: 'timeCreditType',
                  displayLabel: 'Time Credit Type',
                  type: 'select',
                  multiselect: true,
                  hide: () => {
                    return this.state.selectedGroup === 'time';
                  },
                  selection: [],
                  options: _.sortBy(
                    _.uniqBy(
                      quintiqData.time.filter((data) => data.categoryName),
                      'categoryName'
                    ).map((data) => {
                      return { id: data.categoryName, name: data.categoryName };
                    }),
                    'name'
                  ),
                },
                {
                  name: 'allowanceType',
                  displayLabel: 'Allowance Type',
                  type: 'select',
                  multiselect: true,
                  hide: () => {
                    return this.state.selectedGroup === 'allowances';
                  },
                  selection: [],
                  options: _.sortBy(
                    _.uniqBy(
                      quintiqData.allowances.filter((data) => data.categoryName),
                      'categoryName'
                    ).map((data) => {
                      return { id: data.categoryName, name: data.categoryName };
                    }),
                    'name'
                  ),
                },
                {
                  name: 'costCentreCode',
                  displayLabel: 'Cost Centre Code',
                  type: 'select',
                  multiselect: true,
                  selection: [],
                  options: _.sortBy(ccc, 'name'),
                },
                {
                  name: 'showCompleted',
                  displayLabel: 'Completed',
                  type: 'select',
                  selection: null,
                  options: [
                    { name: 'Only Show Completed', value: true },
                    { name: 'Hide Completed', value: false },
                  ],
                },
              ],
            },
          },
          {
            type: 'sort-button',
            classes: 'button--active',
            text: 'Sort By',
            icon: 'sort',
            iconPosition: '2',
            popover: {
              type: 'select',
              onClick: (key) => this.sortResults(key),
              options: [
                { name: 'Date', value: 'unformattedDate' },
                { name: 'Employee', value: 'employeeName' },
                {
                  name: 'Time Entry Category',
                  value: 'categoryName',
                  hide: () => {
                    return this.state.selectedGroup !== 'time';
                  },
                },
                {
                  name: 'Allowance Category',
                  value: 'categoryName',
                  hide: () => {
                    return this.state.selectedGroup !== 'allowances';
                  },
                },
                { name: 'Cost Centre Code', value: 'costCentreCode' },
                { name: 'SAP Reference No.', value: 'sapReferenceNumber' },
                {
                  name: 'SAP Wage Type',
                  value: 'sapWageType',
                  hide: () => {
                    return this.state.selectedGroup !== 'time';
                  },
                },
                {
                  name: 'SAP Pay Code',
                  value: 'sapPayCode',
                  hide: () => {
                    return this.state.selectedGroup !== 'allowances';
                  },
                },
              ],
            },
            selectedSort: 'unformattedDate',
            sortAsc: true,
          },
          {
            type: 'search-input',
            onChange: (key) => this.searchResults(key),
            placeholder: 'Search',
          },
        ],
      },
    ];
  }

  selectStatusGroup(tabName) {
    let buttonData = this.state.buttonData;
    const buttonGroupIndex = buttonData.findIndex((button) => button.type === 'group');
    const buttonGroupData = buttonData[buttonGroupIndex];
    const filterButtonIndex = buttonGroupData.items.findIndex((button) => button.type === 'filter-button');
    const filterPopoverData = buttonGroupData.items[filterButtonIndex].popover;

    let filters = _.filter(filterPopoverData.filters, (filter) => {
      if (typeof filter.hide === 'function') {
        return !filter.hide();
      } else {
        return true;
      }
    });

    buttonGroupData.items[filterButtonIndex].classes = _.some(filters, (filter) => filter.selection && filter.selection.length > 0) ? 'button--active' : '';
    buttonData[buttonGroupIndex] = buttonGroupData;

    this.setState({
      selectedGroup: tabName,
      buttonData: buttonData,
    });
  }

  changeDate(dateRange) {
    this.setState(
      {
        dateTo: dateRange.end,
        dateFrom: dateRange.start,
        isLoading: true,
      },
      () => {
        this.getTimeData().then((timeData) => {
          this.getAllowanceData().then((allowanceData) => {
            this.getNoBreakData().then((noBreakData) => {
              let quintiqData = {
                time: timeData,
                allowances: allowanceData,
                noBreak: noBreakData,
              };

              let buttonData = this.getButtonData(this.state.selectedGroup, quintiqData);
              const buttonGroup = buttonData.find((button) => button.type === 'group');
              const dateRangeButton = buttonGroup.items.find((button) => button.name === 'dateRange');
              dateRangeButton.selection = { start: dateRange.start, end: dateRange.end };

              this.setState({
                masterData: quintiqData,
                quintiqData: quintiqData,
                buttonData: buttonData,
                isLoading: false,
              });
            });
          });
        });
      }
    );
  }

  async updateData(data, complete) {
    let masterData = this.state.masterData;
    let matchingData = masterData[this.state.selectedGroup].find((masterResult) => masterResult.id === data.id);

    matchingData.completedDate = complete ? moment().format('DD/MM/YYYY') : null;
    matchingData.completedBy = complete ? this.props.userData.username : null;

    return new Promise((resolve, reject) => {
      let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/updateQuintiqInput`;

      let body = {
        userId: this.props.userData.userId,
        timesheetId: data.timesheetId,
        remove: !complete,
      };

      switch (this.state.selectedGroup) {
        case 'time':
          body.timePeriodId = data.id;
          break;
        case 'allowances':
          body.timesheetAllowanceId = data.id;
          break;
        case 'noBreak':
          body.noBreak = true;
          break;
        default:
          break;
      }

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      };

      fetch(apiUrl, requestOptions)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
            return res.json();
          }
        })
        .then((data) => {
          fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
        });

      let quintiqData = _.cloneDeep(masterData);

      _.forEach(quintiqData, (group, key) => {
        if (this.state.filterFn) {
          quintiqData[key] = group.filter((result) => this.state.filterFn(result, key));
        }

        if (this.state.searchFn) {
          quintiqData[key] = quintiqData[key].filter(this.state.searchFn);
        }
      });

      this.setState({
        masterData: masterData,
        quintiqData: quintiqData,
      });

      resolve();
    });
  }

  exportToCSV() {
    let fields = [];
    let selectedGroup = this.state.selectedGroup;

    switch (selectedGroup) {
      case 'time':
        fields = {
          Date: 'date',
          'Employee Name': 'employeeName',
          'Start Time': 'startTime',
          'End Time': 'endTime',
          Category: 'categoryName',
          'Cost Centre Code': 'costCentreCode',
          'SAP Reference Number': 'sapReferenceNumber',
          'SAP Wage Type': 'sapWageType',
          'WBS Code': 'wbsCode',
          'Completed Date': 'completedDate',
          'Completed By': 'completedBy',
        };
        break;

      case 'allowances':
        fields = {
          Date: 'date',
          'Employee Name': 'employeeName',
          'Allowance Quantity': 'quantity',
          'Allowance Units': 'unitsName',
          Category: 'categoryName',
          'Cost Centre Code': 'costCentreCode',
          'SAP Reference Number': 'sapReferenceNumber',
          'SAP Pay Code': 'sapPayCode',
          'WBS Code': 'wbsCode',
          'Completed Date': 'completedDate',
          'Completed By': 'completedBy',
        };
        break;

      case 'noBreak':
        fields = {
          Date: 'date',
          'Employee Name': 'employeeName',
          'Productive Hours': 'productive',
          'Official Break': 'officialBreak',
          'Cost Centre Code': 'costCentreCode',
          'SAP Reference Number': 'sapReferenceNumber',
          'Completed Date': 'completedDate',
          'Completed By': 'completedBy',
        };

        break;
      default:
        break;
    }

    const filename = 'Quintiq-Input_' + Utils.titleize(selectedGroup) + '_' + moment().format('DD-MM-YYYY') + '.csv';

    ExportUtils.exportToCSV(this.state.quintiqData[selectedGroup], fields, filename);
  }

  filterResults(filterData) {
    let filterFn;
    let buttonData;
    let data = _.cloneDeep(this.state.masterData);

    if (!filterData) {
      filterFn = (result) => {
        return result;
      };
      buttonData = this.getButtonData(null, this.state.masterData);

      if (this.state.searchFn) {
        _.forEach(data, (group, key) => {
          data[key] = group.filter(this.state.searchFn);
        });
      }
    } else {
      const selectedEmployeeNames = filterData[0].selection;
      const selectedTimeCategory = filterData[1].selection;
      const selectedAllowanceCategory = filterData[2].selection;
      const selectedCostCenterCodes = filterData[3].selection;
      const showCompleted = filterData[4].selection;

      filterFn = function (result, group) {
        return (
          (!selectedEmployeeNames.length || selectedEmployeeNames.includes(result.employeeName)) &&
          (group !== 'time' || !selectedTimeCategory.length > 0 || selectedTimeCategory.includes(result.categoryName)) &&
          (group !== 'allowances' || !selectedAllowanceCategory.length > 0 || selectedAllowanceCategory.includes(result.categoryName)) &&
          (!selectedCostCenterCodes.length > 0 || selectedCostCenterCodes.includes(result.costCentreCode)) &&
          (!showCompleted || !!result.completedDate === showCompleted.value)
        );
      };

      buttonData = this.state.buttonData;

      const buttonGroupIndex = buttonData.findIndex((button) => button.type === 'group');
      const buttonGroupData = buttonData[buttonGroupIndex];
      const filterButtonIndex = buttonGroupData.items.findIndex((button) => button.type === 'filter-button');
      const filterPopoverData = buttonGroupData.items[filterButtonIndex].popover;
      filterPopoverData.filters = filterData;

      buttonGroupData.items[filterButtonIndex].classes = _.some(
        _.filter(filterData, (filter) => typeof filter.hide === 'function' && !filter.hide()),
        'selection'
      )
        ? 'button--active'
        : '';
      buttonGroupData.items[filterButtonIndex].popover = filterPopoverData;
      buttonData[buttonGroupIndex] = buttonGroupData;

      _.forEach(data, (group, key) => {
        data[key] = group.filter((result) => filterFn(result, key));

        if (this.state.searchFn) {
          data[key] = data[key].filter(this.state.searchFn);
        }
      });
    }

    this.setState({
      buttonData: buttonData,
      quintiqData: data,
      filterFn: filterFn ? filterFn : null,
    });
  }

  sortResults(sortKey) {
    let data = this.state.quintiqData;
    let masterData = this.state.masterData;
    let currentSortKey, sortAsc;

    // Find the sort button and check if the sort key was already selected
    let buttonData = this.state.buttonData;
    const buttonGroup = buttonData.find((button) => button.type === 'group');
    buttonGroup.items.forEach((button) => {
      if (button.type === 'sort-button') {
        currentSortKey = button.selectedSort;

        // If the sort key was reselected, toggle asc/desc order
        if (currentSortKey === sortKey) {
          button.sortAsc = !button.sortAsc;
        } else {
          button.sortAsc = true;
        }

        button.selectedSort = sortKey;
        sortAsc = button.sortAsc;
      }
    });

    _.forEach(data, (group, key) => {
      if (group.length > 0) {
        data[key] = _.orderBy(group, sortKey, sortAsc ? 'asc' : 'desc');
      }
    });

    _.forEach(masterData, (group, key) => {
      if (group.length > 0) {
        masterData[key] = _.orderBy(group, sortKey, sortAsc ? 'asc' : 'desc');
      }
    });

    this.setState({
      quintiqData: data,
      masterData: masterData,
    });
  }

  searchResults(searchTerm) {
    let data = _.cloneDeep(this.state.masterData);
    let searchFn = null;

    if (searchTerm) {
      searchFn = function (result) {
        return (
          result.employeeName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (!!result.categoryName && result.categoryName.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (!!result.costCentreCode && result.costCentreCode.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (!!result.sapReferenceNumber && result.sapReferenceNumber.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (!!result.sapWageType && result.sapWageType.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (!!result.sapPayCode && result.sapPayCode.toLowerCase().includes(searchTerm.toLowerCase()))
        );
      };
    }

    _.forEach(data, (group, key) => {
      if (searchTerm) {
        data[key] = group.filter(searchFn);
      }

      if (this.state.filterFn) {
        data[key] = data[key].filter((result) => this.state.filterFn(result, key));
      }
    });

    this.setState({
      quintiqData: data,
      searchFn: searchFn,
    });
  }

  getResultItems() {
    let resultData = {};

    let quintiqData = this.state.quintiqData;

    _.forEach(quintiqData, (group, key) => {
      if (key) {
        resultData[key] = group.map((data, index) => {
          return <QuintiqResult data={data} type={key} updateFn={(complete) => this.updateData(data, complete)} onClick={() => this.openTimesheet(data)} key={index} />;
        });
      }
    });

    return resultData;
  }

  openTimesheet(data) {
    const dateArray = data.date.split('/');
    const newDateString = `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}T00:00:00.000Z`;
    localStorage.setItem('selectedEmployeeTimesheet', JSON.stringify({ id: data.employeeId, name: data.employeeName, date: newDateString }));
    window.open('/hybrid/timesheet/employee', 'TimesheetTab');
  }

  render() {
    let data = { resultData: this.getResultItems() };

    const groups = ['time', 'allowances', 'noBreak'].map((groupName) => {
      return {
        name: groupName,
        count: data.resultData[groupName] ? data.resultData[groupName].length : 0,
      };
    });

    return (
      <div className={'main-content quintiq-page'}>
        <header>
          <h1>Quintiq Input</h1>
          <img className='logo-container' src={process.env.REACT_APP_ENV ? uat_logo : logo} alt='' />
        </header>
        {!this.state.isLoading && <TabContainer id={'manage-timesheets'} groups={groups} selectedTab={this.state.selectedGroup} onClick={(name) => this.selectStatusGroup(name)} />}
        {!this.state.isLoading && (
          <ResultsContainer
            data={data}
            buttonData={this.state.buttonData}
            selectedGroup={this.state.selectedGroup}
            dataClass={'Quintiq Input'}
            isLoading={this.state.isLoading}
            bulkSelecting={this.state.bulkSelecting}
          />
        )}

        {this.state.isLoading && (
          <div className='spinner-container'>
            <Loader type='Bars' color='#008dce' height={30} width={30} visible={this.state.isLoading} />
          </div>
        )}
        <img className='wave' src={wave} alt={''} />
      </div>
    );
  }
}

export default Quintiq;
