export const ROLE = {
  ADMINISTRATOR: 1,
  METER_READER: 2,
  FIELD_OFFICER: 3,
  JOB_SCHEDULER: 4,
  CUSTOMER_LIAISON_OFFICER: 5,
  SUPERVISOR: 6,
  CONTRACT_MANAGER: 7,
  PROVISIONAL_APPROVER: 8,
  MASTER_APPROVER: 9,
  APPROVER: 10,
  ROUTE_APPROVALS: 11,
  DASHBOARD: 12,
  TIME_AND_ATTENDANCE_ADMIN: 14,
  TIMESHEET_ONLY: 15,
};
