import React from 'react';
import DatePicker from './DatePicker';
import Icon from './Icon';
import AssignReaderPopover from './AssignReaderPopover';
import CustomSelect from './CustomSelect';

class InlineEditable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditState: false,
      dataId: props.data && props.data.id ? props.data.id : props.data,
      data: props.data,
      isValid: true,
    };
  }

  handleClick = (e) => {
    const element = document.getElementById('inline-editable-' + this.props.type + '-' + this.state.dataId);
    if (this.state.showEditState && !element.contains(e.target)) {
      this.toggleEditState();
    }
  };

  handleKeydown = (e) => {
    if (e.key === 'Escape') {
      this.toggleEditState(false);
    } else if (e.key === 'Enter' && this.state.showEditState) {
      if (this.props.validationFn(e.target.value)) {
        this.props.updateFn(e.target.value);
        this.toggleEditState(false);
      } else {
        this.setState({ isValid: false });
      }
    }
  };

  handleChange = (e) => {
    this.setState({ data: e.target.value });
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick);

    if (this.props.type === 'input') {
      document.addEventListener('keydown', this.handleKeydown);
    }
  }

  componentWillUnmount() {
    // Remove the event listener on component unmounting
    document.removeEventListener('mousedown', this.handleClick);
    document.removeEventListener('keydown', this.handleKeydown);
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        data: this.props.data,
        dataId: this.props.data && this.props.data.id ? this.props.data.id : this.props.data,
      });
    }
  }

  toggleEditState() {
    const show = !this.state.showEditState;

    this.setState({
      showEditState: show,
    });
  }

  renderEditState(data, text, showEditState) {
    let selectContainer = (
      <div id={'input-' + this.state.dataId} className={`select-container ${showEditState ? 'selected' : ''}`} onClick={() => this.toggleEditState()}>
        <span className='u--ellipsis' title={text}>
          {text}
        </span>
        <Icon name='chevron' />
      </div>
    );

    switch (this.props.type) {
      case 'assign-reader':
      case 'assign-preferred-reader':
        return (
          <>
            {selectContainer}
            <AssignReaderPopover
              data={data}
              toggleState={() => this.toggleEditState()}
              showEditState={showEditState}
              optionLoadFn={this.props.optionLoadFn}
              updateFn={(data) => this.props.updateFn(data)}
              type={this.props.type}
            />
          </>
        );
      case 'date':
        return (
          <>
            {selectContainer}
            <div className={`button-popover date-picker-popover`}>
              <DatePicker route={data} toggleState={() => this.toggleEditState()} showEditState={showEditState} updateRoute={(data) => this.props.updateFn(data)} type={this.props.type} />
            </div>
          </>
        );
      case 'select':
        return <CustomSelect defaultText={data ? data : 'Select'} showOptionList={true} optionsList={this.props.optionList} onClick={(id) => this.props.updateFn(id)} id={this.props.field} />;
      default:
        return (
          <input
            id={'input-' + this.props.field}
            className={this.state.isValid ? '' : 'input--invalid'}
            type='text'
            title="Hit 'Enter' to Save"
            onChange={(key) => this.handleChange(key)}
            value={this.state.data === null ? '' : this.state.data}
            autoFocus={true}
          />
        );
    }
  }

  render() {
    const showEditState = this.state.showEditState;
    const data = this.props.data;

    let text;
    switch (this.props.type) {
      case 'assign-reader':
        text = data.readerName ? data.readerName : 'Unassigned';
        break;
      case 'assign-preferred-reader':
        text = data.preferredReaderName ? data.preferredReaderName : 'Assign Preferred Reader';
        break;
      case 'date':
        text = data.scheduledDate ? data.scheduledDate.format('DD/MM/YYYY') : 'No Scheduled Date';
        break;
      default:
        text = data ? data : 'N/A';
    }

    return (
      <div id={'inline-editable-' + this.props.type + '-' + this.state.dataId} className='inline-editable button-container'>
        {!this.props.readOnly && (
          <>
            {!showEditState && (
              <>
                <span className='u--ellipsis' title={text} onClick={() => this.toggleEditState()}>
                  {text}
                  {this.props.icon && <Icon name='edit' />}
                </span>
              </>
            )}
            {showEditState && this.renderEditState(data, text, showEditState)}
          </>
        )}
        {this.props.readOnly && (
          <span className='read-only u--ellipsis' title={text}>
            {text}
          </span>
        )}
      </div>
    );
  }
}

export default InlineEditable;
