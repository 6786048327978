import React from 'react';
import Button from '../Button';

class EmployeeRosterExportModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sapReference: null,
    };
  }

  updateField(field, value) {
    this.setState({
      [field]: value,
    });
  }

  render() {
    return (
      <div id={'employee-roster-export-modal'} className='modal-content'>
        <div className='heading'>Employee Roster Export</div>
        <div className='form-container'>
          <div className='form-element'>
            <label>SAP Reference # (Optional)</label>
            <input
              id={`input-sap-reference`}
              type='text'
              autoComplete='off'
              value={this.state.sapReference ? this.state.sapReference : ''}
              onChange={(e) => this.updateField('sapReference', e.target.value)}
            />
          </div>
        </div>
        <div className='button-group t--align-right'>
          <Button classes={`button button--plain`} onClick={() => this.props.showModalFn(false)} text='Cancel' />
          <Button classes={`button button--med button--blue`} onClick={() => this.props.onClick(this.state)} text='Export as .xlsx' />
        </div>
      </div>
    );
  }
}

export default EmployeeRosterExportModal;
