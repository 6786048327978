import React from 'react';
import Icon from './Icon';
import SearchInput from './SearchInput';
import _ from 'lodash';
import Utils from '../utils/Utils';

class CustomSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultSelectText: '',
      showOptionList: props.showOptionList ? props.showOptionList : false,
      optionsList: props.optionsList ? props.optionsList : [],
      searchTerm: '',
    };
  }

  componentDidMount() {
    // Add Event Listner to handle the click that happens outside
    // the Custom Select Container
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    // Remove the event listner on component unmounting
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  // This method handles the click that happens outside the
  // select text and list area
  handleClickOutside = (e) => {
    if (!this.state.showOptionList) {
      return;
    }

    const elem = document.getElementById(this.props.id);
    if (
      (!e.target.classList.contains('custom-select-option') &&
        !e.target.classList.contains('custom-select-option-text') &&
        !e.target.classList.contains('selected-text') &&
        !e.target.classList.contains('search-bar') &&
        !e.target.classList.contains('checkbox-label') &&
        !e.target.classList.contains('select-options-list') &&
        !e.target.classList.contains('select-options')) ||
      !elem.contains(e.target)
    ) {
      this.setState({
        showOptionList: false,
        searchTerm: '',
      });
    }
  };

  // This method handles the display of option list
  handleListDisplay = () => {
    this.setState((prevState) => {
      return {
        showOptionList: !prevState.showOptionList,
        optionsList: this.props.optionsList,
        searchTerm: '',
      };
    });
  };

  // This method handles the setting of name in select text area
  // and list display on selection
  handleOptionClick = (e, option) => {
    if (this.props.multiselect) {
      if (!e.target.classList.contains('checkbox-label')) {
        this.props.onClick(option.id);
      }
    } else {
      let text = e.target.getAttribute('data-name') ? e.target.getAttribute('data-name') : option.name;
      this.setState({
        defaultSelectText: text,
        showOptionList: false,
      });
      this.props.onClick(option);
    }
  };

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.optionsList, this.props.optionsList)) {
      this.setState({
        optionsList: this.props.optionsList,
        defaultSelectText: '',
      });

      if (this.state.searchTerm) {
        this.searchOptions(this.state.searchTerm);
      }
    }

    if (prevProps.defaultText !== this.props.defaultText) {
      this.setState({ defaultSelectText: this.props.defaultText ? this.props.defaultText : '' });
    }

    if (prevProps.clear !== this.props.clear) {
      this.setState({ defaultSelectText: '' });
    }
  }

  searchOptions(searchTerm) {
    let searchFn;
    if (searchTerm) {
      searchFn = (option) => {
        return option.name.toLowerCase().includes(searchTerm.toLowerCase());
      };
    } else {
      searchFn = (route) => {
        return route;
      };
    }
    let options = _.filter(this.props.optionsList, searchFn);
    this.setState({ optionsList: options, searchTerm: searchTerm });
  }

  renderOptions(optionsList) {
    return optionsList.map((option) => {
      return (
        <li
          className='custom-select-option u--ellipsis'
          title={option.name}
          data-name={option.name}
          data-id={option.id}
          key={option.id ? option.id : option.name}
          onClick={(e) => this.handleOptionClick(e, option)}>
          {this.props.multiselect && (
            <div className='checkbox-container'>
              <input type='checkbox' id={'checkbox-' + option.id} checked={this.props.selection.includes(option.id)} onChange={() => {}} />
              <label className='checkbox-label' htmlFor={'checkbox-' + option.id} />
            </div>
          )}
          <span className='custom-select-option-text'>{option.name}</span>
        </li>
      );
    });
  }

  render() {
    const optionsList = this.state.optionsList;
    const showOptionList = this.state.showOptionList;
    const defaultSelectText = this.state.defaultSelectText ? this.state.defaultSelectText : this.props.defaultText;
    const placeholder = this.props.displayLabel ? this.props.displayLabel : this.props.placeholder;

    return (
      <div id={this.props.id} className={`custom-select-container`}>
        <div className={`selected-text ${showOptionList ? 'active' : ''} ${this.props.required ? 'required' : ''}`} onClick={this.handleListDisplay}>
          {(this.props.displayLabel || (!this.props.defaultText && !defaultSelectText)) && <span className='select-placeholder u--ellipsis'>{placeholder}</span>}
          <span className={'u--ellipsis default-select-text'} id={`default-select-text-${this.props.id}`}>
            {defaultSelectText}
          </span>
          <Icon name='chevron' />
          <input id={`hidden-${this.props.id}`} style={{ display: 'none' }} value={defaultSelectText ? defaultSelectText : ''} readOnly={true} />
        </div>
        {showOptionList && (
          <div className='select-options'>
            <SearchInput onChange={(term) => this.searchOptions(term)} />
            <ul className='select-options-list'>
              {_.isArray(optionsList) && this.renderOptions(optionsList)}
              {!_.isArray(optionsList) &&
                _.map(optionsList, (options, key) => {
                  return (
                    <div>
                      <div className='list-heading'>{Utils.humanize(key)}</div>
                      {this.renderOptions(options)}
                    </div>
                  );
                })}
            </ul>
          </div>
        )}
      </div>
    );
  }
}
export default CustomSelect;
