import React from 'react';
import _ from 'lodash';
import wave from '../../images/wave.svg';
import logo from '../../images/skilltech-mimtr-logo-dark.png';
import uat_logo from '../../images/skilltech-mimtr-logo-uat-dark.png';
import './route-templates-page.scss';
import Button from '../Button';
import { withRouter } from 'react-router-dom';
import ResultsContainer from '../ResultsContainer';
import RouteJobSequenceResult from './RouteJobSequenceResult';
import { ReactSortable } from 'react-sortablejs';
import { Sortable, MultiDrag } from 'sortablejs';
import moment from 'moment';
import Loader from 'react-loader-spinner';
import Modal from '../Modal';
import RouteJobLocationModal from './RouteJobLocationModal';
import Icon from '../Icon';

Sortable.mount(new MultiDrag());

class ResequenceRouteTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.history.location.state,
      multipleTemplatesData: [],
      isLoading: true,
      isSaving: false,
      isAdding: false,
      hasBeenEdited: false,
      isSortableRendering: false,
      showModal: false,
      modalType: '',
      popupType: '',
      selectedTemplateJob: null,
      selectedRouteTemplate: null,
    };
  }

  componentDidMount() {
    document.title = `${process.env.REACT_APP_ENV ? `[${process.env.REACT_APP_ENV}] ` : ''}MiMtr Hybrid | Resequencing Route`;

    if (!this.state.routeTemplate) {
      this.props.history.push('/hybrid/route-templates');
    } else {
      this.getData();
    }
  }

  async getData() {
    this.setState({ isLoading: true });

    await this.getRouteTemplate('currentTemplate').then((routeTemplateData) => {
      this.getRouteTemplate().then(async (comparisonData) => {
        let multipleTemplatesSequenceData = await this.getAdditionalRouteTemplates();

        // If comparing against current template, just use original template data as readonly data
        let readOnlySequenceData = this.state.resequenceMethod !== 'currentTemplate' ? comparisonData : _.cloneDeep(routeTemplateData);
        if (readOnlySequenceData) {
          // Match to the comparison
          this.compareAndGroupSequences(readOnlySequenceData, routeTemplateData, multipleTemplatesSequenceData);
        }

        if (multipleTemplatesSequenceData) {
          multipleTemplatesSequenceData[this.state.routeTemplate.routeNumber] = routeTemplateData;
        }

        this.setState({
          multipleTemplatesSequenceData: multipleTemplatesSequenceData,
          isLoading: false,
          isSortableRendering: false,
          selectedRouteTemplate: {
            routeTemplateId: this.state.routeTemplate.routeId,
            routeNumber: this.state.routeTemplate.routeNumber,
          },
        });
      });
    });
  }

  async getRouteTemplate(resequenceMethod, routeTemplateId) {
    routeTemplateId = routeTemplateId ? routeTemplateId : this.state.routeTemplate.routeId;

    // If comparing against currentTemplate, no need to get data twice
    if (!resequenceMethod && this.state.resequenceMethod === 'currentTemplate') {
      return new Promise((resolve) => resolve(null));
    }

    resequenceMethod = resequenceMethod ? resequenceMethod : this.state.resequenceMethod;
    let apiUrl = '';
    let formatFn = (job) => {
      return job;
    };

    switch (resequenceMethod) {
      case 'currentTemplate':
        apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getRouteTemplate?DatabaseServer=${this.props.selectedContract.dbServer}&routeTemplateId=${routeTemplateId}`;

        formatFn = (job, index) => {
          job.address = `${job.unitNumber ? job.unitNumber + '/' : ''}${job.streetNumber ? job.streetNumber : ''} ${job.streetName} ${job.suburbName}`;
          return job;
        };

        break;
      case 'clientRoute':
        apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getClientRouteDetails?DatabaseServer=${this.props.selectedContract.dbServer}&clientRouteId=${this.state.routeTemplate.latestClientRouteId}`;
        formatFn = (job) => {
          return {
            address: job.address,
            premiseId: job.premiseId,
            meterNumber: job.meterNumber,
            meterCategory: job.meterCategory,
            jobLocationId: job.jobLocationId,
            jobLocationSequence: job.clientRouteJobSequenceNumber,
            meterSequenceNumber: job.meterReadSequence,
          };
        };

        break;
      case 'workRoute':
        apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getWorkRouteDetails?DatabaseServer=${this.props.selectedContract.dbServer}&workRouteId=${this.state.routeTemplate.lastestWorkrouteId}`;
        formatFn = (job) => {
          return {
            address: job.address,
            meterNumber: job.meterNumber,
            meterCategory: job.meterCategory,
            jobLocationId: job.jobLocationId,
            jobLocationSequence: job.clientRouteJobSequenceNumber,
            meterSequenceNumber: job.meterReadSequence,
            lastRead: moment(job.lastReadUtcTime),
          };
        };

        break;
      default:
        break;
    }

    return new Promise((resolve, reject) => {
      return fetch(apiUrl)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
            reject();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
            return res.json();
          }
        })
        .then((data) => {
          let routeDetails;
          if (resequenceMethod === 'clientRoute') {
            routeDetails = data[1];
            data = data[0];
          }

          if (!data.length) {
            resolve([]);
            return;
          }

          // Handle Multiple Templates
          // Only relevant if comparing to Client Route
          if (routeDetails) {
            if (routeDetails.length > 1) {
              this.showModal('popup', true);

              this.setState({
                multipleTemplatesData: routeDetails,
                popupType: 'multipleTemplates',
              });
            }
          }

          resolve(data.map(formatFn));
        });
    });
  }

  getAdditionalRouteTemplates() {
    let promises = [];
    let multipleRouteTemplates = {};

    let additionalRouteTemplateData = this.state.multipleTemplatesData.filter((template) => template.routeTemplateId !== this.state.routeTemplate.routeId);
    additionalRouteTemplateData.forEach((template) => {
      promises.push(this.getRouteTemplate('currentTemplate', template.routeTemplateId));
    });

    return Promise.all(promises).then((data) => {
      data.forEach((routeTemplate, index) => {
        multipleRouteTemplates[additionalRouteTemplateData[index].routeNumber] = routeTemplate;
      });

      return multipleRouteTemplates;
    });
  }

  compareAndGroupSequences(readOnlySequenceData, routeTemplateData, multipleTemplatesSequenceData) {
    multipleTemplatesSequenceData = multipleTemplatesSequenceData ? multipleTemplatesSequenceData : this.state.multipleTemplatesSequenceData;

    readOnlySequenceData.forEach((routeJob, index) => {
      // Remove any previous selections to start fresh
      routeJob.selected = false;

      let matchingJob = routeTemplateData.find((job) => routeJob.jobLocationId === job.jobLocationId && routeJob.meterNumber === job.meterNumber);
      if (matchingJob) {
        // If found a match in template, note the sequential location order of the comparison data
        matchingJob.comparisonOrder = index;
        routeJob.missingFromTemplate = false;
      } else {
        // If there was no match, note it (highlighted orange)
        routeJob.missingFromTemplate = true;

        if (multipleTemplatesSequenceData) {
          // Check if any other route templates have a match
          _.forEach(multipleTemplatesSequenceData, (template, routeNumber) => {
            let matchingAlternateTemplateJob = template.find((job) => routeJob.jobLocationId === job.jobLocationId && routeJob.meterNumber === job.meterNumber);
            if (matchingAlternateTemplateJob) {
              routeJob.alternateTemplateRouteNumber = routeNumber;
            }
          });
        }
      }
    });

    let jobSortKey = this.state.resequenceMethod === 'workRoute' ? 'lastRead' : 'jobLocationSequence';
    let meterSortKey = this.state.resequenceMethod === 'workRoute' ? null : ['meterSequenceNumber', 'meterNumber'];

    // Group up by job location id - if one row is resequenced, all associated rows must move together
    let readOnlyLocationSequenceData = _.sortBy(
      _.map(_.groupBy(readOnlySequenceData, 'jobLocationId'), (jobLocation) => {
        //TODO: testing on UAT routeno 23104 joblocationid 1150286
        // The meterSequenceNumber is different on the client route than the route template
        if (jobLocation[0].jobLocationId === 1150286) {
          console.log(_.sortBy(jobLocation, meterSortKey));
          console.log(jobLocation);
        }
        return {
          jobLocationId: jobLocation[0].jobLocationId,
          jobs: _.sortBy(jobLocation, meterSortKey),
          jobLocationSequence: jobLocation[0].jobLocationSequence,
        };
      }),
      jobSortKey
    );

    let templateLocationSequenceData = _.sortBy(
      _.map(_.groupBy(routeTemplateData, 'jobLocationId'), (jobLocation) => {
        let locationComparisonOrder = readOnlyLocationSequenceData.findIndex((item) => item.jobLocationId === jobLocation[0].jobLocationId);

        if (jobLocation[0].jobLocationId === 1150286) {
          console.log(jobLocation);
        }
        return {
          jobLocationId: jobLocation[0].jobLocationId,
          jobs: _.sortBy(jobLocation, meterSortKey),
          jobLocationSequence: jobLocation[0].jobLocationSequence,
          locationComparisonOrder: locationComparisonOrder >= 0 ? locationComparisonOrder : null,
        };
      }),
      'jobLocationSequence'
    );

    this.setState({
      masterTemplateData: templateLocationSequenceData,
      readOnlySequenceData: readOnlyLocationSequenceData,
      readOnlyFlatData: readOnlySequenceData,
      templateSequenceData: _.cloneDeep(templateLocationSequenceData),
      templateFlatData: routeTemplateData,
    });
  }

  setSequence(newSequence, isReset) {
    if (!this.state.isSortableRendering) {
      this.setState({
        isSortableRendering: true,
      });

      return;
    }

    this.setState({
      templateSequenceData: newSequence,
      hasBeenEdited: !isReset,
    });
  }

  matchSequence() {
    this.setState({
      templateSequenceData: _.sortBy(this.state.templateSequenceData, 'locationComparisonOrder'),
      hasBeenEdited: true,
    });
  }

  updateSequence() {
    this.setState({
      isSaving: true,
    });

    let body = {
      DatabaseServer: this.props.selectedContract.dbServer,
      routeTemplateId: this.state.routeTemplate.routeId,
      jobLocationSequence: [],
    };

    this.state.templateSequenceData.forEach((jobLocation) => {
      body.jobLocationSequence.push(...jobLocation.jobs);
    });

    return new Promise((resolve, reject) => {
      let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/updateRouteTemplateSequence`;
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      };

      return fetch(apiUrl, requestOptions)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
            reject();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
            return res.json();
          }
        })
        .then((data) => {
          this.getData();

          this.setState({
            hasBeenEdited: false,
            isSaving: false,
            isSortableRendering: false,
          });

          resolve();
        });
    });
  }

  selectAlternateRouteTemplate(selectedTemplate) {
    let readOnlySequenceData = this.state.readOnlyFlatData;
    let routeTemplateData = this.state.multipleTemplatesSequenceData[selectedTemplate.routeNumber];

    this.compareAndGroupSequences(readOnlySequenceData, routeTemplateData);

    if (this.state.isAdding) {
      this.cancelInsertIntoTemplate();
    }
    this.setState({
      selectedRouteTemplate: selectedTemplate,
    });
  }

  selectResult(selectedItem) {
    let readOnlyFlatData = this.state.readOnlyFlatData;
    let templateData = this.state.templateFlatData;
    let flatMatchingJob = readOnlyFlatData.find((item) => item.jobLocationId === selectedItem.jobLocationId && item.meterNumber === selectedItem.meterNumber);
    if (flatMatchingJob && !templateData.find((item) => item.jobLocationId === selectedItem.jobLocationId && item.meterNumber === selectedItem.meterNumber)) {
      flatMatchingJob.selected = !flatMatchingJob.selected;
    }

    this.setState({
      readOnlyFlatData: readOnlyFlatData,
    });
  }

  showModal(type, show) {
    const showModal = show !== undefined ? show : !this.state.showModal;

    if (type === 'addLocation' && !showModal) {
      this.setState({
        modalType: 'popup',
        popupType: 'stopAddToTemplate',
        showModal: true,
      });
    } else {
      if (!showModal) {
        type = null;
        this.setState({
          popupType: null,
        });
      }

      this.setState({
        showModal: showModal,
        modalType: type,
      });
    }
  }

  openEditLocationModal(selectedItem) {
    this.showModal('editLocation', true);
    this.setState({
      selectedTemplateJob: selectedItem,
    });
  }

  addOrUpdateJobLocation(updatedJobLocation, type) {
    this.setState({
      isLoading: true,
    });

    let templateData = this.state.templateSequenceData;
    let matchingIndex = templateData.findIndex((templateJob) => templateJob.jobLocationId === updatedJobLocation.jobLocationId);
    let masterTemplateData = this.state.masterTemplateData;

    if (type === 'addLocation') {
      let readOnlySequenceData = this.state.readOnlySequenceData;
      let readOnlyFlatData = this.state.readOnlyFlatData;
      let matchingFlatReadOnlyIndex = readOnlyFlatData.findIndex((job) => job.jobLocationId === updatedJobLocation.jobLocationId && job.meterNumber === updatedJobLocation.meterNumber);

      let multipleTemplatesSequenceData = this.state.multipleTemplatesSequenceData;

      updatedJobLocation.missingFromTemplate = false;
      updatedJobLocation.selected = false;
      updatedJobLocation.comparisonOrder = matchingFlatReadOnlyIndex;

      if (matchingIndex < 0) {
        let matchingReadOnlyJobLocationIndex = readOnlySequenceData.findIndex((item) => item.jobLocationId === updatedJobLocation.jobLocationId);
        let newJobLocationGroup = {
          jobLocationId: updatedJobLocation.jobLocationId,
          locationComparisonOrder: matchingReadOnlyJobLocationIndex,
          jobLocationSequence: updatedJobLocation.jobLocationSequence,
          jobs: [updatedJobLocation],
        };

        let resultToInsertUnderIndex = templateData.findIndex((item) => item.jobLocationSequence === updatedJobLocation.jobLocationSequence - 1);
        templateData.splice(resultToInsertUnderIndex + 1, 0, newJobLocationGroup);

        let resultToInsertUnderMasterIndex = masterTemplateData.findIndex((item) => item.jobLocationSequence === updatedJobLocation.jobLocationSequence - 1);
        masterTemplateData.splice(resultToInsertUnderMasterIndex + 1, 0, newJobLocationGroup);

        let matchingReadOnlyJobIndex = readOnlySequenceData[matchingReadOnlyJobLocationIndex].jobs.findIndex((item) => item.meterNumber === updatedJobLocation.meterNumber);
        readOnlySequenceData[matchingReadOnlyJobLocationIndex].jobs[matchingReadOnlyJobIndex].missingFromTemplate = false;
      } else {
        templateData[matchingIndex].jobs.push(updatedJobLocation);
      }

      let resultToInsertUnderMultiTemplateIndex = multipleTemplatesSequenceData[this.state.selectedRouteTemplate.routeNumber].findIndex(
        (item) => item.jobLocationSequence === updatedJobLocation.jobLocationSequence - 1
      );
      multipleTemplatesSequenceData[this.state.selectedRouteTemplate.routeNumber].splice(resultToInsertUnderMultiTemplateIndex + 1, 0, updatedJobLocation);

      readOnlyFlatData[matchingFlatReadOnlyIndex].missingFromTemplate = false;

      this.setState({
        multipleTemplatesSequenceData: multipleTemplatesSequenceData,
        readOnlySequenceData: readOnlySequenceData,
        readOnlyFlatData: readOnlyFlatData,
      });

      let jobsToAdd = this.state.jobsToAdd;
      jobsToAdd = jobsToAdd.filter((job) => !(job.jobLocationId === updatedJobLocation.jobLocationId && job.meterNumber === updatedJobLocation.meterNumber));
      this.showModal('', false);

      if (!jobsToAdd.length) {
        this.cancelInsertIntoTemplate();
      } else {
        this.setState(
          {
            jobsToAdd: jobsToAdd,
          },
          () => {
            this.showModal('addLocation', true);
          }
        );
      }
    } else {
      this.showModal('', false);
      templateData[matchingIndex].jobs = templateData[matchingIndex].jobs.map((job) => {
        job.buildingName = updatedJobLocation.buildingName;
        job.unitNumber = updatedJobLocation.unitNumber;
        job.meterCategory = updatedJobLocation.meterCategory;
        job.streetNumber = updatedJobLocation.streetNumber;
        job.streetName = updatedJobLocation.streetName;
        job.streetId = updatedJobLocation.streetId;
        job.suburbId = updatedJobLocation.suburbId;
        job.stateId = updatedJobLocation.stateId;
        job.customAddress = updatedJobLocation.customAddress;
        job.meterNumber = updatedJobLocation.meterNumber;
        job.meterSequenceNumber = updatedJobLocation.meterSequenceNumber;
        job.jobLocationSequence = updatedJobLocation.jobLocationSequence;
        job.address = `${updatedJobLocation.unitNumber ? updatedJobLocation.unitNumber + '/' : ''}${updatedJobLocation.streetNumber ? updatedJobLocation.streetNumber : ''} ${
          updatedJobLocation.streetName
        } ${updatedJobLocation.suburbName}`;
        return job;
      });

      let matchingMasterIndex = masterTemplateData.findIndex((templateJob) => templateJob.jobLocationId === updatedJobLocation.jobLocationId);
      masterTemplateData[matchingMasterIndex].jobs = masterTemplateData[matchingIndex].jobs.map((job) => {
        job.buildingName = updatedJobLocation.buildingName;
        job.unitNumber = updatedJobLocation.unitNumber;
        job.meterCategory = updatedJobLocation.meterCategory;
        job.streetNumber = updatedJobLocation.streetNumber;
        job.streetName = updatedJobLocation.streetName;
        job.streetId = updatedJobLocation.streetId;
        job.suburbId = updatedJobLocation.suburbId;
        job.stateId = updatedJobLocation.stateId;
        job.customAddress = updatedJobLocation.customAddress;
        job.meterNumber = updatedJobLocation.meterNumber;
        job.meterSequenceNumber = updatedJobLocation.meterSequenceNumber;
        job.jobLocationSequence = updatedJobLocation.jobLocationSequence;
        job.address = `${updatedJobLocation.unitNumber ? updatedJobLocation.unitNumber + '/' : ''}${updatedJobLocation.streetNumber ? updatedJobLocation.streetNumber : ''} ${
          updatedJobLocation.streetName
        } ${updatedJobLocation.suburbName}`;
        return job;
      });
    }

    this.setState({
      templateSequenceData: templateData,
      isLoading: false,
    });
  }

  addToTemplate() {
    let selectedItems = this.state.readOnlyFlatData.filter((item) => item.selected);
    if (!selectedItems.length) {
      return;
    }

    // If there is a matching alternate template, do not allow adding to this template
    console.log(selectedItems);
    if (selectedItems.find((selectedItem) => selectedItem.alternateTemplateRouteNumber !== undefined)) {
      this.showModal('popup', true);
      this.setState({
        popupType: 'cannotAddToTemplate',
      });
    } else {
      selectedItems = _.cloneDeep(selectedItems).map((item) => {
        item.customAddress = item.address;
        item.routeTemplateId = this.state.routeTemplate.routeId;

        return item;
      });
      this.setState({
        jobsToAdd: selectedItems,
        isAdding: true,
      });
    }
  }

  insertNewResult(resultToInsertUnder) {
    if (!this.state.isAdding) {
      return;
    }

    let jobsToAdd = this.state.jobsToAdd.map((job) => {
      job.jobLocationSequence = resultToInsertUnder.jobLocationSequence + 1;
      return job;
    });

    this.setState({
      jobsToAdd: jobsToAdd,
    });

    this.showModal('addLocation');
  }

  cancelInsertIntoTemplate() {
    let readOnlyData = this.state.readOnlyFlatData;
    readOnlyData.forEach((item) => {
      if (item.selected) {
        item.selected = false;
      }
    });

    this.setState({
      readOnlyFlatData: readOnlyData,
      jobsToAdd: false,
      isAdding: false,
    });
  }

  openRemoveFromTemplateModal(selectedItem) {
    this.showModal('popup', true);

    this.setState({
      popupType: 'removeFromTemplate',
      selectedTemplateJob: selectedItem,
    });
  }

  removeFromTemplate(selectedItem) {
    return new Promise((resolve, reject) => {
      let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/deleteTemplateJobLocation`;

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          DatabaseServer: this.props.selectedContract.dbServer,
          jobLocationId: selectedItem.jobLocationId,
        }),
      };

      fetch(apiUrl, requestOptions)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
          }
        })
        .then(() => {
          this.setState({
            isSaving: false,
          });

          let templateData = this.state.templateSequenceData;
          let masterTemplateData = this.state.masterTemplateData;
          let multipleTemplatesSequenceData = this.state.multipleTemplatesSequenceData;

          templateData = templateData.filter((jobLocation) => jobLocation.jobLocationId !== selectedItem.jobLocationId);
          masterTemplateData = masterTemplateData.filter((jobLocation) => jobLocation.jobLocationId !== selectedItem.jobLocationId);
          multipleTemplatesSequenceData = multipleTemplatesSequenceData[this.state.selectedRouteTemplate.routeNumber].filter((item) => item.jobLocationId !== selectedItem.jobLocationId);

          let readOnlySequenceData = this.state.readOnlySequenceData;
          let readOnlyFlatData = this.state.readOnlyFlatData;

          let matchingReadOnlyJobLocationIndex = readOnlySequenceData.findIndex((item) => item.jobLocationId === selectedItem.jobLocationId);
          if (matchingReadOnlyJobLocationIndex) {
            let matchingReadOnlyJobIndex = readOnlySequenceData[matchingReadOnlyJobLocationIndex].jobs.findIndex((item) => item.meterNumber === selectedItem.meterNumber);
            if (matchingReadOnlyJobIndex) {
              readOnlySequenceData[matchingReadOnlyJobLocationIndex].jobs[matchingReadOnlyJobIndex].missingFromTemplate = true;
            }
          }

          let matchingFlatReadOnly = readOnlyFlatData.find((job) => job.jobLocationId === selectedItem.jobLocationId && job.meterNumber === selectedItem.meterNumber);
          if (matchingFlatReadOnly) {
            matchingFlatReadOnly.missingFromTemplate = true;
          }

          this.showModal(null, false);

          this.setState({
            popupType: null,
            selectedTemplateJob: null,
            masterTemplateData: masterTemplateData,
            templateSequenceData: templateData,
            multipleTemplatesSequenceData: multipleTemplatesSequenceData,
            readOnlySequenceData: readOnlySequenceData,
            readOnlyFlatData: readOnlyFlatData,
          });
        });
    });
  }

  goBackToRouteTemplateList() {
    this.props.history.push('/hybrid/route-templates/', { routeTemplateData: this.state.routeTemplateData });
  }

  getResultItems(data, isReadOnly) {
    if (!data) {
      return;
    }

    let flatIndex = 0;
    return data.map((jobLocation, locationIndex) => {
      let groupSelectFn = this.state.isAdding && !isReadOnly ? () => this.insertNewResult(jobLocation) : () => {};
      return (
        <div className={'result-item-group'} key={jobLocation.jobLocationId} onClick={groupSelectFn}>
          {jobLocation.jobs.map((job) => {
            if (!isReadOnly) {
              job.flatIndex = flatIndex++;
              job.locationComparisonOrder = jobLocation.locationComparisonOrder;
              job.locationIndex = locationIndex;
            }
            job.isReadOnly = isReadOnly;

            let selectFn = isReadOnly ? () => this.selectResult(job) : () => {};
            let deleteFn = isReadOnly ? () => () => {} : () => this.openRemoveFromTemplateModal(job);
            let editFn = isReadOnly ? () => () => {} : () => this.openEditLocationModal(job);

            return <RouteJobSequenceResult data={job} key={`${job.meterNumber}-${job.jobLocationSequence}-${job.meterSequenceNumber}`} selectFn={selectFn} editFn={editFn} deleteFn={deleteFn} />;
          })}
        </div>
      );
    });
  }

  renderPopup() {
    let title, text, primaryButton, secondaryButton;
    switch (this.state.popupType) {
      case 'multipleTemplates':
        title = 'Multiple Templates';
        text = 'This client route spans multiple templates. Would you like to continue?';
        primaryButton = {
          text: 'Continue',
          onClickFn: () => this.showModal(null, false),
          class: 'button--blue',
        };

        secondaryButton = {
          text: 'Go Back',
          onClickFn: () => this.goBackToRouteTemplateList(),
        };
        break;
      case 'cannotAddToTemplate':
        title = 'Cannot Add To Template';

        let items = this.state.readOnlyFlatData
          .filter((item) => item.selected && item.alternateTemplateRouteNumber !== undefined)
          .map((item) => {
            return `${item.address} (Template #${item.alternateTemplateRouteNumber})`;
          });

        text = `Some of the selected jobs already exist on another template and cannot be added to this template: ${items.join(', ')}.`;
        break;
      case 'stopAddToTemplate':
        title = 'Cancel Adding Locations To Template?';
        text = `Are you sure you want to quit adding locations to this template?`;
        primaryButton = {
          text: 'Quit',
          onClickFn: () => this.showModal('', false),
          class: 'button--orange',
        };

        secondaryButton = {
          text: 'Go Back',
          onClickFn: () => this.showModal('addLocation', true),
        };
        break;

      case 'removeFromTemplate':
        let selectedJob = this.state.selectedTemplateJob;
        title = 'Remove From Template';
        text = `Are you sure you want to remove ${selectedJob.customAddress ? selectedJob.customAddress : selectedJob.address} from the template?`;
        primaryButton = {
          text: 'Remove',
          onClickFn: () => this.removeFromTemplate(selectedJob),
          class: 'button--orange',
        };

        secondaryButton = {
          text: 'Cancel',
          onClickFn: () => this.showModal(null, false),
        };
        break;
      default:
        break;
    }

    return (
      <div id={'popup-modal'} className='modal-content'>
        <div className='heading f--warning'>{title}</div>
        <div className='form-container'>
          <p>{text}</p>
        </div>
        <div className='button-group t--align-right'>
          {secondaryButton && <Button classes={`button button--plain`} onClick={secondaryButton.onClickFn} text={secondaryButton.text} />}
          {primaryButton && <Button classes={`button button--med ${primaryButton.class}`} onClick={primaryButton.onClickFn} text={primaryButton.text} />}
        </div>
      </div>
    );
  }

  render() {
    const readOnlyData = { resultData: this.getResultItems(this.state.readOnlySequenceData, true) };
    const selectedReadOnlyItems = this.state.readOnlyFlatData ? this.state.readOnlyFlatData.filter((item) => item.selected).length : null;

    let title = this.state.resequenceMethod === 'currentTemplate' ? 'Manually Sequence Template' : 'Resequence Template';

    return (
      <div className={'main-content resequence-route-template-page'}>
        <header>
          <h1>Route Resequencing</h1>
          <img className='logo-container' src={process.env.REACT_APP_ENV ? uat_logo : logo} alt='' />
        </header>
        {!this.state.isLoading && (
          <div className={'content-container'}>
            <h2>
              {title} - {this.state.routeTemplate.routeName}
              <Button classes={'button--plain'} text={'Back'} icon={'chevron'} iconPosition={'1'} iconClasses={'back'} onClick={() => this.goBackToRouteTemplateList()} />
            </h2>

            <div className={'route-resequencing-container'}>
              {/* Read Only Container */}
              <div className={'sequence-list'}>
                <h3>
                  {this.state.resequenceMethod === 'currentTemplate' ? 'Current Template' : this.state.resequenceMethod === 'clientRoute' ? 'Client Route' : 'Work Route'} (
                  {this.state.readOnlyFlatData.length})
                </h3>
                <ResultsContainer data={readOnlyData} dataClass={this.state.resequenceMethod} />
                <div className={'button-bar'}>
                  <div></div>
                  <div className={'button-group'}>
                    {!this.state.isSaving && this.state.isAdding && this.state.resequenceMethod === 'clientRoute' && (
                      <Button text={'Cancel'} classes={'button--plain'} onClick={() => this.cancelInsertIntoTemplate()} />
                    )}

                    {!this.state.isSaving && this.state.resequenceMethod === 'clientRoute' && (
                      <Button
                        text={`${this.state.isAdding ? 'Inserting Into Template' : 'Add To Template'} ${selectedReadOnlyItems ? '(' + selectedReadOnlyItems + ')' : ''}`}
                        classes={this.state.isAdding ? 'selected' : ''}
                        onClick={() => this.addToTemplate()}
                      />
                    )}
                  </div>
                </div>
              </div>

              {/* Sortable Container */}
              <div>
                {/* Multiple Templates */}
                <div>
                  {this.state.multipleTemplatesData.length > 1 && (
                    <div className={'sequence-list template-group'}>
                      <h3>Route Templates ({this.state.multipleTemplatesData.length})</h3>
                      <div id={`results-container`}>
                        <div className={`results-list`}>
                          {this.state.multipleTemplatesData.map((template) => {
                            return (
                              <div
                                className={`result-item ${this.state.selectedRouteTemplate.routeTemplateId === template.routeTemplateId ? 'selected' : ''}`}
                                key={template.routeTemplateId}
                                onClick={() => this.selectAlternateRouteTemplate(template)}>
                                <div className={'item-card'}>
                                  <div className={`item-row`}>
                                    <div className={`item-detail`} title={'Route Number'}>
                                      <Icon name={'route'} />
                                      <span>{template.routeNumber}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className={'sequence-list'}>
                    <h3>Edit Template ({this.state.templateFlatData.length})</h3>
                    {!!this.state.templateSequenceData.length && (
                      <div id={'results-container'} className={this.state.isAdding ? 'adding-new' : ''}>
                        <div className={'results-list sortable-container border'}>
                          <ReactSortable multiDrag={true} list={this.state.templateSequenceData} setList={(data) => this.setSequence(data)}>
                            {this.getResultItems(this.state.templateSequenceData)}
                          </ReactSortable>
                        </div>
                      </div>
                    )}
                    {!!this.state.templateSequenceData.length && (
                      <div className={'button-bar'}>
                        <div>{!this.state.isSaving && this.state.resequenceMethod !== 'currentTemplate' && <Button text={'Sequence To Match'} onClick={() => this.matchSequence()} />}</div>
                        <div className={'button-group'}>
                          {!this.state.isSaving && this.state.hasBeenEdited && (
                            <Button classes={'button--plain'} text={'Reset'} onClick={() => this.setSequence(this.state.masterTemplateData, true)} />
                          )}
                          {!this.state.isSaving && <Button classes={'button--blue'} text={'Update Route Template'} onClick={() => this.updateSequence()} />}
                          {this.state.isSaving && (
                            <div className={'spinner-container'}>
                              <Loader type='Bars' color='#008dce' height={30} width={30} visible={this.state.isSaving} />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <img className='wave' src={wave} alt={''} />
        {!this.state.isLoading && this.state.showModal && (
          <Modal showModalFn={(show) => this.showModal(this.state.modalType, show)} showModal={this.state.showModal}>
            {['editLocation', 'addLocation'].includes(this.state.modalType) && (
              <RouteJobLocationModal
                data={this.state.modalType === 'editLocation' ? this.state.selectedTemplateJob : this.state.jobsToAdd[0]}
                type={this.state.modalType}
                selectedContract={this.props.selectedContract}
                showModalFn={(show) => this.showModal(this.state.modalType, show)}
                updateFn={(updatedJobLocation) => this.addOrUpdateJobLocation(updatedJobLocation, this.state.modalType)}
              />
            )}
            {this.state.modalType === 'popup' && this.renderPopup()}
          </Modal>
        )}
      </div>
    );
  }
}

export default withRouter(ResequenceRouteTemplate);
