import React from 'react';
import Loader from 'react-loader-spinner';
import moment from 'moment';
import Zoom from '../Zoom';
import Icon from '../Icon';
import Utils from '../../utils/Utils';
import Button from '../Button';

class ValidationCard extends React.Component {
  constructor(props) {
    super(props);
    let reading = props.data;
    reading.loading = false;
    reading.isValidReading = true;
    reading.warnReading = false;
    reading.isLoading = false;

    this.state = {
      reading: reading,
      showFullImage: false,
      selectedImage: null,
    };
  }

  updateReading(e) {
    const reading = this.state.reading;

    let value = e.target.value;
    if (!parseInt(value)) {
      value = '0';
    } else {
      value = parseInt(e.target.value).toString();
      value = value.slice(0, reading.dials);
    }

    reading.newReading = value;
    reading.isValidReading = 0 <= parseInt(value);
    reading.warnReading = parseInt(value) > reading.high;
    this.setState({ reading: reading });
  }

  toggleFullImage(show, photo) {
    this.setState({
      showFullImage: show,
      selectedImage: photo,
    });
  }

  submit(valid) {
    let reading = this.state.reading;
    if (reading.loading) {
      return;
    }

    if (!['adHoc', 'pole', 'skip'].includes(reading.photoType) && !reading.newReading && !reading.reading) {
      reading.isValidReading = false;
      this.setState({
        reading: reading,
      });
      return;
    }

    this.setLoading(true);
    this.props.submitFn(reading, valid).finally(() => {
      reading.warnReading = false;
      reading.submitted = true;
      reading.lastEdited = moment();
      reading.validated = valid;
      this.setLoading(false);
      this.setState({ reading: reading });
    });
  }

  setLoading(isLoading) {
    let reading = this.state.reading;
    reading.loading = isLoading;
    this.setState({ reading: reading });
  }

  render() {
    const reading = this.state.reading;
    const humanisedRegisterName = Utils.titleize(reading.register);
    const isLoading = reading.isLoading;
    const readingValue = reading.newReading ? reading.newReading.toString() : reading.reading !== null && reading.reading !== undefined ? reading.reading.toString() : '';
    const utilityMetric = reading.utility === 'Gas' ? 'm³' : reading.utility === 'Water' ? 'L' : 'kW';
    const photoType = reading.photoType;

    return (
      <div className='swiper-card'>
        <div className='heading'>
          <div>
            <div className='sub-heading'>Meter No. {reading.newReading && <div className={'note'}>(NEW)</div>}</div>
            {reading.meterNumber}
            <div className='info-container' onClick={() => this.props.openDetailsFn()}>
              <svg width='13px' height='13px' viewBox='0 0 13 13' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
                <path
                  d='M6.5,0 C2.91595994,0 0,2.91595994 0,6.5 C0,10.0840401 2.91595994,13 6.5,13 C10.0840401,13 13,10.0840401 13,6.5 C13,2.91595994 10.0840401,0 6.5,0 Z M6.5,12.1875147 C3.36387059,12.1875147 0.812508845,9.63615299 0.812508845,6.50002359 C0.812508845,3.36389418 3.36387059,0.812508845 6.5,0.812508845 C9.63612941,0.812508845 12.1875147,3.36387059 12.1875147,6.5 C12.1875147,9.63612941 9.63612941,12.1875147 6.5,12.1875147 Z'
                  id='Shape'></path>
                <polygon id='Path' points='6 3 7 3 7 4 6 4'></polygon>
                <polygon id='Path' points='6 5 7 5 7 10.019669 6 10.019669'></polygon>
              </svg>

              <div className='info-popover'>
                <div className='info-content'>
                  <div className='info-element'>
                    <label> Work Route No.</label>
                    <div className='read-only'>{reading.workRouteNumber}</div>
                  </div>
                  <div className='info-element'>
                    <label>Reader</label>
                    <div className='read-only'>{reading.reader}</div>
                  </div>
                  <div className='info-element'>
                    <label>Read Date</label>
                    <div className='read-only'>{reading.readDate.format('DD/MM/YYYY hh:mma')}</div>
                  </div>
                  {reading.skipReason && (
                    <div className='info-element'>
                      <label>Skip Reason</label>
                      <div className='read-only'>{reading.skipReason}</div>
                    </div>
                  )}
                  <div className='info-element'>
                    <label>Trouble Code</label>
                    <div className='read-only'>{reading.troubleCode}</div>
                  </div>
                  <div className='info-element'>
                    <label>Address</label>
                    <div className='read-only'>{reading.address}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {photoType === 'outOfTolerance' && (
            <div>
              <div className='sub-heading'>
                {humanisedRegisterName} Reading ({utilityMetric})
              </div>
              <div className='input-container'>
                {reading.warnReading && <span className='warning-message'>This reading is out of tolerance.</span>}
                <input
                  className={`char-box char-box--${reading.dials} ${!reading.isValidReading ? 'input--invalid' : ''}`}
                  type='number'
                  pattern='[0-9]*'
                  onChange={(e) => this.updateReading(e)}
                  value={readingValue ? readingValue.padStart(reading.dials, '0') : ''}
                  disabled={isLoading}
                />
                <div className='boxes'>
                  {Array.from({ length: reading.dials }, (_, index) => (
                    <div className='box' key={index} />
                  ))}
                </div>
              </div>
              <div className='note'>Previous Read: {reading.previousReading}</div>
            </div>
          )}
          {photoType === 'skip' && (
            <div>
              <div className='sub-heading'>Skip Reason</div>
              {reading.skipReason}
            </div>
          )}
          {['adHoc', 'pole'].includes(photoType) && reading.validated !== undefined && (
            <div className={`status ${reading.validated ? 'accepted' : 'rejected'}`}>{reading.validated ? 'ALL PHOTOS ACCEPTED' : 'ALL PHOTOS REJECTED'}</div>
          )}
        </div>
        {['adHoc', 'pole'].includes(photoType) && (
          <div className='card-body'>
            {reading.photos.length === 1 && (
              <div className='image-preview-container'>
                <Zoom src={reading.photos[0].photoUrl} />
              </div>
            )}
            {!this.state.showFullImage && reading.photos.length > 1 && (
              <div className={'thumbnail-container'}>
                {reading.photos.map((photo) => {
                  return (
                    <div className='image-thumbnail' key={photo.pictureId}>
                      <div className={'image-preview-container'} title={'Click to Enlarge'} onClick={() => this.toggleFullImage(true, photo)}>
                        <img src={photo.photoUrl} alt={''} />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {this.state.showFullImage && (
              <div className={'image-full-view'}>
                <div className={'button-group'}>
                  <Button classes={'button button--plain'} onClick={() => this.toggleFullImage(false)} text={'Back'} icon={'chevron'} iconPosition={'1'} iconClasses={'back'} />
                </div>
                <div className='image-preview-container'>
                  <Zoom src={this.state.selectedImage.photoUrl} />
                </div>
              </div>
            )}
          </div>
        )}
        {!['adHoc', 'pole'].includes(photoType) && (
          <div className='card-body'>
            <div className='image-preview-container'>
              <Zoom src={reading.photoUrl} />
            </div>
          </div>
        )}

        <div className='button-group'>
          {(reading.validated === undefined || reading.validated) && (
            <div className={`button button--large button--orange ${isLoading ? 'button--disabled' : ''}`} onClick={() => this.submit(false)}>
              {!isLoading && (
                <>
                  <Icon name='full-cross' />
                  <span>{reading.submitted && !reading.validated ? 'Resubmit' : 'Reject'}</span>
                </>
              )}
              <Loader type='Bars' color='#FFFFFF' height={20} width={20} visible={isLoading} />
            </div>
          )}
          {(reading.validated === undefined || !reading.validated) && (
            <div className={`button button--large button--green ${isLoading ? 'button--disabled' : ''}`} onClick={() => this.submit(true)}>
              {!isLoading && (
                <>
                  <Icon name='full-check' />
                  <span>{reading.submitted && reading.validated ? 'Resubmit' : 'Validate'}</span>
                </>
              )}
              <Loader type='Bars' color='#FFFFFF' height={20} width={20} visible={isLoading} />
            </div>
          )}
        </div>

        <div className='swiper-card-arrow arrow--prev' onClick={() => this.props.prevFn()}>
          <Icon name='chevron' />
        </div>
        <div className='swiper-card-arrow arrow--next' onClick={() => this.props.nextFn()}>
          <Icon name='chevron' />
        </div>
      </div>
    );
  }
}

export default ValidationCard;
