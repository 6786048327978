import React from 'react';
import Loader from 'react-loader-spinner';
import wave from '../../images/wave.svg';
import logo from '../../images/skilltech-mimtr-logo-dark.png';
import uat_logo from '../../images/skilltech-mimtr-logo-uat-dark.png';
import TabContainer from '../TabContainer';
import ToggleButton from '../ToggleButton';
import Button from '../Button';
import CustomSelect from '../CustomSelect';
import { withRouter } from 'react-router-dom';
import './employees-page.scss';
import _ from 'lodash';
import ResultsContainer from '../ResultsContainer';
import Icon from '../Icon';
import Utils from '../../utils/Utils';
import { ROLE } from '../../constants/UserRole';
import AddNewResult from '../AddNewResult';
import Api from '../../api/Api';
import Modal from '../Modal';
import moment from 'moment';

class EmployeeDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isSaving: false,
      selectedGroup: 'details',
      showRequiredWarning: false,
      showSalariedEmployeeWarning: false,
      rosterFailWarning: null,
      showSuccess: false,
      showModal: false,
      isAdminUserView: props.selectedContract.roles.includes(ROLE.ADMINISTRATOR),
    };
  }

  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    let employeeId = params.get('id');
    let employeeUsername = params.get('username');

    if (!employeeId || !employeeUsername) {
      this.props.history.push('/hybrid/employees');
    } else {
      this.setState(
        {
          employee: {
            id: employeeId,
            name: Utils.titleize(employeeUsername.replace('.', ' ')),
          },
        },
        () => {
          document.title = `${process.env.REACT_APP_ENV ? `[${process.env.REACT_APP_ENV}] ` : ''}MiMtr Hybrid | Employee Details - ${this.state.employee.name}`;
          this.getData();
        }
      );
    }
  }

  async getData() {
    this.setState({ isLoading: true });

    this.getEmployeeDetailsData().then((detailsData) => {
      Api.getSupervisorsByContract(detailsData.homeContractId).then((supervisorsData) => {
        Api.getReaderCategories().then((readerCategoriesData) => {
          Api.getAgreementTypes().then((agreementTypesData) => {
            Api.getPayTypes().then((payTypesData) => {
              this.setState({
                isLoading: false,
                detailsData: detailsData,
                masterData: { ...detailsData },
                supervisorsData: supervisorsData,
                readerCategoriesData: readerCategoriesData,
                agreementTypesData: agreementTypesData,
                payTypesData: payTypesData,
              });
            });
          });
        });
      });
    });
  }

  async getEmployeeDetailsData() {
    let employee = this.state.employee;

    return new Promise((resolve, reject) => {
      let employeeDetailsApiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getEmployeeDetails?employeeId=${employee.id}`;
      fetch(employeeDetailsApiUrl)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, {
              method: 'POST',
            });
            return res.json();
          }
        })
        .then((data) => {
          if (!data.results || !data.results.length || !data.results[0]) {
            resolve([]);
            return;
          }
          let detailsData = data.results[0][0];
          detailsData.homeGpsLatitude = detailsData.homeGps.split(',')[0];
          detailsData.homeGpsLongitude = detailsData.homeGps.split(',')[1];
          detailsData.supervisor = Utils.titleize(detailsData.supervisor.replace('.', ' '));
          detailsData.contracts = data.results[1] ? _.sortBy(data.results[1], [(contract) => contract.name.toLowerCase()]) : [];
          detailsData.areas = data.results[2] ? data.results[2] : [];
          detailsData.routeCategories = data.results[3] ? data.results[3] : [];
          detailsData.roles = data.results[4] ? _.sortBy(data.results[4], [(role) => role.contract.toLowerCase()], 'name') : [];
          detailsData.equipment = data.results[5] ? data.results[5] : [];

          // Roster is only for pay types  3: "Full-Time - Wages" and 4: "Part-Time - Wages"
          if ([3, 4].includes(detailsData.payTypeId)) {
            detailsData.roster = data.results[6] ? data.results[6] : [];
            detailsData.roster = this.sortByDay(detailsData.roster);
          }

          resolve(detailsData);
        });
    });
  }

  sortByDay(arr) {
    const days = { MON: 0, TUE: 1, WED: 2, THU: 3, FRI: 4, SAT: 5, SUN: 6 };

    return arr.sort(function compare(a, b) {
      a = days[a.workDay];
      b = days[b.workDay];
      return a - b;
    });
  }

  renderListResults(dataType) {
    if (!this.state.detailsData) {
      return [];
    }

    return this.state.detailsData[dataType].map((data) => {
      let selection = { [dataType]: data };
      return (
        <div className={'result-item details'} key={`${dataType}-${data.id}-${data.contract}`}>
          <div id={`${dataType}-` + data.id} className={`item-card`}>
            <div className='item-row'>
              <div className='item-detail' title={data.name}>
                <span>
                  {data.areaGroupName ? `${data.areaGroupName} - ` : ''}
                  {data.name}
                </span>
              </div>
              <div className='item-detail' title={data.contract}>
                <span>{data.contract}</span>
              </div>
              <div className='item-detail' onClick={() => this.updateEmployeeDetailsList(dataType, selection, true)}>
                <Icon name='trash' />
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  renderRosterList() {
    if (!this.state.detailsData || !this.state.detailsData.roster) {
      return [];
    }

    return this.state.detailsData.roster.map((data) => {
      return (
        <div className={'result-item details'} key={`roster-${data.workDay}-${data.contract}`}>
          <div id={`roster-` + data.workDay} className={`item-card`}>
            <div className='item-row'>
              <div className='item-detail' title={data.workDay}>
                <Icon name='calendar' />
                <span>{data.workDay}</span>
              </div>
              <div className='item-detail' title={'Start Time - End Time'}>
                <Icon name='timer' />
                <span>
                  {data.startTime} - {data.endTime}
                </span>
              </div>
              <div className='item-detail' title={'Meal Break'}>
                <Icon name='coffee' />
                <span>{data.mealBreak}</span>
              </div>
              <div className='item-detail' onClick={() => this.updateRoster(data, 'delete')}>
                <Icon name='trash' />
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  async getNewResultOptions(type) {
    let promise;
    let formatFn = (data) => {
      return { id: data.id, name: data.name };
    };

    switch (type) {
      case 'areas':
        let contractIds = this.state.detailsData.contracts.map((contract) => contract.id);
        promise = Api.getAreasByContract(contractIds);
        formatFn = (area) => {
          return { id: area.id, name: `${area.areaGroupName} - ${area.name}` };
        };
        break;
      case 'routeCategories':
        promise = Api.getRouteCategories();
        break;
      case 'readFrequency':
        promise = Api.getReadFrequencies();
        break;
      case 'equipment':
        promise = Api.getReaderEquipment();
        break;
      case 'roles':
        promise = Api.getUserRoles();
        break;
      default:
        break;
    }

    return new Promise((resolve, reject) => {
      promise.then((data) => {
        data = _.uniqBy(data, 'id')
          .filter((result) => {
            return type !== 'roles' ? !this.state.detailsData[type].map((data) => data.id).includes(result.id) : result;
          })
          .map((result) => formatFn(result));
        resolve(data);
      });
    });
  }

  updateField(field, value) {
    let details = this.state.detailsData;
    details[field] = value;

    this.setState({
      detailsData: details,
    });
  }

  validateForm() {
    let isValid = true;
    let requiredInputs = document.getElementsByClassName('form-container')[0].querySelectorAll('[required]');
    let requiredElements = document.getElementsByClassName('selected-text required');

    requiredInputs.forEach((element) => {
      if (_.isEmpty(element.value)) {
        element.classList.add('input--invalid');
        isValid = false;
      } else {
        element.classList.remove('input--invalid');
      }
    });

    _.forEach(requiredElements, (element) => {
      if (_.isEmpty(element.getElementsByTagName('input')[0].value)) {
        element.classList.add('input--invalid');
        isValid = false;
      }
    });

    return isValid;
  }

  async updateEmployeeDetails() {
    let body = { ...this.state.detailsData };

    if (!this.validateForm()) {
      this.setState({
        showRequiredWarning: true,
      });
      return;
    }

    this.setState({
      isSaving: true,
    });

    if (body.activeUser && !body.startDate) {
      body.startDate = new Date().toISOString();
    } else if (!body.activeUser && !body.inactiveDate) {
      body.inactiveDate = new Date().toISOString();
    }

    return new Promise((resolve, reject) => {
      let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/updateEmployeeDetails`;
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      };

      fetch(apiUrl, requestOptions).then((res) => {
        if (res.status === 401) {
          this.props.logoutFn();
        } else {
          fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, {
            method: 'POST',
          });
        }

        this.setState(
          {
            showRequiredWarning: false,
            masterData: body,
            showSuccess: true,
            isSaving: false,
          },
          () => {
            setTimeout(() => {
              this.setState({
                showSuccess: false,
              });
            }, 3000);
          }
        );

        this.getData();
      });
    });
  }

  async updateEmployeeDetailsList(type, data, remove) {
    let apiUrl = '';
    let body = {
      userId: this.state.employee.id,
      remove: remove,
    };

    this.setState({
      isSaving: true,
    });

    let newResult = { id: data[type].id, name: data[type].name };
    let sortKey = 'name';

    switch (type) {
      case 'contracts':
        apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/updateUserContracts`;
        body.contractId = data.contracts.id;

        sortKey = [(contract) => contract.name.toLowerCase()];
        break;
      case 'areas':
        apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/updateUserAreas`;
        body.areaId = data.areas.id;
        body.contractId = this.props.selectedContract.id;
        break;
      case 'routeCategories':
        apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/updateUserRouteCategories`;
        body.routeCategoryId = data.routeCategories.id;
        break;
      case 'roles':
        apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/updateUserRoles`;
        body.roleId = data.roles.id;
        body.contractId = data.roles.contractId;

        if (data.contracts) {
          body.contractId = data.contracts.id;
          newResult.contract = data.contracts.name;
        }

        sortKey = [(role) => role.contract.toLowerCase()];
        break;
      case 'equipment':
        apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/updateUserEquipment`;
        body.equipmentId = data.equipment.id;
        break;
      default:
        break;
    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    };

    return new Promise((resolve, reject) => {
      fetch(apiUrl, requestOptions)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, {
              method: 'POST',
            });
            return res.json();
          }
        })
        .then((res) => {
          let detailsData = this.state.detailsData;

          if (remove) {
            detailsData[type] = detailsData[type].filter((result) => {
              if (result.contractId) {
                return !(result.id === data[type].id && result.contractId === data[type].contractId);
              } else {
                return result.id !== data[type].id;
              }
            });
          } else {
            detailsData[type].push(newResult);
            detailsData[type] = _.sortBy(detailsData[type], sortKey, 'name');
          }

          this.setState({
            detailsData: detailsData,
            masterData: { ...detailsData },
            isSaving: false,
          });

          resolve(res);
        });
    });
  }

  updatePayType(option) {
    this.setState({
      showSalariedEmployeeWarning: option.name === 'Salaried Employee',
    });

    this.updateField('payTypeId', option.id);
    this.updateField('payType', option.name);
  }

  updateRoster(data, action) {
    let body = {
      userId: this.state.employee.id,
    };

    this.setState({
      isSaving: true,
    });

    switch (action) {
      case 'add':
        body.action = 'A';
        body.workDay = data.workDay.name;
        body.startTime = data.time.startTime;
        body.endTime = data.time.endTime;
        body.mealBreak = data.mealBreak;
        break;
      case 'delete':
        body.action = 'D';
        body.rosterId = data.rosterId;
        break;
      case 'removeAll':
        body.action = 'R';
        break;
      default:
    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    };

    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/updateEmployeeRoster`, requestOptions)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, {
              method: 'POST',
            });
            return res.json();
          }
        })
        .then((res) => {
          if (res.message === 'PASS') {
            let detailsData = this.state.detailsData;

            if (action === 'add') {
              let newResult = {
                rosterId: res.results[0].rosterId,
                workDay: body.workDay,
                startTime: body.startTime,
                endTime: body.endTime,
                mealBreak: body.mealBreak,
              };
              detailsData.roster.push(newResult);
            } else if (action === 'delete') {
              detailsData.roster = detailsData.roster.filter((result) => result.rosterId !== data.rosterId);
            } else if (action === 'removeAll') {
              detailsData.roster = [];
            }

            detailsData.roster = this.sortByDay(detailsData.roster);

            this.setState({
              detailsData: detailsData,
            });
          } else {
            this.setState({
              showModal: true,
              rosterFailWarning: res.message,
            });
          }

          this.setState({
            isSaving: false,
          });
        });
    });
  }

  selectStatusGroup(tabName) {
    this.setState({
      selectedGroup: tabName,
      showRequiredWarning: false,
      detailsData: { ...this.state.masterData },
    });
  }

  selectEmployeeTimesheet() {
    let employee = this.state.employee;
    localStorage.setItem('selectedEmployeeTimesheet', JSON.stringify({ id: employee.id, name: employee.name }));
    window.open('/hybrid/timesheet/employee');
  }

  showModal(type, show) {
    const showModal = show !== undefined ? show : !this.state.showModal;

    this.setState({
      showModal: showModal,
    });
  }

  render() {
    let employee = this.state.employee ? this.state.employee : {};
    let details = this.state.detailsData;

    let contractList = { resultData: this.renderListResults('contracts') };
    let areaList = { resultData: this.renderListResults('areas') };
    let routeCategoriesList = {
      resultData: this.renderListResults('routeCategories'),
    };
    let rolesList = { resultData: this.renderListResults('roles') };
    let equipmentList = { resultData: this.renderListResults('equipment') };
    let rosterList = { resultData: this.renderRosterList() };

    let groups = ['details', 'pay', 'roster', 'routes', 'roles', 'equipment'].map((group) => {
      return { name: group };
    });

    if (!this.props.selectedContract.roles.includes(ROLE.TIME_AND_ATTENDANCE_ADMIN)) {
      groups = groups.filter((group) => !['pay', 'roster'].includes(group.name));
    }

    if (details && ![3, 4].includes(details.payTypeId)) {
      groups = groups.filter((group) => !['roster'].includes(group.name));
    }

    return (
      <div className={'main-content employee-details-page'}>
        <header>
          <h1>Employee Details - {employee.name}</h1>
          <img className='logo-container' src={process.env.REACT_APP_ENV ? uat_logo : logo} alt='' />
        </header>
        {!this.state.isLoading && <TabContainer id={'employee-details'} groups={groups} selectedTab={this.state.selectedGroup} onClick={(name) => this.selectStatusGroup(name)} />}

        {/* Personal Details Tab */}
        {!this.state.isLoading && this.state.selectedGroup === 'details' && (
          <div className='scrollable-container'>
            <div className={`form-container ${this.state.isSaving ? 'saving' : ''}`}>
              <section>
                <h3>Personal Information</h3>
                <div className={'form-row'}>
                  <div className='form-element'>
                    <label>Employee Name</label>
                    <input
                      id={`input-first-name`}
                      type='text'
                      autoComplete='off'
                      placeholder={'First Name'}
                      value={details.firstName}
                      required
                      onChange={(e) => this.updateField('firstName', e.target.value)}
                    />
                    <input
                      id={`input-last-name`}
                      type='text'
                      autoComplete='off'
                      placeholder={'Last Name'}
                      value={details.lastName}
                      required
                      onChange={(e) => this.updateField('lastName', e.target.value)}
                    />
                  </div>
                </div>
                <div className={'form-row'}>
                  <div className='form-element'>
                    <label>Work Contact Number</label>
                    <input id={`input-work-no`} type='text' autoComplete='off' value={details.officeNumber ?? ''} onChange={(e) => this.updateField('officeNumber', e.target.value)} />
                  </div>
                  <div className='form-element'>
                    <label>Mobile Contact Number</label>
                    <input
                      id={`input-mobile-no`}
                      type='text'
                      autoComplete='off'
                      name={'mobileNumber'}
                      value={details.mobileNumber}
                      onChange={(e) => this.updateField('mobileNumber', e.target.value)}
                    />
                  </div>
                  <div className='form-element'>
                    <label>Email Address</label>
                    <input
                      id={`input-email`}
                      type='text'
                      autoComplete='off'
                      name={'emailAddress'}
                      value={details.emailAddress ? details.emailAddress : ''}
                      onChange={(e) => this.updateField('emailAddress', e.target.value)}
                    />
                  </div>
                </div>
                <div className={'form-row'}>
                  <div className='form-element'>
                    <label>Home GPS (Latitude, Longitude)</label>
                    <input
                      id={`input-home-gps`}
                      className={'input--small'}
                      type='text'
                      autoComplete='off'
                      value={details.homeGpsLatitude}
                      onChange={(e) => this.updateField('homeGpsLatitude', e.target.value)}
                    />
                    <input
                      id={`input-home-gps`}
                      className={'input--small'}
                      type='text'
                      autoComplete='off'
                      value={details.homeGpsLongitude}
                      onChange={(e) => this.updateField('homeGpsLongitude', e.target.value)}
                    />
                  </div>
                </div>
              </section>
              <section>
                <h3>MiMtr User</h3>
                <div className={'form-row'}>
                  <div className='form-element'>
                    <label>Username</label>
                    <input id={`input-username`} type='text' autoComplete='off' value={details.username} required onChange={(e) => this.updateField('username', e.target.value)} />
                  </div>
                  <div className='form-element'>
                    <label>MRID</label>
                    <input id={`input-alias`} type='text' autoComplete='off' value={details.alias} required onChange={(e) => this.updateField('alias', e.target.value)} />
                  </div>
                  <div className='form-element'>
                    <label>Active User</label>
                    <ToggleButton
                      options={[
                        { name: 'Yes', value: true },
                        { name: 'No', value: false },
                      ]}
                      selection={details.activeUser}
                      onClick={(option) => this.updateField('activeUser', option)}
                    />
                  </div>
                </div>
                <div className={'form-row'}>
                  <div className='form-element'>
                    <label>Supervisor</label>
                    <CustomSelect
                      id={`input-supervisor`}
                      placeholder={'Select'}
                      optionsList={this.state.supervisorsData}
                      defaultText={details.supervisor}
                      onClick={(option) => {
                        this.updateField('supervisorId', option.id);
                        this.updateField('supervisor', option.name);
                      }}
                    />
                  </div>
                  <div className='form-element'>
                    <label>Reader Category</label>
                    <CustomSelect
                      id={`input-reader-category`}
                      placeholder={'Select'}
                      optionsList={this.state.readerCategoriesData}
                      defaultText={details.readerCategory}
                      onClick={(option) => {
                        this.updateField('readerCategoryId', option.id);
                        this.updateField('readerCategory', option.name);
                      }}
                    />
                  </div>
                  <div className='form-element'>
                    <label>Active Reader</label>
                    <ToggleButton
                      options={[
                        { name: 'Yes', value: true },
                        { name: 'No', value: false },
                      ]}
                      selection={details.activeReader}
                      onClick={(option) => this.updateField('activeReader', option)}
                    />
                  </div>
                </div>
                <div className={'form-row'}>
                  <div className='form-element'>
                    <label>Work Capacity</label>
                    <input
                      id={`input-work-capacity-hh`}
                      className={'input--small'}
                      type='number'
                      placeholder={'hh'}
                      autoComplete='off'
                      value={details.workCapacityHours}
                      onChange={(e) => this.updateField('workCapacityHours', e.target.value)}
                    />
                    <input
                      id={`input-work-capacity-mm`}
                      className={'input--small'}
                      type='number'
                      placeholder={'mm'}
                      autoComplete='off'
                      value={details.workCapacityMins}
                      onChange={(e) => this.updateField('workCapacityMins', e.target.value)}
                    />
                  </div>
                  <div className='form-element'>
                    <label>Does Monthly Routes</label>
                    <ToggleButton
                      options={[
                        { name: 'Yes', value: true },
                        { name: 'No', value: false },
                      ]}
                      selection={details.monthlyRoutes}
                    />
                  </div>
                </div>
              </section>
            </div>
          </div>
        )}

        {/* Pay Details Tab */}
        {!this.state.isLoading && this.state.selectedGroup === 'pay' && (
          <div className='scrollable-container'>
            <div className={`form-container ${this.state.isSaving ? 'saving' : ''}`}>
              <section>
                <h3>Pay Details</h3>
                <div className={'form-row'}>
                  <div className='form-element'>
                    <label>SAP Reference #</label>
                    <input
                      id={`input-sap-ref-no`}
                      type='text'
                      autoComplete='off'
                      name={'sapReferenceNumber'}
                      value={details.sapReferenceNumber}
                      readOnly={true}
                      onChange={(e) => this.updateField('sapEmployeeId', e.target.value)}
                    />
                  </div>
                </div>

                <div className={'form-row'}>
                  <div className='form-element'>
                    <label>Start Date</label>
                     <input
                      type='text'
                      autoComplete='off'
                      value={details.startDate ? moment(details.startDate).format('DD/MM/YYYY') : 'N/A'}
                      readOnly
                    />
                  </div>

                  <div className='form-element'>
                    <label>Inactive Date</label>
                      <input
                      type='text'
                      autoComplete='off'
                      value={details.inactiveDate ? moment(details.startDate).format('DD/MM/YYYY') : 'N/A'}
                      readOnly
                    />
                  </div>
                </div>

                <div className={'form-row'}>
                  <div className='form-element'>
                    <label>Pay Type</label>
                    <CustomSelect
                      id={`input-pay-type`}
                      placeholder={'Select'}
                      optionsList={this.state.payTypesData}
                      defaultText={details.payType}
                      required={true}
                      onClick={(option) => this.updatePayType(option)}
                    />
                    {this.state.showSalariedEmployeeWarning && <div className={'warning-message'}>Salaried Employee timesheet data is not included in Quintiq</div>}
                  </div>
                </div>
                <div className={'form-row'}>
                  <div className='form-element'>
                    <label>Award / Agreement</label>
                    <CustomSelect
                      id={`input-award-agreement`}
                      placeholder={'Select'}
                      optionsList={this.state.agreementTypesData}
                      defaultText={details.agreementType}
                      required={true}
                      onClick={(option) => {
                        this.updateField('agreementTypeId', option.id);
                        this.updateField('agreementType', option.name);
                      }}
                    />
                  </div>
                </div>
                <div className={'form-row'}>
                  <div className='form-element'>
                    <label>Username</label>
                    <input id={`input-username`} type='text' autoComplete='off' value={details.username} required={true} onChange={(e) => this.updateField('username', e.target.value)} />
                  </div>
                </div>
                <div className={'form-row'}>
                  <div className='form-element'>
                    <label>Home Contract</label>
                    <CustomSelect
                      id={`input-home-contract`}
                      placeholder={'Select'}
                      optionsList={details.contracts}
                      defaultText={details.homeContract}
                      onClick={(option) => {
                        this.updateField('homeContractId', option.id);
                        this.updateField('homeContract', option.name);
                      }}
                    />
                  </div>
                </div>
              </section>
              <section>
                <div>
                  <Button classes={'button--plain'} text={`Go To ${employee.name}'s Timesheets`} onClick={() => this.selectEmployeeTimesheet()} />
                </div>
              </section>
            </div>
          </div>
        )}

        {/* Roster Tab */}
        {!this.state.isLoading && this.state.selectedGroup === 'roster' && (
          <div className='scrollable-container'>
            <div className='form-container'>
              <section>
                <h3>Roster</h3>
                <ResultsContainer data={rosterList} dataClass={'roster'} isLoading={this.state.isSaving} />
                <AddNewResult
                  id='roster'
                  items={[
                    {
                      id: 'workDay',
                      placeholder: 'Select Day',
                      options: [{ name: 'MON' }, { name: 'TUE' }, { name: 'WED' }, { name: 'THU' }, { name: 'FRI' }, { name: 'SAT' }, { name: 'SUN' }],
                    },
                    { id: 'time', type: 'timeRange' },
                    {
                      id: 'mealBreak',
                      type: 'number',
                      placeholder: 'Enter Break',
                    },
                  ]}
                  updateFn={(data) => this.updateRoster(data, 'add')}
                />
                <div className={`remove-all-result-container`} id={`remove-all-rosters`}>
                  <Button classes={'button--destroy'} icon={'trash'} iconPosition={'1'} text={'Remove All'} onClick={() => this.updateRoster(null, 'removeAll')} />
                </div>
              </section>
            </div>
            {this.state.showModal && (
              <Modal showModalFn={(show) => this.showModal(show)} showModal={this.state.showModal}>
                <div id={'popup-modal'} className='modal-content'>
                  <div className='heading f--warning'>Cannot Update Roster</div>
                  <div className='form-container'>
                    <p>{this.state.rosterFailWarning}</p>
                  </div>
                </div>
              </Modal>
            )}
          </div>
        )}

        {/* Routes Tab */}
        {!this.state.isLoading && this.state.selectedGroup === 'routes' && (
          <div className='scrollable-container'>
            <div className='form-container'>
              <section>
                <h3>Contracts</h3>
                <ResultsContainer data={contractList} dataClass={'contract'} isLoading={this.state.isSaving} />
                <AddNewResult
                  id='contracts'
                  items={[
                    {
                      id: 'contracts',
                      getOptionsFn: () =>
                        Api.getContracts().then((data) => {
                          return data.filter((result) => !this.state.detailsData.contracts.map((data) => data.id).includes(result.id));
                        }),
                    },
                  ]}
                  updateFn={(data) => this.updateEmployeeDetailsList('contracts', data)}
                />
              </section>
              <section>
                <h3>Areas</h3>
                <ResultsContainer data={areaList} dataClass={'area'} isLoading={this.state.isSaving} />
                <AddNewResult
                  id='areas'
                  items={[
                    {
                      id: 'areas',
                      getOptionsFn: () => this.getNewResultOptions('areas'),
                    },
                  ]}
                  updateFn={(data) => this.updateEmployeeDetailsList('areas', data)}
                />
              </section>
              <section>
                <h3>Route Categories</h3>
                <ResultsContainer data={routeCategoriesList} dataClass={'routeCategories'} isLoading={this.state.isSaving} />
                <AddNewResult
                  id='routeCategories'
                  getOptionsFn={() => this.getNewResultOptions('routeCategories')}
                  items={[
                    {
                      id: 'routeCategories',
                      getOptionsFn: () => this.getNewResultOptions('routeCategories'),
                    },
                  ]}
                  updateFn={(data) => this.updateEmployeeDetailsList('routeCategories', data)}
                />
              </section>
            </div>
          </div>
        )}

        {/* Roles Tab */}
        {!this.state.isLoading && this.state.selectedGroup === 'roles' && (
          <div className='scrollable-container'>
            <div className='form-container'>
              <section>
                <h3>Work Roles</h3>
                <ResultsContainer data={rolesList} dataClass={'role'} isLoading={this.state.isSaving} />
                {this.state.isAdminUserView && (
                  <AddNewResult
                    id='roles'
                    items={[
                      {
                        id: 'roles',
                        placeholder: 'Select Role',
                        getOptionsFn: () => this.getNewResultOptions('roles'),
                      },
                      {
                        id: 'contracts',
                        placeholder: 'Select Contract',
                        getOptionsFn: () => {
                          return new Promise((resolve) => {
                            resolve(this.state.detailsData.contracts);
                          });
                        },
                      },
                    ]}
                    getOptionsFn={() => this.getNewResultOptions('roles')}
                    updateFn={(data) => this.updateEmployeeDetailsList('roles', data)}
                  />
                )}
              </section>
            </div>
          </div>
        )}

        {/* Equipment Tab */}
        {!this.state.isLoading && this.state.selectedGroup === 'equipment' && (
          <div className='scrollable-container'>
            <div className='form-container'>
              <section>
                <h3>Equipment</h3>
                <ResultsContainer data={equipmentList} dataClass={'equipment'} isLoading={this.state.isSaving} />
                <AddNewResult
                  id='equipment'
                  items={[
                    {
                      id: 'equipment',
                      getOptionsFn: () => this.getNewResultOptions('equipment'),
                    },
                  ]}
                  updateFn={(data) => this.updateEmployeeDetailsList('equipment', data)}
                />
              </section>
            </div>
          </div>
        )}
        {!this.state.isLoading && ['details', 'pay'].includes(this.state.selectedGroup) && (
          <div className='button-group'>
            {this.state.showRequiredWarning && <div className={'warning-message'}>Please fill in the required fields.</div>}
            {!this.state.isSaving && <div className={`success-message ${this.state.showSuccess ? 'a--fade-out' : 'a--fade-in'}`}>Changes saved.</div>}
            {!this.state.isSaving && <Button classes='button button--blue' icon={'check'} iconPosition={'1'} onClick={() => this.updateEmployeeDetails()} text='Save Changes' />}
            {this.state.isSaving && <Loader type='Bars' color='#008dce' height={30} width={30} visible={this.state.isSaving} />}
          </div>
        )}
        {this.state.isLoading && (
          <div className='spinner-container'>
            <Loader type='Bars' color='#008dce' height={30} width={30} visible={this.state.isLoading} />
          </div>
        )}
        <img className='wave' src={wave} alt={''} />
      </div>
    );
  }
}

export default withRouter(EmployeeDetails);
