import React from 'react';
import Icon from '../Icon';

class RouteJobSequenceResult extends React.Component {
  handleClick = (e) => {
    const job = this.props.data;
    const jobId = `${job.meterNumber}-${job.jobLocationSequence}-${job.meterSequenceNumber}`;
    const jobElem = document.getElementById('job-' + jobId);

    if (jobElem.contains(e.target)) {
      const editableElem = document.getElementById('edit-button-' + jobId);

      if (!editableElem || !editableElem.contains(e.target)) {
        this.props.selectFn();
      }
    }
  };

  componentDidMount() {
    const job = this.props.data;
    const element = document.getElementById(`job-${job.meterNumber}-${job.jobLocationSequence}-${job.meterSequenceNumber}`);
    element.addEventListener('mousedown', this.handleClick);
  }

  componentWillUnmount() {
    // Remove the event listener on component unmounting
    const job = this.props.data;
    const element = document.getElementById(`job-${job.meterNumber}-${job.jobLocationSequence}-${job.meterSequenceNumber}`);
    if (!element) {
      debugger;
    }
    element.removeEventListener('mousedown', this.handleClick);
  }

  render() {
    const job = this.props.data;
    let isReadOnly = job.isReadOnly;
    // let mismatched = !isReadOnly && Number.isInteger(job.comparisonOrder) && job.flatIndex !== job.comparisonOrder;
    // let matched = !isReadOnly && Number.isInteger(job.comparisonOrder) && job.flatIndex === job.comparisonOrder;
    let mismatched = !isReadOnly && Number.isInteger(job.locationComparisonOrder) && job.locationIndex !== job.locationComparisonOrder;
    let matched = !isReadOnly && Number.isInteger(job.locationComparisonOrder) && job.locationIndex === job.locationComparisonOrder;

    return (
      <div className={`result-item ${job.selected ? 'selected' : ''}`}>
        <div
          id={`job-${job.meterNumber}-${job.jobLocationSequence}-${job.meterSequenceNumber}`}
          className={`item-card ${mismatched ? 'item--threat' : ''} ${matched ? 'item--highlight' : ''} ${
            job.missingFromTemplate !== undefined ? (job.missingFromTemplate ? 'item--urgent' : 'read-only') : ''
          }`}>
          {!isReadOnly && (
            <div id={`job-${job.meterNumber}-${job.jobLocationSequence}-${job.meterSequenceNumber}`} className={'trash-button'} title={'Click to Remove'} onClick={() => this.props.deleteFn()}>
              <Icon name='trash' />
            </div>
          )}
          <div className='item-row'>
            {/* Custom Address */}
            {!isReadOnly && (
              <div className={`item-detail ${!isReadOnly ? 'inline-editable' : ''}`} title='Custom Address'>
                <Icon name='pin' />
                <span
                  id={`job-custom-address-${job.meterNumber}-${job.jobLocationSequence}-${job.meterSequenceNumber}`}
                  className='u--ellipsis'
                  title={job.customAddress}
                  onClick={() => this.props.editFn()}>
                  {job.customAddress ? job.customAddress : job.customAddress}
                </span>
              </div>
            )}
            {/* Address */}
            <div className={`item-detail ${!isReadOnly ? 'inline-editable' : ''}`} title='Address'>
              <Icon name='map' />
              <span id={`job-address-${job.meterNumber}-${job.jobLocationSequence}-${job.meterSequenceNumber}`} className='u--ellipsis' title={job.address} onClick={() => this.props.editFn()}>
                {job.address ? job.address : job.address}
              </span>
            </div>
            {/* Meter Number */}
            <div className='item-detail' title='Meter Number'>
              <Icon name='meter-box' />
              <span>#{job.meterNumber}</span>
            </div>
            {/* Meter Category */}
            <div className='item-detail' title='Meter Category'>
              <Icon name='label' />
              <span>{job.meterCategory}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RouteJobSequenceResult;
