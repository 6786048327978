import React from 'react';
import Icon from './Icon';

class SearchInput extends React.Component {
  handleChange = (e) => {
    let searchTerm = e.target.value;

    this.props.onChange(searchTerm);
  };

  render() {
    return (
      <div className='input-container'>
        <input id='search-bar' className={'search-bar'} type='text' placeholder={this.props.placeholder ? this.props.placeholder : 'Search'} autoComplete='off' onChange={this.handleChange} />
        <Icon name='magnifying-glass' />
      </div>
    );
  }
}

export default SearchInput;
