import React from 'react';
import _ from 'lodash';
import ButtonGroup from './ButtonGroup';
import CustomSelect from './CustomSelect';
import DatePickerSelect from './DatePickerSelect';

class FilterPopover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showList: false,
      defaultSelectText: 'Select',
      filters: [...props.filters],
    };
  }

  applyFilters() {
    this.props.onClick(this.state.filters);
  }

  clearFilters() {
    this.props.onClick();
  }

  updateField(selectedFilter, val) {
    let filterIndex = this.state.filters.findIndex((filter) => filter.name === selectedFilter.name);
    let filters = this.state.filters;

    selectedFilter.selection = selectedFilter.selection ? selectedFilter.selection : [];
    switch (selectedFilter.type) {
      case 'tags':
        if (selectedFilter.selection.includes(val)) {
          selectedFilter.selection = _.without(selectedFilter.selection, val);
        } else {
          selectedFilter.selection.push(val);
        }

        selectedFilter.selection = selectedFilter.selection.length ? selectedFilter.selection : null;

        filters = selectedFilter.selectCallback(selectedFilter.selection, filters);

        break;

      case 'select':
        if (selectedFilter.multiselect) {
          if (selectedFilter.selection.includes(val)) {
            selectedFilter.selection = _.without(selectedFilter.selection, val);
          } else {
            selectedFilter.selection.push(val);
          }
        } else {
          filters[filterIndex].selection = val;
        }
        break;
      default:
        filters[filterIndex].selection = val;
        break;
    }

    if (selectedFilter.onClick) {
      selectedFilter.onClick();
    }

    this.setState({
      filters: filters,
    });
  }

  renderElement(filter) {
    let element;
    if (typeof filter.hide === 'function' && !filter.hide()) {
      return;
    }

    switch (filter.type) {
      case 'select':
        let defaultText;

        // If multiselect, display selection as count if more than one selected, or just the one selection
        if (filter.multiselect) {
          if (!filter.selection.length) {
            defaultText = null;
          } else {
            defaultText = filter.selection.length > 1 ? `${filter.selection.length} options selected` : filter.options.find((option) => filter.selection[0] === option.id).name;
          }
        } else {
          defaultText = filter.selection ? filter.selection.name : null;
        }

        element = (
          <CustomSelect
            defaultText={defaultText}
            placeholder={this.state.defaultSelectText}
            optionsList={filter.options}
            multiselect={filter.multiselect}
            selection={filter.selection}
            onClick={(option) => this.updateField(filter, option)}
            id={'filter-' + filter.name}
          />
        );
        break;
      case 'tags':
        element = (
          <div className='tag-container'>
            {filter.options.map((option) => {
              const selected = filter.selection && filter.selection.includes(option.id);
              return (
                <div className={`tag ${selected ? 'selected' : ''}`} onClick={() => this.updateField(filter, option.id)} key={option.id}>
                  <span>{option.name}</span>
                </div>
              );
            })}
          </div>
        );
        break;
      case 'dateSelectRange':
        let selection = { startDate: filter.selection.start, endDate: filter.selection.end };
        element = (
          <DatePickerSelect
            id={'read-window'}
            defaultText={'Select'}
            selection={selection}
            onClick={(startDate, endDate) => this.updateField(filter, { start: startDate, end: endDate })}
            range={true}
          />
        );
        break;

      case 'number':
        element = (
          <div className='input-container' key={filter.name}>
            <input id={`input-filter-${filter.name}`} type='number' value={filter.selection} onChange={(e) => this.updateField(filter, e.target.value)} />
          </div>
        );
        break;
      default:
        break;
    }

    return (
      <div className='form-element' key={filter.name}>
        <label>{filter.displayLabel}</label>
        {element}
      </div>
    );
  }

  render() {
    return (
      <div className='popover-content'>
        {_.map(this.state.filters, (filter) => {
          return this.renderElement(filter);
        })}
        <div className='popover-footer'>
          <div />
          <ButtonGroup
            items={[
              { type: 'button', classes: 'button--plain', text: 'Clear All', onClick: () => this.clearFilters() },
              { type: 'button', classes: 'button--blue', text: 'Apply', onClick: () => this.applyFilters() },
            ]}
          />
        </div>
      </div>
    );
  }
}

export default FilterPopover;
