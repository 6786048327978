import Slider from 'react-slick';
import React from 'react';
import Loader from 'react-loader-spinner';

class CardCarousel extends React.Component {
  render() {
    return (
      <div className='card-carousel'>
        <Slider {...this.props.settings} ref={this.props.reference}>
          {this.props.data.map((card) => {
            return card;
          })}
        </Slider>

        {this.props.isLoadingNextPhoto && (
          <div className='card-spinner-container'>
            <Loader type='Bars' color='#008dce' height={30} width={30} visible={this.props.isLoadingNextPhoto} />
          </div>
        )}
      </div>
    );
  }
}

export default CardCarousel;
