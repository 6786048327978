import React from 'react';
import _ from 'lodash';
import ButtonGroup from '../ButtonGroup';
import CustomSelect from '../CustomSelect';

class AssignmentsBulkUpdatePopover extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultSelectText: 'Select',
      readers: _.sortBy(props.options, 'name'),
      selectedReader: null,
      selectedDate: null,
    };
  }

  updateField(field, value) {
    this.setState({
      [field]: value,
    });
  }

  updateRoutes() {
    this.props.onClick(this.state.selectedReader, this.state.selectedDate);
  }

  render() {
    return (
      <div className='popover-content'>
        <div className='form-element'>
          <label>Meter Reader</label>
          <CustomSelect id={'assigned-reader'} placeholder={this.state.defaultSelectText} optionsList={this.state.readers} onClick={(id) => this.updateField('selectedReader', id)} />
        </div>
        <div className='form-element'>
          <label>Skip Code</label>
          <CustomSelect id={'assigned-reader'} placeholder={this.state.defaultSelectText} optionsList={[]} onClick={(id) => this.updateField('skipCode', id)} />
        </div>
        <div className='warning-message'>{this.props.warningMsg}</div>
        <div className='popover-footer'>
          <div></div>
          <ButtonGroup
            items={[
              { type: 'button', classes: 'button--plain', text: 'Cancel', onClick: () => this.props.cancelFn() },
              { type: 'button', classes: `button--blue ${this.props.warningMsg ? 'button--disabled' : ''}`, text: 'Skip', onClick: () => this.updateRoutes() },
            ]}
          />
        </div>
      </div>
    );
  }
}

export default AssignmentsBulkUpdatePopover;
