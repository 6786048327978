import React from 'react';
import Utils from '../utils/Utils';

class Tab extends React.Component {
  render() {
    const name = this.props.displayName ? this.props.displayName : this.props.name;
    let nameHumanised = '';
    if (name[0]) {
      nameHumanised = Utils.humanize(name);
    }
    const count = this.props.count;

    return (
      <div id={this.props.name} className={`tab ${this.props.selected ? 'selected' : ''} ${this.props.isLoading ? 'tab--disabled' : ''}`} onClick={() => this.props.onClick(this.props.name)}>
        {nameHumanised} {this.props.count !== undefined ? ' (' + count + ')' : ''}
      </div>
    );
  }
}

export default Tab;
