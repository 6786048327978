import React from 'react';
import Icon from '../Icon';

class MatrixPartResult extends React.Component {
  handleClick = (e) => {
    const data = this.props.data;
    const routeElem = document.getElementById('matrixPart-' + data.id);

    if (routeElem.contains(e.target)) {
      this.props.selectFn(data.id);
    }
  };

  componentDidMount() {
    const element = document.getElementById('matrixPart-' + this.props.data.id);
    element.addEventListener('mousedown', this.handleClick);
  }

  componentWillUnmount() {
    // Remove the event listener on component unmounting
    const element = document.getElementById('matrixPart-' + this.props.data.id);
    element.removeEventListener('mousedown', this.handleClick);
  }

  render() {
    const data = this.props.data;

    return (
      <div className={`result-item ${data.selected ? 'selected' : ''} matrix-part-item`}>
        <div id={'matrixPart-' + data.id} className={`item-card`}>
          <div className='item-row'>
            {/* Meters */}
            <div className='item-detail' title='Meters'>
              <label>PART NO.</label>
              <span>{data.partNo}</span>
            </div>
            {/* Completed */}
            <div className='item-detail' title='Completed Meters'>
              <label>MAP PART</label>
              <span>{data.mapPart}</span>
            </div>
            {/* Description */}
            <div className='item-detail' title={`Description: ${data.description}`}>
              <Icon name='label' />
              <span className='u--ellipsis'>{data.description}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MatrixPartResult;
