import React from 'react';
import Button from '../Button';
import './route-templates-page.scss';
import _ from 'lodash';
import CustomSelect from '../CustomSelect';
import Api from '../../api/Api';

class RouteTemplateModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jobLocationData: { ...props.data },
      showEditState: false,
      isSaving: false,
    };
  }

  async componentDidMount() {
    let jobLocationData = this.state.jobLocationData;
    Api.getStates(this.props.selectedContract).then((stateData) => {
      Api.getSuburbsByState(this.props.selectedContract, jobLocationData.stateId).then((suburbData) => {
        let matchingState = stateData.find((state) => state.id === jobLocationData.stateId);
        if (matchingState) {
          jobLocationData.stateName = matchingState.name;
        }
        Api.getStreetsBySuburb(this.props.selectedContract, jobLocationData.suburbId).then((streetData) => {
          this.setState({
            stateData: stateData,
            suburbData: suburbData,
            streetData: streetData,
            jobLocationData: jobLocationData,
          });
        });
      });
    });
  }

  updateField(field, value) {
    let jobLocationData = this.state.jobLocationData;
    jobLocationData[field] = value;

    if (field === 'stateId') {
      Api.getSuburbsByState(this.props.selectedContract, value).then((suburbData) => {
        this.setState({
          suburbData: suburbData,
        });
      });
    } else if (field === 'suburbId') {
      Api.getStreetsBySuburb(this.props.selectedContract, value).then((streetData) => {
        this.setState({
          streetData: streetData,
        });
      });
    }

    this.setState({
      jobLocationData: jobLocationData,
    });
  }

  validateForm() {
    let isValid = true;
    let requiredInputs = document.getElementsByClassName('form-container')[0].querySelectorAll('[required]');
    let requiredElements = document.getElementsByClassName('selected-text required');

    requiredInputs.forEach((element) => {
      if (_.isEmpty(element.value)) {
        element.classList.add('input--invalid');
        isValid = false;
      } else {
        element.classList.remove('input--invalid');
      }
    });

    _.forEach(requiredElements, (element) => {
      if (_.isEmpty(element.getElementsByTagName('input')[0].value)) {
        element.classList.add('input--invalid');
        isValid = false;
      }
    });

    return isValid;
  }

  updateRouteTemplate() {
    if (!this.validateForm()) {
      return;
    }

    let body = _.cloneDeep(this.state.jobLocationData);
    body.DatabaseServer = this.props.selectedContract.dbServer;

    this.setState({
      isSaving: true,
    });

    return new Promise((resolve, reject) => {
      let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/`;
      if (this.props.type === 'addLocation') {
        apiUrl += 'createTemplateJobLocation';
      } else {
        apiUrl += 'updateTemplateJobLocation';
      }

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      };

      fetch(apiUrl, requestOptions)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
            return res.json();
          }
        })
        .then((data) => {
          this.setState({
            isSaving: false,
          });

          this.props.updateFn(this.state.jobLocationData);
        });
    });
  }

  render() {
    let jobLocation = this.state.jobLocationData;

    return (
      <div id={'job-location-modal'} className='modal-content job-location-modal'>
        <div className='heading'>{this.props.type === 'editLocation' ? 'Edit' : 'Add'} Job Location</div>
        <div className={'scrollable-container'}>
          {!this.state.isLoading && (
            <div className={`form-container ${this.state.isSaving ? 'saving' : ''}`}>
              <div className='form-column'>
                <div className='form-element'>
                  <label>Unit Number / Street Number</label>
                  <input
                    id={`input-unitNumber`}
                    className={'input--small'}
                    type='text'
                    autoComplete='off'
                    value={jobLocation.unitNumber ? jobLocation.unitNumber : ''}
                    onChange={(e) => this.updateField('unitNumber', e.target.value)}
                  />
                  <input
                    id={`input-streetNumber`}
                    className={'input--small'}
                    type='text'
                    autoComplete='off'
                    required={true}
                    value={jobLocation.streetNumber ? jobLocation.streetNumber : ''}
                    onChange={(e) => this.updateField('streetNumber', e.target.value)}
                  />
                </div>
                <div className='form-element'>
                  <label>Building Name</label>
                  <input
                    id={`input-unit-number`}
                    type='text'
                    autoComplete='off'
                    value={jobLocation.buildingName ? jobLocation.buildingName : ''}
                    onChange={(e) => this.updateField('buildingName', e.target.value)}
                  />
                </div>
                <div className='form-element'>
                  <label>State</label>
                  <CustomSelect
                    placeholder={'Select'}
                    defaultText={jobLocation.stateName}
                    optionsList={this.state.stateData}
                    onClick={(option) => {
                      this.updateField('stateId', option.id);
                      this.updateField('state', option.name);
                    }}
                    required={true}
                    id='state'
                  />
                </div>
                <div className='form-element'>
                  <label>Meter Number</label>
                  <input
                    id={`input-meter-number`}
                    type='text'
                    autoComplete='off'
                    required={true}
                    value={jobLocation.meterNumber ? jobLocation.meterNumber : ''}
                    onChange={(e) => this.updateField('meterNumber', e.target.value)}
                  />
                </div>
              </div>
              <div className='form-column'>
                <div className='form-element'>
                  <label>Street Name</label>
                  <CustomSelect
                    placeholder={jobLocation.suburbId ? 'Select' : 'Select Suburb First'}
                    defaultText={jobLocation.streetName ? jobLocation.streetName : null}
                    optionsList={this.state.streetData}
                    onClick={(option) => {
                      this.updateField('streetId', option.id);
                      this.updateField('streetName', option.name);
                    }}
                    required={true}
                    id='street'
                  />
                </div>
                <div className='form-element'>
                  <label>Suburb</label>
                  <CustomSelect
                    placeholder={jobLocation.stateId ? 'Select' : 'Select State First'}
                    defaultText={jobLocation.suburbName ? jobLocation.suburbName : null}
                    optionsList={this.state.suburbData}
                    onClick={(option) => {
                      this.updateField('suburbId', option.id);
                      this.updateField('suburbName', option.name);
                    }}
                    required={true}
                    id='suburb'
                  />
                </div>
                <div className='form-element'>
                  <label>Custom Address</label>
                  <input
                    id={`input-custom-address`}
                    type='text'
                    autoComplete='off'
                    value={jobLocation.customAddress ? jobLocation.customAddress : ''}
                    onChange={(e) => this.updateField('customAddress', e.target.value)}
                  />
                </div>
                <div className='form-element'>
                  <label>Utility</label>
                  <CustomSelect
                    defaultText={jobLocation.meterCategory}
                    optionsList={[
                      { id: 'E', name: 'Electricity' },
                      { id: 'W', name: 'Water' },
                      { id: 'G', name: 'Gas' },
                    ]}
                    onClick={(option) => this.updateField('meterCategory', option.id)}
                    id='meterCategory'
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className='button-group t--align-right'>
          <Button classes={`button button--plain ${this.state.isSaving ? 'button--disabled' : ''}`} onClick={() => this.props.showModalFn(false)} text='Cancel' />
          <Button classes={`button button--med button--blue ${this.state.isSaving ? 'button--disabled' : ''}`} onClick={() => this.updateRouteTemplate()} text='Submit' />
        </div>
      </div>
    );
  }
}

export default RouteTemplateModal;
