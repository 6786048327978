import React from 'react';
import Icon from '../../Icon';

class ExportFileResult extends React.Component {
  render() {
    const data = this.props.data;

    return (
      <div className={`result-item export-file-item`}>
        <div id={'exportFile-' + data.id} className={`item-card`}>
          <div className='item-row'>
            {/* Exported Date */}
            <div className='item-detail' title='Exported Date'>
              <Icon name='exportDate' />
              <span>{data.exportedDate.format('DD/MM/YYYY hh:mma')}</span>
            </div>
            {/* File Name */}
            <div className='item-detail f--bold' title='File Name'>
              <span className='u--ellipsis'>{data.fileName}</span>
            </div>
            {/* Meters */}
            <div className='item-detail' title='Meters'>
              <Icon name='meter' />
              <span>
                {data.meters} {data.meters === 1 ? 'meter' : 'meters'}
              </span>
            </div>
            {/* Jobs */}
            <div className='item-detail' title='Jobs'>
              <Icon name='job' />
              <span>
                {data.jobs} {data.jobs === 1 ? 'job' : 'jobs'}
              </span>
            </div>
            {/* Client Routes */}
            <div className='item-detail' title='Client Routes'>
              <Icon name='route' />
              <span>
                {data.clientRoutes} {data.clientRoutes === 1 ? 'client route' : 'client routes'}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ExportFileResult;
