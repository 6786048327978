import React from 'react';
import Icon from '../Icon';

class QuintiqResult extends React.Component {
  render() {
    const data = this.props.data;
    const dataType = this.props.type;

    return (
      <div className={`result-item ${dataType} ${data.completedDate ? 'complete' : ''}`}>
        <div id={'quintiq-' + data.id} className={`item-card`}>
          <div className='item-row'>
            {/* Date */}
            <div className='item-detail f--bold' title='Date'>
              <Icon name='date' />
              <span className={'f--link'} onClick={() => this.props.onClick()}>
                {data.date}
              </span>
            </div>
            {/* Name */}
            <div className='item-detail' title={data.employeeName}>
              <Icon name='person' />
              <span className={'u--ellipsis'} title={data.employeeName}>
                {data.employeeName}
              </span>
            </div>
            {/* Category */}
            {dataType !== 'noBreak' && (
              <div className='item-detail' title={`Category: ${data.categoryName}`}>
                <Icon name={'label'} />
                <span className={'u--ellipsis'}>{data.categoryName}</span>
              </div>
            )}
            {/* Productive Hours */}
            {dataType === 'noBreak' && (
              <div id='productive' className='item-detail' title='Productive Hours'>
                <Icon name='timer' />
                <label>Productive:</label>
                <span>{data.productive}</span>
              </div>
            )}
            {/* Official Break Hours */}
            {dataType === 'noBreak' && (
              <div id='officialBreak' className='item-detail' title='Break Hours'>
                <Icon name='coffee' />
                <label>Official Break:</label>
                <span>{data.officialBreak}</span>
              </div>
            )}
            {/* SAP Reference Number */}
            <div id='sapRef' className='item-detail' title='SAP Reference Number'>
              <label>SAP #</label>
              <span>{data.sapReferenceNumber}</span>
            </div>
            {/* SAP Reference Wage Type */}
            {dataType === 'time' && (
              <div id='sapWageType' className='item-detail' title='SAP Wage Type'>
                <label>SAP Wage Type</label>
                <span>{data.sapWageType}</span>
              </div>
            )}
            {/* SAP Reference Pay Code */}
            {dataType === 'allowances' && (
              <div id='sapWageType' className='item-detail' title='SAP Pay Code'>
                <label>SAP Pay Code</label>
                <span>{data.sapPayCode}</span>
              </div>
            )}
            {/* SAP Reference Pay Code */}
            {dataType !== 'noBreak' && (
              <div id='wbsCode' className='item-detail' title='SAP Pay Code'>
                <label>WBS Code</label>
                <span>{data.wbsCode}</span>
              </div>
            )}
            <div id='completed' className='item-detail' title={`${data.completedDate} (${data.completedBy})`}>
              {data.completedDate && (
                <div>
                  <label>Completed</label>
                  <span className={'u--ellipsis'}>
                    {data.completedDate} ({data.completedBy})
                  </span>
                </div>
              )}
            </div>
            <div className='item-detail item-detail--no-margin'>
              {data.completedDate && (
                <div className='item-action' id={`action-incomplete-${data.id}`} title='Mark as Incompleted' onClick={() => this.props.updateFn(false)}>
                  <Icon name='full-check' />
                </div>
              )}
              {!data.completedDate && (
                <div className='item-action' id={`action-complete-${data.id}`} title='Mark As Completed' onClick={() => this.props.updateFn(true)}>
                  <Icon name='check-outline' />
                </div>
              )}
            </div>
          </div>
          <div className='item-row'>
            <div />
            <div />
            {/* Start - End Time */}
            {dataType === 'time' && (
              <div id='startEnd' className='item-detail' title='Start - End Time'>
                <Icon name='timer' />
                <span>
                  {data.startTime} - {data.endTime}
                </span>
              </div>
            )}
            {/* Allowances */}
            {dataType === 'allowances' && (
              <div className='item-detail' title='Allowances'>
                <Icon name='coin' />
                <span className='u--ellipsis'>
                  {data.quantity} {data.unitsName}
                </span>
              </div>
            )}
            {/* Cost Centre Code */}
            <div id='ccc' className='item-detail' title='Cost Centre Code'>
              <label>CCC</label>
              <span>{data.costCentreCode}</span>
            </div>
            <div />
          </div>
        </div>
      </div>
    );
  }
}

export default QuintiqResult;
