import React from 'react';
import Button from './Button';
import Utils from '../utils/Utils';

class MarkForAuditModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditState: false,
      allReads: false,
      isLoading: true,
      workRouteNumbers: [...new Set(props.selectedReads.map((read) => read.workRouteNumber))].join(', '),
      clientRouteNumbers: props.selectedReads
        .filter((read) => read.clientRouteNumber)
        .map((read) => read.clientRouteNumber)
        .join(', '),
      incompleteRoutes: [],
    };
  }

  componentDidMount() {
    let incompleteRoutes = this.props.selectedReads
      .filter((read) => {
        return read.status !== 'Completed';
      })
      .map((read) => read.workRouteNumber);

    this.setState({
      incompleteRoutes: [...new Set(incompleteRoutes)],
    });
  }

  toggleAllReads(toggleOn) {
    let toggleAll = toggleOn ? toggleOn : !this.state.allReads;

    this.setState({
      allReads: toggleAll,
    });
  }

  render() {
    let workRouteNumbers = this.state.workRouteNumbers;
    let clientRouteNumbers = this.state.clientRouteNumbers;

    return (
      <div id={'mark-for-audit-modal'} className='modal-content'>
        <div className='heading'>Mark For Audit</div>
        <div className='form-container'>
          {!this.state.incompleteRoutes.length && (
            <p>
              By confirming, the {this.props.selectedReads.length > 1 ? 'Work Routes' : 'Work Route'} ({workRouteNumbers}) {clientRouteNumbers ? `in the Client Route (${clientRouteNumbers})` : ''}{' '}
              will be marked for audit. Work routes marked for audit may be returned to the field.
            </p>
          )}
          {!this.state.incompleteRoutes.length && clientRouteNumbers && (
            <div className='form-element'>
              <div className='checkbox-container'>
                <input type='checkbox' id={'all-reads'} checked={this.state.allReads} />
                <label htmlFor={'all-reads'} onClick={() => this.toggleAllReads()} />
                <span onClick={() => this.toggleAllReads()}>Automatically mark all {Utils.humanize(this.props.photoType)} photos for this client route</span>
              </div>
            </div>
          )}
          {this.state.incompleteRoutes.length > 0 && (
            <p>
              The {this.state.incompleteRoutes.length > 1 ? 'Work Routes' : 'Work Route'}, {this.state.incompleteRoutes.join(', ')}, {this.state.incompleteRoutes.length > 1 ? 'are' : 'is'} either
              still incomplete and in the field, or {this.state.incompleteRoutes.length > 1 ? 'have' : 'has'} already been delivered. Routes without the Completed status cannot be marked for audit.{' '}
              <br />
              Please refine your selection and try again.
            </p>
          )}
        </div>

        <div className='button-group t--align-right'>
          <Button classes={`button button--plain`} onClick={() => this.props.showModalFn(false, true)} text={!this.state.incompleteRoutes.length ? 'Dismiss' : 'Close'} />
          {!this.state.incompleteRoutes.length && <Button classes={`button button--med button--blue`} onClick={() => this.props.submitFn(this.state.allReads)} text='Confirm' />}
        </div>
      </div>
    );
  }
}

export default MarkForAuditModal;
