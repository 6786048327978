import React from 'react';
import Tab from './Tab';

class TabContainer extends React.Component {
  componentDidMount() {
    this.renderUnderline();
  }

  componentDidUpdate() {
    setTimeout(() => {
      this.renderUnderline();
    }, 500);
  }

  selectTab(id) {
    this.renderUnderline();
    this.props.onClick(id);
  }

  renderUnderline() {
    setTimeout(() => {
      let underline = document.getElementById(`tab-underline-${this.props.id}`);
      if (!underline) {
        return;
      }
      let selectedTab = document.getElementById(this.props.selectedTab);
      underline.style.width = `${Math.ceil(selectedTab.getBoundingClientRect().width)}px`;

      // 64px for the container padding
      let underlineOffset = Math.ceil(selectedTab.getBoundingClientRect().left) - 64;
      let container = document.getElementById(this.props.containerId);
      underlineOffset = container ? underlineOffset - container.getBoundingClientRect().left : underlineOffset;

      underline.style.transform = `translate(${underlineOffset}px, 0)`;
    }, 500);
  }

  renderTab(group) {
    return (
      <Tab
        name={group.name}
        displayName={group.displayName}
        count={group.count}
        selected={group.name === this.props.selectedTab}
        onClick={(id) => this.selectTab(id)}
        isLoading={this.props.isLoading}
        key={group.name}
      />
    );
  }

  render() {
    const groups = this.props.groups;
    return (
      <div className={`tab-container ${this.props.isLoading ? 'tab--disabled' : ''}`} title={`${this.props.isLoading ? 'Loading, please wait..' : ''}`}>
        {groups.map((group, index) => {
          if (Array.isArray(group)) {
            return (
              <div className='tab-group' key={index}>
                {group.map((groupItem) => this.renderTab(groupItem))}
                {index < groups.length - 1 && <div className='separator' key={index} />}
              </div>
            );
          } else {
            return this.renderTab(group);
          }
        })}
        <div id={`tab-underline-${this.props.id}`} className='tab-underline' />
      </div>
    );
  }
}

export default TabContainer;
