import React from 'react';
import Button from '../Button';
import AssignReaderPopover from '../AssignReaderPopover';
import Icon from '../Icon';
import Loader from 'react-loader-spinner';
import Utils from '../../utils/Utils';

class AuditReallocationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditState: false,
      readerData: [],
      newReader: { readerName: null, id: null },
      prevReader: {},
      allReads: false,
      isLoading: true,
    };
  }

  componentDidMount() {
    let readerData = [...this.props.readerData];
    let prevReader = this.state.prevReader;

    if (this.props.selectedRoutes.length === 1) {
      let selectedRoute = this.props.selectedRoutes[0];
      prevReader = readerData.find((reader) => reader.id === selectedRoute.readerId);

      if (!prevReader) {
        prevReader =
          selectedRoute.readerId && selectedRoute.readerName
            ? {
                id: selectedRoute.readerId,
                name: Utils.titleize(selectedRoute.readerName.replace('.', ' ')),
                recommendedReader: 1,
                equipment: [],
              }
            : null;

        readerData.push(prevReader);
      }
    }

    this.setState({
      readerData: readerData,
      newReader: prevReader ? { readerName: prevReader.name, id: prevReader.id } : null,
      prevReader: prevReader ? prevReader : { readerName: null, id: null },
      isLoading: false,
    });
  }

  toggleEditState() {
    const show = !this.state.showEditState;

    this.setState({
      showEditState: show,
    });
  }

  getReaderOptions() {
    return new Promise((resolve) => {
      resolve(this.state.readerData);
    });
  }

  selectReader(reader) {
    this.setState({
      newReader: { readerName: reader.newReaderName, id: reader.newReaderId },
    });
  }

  getIncompleteRoutes() {
    return this.props.selectedRoutes.filter((route) => route.status !== 'Completed').map((route) => route.workRouteNumber);
  }

  reallocateRoute() {
    this.props.submitFn(this.state.newReader);
  }

  render() {
    let incompleteRoutes = this.getIncompleteRoutes();

    return (
      <div id={'reallocation-modal'} className='modal-content'>
        <div className='heading'>Reallocating Routes</div>
        {!this.state.isLoading && (
          <div>
            {!incompleteRoutes.length && (
              <div className='form-container'>
                <p>
                  By reallocating {this.props.selectedRoutes.length > 1 ? 'these routes, these Work Routes' : 'this route, this Work Route'} (
                  {this.props.selectedRoutes.map((route) => route.workRouteNumber).join(', ')}) will be returned to the field. Please select a reader to assign.
                </p>
                <div className='form-element'>
                  <label>Assign To Reader</label>
                  <div className='popover-container'>
                    {!this.state.showEditState && (
                      <>
                        <div id={'input-assign-reader'} className={`select-container`} onClick={() => this.toggleEditState()}>
                          <span className='u--ellipsis' title={this.state.newReader ? this.state.newReader.readerName : ''}>
                            {this.state.newReader ? this.state.newReader.readerName : 'Select'}
                          </span>
                          <Icon name='chevron' />
                        </div>
                      </>
                    )}
                    {this.state.showEditState && (
                      <>
                        <div id={'input-assign-reader'} className={`select-container selected`} onClick={() => this.toggleEditState()}>
                          <span className='u--ellipsis' title={this.state.newReader ? this.state.newReader.readerName : ''}>
                            {this.state.newReader ? this.state.newReader.readerName : 'Select'}
                          </span>
                          <Icon name='chevron' />
                        </div>
                        <AssignReaderPopover
                          data={{ lastReaderId: this.state.prevReader.id }}
                          toggleState={() => this.toggleEditState()}
                          showEditState={this.state.showEditState}
                          optionLoadFn={() => this.getReaderOptions()}
                          updateFn={(data) => this.selectReader(data)}
                          type={'assign-reader'}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
            {incompleteRoutes.length > 0 && (
              <div className='form-container'>
                <p>Some of the selected routes cannot be reallocated as they are still out in the field.</p>
                <p>
                  Please follow up with the current assigned reader{incompleteRoutes.length > 1 ? 's' : ''} to complete {incompleteRoutes.length > 1 ? 'these routes' : 'this route'} (
                  {incompleteRoutes.join(', ')}).
                </p>
              </div>
            )}

            <div className='button-group t--align-right'>
              <Button classes={`button button--plain`} onClick={() => this.props.showModalFn(false)} text='Cancel' />
              <Button classes={`button button--med button--blue ${incompleteRoutes.length > 0 ? 'button--disabled' : ''}`} onClick={() => this.reallocateRoute()} text='Reallocate' />
            </div>
          </div>
        )}
        {this.state.isLoading && (
          <div className='spinner-container'>
            <Loader type='Bars' color='#008dce' height={30} width={30} visible={this.state.isLoading} />
          </div>
        )}
      </div>
    );
  }
}

export default AuditReallocationModal;
