import React from 'react';
import wave from '../../images/wave2.svg';
import logo from '../../images/skilltech-mimtr-logo-dark.png';
import uat_logo from '../../images/skilltech-mimtr-logo-uat-dark.png';
import ResultsContainer from '../ResultsContainer';
import MatrixPartResult from './MatrixPartResult';
import MatrixPartDetails from './MatrixPartDetails';
import _ from 'lodash';
import moment from 'moment';
import ExportUtils from '../../utils/ExportUtils';

class MatrixParts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      searchType: 'partNo',
      searchKey: null,
      resultData: { matrixParts: [] },
      masterPartsData: [],
      partsData: [],
      selectedPart: null,
      showLossMakers: false,
    };
  }

  async componentDidMount() {
    document.title = `${process.env.REACT_APP_ENV ? `[${process.env.REACT_APP_ENV}] ` : ''}MiMtr Hybrid | Matrix Parts`;
    this.props.updateSelectedSubpage('matrix-parts');

    this.getData();
    this.setState({
      isLoading: false,
      buttonData: this.getButtonData(),
    });
  }

  async getData() {
    this.setState({ isLoading: true });
    await this.getMatrixParts().then((partsData) => {
      this.setState({
        masterPartsData: partsData,
        partsData: partsData,
        buttonData: this.getButtonData(partsData),
        isLoading: false,
      });
    });
  }

  getMatrixParts() {
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/getMatrixParts`)
        .then((searchRes) => {
          if (searchRes.status === 401) {
            this.props.logoutFn();
            reject();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
            return searchRes.json();
          }
        })
        .then((data) => {
          let results = data.results.map((result) => {
            let invoice = parseFloat(result.invoice).toFixed(2);
            let SAPCost = parseFloat(result['SAP cost']).toFixed(2);
            let SAPInvoice = parseFloat(result.M_Invoice).toFixed(2);
            let margin = ((invoice - SAPCost) / invoice) * 100;

            return {
              id: result.id,
              partNo: result.PartNumber,
              mapPart: result.MapPart,
              description: result.Description,
              invoice: isNaN(invoice) ? '-' : invoice,
              SAPNo: result['SAP number'],
              SAPCost: isNaN(SAPCost) ? '-' : SAPCost,
              SAPInvoice: isNaN(SAPInvoice) ? '-' : SAPInvoice,
              margin: isNaN(margin) ? '-' : Math.round((margin + Number.EPSILON) * 100) / 100,
              mapPartId: result.MID,
            };
          });
          resolve(results);
        })
        .catch(() => {});
    });
  }

  getButtonData(partsData) {
    return [
      {
        type: 'line',
        id: 1,
        items: [
          {
            type: 'group',
            items: [
              {
                type: 'toggle-button',
                name: 'searchType',
                selection: this.state.searchType,
                options: [
                  { name: 'Part No.', value: 'partNo' },
                  { name: 'Map Part', value: 'mapPart' },
                ],
                onClick: (value) => this.updateToggle('searchType', value),
              },
              {
                type: 'text',
                name: 'searchKey',
                displayLabel: true,
                defaultText: 'Search #',
                classes: 'input--with-placeholder',
                onClick: (name, value) => this.updateField(name, value),
              },
              { type: 'button', classes: 'button--blue', text: 'Show Results', onClick: () => this.runSearch() },
              {
                type: 'checkbox',
                name: 'showLossMakers',
                displayLabel: true,
                text: 'Show loss makers',
                selected: this.state.showLossMakers,
                onClick: () => this.toggleLossMakers(),
              },
            ],
          },
          {
            type: 'group',
            items: [{ type: 'button', classes: 'button--plain', icon: 'checkbox', iconPosition: '1', text: 'Export Results', onClick: () => this.exportToCSV() }],
          },
        ],
      },
      {
        type: 'line',
        id: 2,
        items: [
          {
            type: 'text',
            text: `${partsData ? partsData.length : 0} Parts returned`,
          },
        ],
      },
    ];
  }

  updateField(field, value) {
    this.setState({
      [field]: value,
    });
  }

  updateToggle(field, value) {
    let buttonData = this.state.buttonData;

    const lineIndex = buttonData.findIndex((button) => button.type === 'line' && button.id === 1);
    const groupIndex = buttonData[lineIndex].items.findIndex((button) => button.type === 'group');
    const toggleIndex = buttonData[lineIndex].items[groupIndex].items.findIndex((button) => button.name === field);
    buttonData[lineIndex].items[groupIndex].items[toggleIndex].selection = value;

    this.setState({
      buttonData: buttonData,
      [field]: value,
    });
  }

  getResultItems() {
    if (!this.state.partsData) {
      return { matrixParts: [] };
    }
    let resultData = {};

    resultData.matrixParts = this.state.partsData.map((part, index) => {
      return <MatrixPartResult data={part} selectFn={(id) => this.selectPart(id)} key={index} />;
    });

    return resultData;
  }

  runSearch() {
    let searchKey = this.state.searchKey;
    if (searchKey === null) {
      return;
    }
    let searchType = this.state.searchType;

    let masterPartsData = this.state.masterPartsData;
    let partsData = masterPartsData.filter((part) => part[searchType].includes(searchKey));

    let buttonData = this.state.buttonData;

    const lineIndex = buttonData.findIndex((button) => button.type === 'line' && button.id === 2);
    const textIndex = buttonData[lineIndex].items.findIndex((button) => button.type === 'text');
    buttonData[lineIndex].items[textIndex].text = `${partsData ? partsData.length : 0} Parts returned`;

    this.setState({
      partsData: partsData,
      buttonData: buttonData,
    });
  }

  toggleLossMakers(value) {
    let showLossMakers = !this.state.showLossMakers;

    let buttonData = this.state.buttonData;

    let masterPartsData = this.state.masterPartsData;

    if (masterPartsData) {
      let parts = showLossMakers ? masterPartsData.filter((part) => part.margin < 0) : masterPartsData;

      const lineIndex = buttonData.findIndex((button) => button.type === 'line' && button.id === 2);
      const textIndex = buttonData[lineIndex].items.findIndex((button) => button.type === 'text');
      buttonData[lineIndex].items[textIndex].text = `${parts.length} Parts returned`;

      this.setState({
        partsData: parts,
      });
    }

    const lineIndex = buttonData.findIndex((button) => button.type === 'line' && button.id === 1);
    const groupIndex = buttonData[lineIndex].items.findIndex((button) => button.type === 'group');
    const checkboxIndex = buttonData[lineIndex].items[groupIndex].items.findIndex((button) => button.type === 'checkbox');
    buttonData[lineIndex].items[groupIndex].items[checkboxIndex].selected = value;

    this.setState({
      showLossMakers: value ? value : showLossMakers,
      buttonData: buttonData,
    });
  }

  exportToCSV() {
    let fields = {
      'Part Number': 'partNo',
      'Map Part': 'mapPart',
      Description: 'description',
      'Invoice Rate': 'invoice',
      'SAP Number': 'SAPNo',
      'SAP Cost': 'SAPCost',
      'SAP Invoice': 'SAPInvoice',
      Margin: 'margin',
    };

    const filename = 'Matrix-Parts_' + moment().format('DD-MM-YYYY') + '.csv';

    ExportUtils.exportToCSV(this.state.partsData, fields, filename);
  }

  selectPart(id) {
    let partsData = this.state.masterPartsData;
    let selectedPart = null;

    // Check all visible routes for a match, and toggle select
    _.forEach(partsData, (part) => {
      if (part.id === id) {
        part.selected = !part.selected;

        selectedPart = part;
      } else {
        if (part.selected) {
          part.selected = false;
        }
      }
    });

    this.setState({
      selectedPart: selectedPart,
    });
  }

  renderSidebar() {
    let data = {
      part: this.state.selectedPart,
      updateInvoiceRate: (value) => this.updateInvoiceRate(value),
      updatePartCOG: (value) => this.updatePartCOG(value),
    };
    return <MatrixPartDetails data={data} />;
  }

  updateInvoiceRate(updatedValue) {
    let parsedValue = Math.round((parseFloat(updatedValue) + Number.EPSILON) * 100) / 100;
    updatedValue = isNaN(parsedValue) ? 0 : parsedValue;
    let masterPartsData = this.state.masterPartsData;
    let selectedPartIndex = masterPartsData.findIndex((part) => part.id === this.state.selectedPart.id);

    let selectedPart = masterPartsData[selectedPartIndex];
    selectedPart.invoice = updatedValue;
    selectedPart.SAPInvoice = updatedValue;

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        invoice: updatedValue,
        mapPart: selectedPart.mapPart,
      }),
    };

    fetch(`${process.env.REACT_APP_API_ENDPOINT}/updateMatrixPartInvoice`, requestOptions)
      .then((readerRes) => {
        return readerRes.json();
      })
      .then((data) => {
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
        this.setState({ selectedPart: selectedPart });
      });
  }

  updatePartCOG(updatedPart) {
    let masterPartsData = this.state.masterPartsData;
    let selectedPartIndex = masterPartsData.findIndex((part) => part.id === this.state.selectedPart.id);

    let selectedPart = masterPartsData[selectedPartIndex];
    selectedPart.SAPCost = updatedPart.SAPCost;
    selectedPart.SAPInvoice = updatedPart.SAPInvoice;
    selectedPart.SAPNo = updatedPart.SAPNo;

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        invoice: selectedPart.SAPInvoice,
        mapPart: selectedPart.mapPart,
        sapNumber: selectedPart.SAPNo,
        sapCost: selectedPart.SAPCost,
        mapPartId: selectedPart.mapPartId,
        id: selectedPart.id,
      }),
    };

    fetch(`${process.env.REACT_APP_API_ENDPOINT}/updateMatrixPartCOG`, requestOptions)
      .then((readerRes) => {
        return readerRes.json();
      })
      .then((data) => {
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
        this.setState({ selectedPart: selectedPart });
      });
  }

  render() {
    const data = { resultData: this.getResultItems(), sidebar: this.state.selectedPart ? this.renderSidebar() : null };

    return (
      <div className='main-content matrix-parts-page'>
        <div className='logo-container' />
        <header>
          <h1>Matrix Parts</h1>
          <img className='logo-container' src={process.env.REACT_APP_ENV ? uat_logo : logo} alt='' />
        </header>
        {!this.state.isLoading && (
          <div className='content-container'>
            <ResultsContainer data={data} buttonData={this.state.buttonData} selectedGroup={'matrixParts'} dataClass={''} isLoading={this.state.isLoading} bulkSelecting={false} />
          </div>
        )}
        <img className='wave' src={wave} alt={''} />
      </div>
    );
  }
}

export default MatrixParts;
