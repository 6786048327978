import React from 'react';
import Button from './Button';
import ButtonGroup from './ButtonGroup';

class ButtonBar extends React.Component {
  // Renders UI of element based on provided item's type
  renderElement(item, id) {
    if (typeof item.hide === 'function' && item.hide()) {
      return <div key={id} />;
    }

    switch (item.type) {
      case 'bulk-select-button':
        if (!this.props.bulkSelecting) {
          return <Button classes={item.classes ? item.classes : ''} icon={item.icon} iconPosition={item.iconPosition} text={item.text} onClick={item.toggleBulkSelectFn} key={id} />;
        } else {
          let items = [];
          items.push({ type: 'checkbox-all', onClick: () => item.selectAll() });

          item.actionItems.forEach((action) => {
            if (typeof action.hide === 'function' && !action.hide()) {
              return;
            }

            items.push({
              type: 'bulk-update-button',
              classes: `button--plain ${item.count < 1 ? 'button--disabled' : ''}`,
              text: `${action.text ? action.text : 'Action All'} (${item.count})`,
              icon: action.icon ? action.icon : 'checkbox',
              iconPosition: '1',
              popover: typeof action.getPopover === 'function' ? action.getPopover() : null,
              onClick: () => action.onClick(),
            });

            items.push({ type: 'pipe' });
          });

          items.push({ type: 'close', onClick: () => item.toggleBulkSelectFn() });

          return <ButtonGroup items={items} key={id} />;
        }
      case 'bulk-action-button':
        const bulkActionItems = [
          { type: 'checkbox-all', onClick: () => item.selectAll() },
          { type: 'button', classes: 'button--blue', text: 'Export Selected (' + item.count + ')', icon: 'maximize', iconPosition: '1', onClick: () => item.onClick() },
        ];
        return <ButtonGroup items={bulkActionItems} key={id} />;
      case 'button':
        return <Button classes={item.classes ? item.classes : ''} icon={item.icon} iconPosition={item.iconPosition} text={item.text} onClick={item.onClick} key={id} />;
      case 'group':
        const groupItems = item.items;
        return <ButtonGroup items={groupItems} key={id} />;
      case 'line':
        return (
          <div className='button-line' key={id}>
            {item.items.map((item, index) => this.renderElement(item, index))}
          </div>
        );
      case 'text':
        return <span key={id}>{item.text}</span>;
      default:
        break;
    }
  }

  render() {
    return <div className='button-bar'>{this.props.buttonData.map((item, index) => this.renderElement(item, index))}</div>;
  }
}

export default ButtonBar;
