import React from 'react';
import wave from '../../images/wave2.svg';
import Button from '../Button';
import logo from '../../images/skilltech-mimtr-logo-dark.png';
import uat_logo from '../../images/skilltech-mimtr-logo-uat-dark.png';
import ResultsContainer from '../ResultsContainer';
import RemergeRouteResult from './RemergeRouteResult';
import moment from 'moment';
import _ from 'lodash';

class Remerge extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      selectedContract: null,
      selectedCycleDate: null,
      routeData: [],
      masterRouteData: [],
      contractData: [],
      cycleDateData: [],
      showMerged: false,
    };
  }

  async componentDidMount() {
    document.title = `${process.env.REACT_APP_ENV ? `[${process.env.REACT_APP_ENV}] ` : ''}MiMtr Hybrid | Remerge`;
    this.props.updateSelectedSubpage('remerge');

    this.getData();
  }

  async getData() {
    this.setState({ isLoading: true });
    await this.getContracts().then((contractData) => {
      this.setState({
        isLoading: false,
        contractData: contractData,
        buttonData: this.getButtonData(contractData),
      });
    });
  }

  async getContracts() {
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/getMimtrContracts`)
        .then((contractRes) => {
          if (contractRes.status === 401) {
            this.props.logoutFn();
            reject();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
            return contractRes.json();
          }
        })
        .then((data) => {
          let contractData = data.results.map((contract) => {
            return {
              id: contract.ContractId,
              name: contract.ContractName,
              dbServer: contract.DatabaseInstance,
            };
          });

          resolve(contractData);
        })
        .catch(() => {});
    });
  }

  async getCycleDates(selectedContract) {
    return new Promise((resolve) => {
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/getcycledates?DatabaseServer=${selectedContract.dbServer}&contractid=${selectedContract.id}`)
        .then((cycleDateRes) => {
          return cycleDateRes.json();
        })
        .then((data) => {
          let cycleDateData = data.results.map((cycleDate, index) => {
            return {
              id: index,
              value: cycleDate.CycleDate,
              name: moment.utc(cycleDate.CycleDate).local().format('DD/MM/YYYY'),
            };
          });

          resolve(cycleDateData);
        })
        .catch(() => {
          resolve([]);
        });
    });
  }

  async getMergeRoutes(selectedCycleDate) {
    let selectedContract = this.state.selectedContract;
    selectedCycleDate = selectedCycleDate.value;

    return new Promise((resolve) => {
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/getmergeroutes?DatabaseServer=${selectedContract.dbServer}&contractid=${selectedContract.id}&cycledate=${selectedCycleDate}`)
        .then((cycleDateRes) => {
          return cycleDateRes.json();
        })
        .then((data) => {
          let mergeRouteData = data.results.map((route) => {
            return {
              id: route.ClientRouteId,
              folder: route.Folder,
              importFileName: route.ImportFileName,
              routeNo: route.RouteNumber,
              routeName: route.RouteName,
              dateRangeStart: moment.utc(route.DateRangeStart).local().format('DD/MM/YYYY'),
              dateRangeEnd: moment.utc(route.DateRangeEnd).local().format('DD/MM/YYYY'),
              jobs: parseInt(route.Jobs, 10),
              merged: parseInt(route.Merged, 10),
              unmerged: parseInt(route.UnMerged, 10),
            };
          });

          resolve(mergeRouteData);
        })
        .catch(() => {});
    });
  }

  getButtonData(contractData, cycleDateData, clearCycleDate) {
    contractData = contractData ? contractData : this.state.contractData;
    cycleDateData = cycleDateData ? cycleDateData : this.state.cycleDateData;

    return [
      {
        type: 'group',
        items: [
          {
            type: 'select',
            name: 'contract',
            displayLabel: 'Contract',
            options: contractData,
            updateFn: (selectedContract) => this.selectContract(selectedContract),
          },
          {
            type: 'select',
            name: 'cycleDate',
            displayLabel: 'Cycle Date',
            options: cycleDateData ? cycleDateData : [],
            clear: clearCycleDate,
            updateFn: (cycleDate) => this.selectCycleDate(cycleDate),
          },
          {
            type: 'checkbox',
            name: 'showMerged',
            displayLabel: true,
            text: 'Show merged routes',
            selected: this.state.showMerged,
            onClick: () => this.toggleMergedRoutes(),
          },
        ],
      },
      {
        type: 'text',
        text: `0 Remerge routes returned`,
      },
    ];
  }

  async selectContract(selectedContract) {
    this.setState({
      selectedContract: selectedContract,
      selectedCycleDate: null,
      routeData: [],
    });

    let elem = document.getElementById('default-select-text-cycleDate');
    if (elem) {
      elem.innerHTML = '';
    }

    await this.getCycleDates(selectedContract).then((cycleDateData) => {
      this.setState({
        cycleDateData: cycleDateData,
        buttonData: this.getButtonData(null, cycleDateData),
      });
    });
  }

  async selectCycleDate(selectedCycleDate) {
    await this.getMergeRoutes(selectedCycleDate).then((routes) => {
      let showMerged = this.state.showMerged;
      let unmergedRoutes = routes.filter((route) => route.unmerged > 0);

      let buttonData = this.state.buttonData;
      const textIndex = buttonData.findIndex((button) => button.type === 'text');

      buttonData[textIndex].text = `${this.state.showMerged ? routes.length : unmergedRoutes.length} Remerge routes returned`;

      this.setState({
        selectedCycleDate: selectedCycleDate,
        masterRouteData: routes,
        routeData: showMerged ? routes : unmergedRoutes,
      });
    });
  }

  toggleMergedRoutes(value) {
    let showMerged = !this.state.showMerged;

    let buttonData = this.state.buttonData;

    let masterRouteData = this.state.masterRouteData;

    if (masterRouteData) {
      let routes = showMerged ? masterRouteData : masterRouteData.filter((route) => route.unmerged > 0);

      const textIndex = buttonData.findIndex((button) => button.type === 'text');
      buttonData[textIndex].text = `${routes.length} Remerge routes returned`;

      this.setState({
        routeData: routes,
      });
    }

    const groupIndex = buttonData.findIndex((button) => button.type === 'group');
    const checkboxIndex = buttonData[groupIndex].items.findIndex((button) => button.type === 'checkbox');
    buttonData[groupIndex].items[checkboxIndex].selected = showMerged;

    this.setState({
      showMerged: value ? value : showMerged,
      buttonData: buttonData,
    });
  }

  getResultItems() {
    if (!this.state.routeData) {
      return { remergeRoutes: [] };
    }
    let resultData = {};

    resultData.remergeRoutes = this.state.routeData.map((route, index) => {
      return <RemergeRouteResult data={route} selectFn={(id) => this.selectRoute(id)} key={index} />;
    });

    return resultData;
  }

  selectRoute(id) {
    const routeData = this.state.routeData;

    // Check all visible routes for a match, and toggle select
    _.forEach(routeData, (item) => {
      if (item.id === id) {
        item.selected = !item.selected;
      } else {
        if (item.selected) {
          item.selected = false;
        }
      }
    });

    this.setState({
      routeData: routeData,
    });
  }

  remergeRoute() {
    let selectedContract = this.state.selectedContract;
    let routeData = this.state.routeData;

    let selectedRouteIndex = routeData.findIndex((route) => route.selected);
    let selectedRoute = routeData[selectedRouteIndex];

    fetch(`${process.env.REACT_APP_API_ENDPOINT}/remergeroutes?DatabaseServer=${selectedContract.dbServer}&clientrouteid=${selectedRoute.id}`)
      .then((contractRes) => {
        return contractRes.json();
      })
      .then((data) => {
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });

        let parsedResult = parseInt(data.message, 10);

        if (!isNaN(parsedResult)) {
          selectedRoute.unmerged = parsedResult;
          selectedRoute.merged = selectedRoute.jobs - selectedRoute.unmerged;
        }

        routeData[selectedRouteIndex] = selectedRoute;

        this.setState({
          routeData: routeData,
        });
      })
      .catch(() => {});
  }

  render() {
    const data = { resultData: this.getResultItems() };

    return (
      <div className='main-content remerge-page'>
        <div className='logo-container' />
        <header>
          <h1>Remerge</h1>
          <img className='logo-container' src={process.env.REACT_APP_ENV ? uat_logo : logo} alt='' />
        </header>
        {!this.state.isLoading && (
          <div className='content-container'>
            <ResultsContainer data={data} buttonData={this.state.buttonData} selectedGroup={'remergeRoutes'} dataClass={''} isLoading={this.state.isLoading} bulkSelecting={false} />
            <div className='button-group'>
              <Button classes={`button button--blue ${this.state.routeData.length > 0 ? '' : 'button--disabled'}`} text='Re-Merge' onClick={() => this.remergeRoute()} />
            </div>
          </div>
        )}
        <img className='wave' src={wave} alt={''} />
      </div>
    );
  }
}

export default Remerge;
