import React from 'react';
import Icon from './Icon';

class SelectPopover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: props.item.popover.options,
    };
  }

  render() {
    const onClick = this.props.onClick;
    const selectedOption = this.props.item.selectedSort;
    const sortAsc = this.props.item.sortAsc;
    let options = this.state.options.filter((option) => {
      if (typeof option.hide === 'function') {
        return !option.hide();
      } else {
        return true;
      }
    });

    return (
      <ul>
        {options.map(function (option) {
          const isSelectedOption = option.value === selectedOption;
          return (
            <li className={`${isSelectedOption ? 'selected' : ''} ${!sortAsc ? 'flipped' : ''}`} onClick={() => onClick(option.value)} key={option.name}>
              {option.name}
              {isSelectedOption && <Icon name='arrow' />}
            </li>
          );
        })}
      </ul>
    );
  }
}

export default SelectPopover;
