import React from 'react';
import { ROLE } from '../../constants/UserRole';

class Dashboard extends React.Component {
  componentDidMount() {
    let checkTokenApiUrl = `${process.env.REACT_APP_API_ENDPOINT}/check`;
    fetch(checkTokenApiUrl).then((res) => {
      if (res.status === 401) {
        this.props.logoutFn();
      } else {
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
      }
    });

    document.title = `${process.env.REACT_APP_ENV ? `[${process.env.REACT_APP_ENV}] ` : ''}MiMtr Hybrid | Dashboard`;
  }

  openMyTimesheet() {
    localStorage.removeItem('selectedEmployeeTimesheet');
    this.props.updateSelectedSubpage('timesheet');
  }

  render() {
    const timesheetOnly = this.props.selectedContract.roles.includes(ROLE.TIMESHEET_ONLY);

    return (
      <div className={`main-content dashboard-page`}>
        {!timesheetOnly && (
          <div>
            <div>
              <a className='button button--plain' href={`/hybrid/support`} onClick={() => this.props.updateSelectedSubpage('support')}>
                Report A Problem
              </a>
            </div>
            <div>
              <a className='button button--plain' href={`/hybrid/timesheet/employee`} onClick={() => this.openMyTimesheet('timesheet')}>
                My Timesheet
              </a>
            </div>
            {this.props.userData.securityRoles.canManageTimesheets && (
              <div>
                <a className='button button--plain' href={`/hybrid/timesheet/manage`} onClick={() => this.props.updateSelectedSubpage('timesheet')}>
                  Manage Timesheets
                </a>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default Dashboard;
