import React from 'react';
import Icon from '../Icon';

class ExportRouteResult extends React.Component {
  render() {
    const route = this.props.data;
    const isRoutesExported = route.status === 'routesExported';

    return (
      <div className={`result-item ${route.status} ${route.selected ? 'selected' : ''}`}>
        <div className='checkbox-container'>
          <input type='checkbox' id={'checkbox-' + route.id} checked={route.selected} onClick={() => this.props.selectFn(route.id)} />
          <label className='checkbox-label' htmlFor={'checkbox-' + route.id} />
        </div>
        <div id={'route-' + route.id} className={`item-card`}>
          <div className='item-row'>
            {/* Route No. */}
            <div className='item-detail f--bold' title='Route No.'>
              <span>{route.routeNo}</span>
            </div>
            {/* Route Name */}
            <div className='item-detail' title='Route Name'>
              <Icon name='pin' />
              <span className='u--ellipsis f--link' title={route.name}>
                {route.name}
              </span>
            </div>
            {isRoutesExported && (
              <>
                {/* Export File Name */}
                <div className='item-detail' title='Export File Name'>
                  <Icon name='file' />
                  <span className='u--ellipsis f--link' title={`${route.fileName} (Click To Download)`}>
                    {route.fileName}
                  </span>
                </div>
                {/* Export Date */}
                <div className='item-detail' title='Export Date'>
                  <Icon name='exportDate' />
                  <span>{route.exportedDate.format('DD/MM/YYYY hh:mma')}</span>
                </div>
              </>
            )}
            {/* Meters */}
            <div className='item-detail' title='Total Meters'>
              <Icon name='meter' />
              <span>{route.meters} meters</span>
            </div>
            {/* Total Meters Read */}
            <div className='item-detail' title='Total Meters Read'>
              <Icon name='complete' />
              <span>
                {route.read} read ({route.oot} OOT)
              </span>
            </div>
            {/* Skipped */}
            <div className='item-detail' title='Skipped Meters'>
              <Icon name='skip' />
              <span>{route.skipped} skipped</span>
            </div>
            {/* Total Customers */}
            <div className='item-detail' title='Total Customers'>
              <Icon name='customers' />
              <span>{route.customers} customers</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ExportRouteResult;
