import React from 'react';
import Loader from 'react-loader-spinner';
import wave from '../../images/wave.svg';
import logo from '../../images/skilltech-mimtr-logo-dark.png';
import uat_logo from '../../images/skilltech-mimtr-logo-uat-dark.png';
import './employees-page.scss';
import ResultsContainer from '../ResultsContainer';
import EmployeeResult from './EmployeeResult';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import ExportUtils from '../../utils/ExportUtils';
import moment from 'moment';
import Utils from '../../utils/Utils';
import Api from '../../api/Api';
import Button from '../Button';
import Modal from '../Modal';
import EmployeeRosterExportModal from './EmployeeRosterExportModal';
import NewEmployeeModal from './NewEmployeeModal';

class Employees extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      employeeData: [],
      searchFn: null,
      filterFn: null,
      showModal: false,
      modalType: '',
    };
  }

  componentDidMount() {
    document.title = `${process.env.REACT_APP_ENV ? `[${process.env.REACT_APP_ENV}] ` : ''}MiMtr Hybrid | Employees`;

    this.getData();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.selectedContract.id !== this.props.selectedContract.id) {
      this.setState({});
      this.getData();
    }
  }

  async getData() {
    this.setState({ isLoading: true });
    this.getEmployeeData().then((employeeData) => {
      Api.getContracts().then((contractsData) => {
        contractsData = contractsData.map((contract) => {
          return { id: contract.name, name: contract.name };
        });

        let buttonData = this.getButtonData(employeeData, contractsData);

        // Default to active employee filter on
        let activeEmployeeData = employeeData.filter((employee) => employee.status === 'active');

        const buttonGroupIndex = buttonData[1].items.findIndex((button) => button.type === 'group');
        const buttonGroupData = buttonData[1].items[buttonGroupIndex];
        const filterButtonIndex = buttonGroupData.items.findIndex((button) => button.type === 'filter-button');
        const filterPopoverData = buttonGroupData.items[filterButtonIndex].popover;
        filterPopoverData.filters.find((filter) => filter.name === 'status').selection = { name: 'Active' };

        const textIndex = buttonData[0].items.findIndex((button) => button.type === 'text');
        buttonData[0].items[textIndex].text = `${activeEmployeeData.length} results returned`;

        let filterFn = function (result) {
          return result.status === 'active';
        };

        this.setState({
          isLoading: false,
          employeeData: activeEmployeeData,
          contractsData: contractsData,
          masterData: employeeData,
          buttonData: buttonData,
          filterFn: filterFn,
        });
      });
    });
  }

  async getEmployeeData() {
    return new Promise((resolve, reject) => {
      let employeesApiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getEmployeesList?contractId=${this.props.selectedContract.id}`;
      fetch(employeesApiUrl)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
            return res.json();
          }
        })
        .then((data) => {
          if (!data.results || !data.results.length || !data.results[0]) {
            resolve([]);
            return;
          }

          let employeesData = data.results.map((employee) => {
            employee.name = Utils.titleize(employee.name.replace('.', ' '));
            employee.otherContracts = employee.otherContracts.split(', ').map((contract) => contract.trim());
            return employee;
          });

          resolve(_.sortBy(employeesData, 'name'));
        });
    });
  }

  getButtonData(masterData, contractsData) {
    return [
      {
        type: 'line',
        id: 1,
        items: [
          {
            type: 'text',
            text: `${masterData.length} results returned`,
          },
        ],
      },
      {
        type: 'line',
        id: 2,
        items: [
          { type: 'button', classes: 'button--plain', icon: 'checkbox', iconPosition: '1', text: 'Export Results', onClick: () => this.exportToCSV() },
          {
            type: 'group',
            items: [
              {
                type: 'filter-button',
                text: 'Filter',
                icon: 'filter',
                iconPosition: '2',
                classes: 'button--active',
                popover: {
                  type: 'filter',
                  onClick: (filterData) => this.filterResults(filterData),
                  filters: [
                    {
                      name: 'payType',
                      displayLabel: 'Pay Type',
                      type: 'select',
                      multiselect: true,
                      selection: [],
                      options: _.sortBy(
                        _.uniqBy(masterData, 'payType')
                          .filter((result) => !_.isEmpty(result.payType))
                          .map((result) => {
                            return { id: result.payType, name: result.payType };
                          }),
                        'name'
                      ),
                    },
                    {
                      name: 'agreementType',
                      displayLabel: 'Agreement Type',
                      type: 'select',
                      multiselect: true,
                      selection: [],
                      options: _.sortBy(
                        _.uniqBy(masterData, 'agreementType')
                          .filter((result) => !_.isEmpty(result.payType))
                          .map((result) => {
                            return { id: result.agreementType, name: result.agreementType };
                          }),
                        'name'
                      ),
                    },
                    {
                      name: 'singleContract',
                      displayLabel: 'Single Contract',
                      type: 'select',
                      selection: null,
                      options: _.sortBy(contractsData, 'name'),
                    },
                    {
                      name: 'multiContract',
                      displayLabel: 'Multi-Contract',
                      type: 'select',
                      multiselect: true,
                      selection: [],
                      options: _.sortBy(contractsData, 'name'),
                    },
                    {
                      name: 'status',
                      displayLabel: 'Status',
                      type: 'select',
                      selection: [],
                      options: _.sortBy(
                        _.uniqBy(masterData, 'status')
                          .filter((result) => result.status)
                          .map((result) => {
                            return { id: result.status, name: Utils.titleize(result.status) };
                          }),
                        'name'
                      ),
                    },
                  ],
                },
              },
              {
                type: 'sort-button',
                classes: 'button--active',
                text: 'Sort By',
                icon: 'sort',
                iconPosition: '2',
                popover: {
                  type: 'select',
                  onClick: (key) => this.sortResults(key),
                  options: [
                    { name: 'Name', value: 'name' },
                    { name: 'SAP Reference No.', value: 'sapReferenceNumber' },
                  ],
                },
                selectedSort: 'name',
                sortAsc: true,
              },
              { type: 'search-input', onChange: (key) => this.searchResults(key), placeholder: 'Search' },
            ],
          },
        ],
      },
    ];
  }

  exportToCSV() {
    let fields = {
      Name: 'name',
      Alias: 'alias',
      'SAP Reference Number': 'sapReferenceNumber',
      'Mobile Phone': 'mobileNumber',
      'Office Phone': 'officeNumber',
      'Email Address': 'emailAddress',
      'Other Contracts': 'otherContracts',
    };

    const filename = `Employees_${this.props.selectedContract.name}_${moment().format('DD-MM-YYYY')}.csv`;

    ExportUtils.exportToCSV(this.state.employeeData, fields, filename);
  }

  sortResults(sortKey) {
    let data = this.state.employeeData;
    let masterData = this.state.masterData;
    let currentSortKey, sortAsc;

    // Find the sort button and check if the sort key was already selected
    let buttonData = this.state.buttonData;
    const buttonGroup = buttonData[1].items.find((button) => button.type === 'group');
    buttonGroup.items.forEach((button) => {
      if (button.type === 'sort-button') {
        currentSortKey = button.selectedSort;

        // If the sort key was reselected, toggle asc/desc order
        if (currentSortKey === sortKey) {
          button.sortAsc = !button.sortAsc;
        } else {
          button.sortAsc = true;
        }

        button.selectedSort = sortKey;
        sortAsc = button.sortAsc;
      }
    });

    if (data.length > 0) {
      data = _.orderBy(data, sortKey, sortAsc ? 'asc' : 'desc');
    }

    if (masterData.length > 0) {
      masterData = _.orderBy(masterData, sortKey, sortAsc ? 'asc' : 'desc');

      this.setState({
        employeeData: data,
        masterData: masterData,
      });
    }
  }

  searchResults(searchTerm) {
    let data = _.cloneDeep(this.state.masterData);
    let searchFn = null;

    if (searchTerm) {
      searchFn = function (result) {
        return (
          (result.name && result.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (result.alias && result.alias.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (result.areas && result.areas.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (!_.isEmpty(result.sapReferenceNumber) && result.sapReferenceNumber.toLowerCase().includes(searchTerm.toLowerCase()))
        );
      };

      data = data.filter(searchFn);
    }

    if (this.state.filterFn) {
      data = data.filter((result) => this.state.filterFn(result));
    }

    this.setState({
      searchFn: searchFn,
      employeeData: data,
    });
  }

  filterResults(filterData) {
    let filterFn;
    let buttonData;
    let data = _.cloneDeep(this.state.masterData);

    if (!filterData) {
      filterFn = (result) => {
        return result;
      };
      buttonData = this.getButtonData(data, this.state.contractsData);

      if (this.state.searchFn) {
        data = data.filter(this.state.searchFn);
      }
    } else {
      const selectedPayTypes = filterData[0].selection;
      const selectedAgreementTypes = filterData[1].selection;
      const selectedContract = filterData[2].selection;
      const selectedContracts = filterData[3].selection;
      const selectedStatus = filterData[4].selection.id;

      let selectedContractsFn = (resultContracts, selectedList) => {
        let matchesAllContracts = true;
        selectedList.forEach((contract) => {
          if (!resultContracts.includes(contract)) {
            matchesAllContracts = false;
          }
        });

        return matchesAllContracts;
      };

      filterFn = function (result) {
        return (
          (!selectedPayTypes.length || selectedPayTypes.includes(result.payType)) &&
          (!selectedAgreementTypes.length || selectedAgreementTypes.includes(result.agreementType)) &&
          (!selectedContract || (result.otherContracts.length === 1 && selectedContract.name === result.otherContracts[0])) &&
          (!selectedContracts.length > 0 || selectedContractsFn(result.otherContracts, selectedContracts)) &&
          (!selectedStatus || result.status === selectedStatus)
        );
      };

      buttonData = this.state.buttonData;

      const buttonGroupIndex = buttonData[1].items.findIndex((button) => button.type === 'group');
      const buttonGroupData = buttonData[1].items[buttonGroupIndex];
      const filterButtonIndex = buttonGroupData.items.findIndex((button) => button.type === 'filter-button');
      const filterPopoverData = buttonGroupData.items[filterButtonIndex].popover;
      filterPopoverData.filters = filterData;

      buttonGroupData.items[filterButtonIndex].classes = _.some(
        _.filter(filterData, (filter) => !filter.hide || (typeof filter.hide === 'function' && !filter.hide())),
        'selection'
      )
        ? 'button--active'
        : '';
      buttonGroupData.items[filterButtonIndex].popover = filterPopoverData;
      buttonData[1].items[buttonGroupIndex] = buttonGroupData;

      data = data.filter((result) => filterFn(result));

      if (this.state.searchFn) {
        data = data.filter(this.state.searchFn);
      }

      const textIndex = buttonData[0].items.findIndex((button) => button.type === 'text');
      buttonData[0].items[textIndex].text = `${data.length} results returned`;
    }

    this.setState({
      buttonData: buttonData,
      employeeData: data,
      filterFn: filterFn ? filterFn : null,
    });
  }

  selectEmployee(employeeData) {
    window.open(`/hybrid/employee-details?id=${employeeData.id}&username=${employeeData.name}`);
  }

  getResultItems() {
    let employeeData = this.state.employeeData;

    return employeeData.map((data, index) => {
      return <EmployeeResult data={data} onClick={() => this.selectEmployee(data)} key={index} />;
    });
  }

  showModal(show, type) {
    const showingModal = this.state.showModal;

    this.setState({
      showModal: show != null ? show : !showingModal,
      modalType: type,
    });
  }

  submitNewEmployee(data) {
    data.homeContractId = this.props.selectedContract.id;
    let body = data;

    return new Promise((resolve, reject) => {
      let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/createNewEmployee`;
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      };

      fetch(apiUrl, requestOptions)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
            return res.json();
          }
        })
        .then((res) => {
          if (res.message === 'PASS') {
            // Redirect to new Employee Details page
            window.location.href = `/hybrid/employee-details?id=${res.results[0].userId}&username=${data.firstName}.${data.lastName}`;

            resolve();
          } else {
            reject(res.message);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  exportRoster(params) {
    let dateFrom = moment().add(1, 'week').startOf('week').format('YYYY-MM-DD');
    let dateTo = moment().add(2, 'week').endOf('week').format('YYYY-MM-DD');

    let contract = this.props.selectedContract;

    let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getEmployeeRoster?dateFrom=${dateFrom}&dateTo=${dateTo}&contractId=${contract.id}`;
    if (params.sapReference) {
      apiUrl += `&sapReference=${params.sapReference}`;
    }

    fetch(apiUrl)
      .then((res) => {
        if (res.status === 401) {
          this.props.logoutFn();
        } else {
          fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
          return res.json();
        }
      })
      .then((data) => {
        let excelData =
          data.results.length > 0 ? data.results : [{ 'There is no data for these params:': `Contract: ${contract.name}, Date Range: ${dateFrom}-${dateTo}, SAP Reference: ${params.sapReference}` }];
        ExportUtils.exportToExcel(excelData, `Employee-Roster-Export_${contract.name}_${dateFrom}-${dateTo}.xlsx`);
      });
  }

  render() {
    let data = { resultData: this.getResultItems() };

    return (
      <div className={'main-content employees-page'}>
        <header>
          <h1>Employees</h1>
          <img className='logo-container' src={process.env.REACT_APP_ENV ? uat_logo : logo} alt='' />
        </header>
        {!this.state.isLoading && <ResultsContainer data={data} buttonData={this.state.buttonData} dataClass={'employee'} isLoading={this.state.isLoading} bulkSelecting={this.state.bulkSelecting} />}
        {!this.state.isLoading && (
          <div className={'button-group'}>
            <Button classes={'button--plain'} text={'+ Add New Employee'} onClick={() => this.showModal(true, 'new-employee')} />
            <Button classes={'button--blue'} text={'Generate Roster'} onClick={() => this.showModal(true, 'roster')} />
          </div>
        )}
        {!this.state.isLoading && this.state.showModal && (
          <Modal showModalFn={(show) => this.showModal(show)} showModal={this.state.showModal}>
            {this.state.modalType === 'roster' && (
              <EmployeeRosterExportModal showModalFn={(show) => this.showModal(show)} showModal={this.state.showModal} onClick={(params) => this.exportRoster(params)} />
            )}
            {this.state.modalType === 'new-employee' && (
              <NewEmployeeModal
                data={{ contract: this.props.selectedContract }}
                showModalFn={(show) => this.showModal(show)}
                showModal={this.state.showModal}
                onClick={(params) => this.submitNewEmployee(params)}
              />
            )}
          </Modal>
        )}
        {this.state.isLoading && (
          <div className='spinner-container'>
            <Loader type='Bars' color='#008dce' height={30} width={30} visible={this.state.isLoading} />
          </div>
        )}
        <img className='wave' src={wave} alt={''} />
      </div>
    );
  }
}

export default withRouter(Employees);
