import React from 'react';
import Icon from '../../Icon';

class ClientRouteResult extends React.Component {
  render() {
    const data = this.props.data;

    return (
      <div className={`result-item client-route-item`} onClick={() => this.props.selectFn()}>
        <div id={'clientRoute-' + data.id} className={`item-card`}>
          <div className='item-row'>
            {/* Due Date */}
            <div className='item-detail' title='Due Date'>
              <Icon name='bell' />
              <span>{data.dueDate.format('DD/MM/YYYY')}</span>
            </div>
            {/* Client Route Number */}
            <div className='item-detail f--bold' title='Client Route Number'>
              <span>{data.clientRouteNumber}</span>
            </div>
            {/* Scheduled Date */}
            <div className='item-detail' title='Scheduled Date'>
              <Icon name='date' />
              <span>{data.scheduledDate.format('DD/MM/YYYY')}</span>
            </div>
            {/* Meters */}
            <div className='item-detail' title='Meters'>
              <Icon name='meter' />
              <span>
                {data.totalMeters} {data.totalMeters === 1 ? 'meter' : 'meters'}
              </span>
            </div>
            {/* Completed */}
            <div className='item-detail' title='Completed Meters'>
              <Icon name='complete' />
              <span>{data.completed} completed</span>
            </div>
            {/* Incomplete */}
            <div className='item-detail' title='Incomplete Meters'>
              <Icon name='incomplete' />
              <span>{data.incomplete} incomplete</span>
            </div>
            {/* Skipped */}
            <div className='item-detail' title='Skipped Meters'>
              <Icon name='skip' />
              <span>{data.skipped} skipped</span>
            </div>
            {/* Work Routes */}
            <div className='item-detail' title='Meters Per Work Route'>
              <Icon name='job' />
              <span className='u--multiline-ellipsis' title={`Work Routes: ${data.metersPerWorkRoute}`}>
                Work Routes: {data.metersPerWorkRoute}
              </span>
            </div>
          </div>
          <div className='item-row'>
            <div />
            {/* Status */}
            <div className='item-detail' title='Status'>
              <Icon name='status' />
              <span>{data.status}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ClientRouteResult;
