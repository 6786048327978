import React from 'react';
import FilterPopover from './FilterPopover';
import SelectPopover from './SelectPopover';

class Popover extends React.Component {
  render() {
    const type = this.props.item.type;

    switch (type) {
      case 'filter-button':
        return (
          <div className='button-popover filter-popover'>
            <FilterPopover item={this.props.item} onClick={this.props.onClick} filters={this.props.item.popover.filters} />
          </div>
        );
      case 'select':
      case 'sort-button':
        return (
          <div className='button-popover select-popover'>
            <SelectPopover item={this.props.item} onClick={(key) => this.props.onClick(key)} />
          </div>
        );
      case 'bulk-update-button':
        let popover = React.cloneElement(this.props.item.popover, { cancelFn: this.props.onClick });
        return <div className='button-popover bulk-update-popover'>{popover}</div>;
      default:
        break;
    }
  }
}

export default Popover;
