import React from 'react';
import ResultsContainer from '../ResultsContainer';
import _ from 'lodash';
import moment from 'moment';
import TabContainer from '../TabContainer';
import RouteResult from './RouteResult';
import wave from '../../images/wave.svg';
import logo from '../../images/skilltech-mimtr-logo-dark.png';
import uat_logo from '../../images/skilltech-mimtr-logo-uat-dark.png';
import Modal from '../Modal';
import AssignmentReallocationModal from './AssignmentReallocationModal';
import './assignments-page.scss';
import AssignmentsBulkUpdatePopover from './AssignmentsBulkUpdatePopover';
import Api from '../../api/Api';
import AssignmentsBulkSkipPopover from './AssignmentsBulkSkipPopover';

class Assignments extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      masterRouteData: [],
      routeData: [],
      readerData: [],
      buttonData: [],
      userData: props.userData,
      isLoading: true,
      bulkSelecting: false,
      selectAll: false,
      selectedGroup: 'critical',
      sortKey: 'name',
      searchFn: null,
      filterFn: null,
      resultData: [],
      showModal: false,
      reallocateToReader: null,
    };
  }

  async componentDidMount() {
    document.title = `${process.env.REACT_APP_ENV ? `[${process.env.REACT_APP_ENV}] ` : ''}MiMtr Hybrid | Assignments`;

    this.getData();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.selectedContract.id !== this.props.selectedContract.id) {
      this.setState({
        bulkSelecting: false,
        selectAll: false,
        selectedGroup: 'critical',
        sortKey: 'name',
        searchFn: null,
        filterFn: null,
      });
      this.getData();
    }
  }

  async getData() {
    this.setState({ isLoading: true });
    await this.getRouteData(this.props.selectedContract, this.props.selectedAreas).then((routeData) => {
      let groupedData = this.getGroupedData(routeData);

      Api.getReaders(this.props.selectedContract).then((readerData) => {
        this.setState({
          masterRouteData: routeData,
          routeData: groupedData,
          readerData: readerData,
          buttonData: this.getButtonData(readerData, routeData),
          isLoading: false,
        });
      });
    });
  }

  async getRouteData(selectedContract, selectedAreas) {
    return new Promise((resolve, reject) => {
      let routesApiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getassignableworkroutes?DatabaseServer=${selectedContract.dbServer}&contractid=${selectedContract.id}&areas=${[
        ...new Set(
          selectedAreas.map((area) => {
            return area.id;
          })
        ),
      ]}`;
      fetch(routesApiUrl)
        .then((routesRes) => {
          if (routesRes.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
            return routesRes.json();
          }
        })
        .then((data) => {
          let routeData = data.map((route) => {
            let area = _.find(this.props.userData.areas, { id: route.AreaId, contractId: selectedContract.id });
            let areaGroup = area ? _.find(this.props.userData.areaGroups, { id: area.areaGroupId, contractId: selectedContract.id }) : {};

            return {
              routeNo: route.StRouteNumber,
              name: route.StRouteName,
              status: route.Status.charAt(0).toLowerCase() + route.Status.substring(1),
              initialDate: route.InitStartDate ? moment(route.InitStartDate) : null,
              endDate: route.InitEndDate ? moment(route.InitEndDate) : null,
              urgent: route.InitEndDate ? moment(route.InitEndDate).isBefore(moment()) : false,
              totalMeters: route.NumberOfMeters,
              time: route.RouteTime,
              requirements: route.RouteRequirements ? route.RouteRequirements.split('|') : [],
              readerName: route.AssignedReaderName.replace(' - ', ' '),
              readerId: route.AssignedReaderId,
              id: route.WorkRouteId === 0 ? `${route.WorkRouteId}-${route.StRouteNumber}` : route.WorkRouteId,
              scheduledDate: route.ScheduledDate ? moment(route.ScheduledDate) : null,
              preferredReaderName: route.PreferredReaderName.replace(' - ', ' '),
              preferredReaderId: route.PreferredReaderId,
              category: route.RouteCategory,
              area: route.AreaName,
              areaId: route.AreaId,
              areaGroup: areaGroup.name,
              areaGroupId: areaGroup.id,
              grade: route.RouteGradeName,
              completed: route.CompletedJobs,
              skipped: route.Skipped,
              readFrequency: route.ReadFrequency,
              lastSync: route.LastSyncDateTime ? moment(route.LastSyncDateTime) : null,
              lastReading: route.LastUpdatedDatetime ? moment(route.LastUpdatedDatetime) : null,
              lastReaderName: route.LastReaderName ? route.LastReaderName.replace(' - ', ' ') : null,
              lastReaderId: route.LastReaderId,
              selected: false,
            };
          });
          resolve(routeData);
        });
    });
  }

  getGroupedData(masterRouteData, sortKey, filterFn, searchFn) {
    let routeData = _.cloneDeep(masterRouteData);
    routeData = _.sortBy(routeData, sortKey ? sortKey : this.state.sortKey);

    routeData = _.filter(routeData, filterFn ? filterFn : this.state.filterFn);

    searchFn = searchFn ? searchFn : this.state.searchFn;
    if (searchFn) {
      routeData = _.filter(routeData, searchFn);
    }

    // Take out unmerged routes from 'All' routes because they cannot be actioned
    let allRoutes = routeData.filter((route) => route.status !== 'unmerged');

    routeData = _.groupBy(routeData, 'status');

    routeData.critical = routeData.critical ? routeData.critical : [];
    routeData.unallocated = routeData.unallocated ? routeData.unallocated : [];
    routeData.scheduled = routeData.scheduled ? routeData.scheduled : [];
    routeData.inProgress = routeData.inProgress ? routeData.inProgress : [];
    routeData.unmerged = routeData.unmerged ? routeData.unmerged : [];
    routeData.all = routeData.all ? routeData.all : allRoutes;

    return routeData;
  }

  getButtonData(readerData, masterRouteData) {
    return [
      {
        type: 'bulk-select-button',
        classes: 'button--plain',
        text: 'Bulk Select',
        icon: 'checkbox',
        iconPosition: '1',
        actionItems: [
          {
            text: 'Update All',
            onClick: (reader, date) => this.bulkUpdateRoutes(reader, date),
            getPopover: () => this.getBulkUpdatePopover(readerData),
          },
          {
            text: 'Skip All',
            icon: 'skip',
            onClick: (reader, date) => this.bulkSkipRoutes(reader, date),
            hide: () => {
              return this.props.userData.securityRoles.canSkipRoutes && this.state.selectedGroup === 'all';
            },
            getPopover: () => this.getBulkSkipPopover(readerData),
          },
        ],
        selectAll: () => this.selectRoute(null, null, true),
        toggleBulkSelectFn: () => this.toggleBulkSelecting(),
        count: 0,
        hide: () => {
          return this.state.selectedGroup === 'unmerged';
        },
      },
      {
        type: 'group',
        items: [
          {
            type: 'button',
            icon: 'refresh',
            iconPosition: '2',
            text: 'Refresh',
            onClick: () => this.getData(),
          },
          {
            type: 'filter-button',
            text: 'Filter',
            icon: 'filter',
            iconPosition: '2',
            popover: {
              type: 'filter',
              onClick: (filterData) => this.filterResults(filterData),
              filters: [
                {
                  name: 'areaGroups',
                  displayLabel: 'Area Groups',
                  type: 'tags',
                  selection: [],
                  options: _.sortBy(
                    _.uniqBy(masterRouteData, 'areaGroupId').map((route) => {
                      return { id: route.areaGroupId, name: route.areaGroup };
                    }),
                    'name'
                  ),
                  selectCallback: (selectedAreaGroups, filters) => {
                    let areaIndex = filters.findIndex((filter) => filter.name === 'areas');
                    let contractAreas = _.filter(this.props.userData.areas, (area) => area.contractId === this.props.selectedContract.id);
                    filters[areaIndex].options = selectedAreaGroups ? _.filter(contractAreas, (area) => selectedAreaGroups.includes(area.areaGroupId)) : contractAreas;
                    return filters;
                  },
                },
                {
                  name: 'areas',
                  displayLabel: 'Areas',
                  type: 'select',
                  multiselect: true,
                  selection: [],
                  options: _.sortBy(
                    _.uniqBy(masterRouteData, 'areaId').map((route) => {
                      return { id: route.areaId, name: route.area };
                    }),
                    'name'
                  ),
                },
                {
                  name: 'selectedDateRange',
                  displayLabel: 'Read Window',
                  type: 'dateSelectRange',
                  selection: { start: null, end: null },
                },
                {
                  name: 'reader',
                  displayLabel: 'Assigned Reader',
                  type: 'select',
                  multiselect: true,
                  selection: [],
                  options: _.sortBy(
                    _.uniqBy(masterRouteData, 'readerId')
                      .filter((route) => route.readerId)
                      .map((route) => {
                        return { id: route.readerId, name: route.readerName };
                      }),
                    'name'
                  ),
                },
                {
                  name: 'category',
                  displayLabel: 'Route Category',
                  type: 'select',
                  multiselect: true,
                  selection: [],
                  options: _.sortBy(
                    _.uniqBy(masterRouteData, 'category').map((route) => {
                      return { id: route.category, name: route.category };
                    }),
                    'name'
                  ),
                },
                {
                  name: 'readFrequency',
                  displayLabel: 'Read Frequency',
                  type: 'select',
                  multiselect: true,
                  selection: [],
                  options: _.sortBy(
                    _.uniqBy(masterRouteData, 'readFrequency').map((route) => {
                      return { id: route.readFrequency, name: route.readFrequency };
                    }),
                    'name'
                  ),
                },
              ],
            },
          },
          {
            type: 'sort-button',
            classes: 'button--active',
            text: 'Sort By',
            icon: 'sort',
            iconPosition: '2',
            popover: {
              type: 'select',
              onClick: (key) => this.sortResults(key),
              options: [
                { name: 'Route No.', value: 'routeNo' },
                { name: 'Route Name', value: 'name' },
                { name: 'Due Date', value: 'endDate' },
                { name: 'Scheduled Date', value: 'scheduledDate' },
                { name: 'Assigned Reader', value: 'readerName' },
              ],
            },
            selectedSort: 'name',
            sortAsc: true,
          },
          { type: 'search-input', onChange: (key) => this.searchResults(key) },
        ],
      },
    ];
  }

  selectStatusGroup(tabName) {
    const data = this.getGroupedData(this.state.masterRouteData);

    this.setState({
      selectedGroup: tabName,
      bulkSelecting: false,
      routeData: data,
    });
  }

  toggleBulkSelecting(toggle) {
    // Switch to the opposite state
    const bulkSelecting = toggle !== undefined ? toggle : !this.state.bulkSelecting;

    // Clear changes when toggling bulk select
    const bulkButton = this.state.buttonData.find((button) => button.type === 'bulk-select-button');
    bulkButton.count = 0;
    let selectedRoutes = [];

    let routeData = this.state.masterRouteData;
    if (!bulkSelecting) {
      routeData.forEach((route) => {
        route.selected = false;
      });
    }

    this.setState({
      bulkSelecting: bulkSelecting,
      selectAll: false,
      routeData: this.getGroupedData(routeData),
      selectedRoutes: selectedRoutes,
    });
  }

  getBulkUpdatePopover(readerData) {
    let warningMsg = '';

    let selectedInProgress = _.some(this.state.selectedRoutes, { status: 'inProgress' });
    if ((this.state.selectedGroup === 'inProgress' || selectedInProgress) && this.state.selectedRoutes.length > 0) {
      let reader = this.state.selectedRoutes[0].readerName;
      warningMsg =
        this.state.selectedRoutes.filter((route) => route.readerName !== reader).length > 0 ? 'Please select routes that are all allocated to the same reader in order to bulk reallocate.' : '';
    }

    return <AssignmentsBulkUpdatePopover onClick={(readerId, date) => this.bulkUpdateRoutes(readerId, date)} selectedGroup={this.state.selectedGroup} warningMsg={warningMsg} options={readerData} />;
  }

  getBulkSkipPopover(readerData) {
    let warningMsg = '';

    // let selectedInvalid = _.some(this.state.selectedRoutes, {status: 'inProgress'});
    let selectedInvalid = true;
    if (selectedInvalid && this.state.selectedRoutes.length > 0) {
      warningMsg = 'Please select routes that are not in progress.';
    }

    return <AssignmentsBulkSkipPopover onClick={(readerId, date) => this.bulkUpdateRoutes(readerId, date)} selectedGroup={this.state.selectedGroup} warningMsg={warningMsg} options={readerData} />;
  }

  selectRoute(id, routeNo, toggleAll) {
    const bulkSelecting = this.state.bulkSelecting;
    const selectedGroup = this.state.selectedGroup;
    let selectAll = this.state.selectAll;
    // If bulk selecting and toggling all, switch according to the selectAll state
    if (bulkSelecting && toggleAll) {
      selectAll = !selectAll;
    }

    const routeData = this.getGroupedData(this.state.masterRouteData);

    const visibleRoutes = this.state.routeData[selectedGroup];

    // Check all visible routes for a match, and toggle select
    _.forEach(visibleRoutes, (item) => {
      if (bulkSelecting && toggleAll) {
        item.selected = selectAll;
      } else if (item.id === id && item.routeNo === routeNo) {
        item.selected = !item.selected;
      } else {
        if (!bulkSelecting && item.selected) {
          item.selected = false;
        }
      }
    });

    if (bulkSelecting) {
      // Add to the bulk select count
      let selectedRoutes = visibleRoutes.filter((route) => route.selected);
      let bulkButton = _.find(this.state.buttonData, { type: 'bulk-select-button' });
      bulkButton.count = selectedRoutes.length;

      this.setState({
        selectedRoutes: selectedRoutes,
      });
    }

    routeData[selectedGroup] = visibleRoutes;

    this.setState({
      routeData: routeData,
      selectAll: selectAll,
    });
  }

  filterResults(filterData) {
    let filterFn;
    let buttonData;

    if (!filterData) {
      filterFn = (route) => {
        return route;
      };
      buttonData = this.getButtonData(this.state.readerData, this.state.masterRouteData);
    } else {
      const selectedAreaGroups = filterData[0].selection;
      const selectedArea = filterData[1].selection;
      const selectedDateRangeStart = filterData[2].selection.start;
      const selectedDateRangeEnd = filterData[2].selection.end;
      const selectedReader = filterData[3].selection;
      const selectedCategory = filterData[4].selection;
      const selectedFrequency = filterData[5].selection;

      filterFn = function (route) {
        return (
          (!selectedAreaGroups.length || selectedAreaGroups.includes(route.areaGroupId)) &&
          (!selectedDateRangeStart || !selectedDateRangeEnd || (selectedDateRangeStart.isSameOrBefore(route.initialDate, 'day') && selectedDateRangeEnd.isSameOrAfter(route.endDate, 'day'))) &&
          (!selectedArea.length > 0 || selectedArea.includes(route.areaId)) &&
          (!selectedReader.length > 0 || selectedReader.includes(route.readerId)) &&
          (!selectedCategory.length > 0 || selectedCategory.includes(route.category)) &&
          (!selectedFrequency.length > 0 || selectedFrequency.includes(route.readFrequency))
        );
      };

      buttonData = this.state.buttonData;

      const buttonGroupIndex = buttonData.findIndex((button) => button.type === 'group');
      const buttonGroupData = buttonData[buttonGroupIndex];
      const filterButtonIndex = buttonGroupData.items.findIndex((button) => button.type === 'filter-button');
      const filterPopoverData = buttonGroupData.items[filterButtonIndex].popover;
      filterPopoverData.filters = filterData;

      buttonGroupData.items[filterButtonIndex].classes = _.some(filterData, 'selection') ? 'button--active' : '';
      buttonGroupData.items[filterButtonIndex].popover = filterPopoverData;
      buttonData[buttonGroupIndex] = buttonGroupData;
    }

    let data = this.getGroupedData(this.state.masterRouteData, null, filterFn, null);

    this.setState({
      buttonData: buttonData,
      routeData: data,
      filterFn: filterFn ? filterFn : null,
    });
  }

  sortResults(sortKey) {
    let data = this.getGroupedData(this.state.masterRouteData, sortKey);
    let currentSortKey, sortAsc;

    // Find the sort button and check if the sort key was already selected
    let buttonData = this.state.buttonData;
    const buttonGroup = buttonData.find((button) => button.type === 'group');
    buttonGroup.items.forEach((button) => {
      if (button.type === 'sort-button') {
        currentSortKey = button.selectedSort;

        // If the sort key was reselected, toggle asc/desc order
        if (currentSortKey === sortKey) {
          button.sortAsc = !button.sortAsc;
        } else {
          button.sortAsc = true;
        }

        button.selectedSort = sortKey;
        sortAsc = button.sortAsc;
      }
    });

    // Order each route group by the sorting key
    _.forEach(data, (group, key) => {
      data[key] = _.orderBy(data[key], sortKey, sortAsc ? 'asc' : 'desc');
    });

    this.setState({
      routeData: data,
      buttonData: buttonData,
      sortKey: sortKey,
    });
  }

  searchResults(searchTerm) {
    let searchFn;
    if (searchTerm) {
      searchFn = (route) => {
        return route.routeNo.includes(searchTerm) || route.name.toLowerCase().includes(searchTerm.toLowerCase());
      };
    } else {
      searchFn = (route) => {
        return route;
      };
    }

    let data = this.getGroupedData(this.state.masterRouteData, null, null, searchFn);

    this.setState({
      routeData: data,
      searchFn: searchFn,
    });
  }

  inlineUpdateRoute(updatedRoute) {
    if (updatedRoute.status === 'inProgress') {
      this.setState({
        showModal: true,
        selectedRoutes: [updatedRoute],
      });
      return;
    }

    // Send data off
    this.updateRoute(updatedRoute).then(() => {
      // Update route results list
      this.updateRouteData([updatedRoute]);
    });
  }

  async bulkUpdateRoutes(reader, date) {
    if (!reader && !date) {
      return;
    }

    let selectedRoutes = this.state.selectedRoutes;
    let selectedInProgress = _.some(selectedRoutes, { status: 'inProgress' });

    if (this.state.selectedGroup === 'inProgress' || selectedInProgress) {
      selectedRoutes[0].newReaderName = reader.name;
      selectedRoutes[0].newReaderId = reader.id;
      this.setState({
        selectedRoutes: selectedRoutes,
        showModal: true,
      });
      return;
    }

    selectedRoutes.forEach((route) => {
      if (reader) {
        route.newReaderId = parseInt(reader.id);
        route.newReaderName = reader.name;
      }

      if (date) {
        route.scheduledDate = date;
      }
    });

    const promises = selectedRoutes.map(async (route) => {
      return await this.updateRoute(route);
    });

    await Promise.all(promises).then(() => {
      this.updateRouteData(selectedRoutes);
    });
  }

  updateRoute(updatedRoute, field) {
    let apiUrl = '';
    let body = {
      DatabaseServer: this.props.selectedContract.dbServer,
      workrouteid: updatedRoute.id,
    };
    // Can only be assigned if a reader is allocated, else unassigning
    if (updatedRoute.newReaderId) {
      body.readerid = updatedRoute.newReaderId;

      switch (field) {
        case 'preferredReader':
          apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/setpreferredreader`;
          break;
        default:
          apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/assignroute`;
          body.startdate = updatedRoute.scheduledDate.format('YYYY-MM-DD');
          break;
      }
    } else {
      apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/unassignroute`;
    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    };

    return new Promise((resolve, reject) => {
      fetch(apiUrl, requestOptions)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
            return res.json();
          }
        })
        .then((data) => {
          if (data.message === 'PASS') {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
            resolve();
          }
        });
    });
  }

  bulkSkipRoutes() {
    //TODO: add api
  }

  updateRouteData(updatedRoutes) {
    let routeData = _.cloneDeep(this.state.masterRouteData);

    updatedRoutes.forEach((updatedRoute) => {
      updatedRoute.readerId = updatedRoute.newReaderId ? updatedRoute.newReaderId : updatedRoute.readerId;
      updatedRoute.readerName = updatedRoute.newReaderName ? updatedRoute.newReaderName : updatedRoute.readerName;
      delete updatedRoute.newReaderId;
      delete updatedRoute.newReaderName;
      if (updatedRoute.scheduledDate && updatedRoute.readerId) {
        updatedRoute.status = updatedRoute.status === 'inProgress' ? 'inProgress' : 'scheduled';
      } else {
        updatedRoute.status = 'unallocated';
      }

      updatedRoute.selected = false;

      let masterRouteIndex = this.state.masterRouteData.findIndex((route) => route.id === updatedRoute.id);

      routeData[masterRouteIndex] = updatedRoute;
    });

    let groupedData = this.getGroupedData(routeData);

    this.toggleBulkSelecting(false);

    this.setState({
      routeData: groupedData,
      masterRouteData: routeData,
    });
  }

  showModal(show) {
    const showingModal = this.state.showModal;

    this.setState({
      showModal: show != null ? show : !showingModal,
    });
  }

  renderReallocationModal() {
    let data = {
      currentReader: { readerName: this.state.selectedRoutes[0].readerName, lastSync: this.state.selectedRoutes[0].lastSync },
      newReader: { readerName: this.state.selectedRoutes[0].newReaderName, id: this.state.selectedRoutes[0].newReaderId },
      routeCount: this.state.selectedRoutes.length,
    };

    return (
      <AssignmentReallocationModal
        data={data}
        userData={this.props.userData}
        userRoles={this.props.selectedContract.roles}
        optionLoadFn={(date, routeId) => Api.getReaders(this.props.selectedContract, routeId, date)}
        updateFn={(data) => this.reallocateRoute(data)}
      />
    );
  }

  async reallocateRoute(reader, reason) {
    let updatedRoutes = [];

    const promises = this.state.selectedRoutes.map(async (route) => {
      route.newReaderId = parseInt(reader.id);
      route.newReaderName = reader.readerName;
      updatedRoutes.push(route);
      return await this.updateRoute(route);
    });

    await Promise.all(promises).then(() => {
      this.setState({
        showModal: false,
      });

      this.updateRouteData(updatedRoutes);
    });
  }

  getResultItems() {
    if (!this.state.routeData) {
      return;
    }
    let resultData = {};

    _.forEach(this.state.routeData, (group, key) => {
      if (key) {
        resultData[key] = group.map((route, index) => {
          if (this.state.selectedGroup === 'all') {
            route.all = true;
          }

          return (
            <RouteResult
              data={route}
              bulkSelecting={this.state.bulkSelecting}
              selectFn={() => this.selectRoute(route.id, route.routeNo)}
              updateFn={(route, field) => this.inlineUpdateRoute(route, field)}
              getReadersFn={(date, routeId) => Api.getReaders(this.props.selectedContract, routeId, date)}
              key={index}
            />
          );
        });
      }
    });

    return resultData;
  }

  render() {
    let data = { resultData: this.getResultItems() };

    const groups = [];

    ['critical', 'unallocated', 'scheduled', 'inProgress', 'unmerged', 'all'].forEach((groupName) => {
      let group = {
        name: groupName,
        count: data.resultData[groupName] ? data.resultData[groupName].length : 0,
      };

      groups.push(group);
    });

    return (
      <div className={'main-content assignments-page'}>
        <header>
          <h1>Manage Work Routes</h1>
          <img className='logo-container' src={process.env.REACT_APP_ENV ? uat_logo : logo} alt='' />
        </header>
        <TabContainer id={'assignments'} groups={groups} selectedTab={this.state.selectedGroup} onClick={(name) => this.selectStatusGroup(name)} />
        <ResultsContainer
          data={data}
          buttonData={this.state.buttonData}
          selectedGroup={this.state.selectedGroup}
          dataClass={'route'}
          isLoading={this.state.isLoading}
          bulkSelecting={this.state.bulkSelecting}
        />
        {this.state.showModal && (
          <Modal showModalFn={(show) => this.showModal(show)} showModal={this.state.showModal}>
            {this.renderReallocationModal()}
          </Modal>
        )}
        <img className='wave' src={wave} alt={''} />
      </div>
    );
  }
}

export default Assignments;
