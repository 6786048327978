import React from 'react';
import DatePickerSelect from '../DatePickerSelect';
import Utils from '../../utils/Utils';
import TimeInput from '../TimeInput';
import Loader from 'react-loader-spinner';
import { TimesheetEntryBase } from './TimesheetEntryBase';

class TimesheetEntryModal extends TimesheetEntryBase {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      startTime: props.data.selectedEntry ? props.data.selectedEntry.startTime : '00:00',
      endTime: props.data.selectedEntry ? props.data.selectedEntry.endTime : '00:00',
    };
  }

  render() {
    return (
      <div id={'timesheet-entry-modal'} className='modal-content'>
        <div className='heading'>
          <div>
            <div className='heading'>
              {this.props.data.selectedEntry ? 'Edit' : 'New'} {Utils.humanize(this.props.type)}
            </div>
          </div>
        </div>
        {!this.state.isLoading && (
          <div>
            <div className='form-container'>
              <div className='form-row'>
                <div className='form-element'>
                  <label>Date</label>
                  <DatePickerSelect id={'entry-date'} selection={{ startDate: this.state.date }} onClick={(date) => this.updateField('date', date)} />
                </div>
              </div>
              {this.props.type === 'timeEntry' && (
                <>
                  <div className='form-row'>
                    <div className='form-element'>
                      <label>Start Time</label>
                      <div className='input-container'>
                        <TimeInput value={this.state.startTime} id={`startTime`} required={true} onChange={(value) => this.updateField('startTime', value)} />
                      </div>
                    </div>
                    <div className='form-element'>
                      <label>End Time</label>
                      <div className='input-container'>
                        <TimeInput value={this.state.endTime} id={`endTime`} required={true} onChange={(value) => this.updateField('endTime', value)} />
                      </div>
                    </div>
                  </div>
                  {this.props.timesheetErrorInfo && (
                    <div className='f--warning' style={{ marginTop: '-20px', marginBottom: '24px' }}>
                      <b> {this.props.timesheetErrorInfo}</b>
                    </div>
                  )}
                </>
              )}

              <div className='form-row'>{this.renderCategory()}</div>

              {this.renderQuantity()}

              <div className='form-row'>{this.renderComment()}</div>

              {this.renderTotalHours()}
            </div>

            {this.renderButtons()}
          </div>
        )}
        {this.state.isLoading && (
          <div className={'spinner-container'}>
            <Loader type='Bars' color='#008dce' height={30} width={30} visible={this.state.isLoading} />
          </div>
        )}
      </div>
    );
  }
}

export default TimesheetEntryModal;
