import React from 'react';
import Icon from '../../Icon';
import Utils from '../../../utils/Utils';

class MeterResult extends React.Component {
  handleClick = (e) => {
    const id = this.props.data.id;
    const readingElem = document.getElementById('meter-' + id);

    if (readingElem.contains(e.target)) {
      this.props.selectFn(this.props.data);
    }
  };

  componentDidMount() {
    const element = document.getElementById('meter-' + this.props.data.id);
    element.addEventListener('mousedown', this.handleClick);
  }

  componentWillUnmount() {
    // Remove the event listener on component unmounting
    const element = document.getElementById('meter-' + this.props.data.id);
    element.removeEventListener('mousedown', this.handleClick);
  }

  render() {
    const data = this.props.data;
    const bulkSelecting = this.props.bulkSelecting;

    return (
      <div className={`result-item ${data.selected ? 'selected' : ''}`}>
        {bulkSelecting && (
          <div className='checkbox-container'>
            <input type='checkbox' id={'checkbox-' + data.id} checked={data.selected} onClick={() => this.props.selectFn()} />
            <label className='checkbox-label' htmlFor={'checkbox-' + data.id} />
          </div>
        )}
        <div id={'meter-' + data.id} className={`item-card`}>
          <div className='item-row'>
            {/* Work Route Number */}
            <div className='item-detail f--bold' title='Work Route Number'>
              <span>{data.workRouteNumber}</span>
            </div>
            {/* Route Description */}
            <div className='item-detail' title='Route Description'>
              <Icon name='pin' />
              <span className='u--ellipsis' title={data.address}>
                {data.address}
              </span>
            </div>
            {/* Meter Number*/}
            <div className='item-detail' title='Meter Number'>
              <Icon name='meter-box' />
              <span>#{data.meterNumber}</span>
            </div>
            {/* Date Completed */}
            <div className='item-detail' title='Date Completed'>
              <Icon name='date' />
              <span>{data.dateCompleted ? data.dateCompleted.format('DD/MM/YYYY') : 'No Scheduled Date'}</span>
            </div>
            {/* Skip Reason */}
            <div className='item-detail' title='Skip Reason'>
              <Icon name='skip' />
              <span className='u--ellipsis' title={data.skipReason ? Utils.titleize(data.skipReason) : 'N/A'}>
                Skip Reason: {data.skipReason ? Utils.titleize(data.skipReason) : 'N/A'}
              </span>
            </div>
            {/* Registers */}
            <div className='item-detail' title='Text Prompt'>
              <Icon name='label' />
              <span>
                {data.registers} {data.registers === 1 ? 'register' : 'registers'}
              </span>
            </div>
            {/* Marked For Audit */}
            {data.markedForAudit > 0 && (
              <div className='item-detail' title='Marked For Audit'>
                <Icon name='audit' />
                <span className='u--ellipsis' title={'Marked For Audit'}>
                  For Audit
                </span>
              </div>
            )}
          </div>
          <div className='item-row'>
            <div></div>
            {/* Area Group */}
            <div className='item-detail' title='Area Group'>
              <Icon name='map' />
              <span>{data.areaGroup}</span>
            </div>
            {/* Status */}
            <div className='item-detail' title='Status'>
              <Icon name='status' />
              <span>{data.status}</span>
            </div>
            {/* Completed By */}
            <div className='item-detail' title='Completed By'>
              <Icon name='person' />
              <span>{data.completedBy ? data.completedBy : 'Unassigned'}</span>
            </div>
            {/* Photos */}
            <div className='item-detail' title='Photo Count'>
              <Icon name='camera' />
              <span>
                {data.photoCount ? data.photoCount : 0} {data.photoCount === 1 ? 'photo' : 'photos'}
              </span>
            </div>
            {/* Single Register Reading */}
            {data.registers === 1 && (
              <div className='item-detail' title='Reading'>
                <Icon name='meter' />
                <span>{data.reading ? data.reading : '—'}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default MeterResult;
