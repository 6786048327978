import React from 'react';
import Icon from '../Icon';
import InlineEditable from '../InlineEditable';
import Utils from '../../utils/Utils';

class RouteResult extends React.Component {
  handleClick = (e) => {
    const routeId = this.props.data.id;
    const routeElem = document.getElementById('route-' + routeId);

    if (routeElem.contains(e.target)) {
      const editableElem = document.getElementById('inline-editable-assign-reader-' + routeId);
      const editableElem2 = document.getElementById('inline-editable-assign-preferred-reader-' + routeId);
      const editableElem3 = document.getElementById('inline-editable-schedule-date-' + routeId);

      if ((!editableElem || !editableElem.contains(e.target)) && (!editableElem2 || !editableElem2.contains(e.target)) && (!editableElem3 || !editableElem3.contains(e.target))) {
        this.props.selectFn();
      }
    }
  };

  componentDidMount() {
    const element = document.getElementById('route-' + this.props.data.id);
    element.addEventListener('mousedown', this.handleClick);
  }

  componentWillUnmount() {
    // Remove the event listener on component unmounting
    const element = document.getElementById('route-' + this.props.data.id);
    element.removeEventListener('mousedown', this.handleClick);
  }

  render() {
    const route = this.props.data;
    const bulkSelecting = this.props.bulkSelecting;
    const displayingAll = route.all;
    const isInProgress = route.status === 'inProgress';
    const isUnmerged = route.status === 'unmerged';

    return (
      <div className={`result-item ${route.selected ? 'selected' : ''}`}>
        {bulkSelecting && (
          <div className='checkbox-container'>
            <input type='checkbox' id={'checkbox-' + route.id} checked={route.selected} onChange={() => this.props.selectFn()} />
            <label className='checkbox-label' htmlFor={'checkbox-' + route.id} />
          </div>
        )}
        <div id={'route-' + route.id} className={`item-card ${route.urgent ? 'item--urgent' : ''}`}>
          <div className='item-row'>
            {/* Initial Date */}
            <div className='item-detail' title='Read Window Start Date'>
              {!route.urgent && <Icon name='bell' />}
              {route.urgent && <Icon name='full-bell' />}
              <span>{route.initialDate ? route.initialDate.format('DD/MM/YYYY') : '—'}</span>
            </div>
            {/* Route No. */}
            <div className='item-detail f--bold' title='Route No.'>
              <span>{route.routeNo}</span>
            </div>
            {/* Meters */}
            <div className='item-detail' title='Total Meters'>
              <Icon name='meter' />
              <span>{route.totalMeters} meters</span>
            </div>
            {/* Route Category */}
            {!isInProgress && (
              <div>
                <div className='item-detail' title='Route Category'>
                  <Icon name='house' />
                  <span>{route.category}</span>
                </div>
              </div>
            )}
            {/* Completed */}
            {isInProgress && (
              <div className='item-detail' title='Completed Meters'>
                <Icon name='complete' />
                <span>{route.completed} completed</span>
              </div>
            )}
            {/* Requirements */}
            {!isUnmerged && !displayingAll && (
              <div className='item-detail' title='Requirements'>
                <Icon name='clipboard' />
                <span className={'u--ellipsis'} title={route.requirements.length > 0 ? 'Requires ' + route.requirements.join(', ') : 'No Requirements'}>
                  {route.requirements.length > 0 ? 'Requires ' + route.requirements.join(', ') : 'No Requirements'}
                </span>
              </div>
            )}
            {/* Status */}
            {displayingAll && (
              <div>
                <div className='item-detail' title='Status'>
                  <Icon name='status' />
                  <span>{Utils.humanize(route.status)}</span>
                </div>
              </div>
            )}
            {/* Scheduled Date */}
            {!isUnmerged && (
              <div className='item-detail editable' title='Scheduled Date'>
                <Icon name='date' />
                <InlineEditable id={'schedule-date-' + route.id} type='date' readOnly={isUnmerged} data={route} updateFn={(route) => this.props.updateFn(route)} />
              </div>
            )}
            {/* Last Sync */}
            {isInProgress && (
              <div className='item-detail' title='Last Sync'>
                <Icon name='history' />
                <span>Last Sync: {route.lastSync ? route.lastSync.format('DD/MM/YYYY hh:mma') : 'N/A'}</span>
              </div>
            )}
          </div>
          <div className='item-row'>
            {/* End Date */}
            <div className='item-detail detail--no-icon' title='Read Window End Date'>
              <span>{route.endDate ? route.endDate.format('DD/MM/YYYY') : '—'}</span>
            </div>
            {/* Route Name */}
            <div className='item-detail' title='Route Name'>
              <Icon name='pin' />
              <span className='u--ellipsis' title={route.name}>
                {route.name}
              </span>
            </div>
            {/* Area */}
            <div className='item-detail' title='Area'>
              <Icon name='map' />
              <span className='u--ellipsis'>
                {route.areaGroup} - {route.area}
              </span>
            </div>
            {/* Read Frequency */}
            {!isInProgress && (
              <div>
                <div className='item-detail' title='Read Frequency'>
                  <Icon name='repeat' />
                  <span>{route.readFrequency}</span>
                </div>
              </div>
            )}
            {!isInProgress && !displayingAll && <div />}
            {/* Skipped */}
            {isInProgress && (
              <>
                <div className='item-detail' title='Skipped Meters'>
                  <Icon name='skip' />
                  <span>{route.skipped} skipped</span>
                </div>
                {/* Time Estimate */}
                {!displayingAll && (
                  <div className='item-detail' title='Time Estimate'>
                    <Icon name='timer' />
                    <span>{route.time}</span>
                  </div>
                )}
              </>
            )}
            {!isUnmerged && displayingAll && (
              <div className='item-detail' title='Requirements'>
                <Icon name='clipboard' />
                <span className={'u--ellipsis'} title={route.requirements.length > 0 ? 'Requires ' + route.requirements.join(', ') : 'No Requirements'}>
                  {route.requirements.length > 0 ? 'Requires ' + route.requirements.join(', ') : 'No Requirements'}
                </span>
              </div>
            )}
            {/* Reader */}
            {!isUnmerged && (
              <div className='item-detail editable' title='Assigned Reader'>
                <Icon name='person' />
                <InlineEditable
                  id={'assign-reader-' + route.id}
                  type='assign-reader'
                  readOnly={isUnmerged}
                  optionLoadFn={() => this.props.getReadersFn(route.scheduledDate, route.id)}
                  data={route}
                  updateFn={(route) => this.props.updateFn(route)}
                />
              </div>
            )}
            {/*Last Reading*/}
            {isInProgress && (
              <div className='item-detail detail--no-icon' title='Last Reading'>
                <span>Last Reading: {route.lastReading ? route.lastReading.format('DD/MM/YYYY hh:mma') : 'N/A'}</span>
              </div>
            )}
          </div>
          {route.selected && (
            <div className='item-row'>
              <div></div>
              {/* Grade */}
              <div className='item-detail' title='Grade'>
                <Icon name='badge' />
                <span>{route.grade}</span>
              </div>
              {isInProgress && (
                <div>
                  <div className='item-detail' title='Route Category'>
                    <Icon name='house' />
                    <span>{route.category}</span>
                  </div>
                </div>
              )}
              {isInProgress && (
                <div>
                  <div className='item-detail' title='Read Frequency'>
                    <Icon name='repeat' />
                    <span>{route.readFrequency}</span>
                  </div>
                </div>
              )}
              {displayingAll && isInProgress && (
                <div className='item-detail' title='Time Estimate'>
                  <Icon name='timer' />
                  <span>{route.time}</span>
                </div>
              )}
              {!isInProgress && (
                <>
                  <div />
                  <div />
                  <div />
                </>
              )}
              {/* Preferred Reader */}
              {!isUnmerged && (
                <div className='item-detail editable' title='Preferred Reader'>
                  <Icon name='star-person' />
                  <InlineEditable
                    id={'assign-preferred-reader-' + route.id}
                    type='assign-preferred-reader'
                    readOnly={isUnmerged}
                    optionLoadFn={() => this.props.getReadersFn()}
                    data={route}
                    updateFn={(route) => this.props.updateFn(route, 'preferredReader')}
                  />
                </div>
              )}
              {isInProgress && (
                <div className='item-detail detail--no-icon' title='Last Reader'>
                  <span title={route.lastReader}>Last Reader: {route.lastReader ? route.lastReader : 'N/A'}</span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default RouteResult;
