import React from 'react';
import moment from 'moment';
import Icon from './Icon';
import ButtonGroup from './ButtonGroup';

const Day = ({ currentDate, date, startDate, endDate, selectedWeek, onClick }) => {
  let className = [];

  if (moment().isSame(date, 'day')) {
    className.push('today');
  }

  if (date.isSame(startDate, 'day')) {
    className.push('start');
  }

  if (date.isBetween(startDate, endDate, 'day')) {
    className.push('between');
  }

  if (date.isSame(endDate, 'day')) {
    className.push('end');
  }

  if (!date.isSame(currentDate, 'month')) {
    className.push('muted');
  }

  if (selectedWeek && date.isSame(currentDate, 'week')) {
    className.push('selectedWeek');

    if (moment(currentDate).startOf('week').isSame(date, 'day')) {
      className.push('selectedWeekStart');
    }

    if (moment(currentDate).endOf('week').isSame(date, 'day')) {
      className.push('selectedWeekEnd');
    }
  }

  return (
    <span onClick={() => onClick(date)} className={className.join(' ')}>
      {date.date()}
    </span>
  );
};

const Days = ({ date, startDate, endDate, selectedWeek, onClick }) => {
  const thisDate = moment(date);
  const daysInMonth = moment(date).daysInMonth();
  const firstDayDate = moment(date).startOf('month');
  const previousMonth = moment(date).subtract(1, 'month');
  const previousMonthDays = previousMonth.daysInMonth();
  const nextMonth = moment(date).add(1, 'month');
  let days = [];
  let labels = [];

  for (let i = 0; i < 7; i++) {
    labels.push(<span className='label'>{moment().day(i).format('ddd')}</span>);
  }

  for (let i = firstDayDate.day(); i > 0; i--) {
    previousMonth.date(previousMonthDays - i + 1);

    days.push(
      <Day
        key={moment(previousMonth).format('DD MM YYYY')}
        onClick={(date) => onClick(date)}
        currentDate={date}
        date={moment(previousMonth)}
        startDate={startDate}
        endDate={endDate}
        selectedWeek={selectedWeek}
      />
    );
  }

  for (let i = 1; i <= daysInMonth; i++) {
    thisDate.date(i);

    days.push(
      <Day
        key={moment(thisDate).format('DD MM YYYY')}
        onClick={(date) => onClick(date)}
        currentDate={date}
        date={moment(thisDate)}
        startDate={startDate}
        endDate={endDate}
        selectedWeek={selectedWeek}
      />
    );
  }

  const daysCount = days.length;
  for (let i = 1; i <= 42 - daysCount; i++) {
    nextMonth.date(i);
    days.push(
      <Day
        key={moment(nextMonth).format('DD MM YYYY')}
        onClick={(date) => onClick(date)}
        currentDate={date}
        date={moment(nextMonth)}
        startDate={startDate}
        endDate={endDate}
        selectedWeek={selectedWeek}
      />
    );
  }

  return <div className='calendar-dates'>{days.concat()}</div>;
};

class DatePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      date: props.selectedDate ? props.selectedDate : moment(),
      startDate: props.selectedDate ? props.selectedDate : null,
      endDate: props.selectedDate ? props.selectedDate : null,
    };
  }

  changeDate(date) {
    let { startDate, endDate } = this.state;

    if (!this.props.range) {
      startDate = moment(date);
      endDate = moment(date);
    } else if (startDate === null || date.isBefore(startDate, 'day') || !startDate.isSame(endDate, 'day')) {
      startDate = moment(date);
      endDate = moment(date);
    } else if (date.isSame(startDate, 'day') && date.isSame(endDate, 'day')) {
      startDate = null;
      endDate = null;
    } else if (date.isAfter(startDate, 'day')) {
      endDate = moment(date);
    }

    this.setState({
      startDate,
      endDate,
    });

    if (!this.props.range && startDate != null) {
      if (this.props.route) {
        var route = this.props.route;
        route.scheduledDate = startDate;
        this.props.updateRoute(route);
      } else {
        this.props.onClick(startDate);
      }

      if (this.props.toggleState) {
        this.props.toggleState();
      }
    }
  }

  changeMonth(month) {
    const { date } = this.state;

    date.month(month);

    this.setState(date);
  }

  handleClick = (e) => {
    const element = document.getElementById('date-picker-' + this.props.id);

    if (!element.contains(e.target) && !e.target.classList.contains('custom-select-option') && !e.target.classList.contains('selected-text')) {
      if (this.props.toggleState) {
        this.props.toggleState();
      }
    }
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.selectedDate !== this.props.selectedDate) {
      this.setState({
        date: this.props.selectedDate,
        startDate: this.props.selectedDate ? this.props.selectedDate : null,
        endDate: this.props.selectedDate ? this.props.selectedDate : null,
      });
    }
  }

  selectDateRange() {
    this.props.onClick(this.state.startDate, this.state.endDate);
  }

  render() {
    const { date, startDate, endDate } = this.state;
    const datePickerId = this.props.id;

    return (
      <div id={`date-picker-${datePickerId}`} className={`date-picker ${this.props.range ? 'date-picker--range' : ''}`}>
        <div className='header'>
          <a className='month-nav' onClick={() => this.changeMonth(date.month() - 1)}>
            <Icon name='chevron' />
          </a>
          <div>
            {date.format('MMMM')} {date.format('YYYY')}
          </div>
          <a className='month-nav' onClick={() => this.changeMonth(date.month() + 1)}>
            <Icon name='chevron' />
          </a>
        </div>
        <div className='calendar-days'>
          <div>Su</div>
          <div>Mo</div>
          <div>Tu</div>
          <div>We</div>
          <div>Th</div>
          <div>Fr</div>
          <div>Sa</div>
        </div>
        <Days onClick={(date) => this.changeDate(date)} date={date} startDate={startDate} endDate={endDate} selectedWeek={this.props.selectedWeek} />

        {this.props.range && (
          <div className='popover-footer'>
            <div />
            <ButtonGroup
              items={[
                { type: 'button', classes: 'button--plain', text: 'Cancel', onClick: () => this.props.toggleState() },
                { type: 'button', classes: 'button--blue', text: 'Select', onClick: () => this.selectDateRange() },
              ]}
            />
          </div>
        )}
      </div>
    );
  }
}

export default DatePicker;
