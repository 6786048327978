import React from 'react';
import * as XLSX from 'xlsx';
import _ from 'lodash';

class ExportUtils extends React.Component {
  static exportToCSV = (data, fields, fileName, separator) => {
    let csvData = [];
    let headers = _.keys(fields);

    separator = separator ? separator : ',';

    // The first row of the data is the headers
    csvData.push(headers.join(separator));

    data.forEach((result) => {
      let row = [];
      _.forEach(fields, (field) => {
        row.push('"' + result[field] + '"');
      });

      csvData.push(row.join(separator));
    });

    this.downloadFile(csvData.join('\n'), 'text/csv', fileName);
  };

  static downloadFile(fileData, fileType, fileName) {
    let file = new Blob([fileData], { type: fileType });
    let downloadLink = document.createElement('a');

    // File name
    downloadLink.download = fileName;

    // We have to create a link to the file
    downloadLink.href = window.URL.createObjectURL(file);

    // Make sure that the link is not displayed
    downloadLink.style.display = 'none';

    // Add the link to your DOM
    document.body.appendChild(downloadLink);

    downloadLink.click();
  }

  static exportToExcel(fileData, fileName) {
    const ws = XLSX.utils.json_to_sheet(fileData);

    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, 'output');
    XLSX.writeFile(wb, fileName);
  }
}
export default ExportUtils;
