import React from 'react';
import Icon from './Icon';
import DatePicker from './DatePicker';

class DatePickerSelect extends React.Component {
  constructor(props) {
    super(props);

    let selectedText = null;
    if (props.selection && props.selection.startDate) {
      selectedText = props.range ? props.selection.startDate.format('DD/MM/YY') + ' - ' + props.selection.endDate.format('DD/MM/YY') : props.selection.startDate.format('DD/MM/YY');
    }

    this.state = {
      showOptionList: false,
      defaultSelectText: selectedText,
    };
  }

  updateDateSelection(startDate, endDate) {
    if (!startDate && !endDate) {
      this.handleListDisplay();
      return;
    }

    const dateDisplay = this.props.range ? startDate.format('DD/MM/YY') + ' - ' + endDate.format('DD/MM/YY') : startDate.format('DD/MM/YY');

    this.setState({
      defaultSelectText: dateDisplay,
    });

    if (this.props.range) {
      this.handleListDisplay();
      this.props.onClick(startDate, endDate);
    } else {
      this.props.onClick(startDate);
    }
  }

  // This method handles the display of option list
  handleListDisplay = () => {
    this.setState((prevState) => {
      return {
        showOptionList: !prevState.showOptionList,
      };
    });
  };

  render() {
    const showOptionList = this.state.showOptionList;
    const defaultSelectText = this.props.displayLabel ? this.props.selectionText : this.state.defaultSelectText;
    const placeholder = this.props.displayLabel ? this.props.displayLabel : this.props.defaultText;

    return (
      <div className='custom-select-container date-picker-select'>
        <div className={showOptionList ? 'selected-text active' : 'selected-text'} onClick={this.handleListDisplay}>
          {(this.props.displayLabel || !defaultSelectText) && <span className='select-placeholder u--ellipsis'>{placeholder}</span>}
          {defaultSelectText && <span id={`default-select-text-${this.props.id}`}>{defaultSelectText}</span>}
          <Icon name='calendar' />
        </div>

        {showOptionList && (
          <div className={`button-popover date-picker-popover`}>
            <DatePicker
              id={this.props.route ? this.props.route.id : this.props.id}
              range={this.props.range}
              toggleState={() => this.handleListDisplay()}
              onClick={(startDate, endDate) => this.updateDateSelection(startDate, endDate)}
            />
          </div>
        )}
      </div>
    );
  }
}

export default DatePickerSelect;
