import React from 'react';
import moment from 'moment';
import Icon from '../Icon';
import MobileTimesheetEntry from './MobileTimesheetEntry';
import FloatingActionButton from '../FloatingActionButton';
import { TIMESHEET_STATUS } from '../../constants/TimesheetStatus';

class MobileTimesheet extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      entryType: null,
      selectedEntry: null,
      showReviewableTimesheetsList: false,
      showTimesheetStatusDetails: false,
      selectedTimesheetDetails: null,
      popupMessage: null,
      showHideAddNewTimeEntry: false,
      showHideAddNewAllowance: false,
      showHideSubmit: false,
    };
  }

  hideComponent(name, hidden) {
    switch (name) {
      case 'showHideAddNewTimeEntry':
        this.setState({ showHideAddNewTimeEntry: hidden });
        break;
      case 'showHideAddNewAllowance':
        this.setState({ showHideAddNewAllowance: hidden });
        break;
      case 'showHideSubmit':
        this.setState({ showHideSubmit: hidden });
        break;
      default:
        this.setState({ showHideAddNewTimeEntry: true });
        this.setState({ showHideAddNewAllowance: true });
        this.setState({ showHideSubmit: true });
        break;
    }
  }

  componentDidUpdate() {
    let timeblocksElem = document.getElementById('timesheet-week-timeblocks--mobile');

    if (timeblocksElem) {
      timeblocksElem.scrollTop = 420;
    }
  }

  selectEntry(type, entry) {
    this.setState({
      selectedEntry: entry,
      entryType: type,
    });

    this.props.data.clearTimesheetErrorInfo();
  }

  submitTimesheet() {
    let selectedDateTimesheet = this.props.data.selectedDateTimesheet;
    let isDraftTimesheet = selectedDateTimesheet && (selectedDateTimesheet.statusId === TIMESHEET_STATUS.DRAFT || selectedDateTimesheet.statusId === null);

    // Either submitting draft to pending approval, or accepting a returned timesheet
    let promise = isDraftTimesheet ? () => this.props.data.submitTimesheetFn() : () => this.props.data.updateTimesheetStatusFn(TIMESHEET_STATUS.APPROVED);

    promise().then((data) => {
      if (data === 'PASS') {
        this.setState({
          popupMessage: {
            text: `${isDraftTimesheet ? 'Submitted' : 'Accepted'} Timesheet for ${this.props.data.selectedDate.format('ddd, DD/MM')}!`,
          },
        });

        setTimeout(() => {
          this.setState({
            popupMessage: null,
          });
        }, 3000);
      } else {
        this.setState({
          popupMessage: {
            text: data,
            class: 'warning',
            button: {
              text: 'Close',
              onClick: () => this.setState({ popupMessage: null }),
            },
          },
        });
      }
    });
  }

  toggleDatePicker() {
    const elem = document.getElementById('date-input');
    elem.focus();
    elem.click();
  }

  handleDateChange = (e) => {
    this.props.data.changeDateFn(moment(e.target.value));
  };

  toggleReviewableTimesheetsList() {
    let showReviewableTimesheetsList = !this.state.showReviewableTimesheetsList;
    this.setState({
      showReviewableTimesheetsList: showReviewableTimesheetsList,
    });
  }

  selectReviewableTimesheet(timesheetDate) {
    this.props.data.changeDateFn(timesheetDate);
    this.toggleReviewableTimesheetsList();
  }

  toggleTimesheetStatusDetails(timesheet) {
    if (
      timesheet &&
      ![TIMESHEET_STATUS.RETURNED, TIMESHEET_STATUS.RETURNED_BY_PROVISIONAL_APPROVER, TIMESHEET_STATUS.REJECTED, TIMESHEET_STATUS.REJECTED_TO_PROVISIONAL_APPROVER].includes(timesheet.statusId)
    ) {
      return;
    }

    let showTimesheetStatusDetails = !this.state.showTimesheetStatusDetails;

    this.setState({
      showTimesheetStatusDetails: showTimesheetStatusDetails,
      selectedTimesheetDetails: timesheet ? timesheet : null,
    });
  }

  saveTimesheetEntry(operation, data) {
    this.props.data.saveFn(this.state.entryType, operation, data).then((data) => {
      if (data !== 'PASS') {
        this.setState({
          popupMessage: {
            text: data,
            class: 'warning',
            button: {
              text: 'Close',
              onClick: () => this.setState({ popupMessage: null }),
            },
          },
        });
      }

      this.setState({
        entryType: null,
      });
    });
  }

  getActionButtons() {
    let status = this.props.data.selectedDateTimesheet && this.props.data.selectedDateTimesheet.statusId;
    let buttons = [];

    if ([TIMESHEET_STATUS.RETURNED, TIMESHEET_STATUS.RETURNED_BY_PROVISIONAL_APPROVER].includes(status)) {
      buttons.push({
        text: `Accept Timesheet for ${this.props.data.selectedDate.format('ddd, DD/MM')}`,
        icon: 'full-check',
        onClick: () => this.submitTimesheet(),
      });
    } else if (status === TIMESHEET_STATUS.DRAFT || status === null) {
      buttons.push({
        text: `Submit Timesheet for ${this.props.data.selectedDate.format('ddd, DD/MM')}`,
        icon: 'full-check',
        onClick: () => this.submitTimesheet(),
      });
    }

    var today = moment();
    var yesterday = today.subtract(1, 'days');
    if (yesterday.isSameOrAfter(this.props.data.selectedDate)) {
      buttons.push({
        text: 'New Time Entry',
        icon: 'full-plus',
        onClick: () => this.selectEntry('timeEntry'),
      });
      buttons.push({
        text: 'New Allowance',
        icon: 'full-plus',
        onClick: () => this.selectEntry('allowance'),
      });
    }
    return buttons;
  }

  getNextDate() {
    const nextWeekDay = this.props.data.selectedDate.add(1, 'week');
    return nextWeekDay.isBefore(moment(), 'day') ? nextWeekDay : moment().subtract(1, 'days');
  }

  render() {
    let changeDate = this.props.data.changeDateFn;
    let selectedDate = this.props.data.selectedDate;
    let selectedDateTimesheet = this.props.data.selectedDateTimesheet;
    let timesheetWeek = this.props.data.timesheetWeek;

    let isPosted = selectedDateTimesheet && TIMESHEET_STATUS.POSTED === selectedDateTimesheet.statusId;
    let isPendingApproval = selectedDateTimesheet && selectedDateTimesheet.statusId === TIMESHEET_STATUS.PENDING_APPROVAL;
    let isAmended = selectedDateTimesheet && [TIMESHEET_STATUS.AMENDED, TIMESHEET_STATUS.AMENDED_BY_PROVISIONAL_APPROVER].includes(selectedDateTimesheet.statusId);
    let isApproved = selectedDateTimesheet && [TIMESHEET_STATUS.APPROVED, TIMESHEET_STATUS.PROVISIONALLY_APPROVED].includes(selectedDateTimesheet.statusId);

    let rejectedTimesheets = this.props.data.reviewableTimesheetData.rejectedTimesheets;
    let returnedTimesheets = this.props.data.reviewableTimesheetData.returnedTimesheets;

    return (
      <div className='mob-hide--mid mob-hide--large'>
        {!this.state.entryType && !this.state.showReviewableTimesheetsList && !this.state.showTimesheetStatusDetails && (
          <div>
            <header>
              <div className='nav-button--icon' onClick={this.props.data.backFn}>
                <Icon name='chevron' />
              </div>
              <h1>My Timesheet - {selectedDate.format('MMM YYYY')}</h1>
              <div className='nav-button--icon' onClick={() => this.toggleDatePicker()}>
                <Icon name='calendar' />
              </div>
            </header>
            {(returnedTimesheets.length > 0 || rejectedTimesheets.length > 0) && (
              <div className={'notification-bar notification--warning'}>
                <Icon name={'bell'} />
                Your timesheets have been returned or rejected.{' '}
                <span className={'f--link'} onClick={() => this.toggleReviewableTimesheetsList()}>
                  Click to Review
                </span>
              </div>
            )}
            <input id='date-input' type='date' onChange={this.handleDateChange} />
            <div className='content-container'>
              <div className='timesheet-week-summary--prev-button' onClick={() => changeDate(selectedDate.subtract(1, 'week'))}>
                <Icon name='chevron' />
              </div>
              <div className='timesheet-week-summary'>
                <div>
                  {timesheetWeek.map((day) => {
                    return (
                      <div
                        className={`${selectedDate.isSame(day.date, 'day') ? 'selected' : ''} ${moment().isSameOrBefore(day.date, 'day') ? 'disabled' : ''}`}
                        onClick={() => changeDate(day.date)}
                        key={moment(day.date).format('dddd')}>
                        <div className='timesheet-week-summary--day'>{moment(day.date).format('dd')}</div>
                        <div className='timesheet-week-summary--date'>{day.date.format('D')}</div>
                        <div className={`timesheet-week-summary--value ${day.totalHours || moment().isBefore(day.date) ? '' : 'warning'}`} onClick={() => this.toggleTimesheetStatusDetails(day)}>
                          {day.totalHours || moment().isBefore(day.date) ? day.totalHours : '—'}
                          <div className={`timesheet-week-summary--status ${day.status}`}>{day.status} </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              {timesheetWeek.length && moment().isAfter(timesheetWeek[timesheetWeek.length - 1].date) && (
                <div className='timesheet-week-summary--next-button' onClick={() => changeDate(this.getNextDate())}>
                  <Icon name='chevron' />
                </div>
              )}
              <section>
                <header>
                  <h4>Time Entries</h4>
                </header>
                <div id='timesheet-week-timeblocks--mobile' className='timesheet-week-timeblocks'>
                  <div className='timesheet-hourblocks'>
                    {this.props.data.hourBlocks.map((hour) => {
                      return (
                        <div className='hourblock' key={hour}>
                          <label>{hour}</label>
                          <div />
                        </div>
                      );
                    })}
                  </div>
                  {selectedDateTimesheet && (
                    <div className={`timesheet-day`}>
                      {selectedDateTimesheet.entries.map((timeEntry, index) => {
                        // Calculate time entry top positioning based on 6am to entry start time and height based on total minutes
                        let top = parseFloat(timeEntry.startTime.replace(':', '.')).toFixed(2).toString().replace('.', ':');
                        top = moment.duration(top).asMinutes();
                        let style = {
                          height: `${timeEntry.totalMinutes}px`,
                          top: `${top}px`,
                        };

                        return (
                          <div
                            className={`timesheet-entry ${timeEntry.category === 'Automatic Break' ? 'break' : ''}`}
                            style={style}
                            onClick={timeEntry.category === 'Productive (System)' ? () => {} : () => this.selectEntry('timeEntry', timeEntry)}
                            //  onClick={() => this.selectEntry('timeEntry', timeEntry)}
                            key={`${index}-${timeEntry.id}`}>
                            <div className='timesheet-entry--content'>
                              <label>{timeEntry.category}</label>
                              <Icon name='edit' />
                              <div className='timesheet-entry--detail'>
                                <div>
                                  {timeEntry.startTime} - {timeEntry.endTime}
                                </div>
                                <label>{timeEntry.totalHours}</label>
                              </div>
                              <div className='timesheet-entry--comment'>
                                <div>{timeEntry.comment}</div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </section>
              <section>
                <header>
                  <h4>Allowances</h4>
                </header>
                {selectedDateTimesheet && (
                  <div className='timesheet-week-allowances'>
                    {selectedDateTimesheet.allowances.map((allowance, index) => {
                      return (
                        <div className='section-row' key={index} onClick={() => this.selectEntry('allowance', allowance)}>
                          <div className='timesheet-entry--content'>
                            <div>
                              <h3>{allowance.category}</h3>
                              <h4>{allowance.comment}</h4>
                            </div>
                            <h3 className='t--align-right'>
                              {allowance.qty} {allowance.unit}
                            </h3>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </section>
              {!isPosted && !isPendingApproval && !isAmended && !isApproved && moment().isAfter(selectedDate, 'day') && <FloatingActionButton options={this.getActionButtons()} />}
            </div>
          </div>
        )}
        {this.state.entryType && (
          <div>
            <MobileTimesheetEntry
              data={{
                selectedEntry: this.state.selectedEntry,
                selectedDate: selectedDate,
                allowanceCategories: this.props.data.allowanceCategories,
                getCategoriesFn: (type) => this.props.data.getCategoriesFn(type),
                calculateTotalHoursFn: (startTime, endTime) => this.props.data.calculateTotalHoursFn(startTime, endTime),
              }}
              type={this.state.entryType}
              backFn={() => this.setState({ entryType: null })}
              onClick={(operation, data) => this.saveTimesheetEntry(operation, data)}
              timesheetErrorInfo={this.props.data.timesheetErrorInfo}
            />
          </div>
        )}
        {this.state.showReviewableTimesheetsList && (
          <div>
            <header>
              <div className='nav-button--icon' onClick={() => this.toggleReviewableTimesheetsList()}>
                <Icon name='chevron' />
              </div>
              <h1>My Timesheet</h1>
              <div className='nav-button--icon' />
            </header>
            <div>
              {rejectedTimesheets.length > 0 && (
                <section>
                  <header>
                    <h4>Rejected Timesheets</h4>
                  </header>
                  <div className='rejected-timesheets'>
                    <div className='notification-bar'>
                      <span>Your timesheets have been rejected for the following dates. Please review and speak to your supervisor.</span>
                    </div>
                    {rejectedTimesheets.map((rejectedTimesheetDate, index) => {
                      return (
                        <div className='section-row' key={index} onClick={() => this.selectReviewableTimesheet(moment(rejectedTimesheetDate))}>
                          <div className='timesheet-entry--content'>
                            <div>
                              <h3 className={'f--link'}>{moment(rejectedTimesheetDate).format('DD/MM/YY')}</h3>
                            </div>
                            <h3 className='t--align-right f--warning'>Rejected</h3>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </section>
              )}
              {returnedTimesheets.length > 0 && (
                <section>
                  <header>
                    <h4>Returned Timesheets</h4>
                  </header>
                  <div className='rejected-timesheets'>
                    <div className='notification-bar'>
                      <span>Your timesheets have been returned with amendments for the following dates. Please review and speak to your supervisor.</span>
                    </div>
                    {returnedTimesheets.map((returnedTimesheetDate, index) => {
                      return (
                        <div className='section-row' key={index} onClick={() => this.selectReviewableTimesheet(moment(returnedTimesheetDate))}>
                          <div className='timesheet-entry--content'>
                            <div>
                              <h3 className={'f--link'}>{moment(returnedTimesheetDate).format('DD/MM/YY')}</h3>
                            </div>
                            <h3 className='t--align-right f--notify'>Returned</h3>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </section>
              )}
            </div>
          </div>
        )}
        {this.state.showTimesheetStatusDetails && (
          <div>
            <header>
              <div className='nav-button--icon' onClick={() => this.toggleTimesheetStatusDetails()}>
                <Icon name='chevron' />
              </div>
              <h1>My Timesheet</h1>
              <div className='nav-button--icon' />
            </header>
            <section>
              <div className='timesheet-details'>
                <div className='section-row'>
                  <div className='timesheet-entry--content'>
                    <div>
                      <h3 className={'f--link'}>{this.state.selectedTimesheetDetails.date.format('DD/MM/YY')}</h3>
                      <h4>Reason: {this.state.selectedTimesheetDetails.rejectionReason}</h4>
                      <h4>Actioned By: {this.state.selectedTimesheetDetails.approverName}</h4>
                    </div>
                    <h3 className='t--align-right f--warning'>{this.state.selectedTimesheetDetails.status}</h3>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
        {this.state.popupMessage && this.state.popupMessage.text && (
          <div className={`popup-message-container`}>
            <div className={`popup-message-overlay`} />
            <div className={`popup-message-text ${this.state.popupMessage.class}`}>
              {this.state.popupMessage.text}
              {this.state.popupMessage.button && (
                <div className={`f--link`} onClick={() => this.state.popupMessage.button.onClick()}>
                  {this.state.popupMessage.button.text}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default MobileTimesheet;
