import React from 'react';
import Icon from './Icon';
import _ from 'lodash';
import Loader from 'react-loader-spinner';

class AssignReaderPopover extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      allReaders: [],
      isLoading: true,
      hoveredReader: null,
    };
  }

  getRecommendedReaders() {
    return _.filter(this.state.allReaders, { recommendedReader: 1 });
  }

  getOtherReaders(recommendedReaders) {
    return _.filter(this.state.allReaders, { recommendedReader: 0 });
  }

  updateFn(id) {
    let data = this.props.data;
    let readerId, reader;
    switch (this.props.type) {
      case 'assign-reader':
        readerId = id ? parseInt(id, 10) : null;
        reader = _.find(this.state.allReaders, { id: readerId });
        data.newReaderName = reader ? reader.name : null;
        data.newReaderId = readerId;
        break;
      case 'assign-preferred-reader':
        readerId = id ? parseInt(id, 10) : null;
        reader = _.find(this.state.allReaders, { id: readerId });
        data.preferredReaderName = reader ? reader.name : null;
        data.preferredReaderId = readerId;
        break;
      default:
        break;
    }
    this.props.updateFn(data);
    this.props.toggleState();
  }

  handleMouseover = (e) => {
    if (e.target.classList.contains('list-item')) {
      const readerId = parseInt(e.target.id, 10);
      const reader = _.find(this.state.allReaders, { id: readerId });

      this.setState({
        hoveredReader: reader,
      });
    }
  };

  handleClick = (e) => {
    const element = document.getElementById('route-' + this.props.data.id + '-readers');
    if (e.target.classList.contains('list-item')) {
      this.updateFn(e.target.id);
    } else if (!element.contains(e.target)) {
      this.props.toggleState();
    }
  };

  async componentDidMount() {
    const element = document.getElementById('route-' + this.props.data.id + '-readers');
    element.addEventListener('mouseover', this.handleMouseover);
    document.addEventListener('mousedown', this.handleClick);

    this.props.optionLoadFn().then((readerData) => {
      this.setState({
        allReaders: readerData,
        isLoading: false,
      });
    });
  }

  componentWillUnmount() {
    // Remove the event listener on component unmounting
    const element = document.getElementById('route-' + this.props.data.id + '-readers');
    element.removeEventListener('mouseover', this.handleMouseover);
    document.removeEventListener('mousedown', this.handleClick);
  }

  render() {
    const data = this.props.data;
    const isLoading = this.state.isLoading;
    const recommendedReaders = this.getRecommendedReaders();
    const otherReaders = this.getOtherReaders(recommendedReaders);
    const hoveredReader = this.state.hoveredReader;
    const isPreviousReader = hoveredReader && data.lastReaderId === hoveredReader.id;
    const isPreferredReader = hoveredReader && data.preferredReaderId === hoveredReader.id;

    return (
      <div id={'route-' + data.id + '-readers'} className='button-popover assign-reader-popover'>
        {isLoading && (
          <ul>
            <div className='empty-state'>
              <div className='spinner-container'>
                <Loader type='Bars' color='#008dce' height={20} width={20} visible={isLoading} />
              </div>
            </div>
          </ul>
        )}
        {!isLoading && (
          <ul>
            {data.readerId && (
              <li className='list-item remove-option' id={null}>
                <Icon name='close' />
                Remove Reader
              </li>
            )}
            <div className='list-heading'>Recommended</div>
            {recommendedReaders.map(function (reader) {
              return (
                <li className={`list-item u--ellipsis ${hoveredReader && hoveredReader.id === reader.id ? 'hovered' : ''}`} id={reader.id} key={reader.id} title={reader.name}>
                  {reader.name}
                </li>
              );
            })}
            <div className='list-heading'>Other</div>
            {otherReaders.map(function (reader) {
              return (
                <li className={`list-item u--ellipsis ${hoveredReader && hoveredReader.id === reader.id ? 'hovered' : ''}`} id={reader.id} title={reader.name} key={reader.id}>
                  {reader.name}
                </li>
              );
            })}
          </ul>
        )}
        {hoveredReader && (
          <div className='list-details'>
            <div className='heading'>{hoveredReader.name}</div>
            <div className='field-value-container'>
              <div className='field'>Equipment</div>
              <div className='value'>{hoveredReader.equipment.length > 0 ? hoveredReader.equipment.join(', ') : 'None'}</div>
            </div>
            {hoveredReader.workCapacity && (
              <div className='field-value-container'>
                <div className='field'>Work Capacity</div>
                <div className='value'>{hoveredReader.workCapacity}</div>
              </div>
            )}
            {hoveredReader.incompleteJobs && (
              <div className='field-value-container'>
                <div className='field'>Incomplete Jobs</div>
                <div className='value'>{hoveredReader.incompleteJobs}</div>
              </div>
            )}
            <div className='field-value-container'>
              <div className='field'>Ranking</div>
              <div className='value'>{hoveredReader.ranking}</div>
            </div>
            {isPreviousReader && !isPreferredReader && (
              <div className='note'>
                <Icon name='history' />
                <span>Previously completed this route</span>
              </div>
            )}
            {isPreferredReader && (
              <div className='note'>
                <Icon name='star-person' />
                <span>Preferred reader for this route</span>
              </div>
            )}
          </div>
        )}
        {!hoveredReader && (
          <div className='list-details'>
            <div className='note empty-state t--align-center'>Mouse over a reader to see details</div>
          </div>
        )}
      </div>
    );
  }
}

export default AssignReaderPopover;
