import React from 'react';
import ResultsList from './ResultsList';
import ButtonBar from './ButtonBar';
import Utils from '../utils/Utils';
import Loader from 'react-loader-spinner';

class ResultsContainer extends React.Component {
  componentDidUpdate() {
    this.setContainerBorder();
  }

  componentDidMount() {
    this.setContainerBorder();
  }

  setContainerBorder() {
    var listElem = document.querySelector(`#${this.props.dataClass}-content`);
    listElem = listElem ? listElem : document.querySelector('.results-list');

    var scrollableElem = document.querySelector(`#results-${this.props.dataClass}`);

    if (!listElem || !scrollableElem) {
      return;
    }

    var scrollHeight = scrollableElem.scrollHeight;

    if (listElem.scrollHeight > scrollHeight) {
      listElem.classList.add('border');
    } else {
      listElem.classList.remove('border');
    }
  }

  render() {
    const bulkSelecting = this.props.bulkSelecting;
    const isLoading = this.props.isLoading;

    let data = { ...this.props.data };
    data.resultData = this.props.selectedGroup ? this.props.data.resultData[this.props.selectedGroup] : this.props.data.resultData;

    let dataName = `${this.props.dataClass}s`;

    if (this.props.selectedGroup) {
      dataName = `${this.props.selectedGroup}${this.props.dataClass ? ' ' + this.props.dataClass : ''}s`;

      if (!this.props.dataClass) {
        dataName = `${this.props.selectedGroup}`;
      }
    }

    const dataNameHumanised = Utils.humanize(dataName);

    return (
      <div id='results-container' className={`${bulkSelecting ? 'bulk-selecting' : ''}`}>
        {this.props.buttonData && <ButtonBar bulkSelecting={bulkSelecting} buttonData={this.props.buttonData} />}
        {!isLoading && data.resultData.length > 0 && (
          <div className='scrollable-container' id={`results-${this.props.dataClass}`}>
            <ResultsList dataName={dataName} data={data} isLoading={this.props.isLoading} group={this.props.selectedGroup ? this.props.selectedGroup : this.props.dataClass} />
          </div>
        )}
        {!isLoading && data.resultData.length > 0 && data.sidebar && <div className='results-list-sidebar'>{data.sidebar}</div>}
        {!isLoading && !data.resultData.length && <div className='empty-state'>{this.props.emptyStateText ? this.props.emptyStateText : `There are no ${dataNameHumanised}.`}</div>}
        {isLoading && (
          <div className='spinner-container'>
            <Loader type='Bars' color='#008dce' height={30} width={30} visible={isLoading} />
          </div>
        )}
      </div>
    );
  }
}

export default ResultsContainer;
