import React from 'react';
import logo from '../../images/skilltech-mimtr-logo-dark.png';
import uat_logo from '../../images/skilltech-mimtr-logo-uat-dark.png';
import TabContainer from '../TabContainer';
import ResultsContainer from '../ResultsContainer';
import wave from '../../images/wave.svg';
import _ from 'lodash';
import moment from 'moment';
import ExportRouteResult from './ExportRouteResult';
import ToggleButton from '../ToggleButton';
import Button from '../Button';
import Icon from '../Icon';
import './export-page.scss';
import CustomSelect from '../CustomSelect';
import Api from '../../api/Api';
import AddNewResult from '../AddNewResult';

class Export extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      masterData: {},
      exportRouteData: {},
      configData: {},
      buttonData: [],
      selectedGroup: 'routesReady',
      exportProcess: 'manual',
      bulkSelecting: true,
      dateFrom: moment(),
      dateTo: moment(),
      isLoading: true,
      showSuccess: false,
    };
  }

  async componentDidMount() {
    document.title = `${process.env.REACT_APP_ENV ? `[${process.env.REACT_APP_ENV}] ` : ''}MiMtr Hybrid | Export Routes`;
    this.getData();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.selectedContract.id !== this.props.selectedContract.id) {
      this.setState({
        selectedGroup: 'routesReady',
      });
      this.getData();
    }
  }

  async getData() {
    this.setState({ isLoading: true });
    await this.getExportReadyRoutes().then((exportReadyData) => {
      this.getExportedRoutes().then((exportedData) => {
        Api.getCodesets(this.props.selectedContract, 'skip').then((skipCodeData) => {
          this.getExportConfigurations(this.props.selectedContract, skipCodeData).then((configData) => {
            this.setState({
              masterData: {
                routesReady: exportReadyData,
                routesExported: exportedData,
              },
              exportRouteData: {
                routesReady: exportReadyData,
                routesExported: exportedData,
              },
              configData: configData,
              skipCodeData: skipCodeData,
              buttonData: this.getButtonData(),
              isLoading: false,
            });
          });
        });
      });
    });
  }

  async getExportReadyRoutes(selectedContract) {
    return new Promise((resolve, reject) => {
      selectedContract = selectedContract ? selectedContract : this.props.selectedContract;
      let contractId = selectedContract ? selectedContract.id : 0;

      fetch(`${process.env.REACT_APP_API_ENDPOINT}/getExportRoutes?DatabaseServer=${selectedContract.dbServer}&contractId=${contractId}`)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
            reject();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
            return res.json();
          }
        })
        .then((data) => {
          let exportRouteData = data.results.map((route) => {
            return {
              id: route.ClientRouteId,
              name: route.RouteName,
              routeNo: route.ClientRouteNumber,
              meters: route.meters,
              read: route.read,
              oot: route.OOT,
              skipped: route.skipped,
              customers: route.customers,
            };
          });

          resolve(exportRouteData);
        })
        .catch(() => {});
    });
  }

  async getExportedRoutes(selectedContract) {
    return new Promise((resolve) => {
      let dateFrom = this.state.dateFrom.format('YYYY-MM-DD');
      let dateTo = this.state.dateTo.format('YYYY-MM-DD');

      selectedContract = selectedContract ? selectedContract : this.props.selectedContract;
      let contractId = selectedContract ? selectedContract.id : 0;

      fetch(`${process.env.REACT_APP_API_ENDPOINT}/getExportRoutes?DatabaseServer=${selectedContract.dbServer}&contractId=${contractId}&exported=1&dateFrom=${dateFrom}&dateTo=${dateTo}`)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
            return res.json();
          }
        })
        .then((data) => {
          let exportRouteData = data.results.map((route) => {
            return {
              id: route.ClientRouteId,
              name: route.RouteName,
              routeNo: route.ClientRouteNumber,
              meters: route.meters,
              read: route.read,
              oot: route.OOT,
              skipped: route.skipped,
              customers: route.customers,
              fileName: route.ExportedFileName,
              exportedDate: moment(route.DateTimeExportedUtc),
            };
          });

          resolve(exportRouteData);
        })
        .catch(() => {});
    });
  }

  async getExportConfigurations(selectedContract, skipCodeData) {
    return new Promise((resolve) => {
      selectedContract = selectedContract ? selectedContract : this.props.selectedContract;
      let contractId = selectedContract ? selectedContract.id : 0;

      fetch(`${process.env.REACT_APP_API_ENDPOINT}/getExportConfig?contractId=${contractId}`)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
            return res.json();
          }
        })
        .then((data) => {
          let skipCode = skipCodeData.find((skipCode) => skipCode.id === parseInt(data.results[0][0].MTMSkipCode));

          let configData = {
            exportProcess: data.results[0][0].AutoExport ? 'automatic' : 'manual',
            partialExport: data.results[0][0].PartialExport ? (data.results[0][0].PartialExport === 1 ? 'cumulative' : 'unique') : 'off',
            exportFolder: data.results[0][0].ExportFolder,
            exportFileType: data.results[0][0].ExportFileExt,
            mtmDefaultMessage: data.results[0][0].MTMDefaultMessage,
            mtmDefaultMeterReaderId: data.results[0][0].MTMDefaultMRID,
            mtmDefaultSkipCode: data.results[0][0].MTMSkipCode,
            mtmDefaultSkipCodeName: skipCode.name,
            mtmInExport: data.results[0][0].MTMRecordInExport,
            mtcInExport: data.results[0][0].MTCRecordInExport,
            autoExportSchedule: data.results[1].map((data) => {
              return { id: data.ScheduleId, contractId: data.ContractId, startTime: moment(data.StartTime).utc().format('HH:mm') };
            }),
          };

          resolve(configData);
        })
        .catch(() => {});
    });
  }

  getButtonData() {
    return [
      {
        type: 'bulk-action-button',
        classes: 'button--blue',
        text: 'Export Selected',
        icon: 'checkbox',
        iconPosition: '1',
        count: 0,
        selectAll: () => this.selectRoute(null, true),
        onClick: () => this.bulkExportRoutes(),
      },
      {
        type: 'group',
        items: [
          {
            type: 'toggle-button',
            name: 'exportProcess',
            selection: this.state.exportProcess,
            options: [
              { name: 'Manual', value: 'manual' },
              { name: 'Automatic', value: 'automatic' },
            ],
            showCondition: () => {
              return this.state.selectedGroup === 'routesReady';
            },
            onClick: (value) => this.updateToggle('exportProcess', value),
          },
          {
            type: 'date-picker-select',
            name: 'dateRange',
            displayLabel: 'Date Range',
            range: true,
            selection: { start: this.state.dateFrom, end: this.state.dateTo },
            showCondition: () => {
              return this.state.selectedGroup === 'routesExported';
            },
            updateFn: (name, dateRange) => this.changeDate(dateRange),
          },
          {
            type: 'sort-button',
            classes: 'button--active',
            text: 'Sort By',
            icon: 'sort',
            iconPosition: '2',
            popover: {
              type: 'select',
              onClick: (key) => this.sortResults(key),
              options: [
                { name: 'Route No.', value: 'routeNo' },
                { name: 'Route Name', value: 'routeName' },
              ],
            },
          },
          { type: 'search-input', onChange: (key) => this.searchResults(key) },
        ],
      },
    ];
  }

  changeDate(dateRange) {
    this.setState(
      {
        dateTo: dateRange.end,
        dateFrom: dateRange.start,
        isLoading: true,
      },
      () => {
        this.getExportedRoutes().then((exportedData) => {
          let masterData = this.state.masterData;
          masterData.routesExported = exportedData;

          let exportRouteData = this.state.exportRouteData;
          exportRouteData.routesExported = exportedData;

          this.setState({
            masterData: masterData,
            exportRouteData: exportRouteData,
            buttonData: this.getButtonData(),
            isLoading: false,
          });

          let buttonData = this.getButtonData();
          const buttonGroup = buttonData.find((button) => button.type === 'group');
          const dateRangeButton = buttonGroup.items.find((button) => button.name === 'dateRange');
          dateRangeButton.selection = { start: dateRange.start, end: dateRange.end };
        });
      }
    );
  }

  selectStatusGroup(tabName) {
    this.setState({
      selectedGroup: tabName,
    });
  }

  selectRoute(id, toggleAll) {
    const selectedGroup = this.state.selectedGroup;
    let selectAll = this.state.selectAll;
    // If toggling all, switch according to the selectAll state
    if (toggleAll) {
      selectAll = !selectAll;
    }

    const visibleRoutes = this.state.exportRouteData[selectedGroup];

    // Check all visible routes for a match, and toggle select
    visibleRoutes.forEach((item) => {
      if (toggleAll) {
        item.selected = selectAll;
      } else if (item.id === id) {
        item.selected = !item.selected;
      }
    });

    // Add to the bulk select count
    const count = _.filter(visibleRoutes, { selected: true }).length;
    const bulkButton = _.find(this.state.buttonData, { type: 'bulk-action-button' });
    bulkButton.count = count;

    this.setState({
      selectAll: selectAll,
    });
  }

  bulkExportRoutes() {}

  searchResults(searchTerm) {
    let data = _.cloneDeep(this.state.masterData);

    if (searchTerm) {
      _.forEach(data, (group, key) => {
        data[key] = data[key].filter((result) => {
          return result.routeNo.toLowerCase().includes(searchTerm.toLowerCase()) || result.name.toLowerCase().includes(searchTerm.toLowerCase());
        });
      });
    }

    this.setState({
      exportRouteData: data,
    });
  }

  getResultItems() {
    let resultData = {};

    _.forEach(this.state.exportRouteData, (group, key) => {
      if (key) {
        resultData[key] = group.map((route, index) => {
          route.status = key;
          return (
            <ExportRouteResult data={route} bulkSelecting={this.state.bulkSelecting} selectFn={(id) => this.selectRoute(id)} updateFn={(field, route) => this.updateRoute(field, route)} key={index} />
          );
        });
      }
    });

    return resultData;
  }

  updateField(field, value) {
    let configData = this.state.configData;

    switch (field) {
      case 'mtmSkipCode':
        configData.mtmSkipCode = value.id;
        configData.mtmSkipCodeName = value.name;
        break;

      default:
        configData[field] = value;
    }

    this.setState({
      configData: configData,
    });
  }

  getAutoExportScheduleList() {
    if (!this.state.configData.autoExportSchedule) {
      return [];
    }

    return this.state.configData.autoExportSchedule.map((data) => {
      return (
        <div className={'result-item'} key={`autoExportSchedule-${data.id}}`}>
          <div id={`autoExportSchedule-${data.id}`} className={`item-card`}>
            <div className='item-row'>
              <div className='item-detail' title={data.startTime}>
                <span>{data.startTime}</span>
              </div>
              <div className='item-detail' onClick={() => this.updateAutoExportSchedule(data, 'delete')}>
                <Icon name='trash' />
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  updateAutoExportSchedule(updatedSchedule, action) {
    return new Promise((resolve, reject) => {
      let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/updateAutoExportSchedule`;
      let body = {
        contractId: this.props.selectedContract.id,
        startTime: updatedSchedule.startTime,
        action: action[0].toUpperCase(),
      };

      if (updatedSchedule.id) {
        body.scheduleId = updatedSchedule.id;
      }

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      };

      fetch(apiUrl, requestOptions)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
            return res.json();
          }
        })
        .then((data) => {
          let configData = this.state.configData;

          if (action === 'add') {
            configData.autoExportSchedule.push({ id: data.results[0].scheduleId, startTime: updatedSchedule.startTime });
          } else if (action === 'delete') {
            configData.autoExportSchedule = configData.autoExportSchedule.filter((schedule) => schedule.id !== data.results[0].scheduleId);
          }

          configData.autoExportSchedule = _.sortBy(configData.autoExportSchedule, 'startTime');

          this.setState({
            configData: configData,
          });
        });
    });
  }

  async updateConfigurations() {
    let body = _.cloneDeep(this.state.configData);
    body.contractId = this.props.selectedContract.id;
    body.exportProcess = body.exportProcess !== 'manual';
    body.partialExport = body.exportProcess === 'off' ? 0 : body.exportProcess === 'cumulative' ? 1 : 2;

    return new Promise((resolve, reject) => {
      let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/updateExportConfig`;
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      };

      fetch(apiUrl, requestOptions).then((res) => {
        if (res.status === 401) {
          this.props.logoutFn();
        } else {
          fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
        }

        this.setState(
          {
            showSuccess: true,
          },
          () => {
            setTimeout(() => {
              this.setState({
                showSuccess: false,
              });
            }, 3000);
          }
        );
      });
    });
  }

  render() {
    const data = { resultData: this.getResultItems() };

    let tabGroup = ['routesReady', 'routesExported'].map((groupName) => {
      return {
        name: groupName,
        count: data.resultData[groupName] ? data.resultData[groupName].length : 0,
      };
    });

    const groups = [tabGroup, [{ name: 'exportConfigurations' }]];

    return (
      <section className='main-content export-page'>
        <header>
          <h1>Export Routes</h1>
          <img className='logo-container' src={process.env.REACT_APP_ENV ? uat_logo : logo} alt='' />
        </header>
        <TabContainer id={'export'} groups={groups} selectedTab={this.state.selectedGroup} onClick={(name) => this.selectStatusGroup(name)} />
        {this.state.selectedGroup !== 'exportConfigurations' && (
          <ResultsContainer data={data} buttonData={this.state.buttonData} selectedGroup={this.state.selectedGroup} dataClass={''} isLoading={this.state.isLoading} bulkSelecting={true} />
        )}
        {this.state.selectedGroup === 'exportConfigurations' && (
          <div className='scrollable-container'>
            <div className='form-container'>
              <section>
                <div>
                  <div className='form-element'>
                    <label>Export Process</label>
                    <div className='description'>Manually export routes or run export on scheduled times</div>
                    <ToggleButton
                      options={[
                        { name: 'Manual', value: 'manual' },
                        { name: 'Automatic', value: 'automatic' },
                      ]}
                      selection={this.state.configData.exportProcess}
                    />
                  </div>
                  <div className='form-element'>
                    <label>Partial Export</label>
                    <div className='description'>Allow exports of routes partially completed.</div>
                    <ToggleButton
                      options={[
                        { name: 'Off', value: 'off' },
                        { name: 'Unique', value: 'unique' },
                        { name: 'Cumulative', value: 'cumulative' },
                      ]}
                      selection={this.state.configData.partialExport}
                    />
                  </div>
                </div>
                <div>
                  <div className='form-element'>
                    <label>Auto Export Schedule</label>
                    <div className='description'>Add or remove scheduled times for auto export. Times are in 24 hour format.</div>
                    <ResultsContainer data={{ resultData: this.getAutoExportScheduleList() }} dataClass={'autoExportSchedule'} />
                    <AddNewResult
                      id='autoExportSchedule'
                      items={[{ id: 'autoExportSchedule', type: 'time' }]}
                      updateFn={(data) => this.updateAutoExportSchedule({ startTime: data.autoExportSchedule }, 'add')}
                    />
                  </div>
                </div>
              </section>
              <section>
                <div>
                  <div className='form-element'>
                    <label>Export File Destination</label>
                    <div className='description'>Specify a path to a folder where exported files will be saved.</div>
                    <div className='input-container'>
                      <input
                        id={`input-file-destination`}
                        className='search-bar'
                        type='text'
                        placeholder='Browse'
                        autoComplete='off'
                        value={this.state.configData.exportFolder}
                        onChange={(e) => this.updateField('exportFolder', e.target.value)}
                      />
                      <Icon name='magnifying-glass' />
                    </div>
                  </div>
                  <div className='form-element'>
                    <label>Export File Type</label>
                    <div className='description'>Specify a filename extension for the export file.</div>
                    <input
                      id={`input-file-type`}
                      className='search-bar'
                      type='text'
                      placeholder="eg. 'txt', 'dat'"
                      autoComplete='off'
                      value={this.state.configData.exportFileType}
                      onChange={(e) => this.updateField('exportFileType', e.target.value)}
                    />
                  </div>
                </div>
                <div>
                  <div className='form-element'>
                    <label>Export Filename Template</label>
                    <div className='description'>Specify a format for naming exported files.</div>
                    <div className='input-container'>
                      <input
                        id={`input-filename`}
                        className='search-bar'
                        type='text'
                        placeholder='contract-dd/mm/yy'
                        autoComplete='off'
                        value={this.state.configData.filename}
                        onChange={(e) => this.updateField('filename', e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </section>
              <section>
                <div>
                  <div className='form-element'>
                    <label>MTM Record In Export</label>
                    <div className='description'>Include Meter Trouble Message record in export file.</div>
                    <ToggleButton
                      classes={'button--small'}
                      options={[
                        { name: 'Yes', value: true },
                        { name: 'No', value: false },
                      ]}
                      selection={this.state.configData.mtmInExport}
                    />
                  </div>
                  <div className='form-element'>
                    <label>Default MTM</label>
                    <div className='description'>Specify the default Meter Trouble Message.</div>
                    <input
                      id={`input-mtm-default`}
                      className='search-bar'
                      type='text'
                      placeholder='contract-dd/mm/yy'
                      autoComplete='off'
                      value={this.state.configData.mtmDefaultMessage}
                      onChange={(e) => this.updateField('mtmDefaultMessage', e.target.value)}
                    />
                  </div>
                </div>
                <div>
                  <div className='form-element'>
                    <label>MTM Default MRID</label>
                    <div className='description'>Specify the default Meter Reader ID.</div>
                    <input
                      id={`input-mtm-mrid`}
                      className='search-bar'
                      type='text'
                      placeholder='contract-dd/mm/yy'
                      autoComplete='off'
                      value={this.state.configData.mtmDefaultMeterReaderId}
                      onChange={(e) => this.updateField('mtmDefaultMeterReaderId', e.target.value)}
                    />
                  </div>
                  <div className='form-element'>
                    <label>MTM Default Skip Code</label>
                    <div className='description'>Specify the default Skip Code.</div>
                    <CustomSelect
                      id={'mtm-skip-code'}
                      defaultText={this.state.configData.mtmDefaultSkipCodeName}
                      optionsList={this.state.skipCodeData}
                      onClick={(option) => this.updateField('mtmDefaultSkipCode', option)}
                    />
                  </div>
                </div>
              </section>
              <section>
                <div>
                  <div className='form-element'>
                    <label>MTC Record In Export</label>
                    <div className='description'>Include Meter Change record in export file.</div>
                    <ToggleButton
                      classes={'button--small'}
                      options={[
                        { name: 'Yes', value: true },
                        { name: 'No', value: false },
                      ]}
                      selection={this.state.configData.mtcInExport}
                    />
                  </div>
                </div>
              </section>
            </div>
            <div className='button-group'>
              <div className={`success-message ${this.state.showSuccess ? 'a--fade-out' : 'a--fade-in'}`}>Changes saved.</div>
              <Button classes='button button--blue' icon={'check'} iconPosition={'1'} text='Save Changes' onClick={() => this.updateConfigurations()} />
            </div>
          </div>
        )}
        <img className='wave' src={wave} alt={''} />
      </section>
    );
  }
}

export default Export;
