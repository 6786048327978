import React from 'react';
import EmployeeTimesheet from './EmployeeTimesheet';
import { Route, Switch, withRouter } from 'react-router-dom';
import './timesheet-page.scss';
import ManageTimesheets from './ManageTimesheets';

class Timesheet extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedEmployee: {},
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let checkTokenApiUrl = `${process.env.REACT_APP_API_ENDPOINT}/check`;
    fetch(checkTokenApiUrl).then((res) => {
      if (res.status === 401) {
        this.props.logoutFn();
      }
    });

    if (prevProps.selectedContract !== this.props.selectedContract) {
      if (!this.props.userData.securityRoles.canManageTimesheets) {
        this.props.history.push('/hybrid/');
      }
    }
  }

  selectEmployeeTimesheet(employee, date) {
    localStorage.setItem('selectedEmployeeTimesheet', JSON.stringify({ id: employee.id, name: employee.name, date: date }));
    window.open('/hybrid/timesheet/employee', 'EmployeeTimesheetTab');
  }

  render() {
    return (
      <Switch>
        <Route path='/hybrid/timesheet/employee'>
          <EmployeeTimesheet userData={this.props.userData} selectedContract={this.props.selectedContract} logoutFn={() => this.props.logoutFn()} />
        </Route>

        {this.props.userData.securityRoles.canManageTimesheets && (
          <Route path='/hybrid/timesheet/manage'>
            <ManageTimesheets
              userData={this.props.userData}
              selectedContract={this.props.selectedContract}
              selectEmployeeTimesheetFn={(employee, date) => this.selectEmployeeTimesheet(employee, date)}
              logoutFn={() => this.props.logoutFn()}
            />
          </Route>
        )}
      </Switch>
    );
  }
}

export default withRouter(Timesheet);
