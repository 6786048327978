import React from 'react';
import Button from '../Button';
import _ from 'lodash';
import Loader from 'react-loader-spinner';
import { TIMESHEET_STATUS } from '../../constants/TimesheetStatus';

class TimesheetApproverDetailsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rejectionReasonProvided: false,
      rejectionReason: props.rejectionReason,
    };
  }

  handleReasonChange(e) {
    this.setState({
      rejectionReasonProvided: e.target.value.length > 1,
      rejectionReason: e.target.value,
    });
  }

  validateForm() {
    let isValid = true;
    let requiredInputs = document.getElementsByClassName('form-container')[0].querySelectorAll('[required]');
    let requiredElements = document.getElementsByClassName('selected-text required');

    requiredInputs.forEach((element) => {
      if (_.isEmpty(element.value)) {
        element.classList.add('input--invalid');
        isValid = false;
      } else {
        element.classList.remove('input--invalid');
      }
    });

    _.forEach(requiredElements, (element) => {
      if (_.isEmpty(element.getElementsByTagName('input')[0].value)) {
        element.classList.add('input--invalid');
        isValid = false;
      }
    });

    return isValid;
  }

  submitForm() {
    if (!this.validateForm()) {
      return;
    }

    let timesheet = this.props.data.timesheet;
    timesheet.rejectionReason = this.state.rejectionReason;

    this.props.onClick(this.props.data.newStatusId, timesheet);
  }

  render() {
    let timesheet = this.props.data.timesheet;
    let newStatusId = this.props.data.newStatusId;

    let isRejected = [TIMESHEET_STATUS.REJECTED, TIMESHEET_STATUS.REJECTED_TO_PROVISIONAL_APPROVER].includes(timesheet.statusId);
    let isReturned = [TIMESHEET_STATUS.RETURNED, TIMESHEET_STATUS.RETURNED_BY_PROVISIONAL_APPROVER].includes(timesheet.statusId);
    let isBeingRejected = [TIMESHEET_STATUS.REJECTED, TIMESHEET_STATUS.REJECTED_TO_PROVISIONAL_APPROVER].includes(newStatusId);
    let isBeingReturned = [TIMESHEET_STATUS.RETURNED, TIMESHEET_STATUS.RETURNED_BY_PROVISIONAL_APPROVER].includes(newStatusId);
    let isBeingProvisionallyRejected = TIMESHEET_STATUS.REJECTED_TO_PROVISIONAL_APPROVER === newStatusId;
    let isBeingProvisionallyReturned = TIMESHEET_STATUS.RETURNED_BY_PROVISIONAL_APPROVER === newStatusId;

    return (
      <div id={'timesheet-approver-details-modal'} className='modal-content'>
        <div className='heading'>
          <div>{timesheet.date ? `Timesheet for ${timesheet.date.format('MMM DD, YYYY')}` : `Bulk Update Timesheets`}</div>
          {!(isBeingRejected || isBeingReturned) && <h3 className={timesheet.status}>{timesheet.status}</h3>}
          {isBeingRejected && <h3 className={'rejected'}>{isBeingProvisionallyRejected ? 'Provisionally Rejecting' : 'Rejecting'}</h3>}
          {isBeingReturned && <h3 className={'returned'}>{isBeingProvisionallyReturned ? 'Provisionally Returning' : 'Returning'}</h3>}
        </div>
        {!this.state.isLoading && (
          <div>
            <div className='form-container'>
              {timesheet.approverName && (
                <div className='form-row'>
                  <div className='form-element'>
                    <label>Approver:</label>
                    <div className='read-only'>{timesheet.approverName}</div>
                  </div>
                </div>
              )}
              {(isRejected || isReturned || isBeingRejected || isBeingReturned) && (
                <div className='form-row'>
                  <div className='form-element'>
                    <label>Reason for {isRejected || isBeingRejected ? 'Rejection' : 'Amendment'}:</label>
                    {(isRejected || isReturned) && <div className='read-only'>{timesheet.rejectionReason}</div>}
                    {(isBeingRejected || isBeingReturned) && (
                      <textarea name={'reason'} required={true} value={this.state.rejectionReason} onChange={(e) => this.handleReasonChange(e)} maxLength={100} />
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className='button-group t--align-right'>
              {(isBeingRejected || isBeingReturned) && <Button classes={`button button--plain`} onClick={() => this.props.showModalFn(false)} text='Cancel' />}
              {isBeingRejected && <Button classes='button button--med button--orange' onClick={() => this.submitForm()} text='Reject' />}
              {isBeingReturned && <Button classes='button button--med button--orange' onClick={() => this.submitForm()} text='Return' />}
            </div>
          </div>
        )}
        {this.state.isLoading && (
          <div className={'spinner-container'}>
            <Loader type='Bars' color='#008dce' height={30} width={30} visible={this.state.isLoading} />
          </div>
        )}
      </div>
    );
  }
}

export default TimesheetApproverDetailsModal;
