import React from 'react';
import Button from '../Button';
import './route-templates-page.scss';
import CustomSelect from '../CustomSelect';
import AssignReaderPopover from '../AssignReaderPopover';
import Icon from '../Icon';
import Utils from '../../utils/Utils';
import _ from 'lodash';
import ResultsContainer from '../ResultsContainer';
import AddNewResult from '../AddNewResult';
import Api from '../../api/Api';

class RouteTemplateModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      masterData: { ...props.data },
      showEditState: false,
      isLoading: true,
      isSaving: false,
    };
  }

  async componentDidMount() {
    let routeTemplateData = _.cloneDeep(this.props.data);
    let readerData = [...this.props.options.readerOptions];
    let prevReader = readerData.find((reader) => reader.id === routeTemplateData.lastReaderId);

    if (!prevReader) {
      prevReader =
        routeTemplateData.lastReaderId && routeTemplateData.lastReaderName
          ? {
              id: routeTemplateData.lastReaderId,
              name: Utils.titleize(routeTemplateData.lastReaderName.replace('.', ' ')),
              recommendedReader: 1,
              equipment: [],
            }
          : null;

      readerData.push(prevReader);
    }

    await Api.getReaderEquipment().then((equipmentData) => {
      routeTemplateData.requirements = routeTemplateData.requirements.map((requirement) => {
        return equipmentData.find((equipment) => requirement.split(' ')[0] === equipment.name);
      });

      this.setState({
        readerData: readerData,
        equipmentData: equipmentData,
        routeTemplateData: routeTemplateData,
        isLoading: false,
      });
    });
  }

  updateField(field, value) {
    if (field === 'routeNumber') {
      value = value.replace('.', '');
    }

    let routeTemplateData = this.state.routeTemplateData;
    routeTemplateData[field] = value;

    this.setState({
      routeTemplateData: routeTemplateData,
    });
  }

  toggleEditState() {
    const show = !this.state.showEditState;

    this.setState({
      showEditState: show,
    });
  }

  getReaderOptions() {
    return new Promise((resolve) => {
      resolve(this.state.readerData);
    });
  }

  async getNewRequirements(type) {
    return new Promise((resolve, reject) => {
      let data = _.uniqBy(this.state.equipmentData, 'id').filter((result) => {
        return type === 'requirements' ? !this.state.routeTemplateData.requirements.map((data) => data.id).includes(result.id) : result;
      });
      resolve(data);
    });
  }

  renderRequirementList() {
    if (!this.state.routeTemplateData.requirements) {
      return [];
    }

    return {
      resultData: this.state.routeTemplateData.requirements.map((data) => {
        return (
          <div className={'result-item'} key={`requirement-${data.id}`}>
            <div id={`requirement-` + data.id} className={`item-card`}>
              <div className='item-row'>
                <div className='item-detail' title={data.name}>
                  <span>{data.name}</span>
                </div>
                <div className='item-detail' onClick={() => this.updateRequirementList(data, true)}>
                  <Icon name='trash' />
                </div>
              </div>
            </div>
          </div>
        );
      }),
    };
  }

  updateRequirementList(data, remove) {
    let routeTemplate = this.state.routeTemplateData;

    if (remove) {
      routeTemplate.requirements = routeTemplate.requirements.filter((requirement) => requirement.id !== data.id);
    } else {
      routeTemplate.requirements.push(data);
    }

    this.setState({
      routeTemplateData: routeTemplate,
    });
  }

  validateForm() {
    let isValid = true;
    let requiredInputs = document.getElementsByClassName('form-container')[0].querySelectorAll('[required]');
    let requiredElements = document.getElementsByClassName('selected-text required');

    requiredInputs.forEach((element) => {
      if (_.isEmpty(element.value)) {
        element.classList.add('input--invalid');
        isValid = false;
      } else {
        element.classList.remove('input--invalid');
      }
    });

    _.forEach(requiredElements, (element) => {
      if (_.isEmpty(element.getElementsByTagName('input')[0].value)) {
        element.classList.add('input--invalid');
        isValid = false;
      }
    });

    return isValid;
  }

  updateRouteTemplate() {
    if (!this.validateForm()) {
      return;
    }

    let body = this.state.routeTemplateData;

    this.setState({
      isSaving: true,
      routeNumberWarning: null,
    });

    return new Promise((resolve, reject) => {
      let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/updateRouteTemplate`;
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      };

      fetch(apiUrl, requestOptions)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
            return res.json();
          }
        })
        .then((data) => {
          if (data.output.RETURN === 'PASS') {
            this.setState({
              isSaving: false,
            });

            this.updateRouteTemplateRequirements().then(() => {
              this.props.updateFn();
            });
          } else {
            let routeNumberElement = document.getElementById('input-routeNumber');
            routeNumberElement.classList.add('input--invalid');

            this.setState({
              routeNumberWarning: data.output.RETURN,
              isSaving: false,
            });
          }
        });
    });
  }

  updateRouteTemplateRequirements() {
    return new Promise((resolve, reject) => {
      let body = {
        routeTemplateId: this.state.routeTemplateData.routeId,
        equipmentIds: this.state.routeTemplateData.requirements.map((requirement) => requirement.id).join(','),
        DatabaseServer: this.state.routeTemplateData.DatabaseServer,
      };

      let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/updateRouteTemplateRequirements`;
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      };

      fetch(apiUrl, requestOptions).then((res) => {
        resolve(res.json());
      });
    });
  }

  render() {
    let routeTemplate = this.state.routeTemplateData;

    return (
      <div id={'route-template-modal'} className='modal-content route-template-modal'>
        <div className='heading'>Edit Route Template</div>
        <div className={'scrollable-container'}>
          {!this.state.isLoading && (
            <div className={`form-container ${this.state.isSaving ? 'saving' : ''}`}>
              <div className='form-column'>
                <div className='form-element'>
                  <label>Route Number</label>
                  <input
                    id={`input-routeNumber`}
                    type='number'
                    autoComplete='off'
                    required={true}
                    value={routeTemplate.routeNumber ? routeTemplate.routeNumber : ''}
                    onChange={(e) => this.updateField('routeNumber', e.target.value)}
                  />
                  <div className={'f--warning'}>{this.state.routeNumberWarning}</div>
                </div>
                <div className='form-element'>
                  <label>Route Description</label>
                  <input
                    id={`input-description`}
                    type='text'
                    autoComplete='off'
                    required={true}
                    value={routeTemplate.description ? routeTemplate.description : ''}
                    onChange={(e) => this.updateField('description', e.target.value)}
                  />
                </div>
                <div className='form-element'>
                  <label>Route Grade</label>
                  <CustomSelect
                    id={`input-route-grade`}
                    placeholder={'Select'}
                    optionsList={this.props.options.gradeOptions}
                    defaultText={routeTemplate.routeGradeName}
                    onClick={(option) => {
                      this.updateField('routeGradeId', option.id);
                      this.updateField('routeGradeName', option.name);
                    }}
                  />
                </div>
                <div className='form-element'>
                  <label>Area</label>
                  <CustomSelect
                    id={`input-area`}
                    placeholder={'Select'}
                    optionsList={this.props.options.areaOptions}
                    defaultText={routeTemplate.areaName}
                    onClick={(option) => {
                      this.updateField('areaId', option.id);
                      this.updateField('areaName', option.name);
                    }}
                  />
                </div>
                <div className='form-element'>
                  <label>Preferred Reader</label>
                  <div className='popover-container'>
                    {!this.state.showEditState && (
                      <>
                        <div id={'input-assign-reader'} className={`select-container`} onClick={() => this.toggleEditState()}>
                          <span className='u--ellipsis' title={routeTemplate.preferredReaderName ? Utils.titleize(routeTemplate.preferredReaderName.replace('.', ' ')) : ''}>
                            {routeTemplate.preferredReaderName ? Utils.titleize(routeTemplate.preferredReaderName.replace('.', ' ')) : 'Select'}
                          </span>
                          <Icon name='chevron' />
                        </div>
                      </>
                    )}
                    {this.state.showEditState && (
                      <>
                        <div id={'input-assign-reader'} className={`select-container selected`} onClick={() => this.toggleEditState()}>
                          <span className='u--ellipsis' title={routeTemplate.preferredReaderName ? Utils.titleize(routeTemplate.preferredReaderName.replace('.', ' ')) : ''}>
                            {routeTemplate.preferredReaderName ? Utils.titleize(routeTemplate.preferredReaderName.replace('.', ' ')) : 'Select'}
                          </span>
                          <Icon name='chevron' />
                        </div>
                        <AssignReaderPopover
                          data={{ lastReaderId: routeTemplate.lastReaderId }}
                          toggleState={() => this.toggleEditState()}
                          showEditState={this.state.showEditState}
                          optionLoadFn={() => this.getReaderOptions()}
                          updateFn={(option) => {
                            this.updateField('preferredReaderId', option.newReaderId);
                            this.updateField('preferredReaderName', option.newReaderName);
                          }}
                          type={'assign-reader'}
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className='form-element'>
                  <div className='checkbox-container'>
                    <input type='checkbox' id={'preferredReaderMandatory'} checked={routeTemplate.preferredReaderMandatory} />
                    <label htmlFor={'preferredReaderMandatory'} onClick={() => this.updateField('preferredReaderMandatory', !routeTemplate.preferredReaderMandatory)} />
                    <label onClick={() => this.updateField('preferredReaderMandatory', !routeTemplate.preferredReaderMandatory)}>Preferred Reader Mandatory</label>
                  </div>
                </div>
                <div className='form-element'>
                  <label>Start Location (Latitude, Longitude)</label>
                  <input
                    id={`input-startLatitude`}
                    className={'input--small'}
                    type='text'
                    autoComplete='off'
                    value={routeTemplate.startLatitude}
                    onChange={(e) => this.updateField('startLatitude', e.target.value)}
                  />
                  <input
                    id={`input-startLongitude`}
                    className={'input--small'}
                    type='text'
                    autoComplete='off'
                    value={routeTemplate.startLongitude}
                    onChange={(e) => this.updateField('startLongitude', e.target.value)}
                  />
                </div>
                <div className='form-element'>
                  <label>End Location (Latitude, Longitude)</label>
                  <input
                    id={`input-endLatitude`}
                    className={'input--small'}
                    type='text'
                    autoComplete='off'
                    value={routeTemplate.endLatitude}
                    onChange={(e) => this.updateField('endLatitude', e.target.value)}
                  />
                  <input
                    id={`input-endLongitude`}
                    className={'input--small'}
                    type='text'
                    autoComplete='off'
                    value={routeTemplate.endLongitude}
                    onChange={(e) => this.updateField('endLongitude', e.target.value)}
                  />
                </div>
                <div className='form-element'>
                  <label>Survey Photos</label>
                  <div>
                    <input type='checkbox' id={'surveyGas'} checked={routeTemplate.surveyPhotosGas} />
                    <label htmlFor={'surveyGas'} onClick={() => this.updateField('surveyPhotosGas', !routeTemplate.surveyPhotosGas)} />
                    <span onClick={() => this.updateField('surveyPhotosGas', !routeTemplate.surveyPhotosGas)}>Gas</span>
                  </div>
                  <div>
                    <input type='checkbox' id={'surveyElectricity'} checked={routeTemplate.surveyPhotosElectricity} />
                    <label htmlFor={'surveyElectricity'} onClick={() => this.updateField('surveyPhotosElectricity', !routeTemplate.surveyPhotosElectricity)} />
                    <span onClick={() => this.updateField('surveyPhotosElectricity', !routeTemplate.surveyPhotosElectricity)}>Electricity</span>
                  </div>
                  <div>
                    <input type='checkbox' id={'surveyWater'} checked={routeTemplate.surveyPhotosWater} />
                    <label htmlFor={'surveyWater'} onClick={() => this.updateField('surveyPhotosWater', !routeTemplate.surveyPhotosWater)} />
                    <span onClick={() => this.updateField('surveyPhotosWater', !routeTemplate.surveyPhotosWater)}>Water</span>
                  </div>
                </div>
              </div>
              <div className='form-column'>
                <div className='form-element'>
                  <label>Route Name</label>
                  <input
                    id={`input-routeName`}
                    type='text'
                    autoComplete='off'
                    required={true}
                    value={routeTemplate.routeName ? routeTemplate.routeName : ''}
                    onChange={(e) => this.updateField('routeName', e.target.value)}
                  />
                </div>
                <div className='form-element'>
                  <label>Read Frequency</label>
                  <CustomSelect
                    id={`input-read-frequency`}
                    placeholder={'Select'}
                    optionsList={this.props.options.frequencyOptions}
                    defaultText={routeTemplate.readFrequencyName}
                    onClick={(option) => {
                      this.updateField('readFrequencyId', option.id);
                      this.updateField('readFrequencyName', option.name);
                    }}
                  />
                </div>
                <div className='form-element'>
                  <label>Route Category</label>
                  <CustomSelect
                    id={`input-route-category`}
                    placeholder={'Select'}
                    optionsList={this.props.options.categoryOptions}
                    defaultText={routeTemplate.routeCategoryName}
                    onClick={(option) => {
                      this.updateField('routeCategory', option.id);
                      this.updateField('routeCategoryName', option.name);
                    }}
                  />
                </div>
                <div className='form-element'>
                  <label>Requirements</label>
                  <ResultsContainer data={this.renderRequirementList()} dataClass={'requirement'} />
                  <AddNewResult
                    id='equipment'
                    items={[{ id: 'requirements', getOptionsFn: () => this.getNewRequirements('requirements') }]}
                    updateFn={(data) => this.updateRequirementList(data.requirements)}
                  />
                </div>
                <div className='form-element'>
                  <div className='checkbox-container'>
                    <input type='checkbox' id={'manual-scheduling'} checked={routeTemplate.notForAutoScheduling} />
                    <label htmlFor={'manual-scheduling'} onClick={() => this.updateField('notForAutoScheduling', !routeTemplate.notForAutoScheduling)} />
                    <label onClick={() => this.updateField('notForAutoScheduling', !routeTemplate.notForAutoScheduling)}>Requires Manual Scheduling</label>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className='button-group t--align-right'>
          <Button classes={`button button--plain ${this.state.isSaving ? 'button--disabled' : ''}`} onClick={() => this.props.showModalFn(false)} text='Cancel' />
          <Button classes={`button button--med button--blue ${this.state.isSaving ? 'button--disabled' : ''}`} onClick={() => this.updateRouteTemplate()} text='Submit' />
        </div>
      </div>
    );
  }
}

export default RouteTemplateModal;
