import React from 'react';
import '../log-in/log-in-page.scss';

class LogIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: { value: '', isValid: true },
      password: { value: '', isValid: true },
    };
  }

  componentDidMount() {
    const usernameInput = document.querySelector('#username');
    const passwordInput = document.querySelector('#password');

    usernameInput.addEventListener('keyup', (e) => this.handleEnter(e));
    passwordInput.addEventListener('keyup', (e) => this.handleEnter(e));

    usernameInput.focus();
  }

  handleEnter(e) {
    if (e.keyCode === 13) {
      this.submitForm();
    }
  }

  changeHandler = (event) => {
    let name = event.target.name;
    let val = event.target.value;
    this.setState({ [name]: { value: val, isValid: true } });
  };

  submitForm() {
    this.props
      .submitFn(this.state.username.value, this.state.password.value)
      .then((res) => {})
      .catch((res) => {
        this.setState({
          errorMessage: res.message,
        });
      });
  }

  render() {
    let errMsg = this.props.errMsg ? `${this.props.errMsg}` : this.state.errorMessage;

    return (
      <div className='log-in-page'>
        <div className='hero' />

        <div className='content-container'>
          <div className='form-container'>
            <div className={`logo-container ${process.env.REACT_APP_ENV ? 'uat' : ''}`} />
            <div className='form-element'>
              <label>Username</label>
              <div className={`input-container input--gradient ${!this.state.username.isValid ? 'input--invalid' : ''}`}>
                <input id='username' type='text' name='username' onChange={this.changeHandler} required />
              </div>
            </div>

            <div className='form-element'>
              <label>Password</label>
              <div className={`input-container input--gradient ${!this.state.password.isValid ? 'input--invalid' : ''}`}>
                <input id='password' type='password' name='password' onChange={this.changeHandler} required />
              </div>
            </div>

            <div className='error-message'>
              <span>{errMsg}</span>
            </div>

            <div className='button button--large button--gradient' onClick={this.submitForm.bind(this)}>
              <span>Login</span>
            </div>
            <div className='button-group'>
              <div className='alt-link' onClick={() => this.props.history.goBack()}>
                Back to Consumer Portal
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LogIn;
