import React from 'react';
import Icon from '../Icon';
import Utils from '../../utils/Utils';
import moment from 'moment';
import MobileDetect from 'mobile-detect';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import _ from 'lodash';
import { TimesheetEntryBase } from './TimesheetEntryBase';

class MobileTimesheetEntry extends TimesheetEntryBase {
  toggleDatePicker() {
    const elem = document.getElementById('date-input');
    elem.focus();
  }

  handleDateChange = (e) => {
    this.updateField('date', moment(e.target.value));
  };

  render() {
    let systemType = new MobileDetect(window.navigator.userAgent);
    let isAndroid = systemType.os() === 'AndroidOS';

    return (
      <div className={`mobile-timesheet-entry ${isAndroid ? 'mobile-timesheet-entry--android' : ''}`}>
        <header>
          <div className='nav-button--plain' onClick={this.props.backFn}>
            Cancel
          </div>
          <h1>{moment(this.state.date).format('dddd,  D MMMM')}</h1>
          <div className='nav-button--icon' onClick={this.toggleDatePicker}>
            <Icon name='calendar' />
          </div>
        </header>
        <input id='date-input' type='date' onChange={this.handleDateChange} />
        <div className='content-container'>
          <h3 className='t--align-center'>
            {this.props.data.selectedEntry ? 'Edit' : 'New'} {Utils.humanize(this.props.type)}
          </h3>
          <div className='form-container'>
            {this.props.type === 'timeEntry' && (
              <>
                <div className='form-row'>
                  <div className='form-element'>
                    <label>Start Time</label>
                    <div className='input-container'>
                      {!isAndroid && <input id={`startTime`} type='time' name={'startTime'} value={this.state.startTime} onChange={this.handleChange} required />}
                      {isAndroid && (
                        <TimePicker
                          id='startTime'
                          className={'custom-time-picker'}
                          defaultValue={!_.isEmpty(this.state.startTime) ? moment(this.state.startTime, 'HH:mm') : moment().hour(0).minute(0)}
                          showSecond={false}
                          onChange={(selectedTime) => this.updateField('startTime', selectedTime.format('HH:mm'))}
                          //  format={'h:mm a'}
                          use12Hours
                          inputReadOnly
                        />
                      )}
                    </div>
                  </div>
                  <div className='form-element'>
                    <label>End Time</label>
                    <div className='input-container'>
                      {!isAndroid && <input id={`endTime`} type='time' name={'endTime'} value={this.state.endTime} onChange={this.handleChange} required />}
                      {isAndroid && (
                        <TimePicker
                          id='endTime'
                          className={'custom-time-picker'}
                          defaultValue={!_.isEmpty(this.state.endTime) ? moment(this.state.endTime, 'HH:mm') : moment().hour(0).minute(0)}
                          showSecond={false}
                          onChange={(selectedTime) => this.updateField('endTime', selectedTime.format('HH:mm'))}
                          //  format={'h:mm a'}
                          use12Hours
                          inputReadOnly
                        />
                      )}
                    </div>
                  </div>
                </div>
                {this.props.timesheetErrorInfo && (
                  <div className='f--warning' style={{ marginTop: '-30px', marginBottom: '24px' }}>
                    <b> {this.props.timesheetErrorInfo}</b>
                  </div>
                )}
              </>
            )}

            {this.renderCategory()}

            {this.renderQuantity()}

            {this.renderComment()}

            {this.renderTotalHours()}

            {this.renderButtons()}
          </div>
        </div>
      </div>
    );
  }
}

export default MobileTimesheetEntry;
