import React from 'react';
import Icon from '../Icon';

class RemergeRouteResult extends React.Component {
  handleClick = (e) => {
    const route = this.props.data;
    const routeElem = document.getElementById('route-' + route.id);

    // Route is disabled if there is no merged jobs
    if (route.merged !== 0) {
      if (routeElem.contains(e.target)) {
        this.props.selectFn(route.id);
      }
    }
  };

  componentDidMount() {
    const element = document.getElementById('route-' + this.props.data.id);
    element.addEventListener('mousedown', this.handleClick);
  }

  componentWillUnmount() {
    // Remove the event listener on component unmounting
    const element = document.getElementById('route-' + this.props.data.id);
    element.removeEventListener('mousedown', this.handleClick);
  }

  render() {
    const route = this.props.data;
    const status = route.merged === 0 ? 'Add one location to Mimtr' : route.unmerged ? `Manual Required (${route.unmerged})` : 'Merged';

    return (
      <div className={`result-item ${route.selected ? 'selected' : ''} ${route.merged === 0 ? 'disabled' : ''}`}>
        <div id={'route-' + route.id} className={`item-card`}>
          <div className='item-row'>
            {/* Folder */}
            <div className='item-detail' title='Folder'>
              <Icon name='label' />
              <span>{route.folder}</span>
            </div>
            {/* Import File Name */}
            <div className='item-detail' title='Import File Name'>
              <Icon name='file' />
              <span>{route.importFileName}</span>
            </div>
            {/* Route No. */}
            <div className='item-detail' title='Route No.'>
              <Icon name='route' />
              <span>{route.routeNo}</span>
            </div>
            {/* Route Name. */}
            <div className='item-detail' title={`Route Name: ${route.routeName}`}>
              <Icon name='pin' />
              <span className='u--ellipsis'>{route.routeName}</span>
            </div>
            {/* Date Range */}
            <div className='item-detail' title='Date Range'>
              <Icon name='date' />
              <span>
                {route.dateRangeStart} - {route.dateRangeEnd}
              </span>
            </div>
            {/* Merged */}
            <div className='item-detail' title='Merged'>
              <Icon name='job' />
              <span>
                {route.merged} / {route.jobs}
              </span>
            </div>
            {/* Status */}
            <div className='item-detail' title='Status'>
              <Icon name='status' />
              <span>{status}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RemergeRouteResult;
