import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import CustomSelect from '../CustomSelect';
import Button from '../Button';

export class TimesheetEntryBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: props.data.selectedEntry ? props.data.selectedEntry.date : props.data.selectedDate,
      startTime: props.data.selectedEntry ? props.data.selectedEntry.startTime : moment().format('HH:mm:ss'),
      endTime: props.data.selectedEntry ? props.data.selectedEntry.endTime : moment().format('HH:mm:ss'),
      comment: props.data.selectedEntry ? props.data.selectedEntry.comment : undefined,
      categoryId: props.data.selectedEntry ? props.data.selectedEntry.categoryId : '',
      qty: props.data.selectedEntry ? props.data.selectedEntry.qty : '',
      id: props.data.selectedEntry ? props.data.selectedEntry.id : '',
      categoryData: [],
      isLoading: true,
      editingfieldduties: false,
      editing: false,
      addingcat: null,
      commentErrorMessage: '',
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.getCategories();
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });

    if (e.target.name === 'comment') {
      if (e.target.value) {
        this.removeCommentError();
      } else {
        const category = document.getElementById('hidden-category');
        if (!category.value.toString().toLowerCase().includes('meal break')) {
          this.addCommentError();
        }
      }
    } else if (e.target.name === 'qty') {
      const qtyElem = document.getElementById('qty');
      if (e.target.value) {
        qtyElem.classList.remove('input--invalid');
      } else {
        qtyElem.classList.add('input--invalid');
      }
    }
  };

  handleClickCategory(selection) {
    this.setState({ categoryId: selection.id });

    if (selection.name.toLowerCase().includes('meal break')) {
      this.removeCommentError();
    } else if (!this.state.comment && this.state.comment !== undefined) {
      this.addCommentError();
    }
  }

  addCommentError() {
    const commentElem = document.getElementById('comment');
    commentElem.classList.add('input--invalid');
    this.setState({ commentErrorMessage: 'You MUST enter comments.' });
  }
  removeCommentError() {
    const commentElem = document.getElementById('comment');
    commentElem.classList.remove('input--invalid');
    this.setState({ commentErrorMessage: '' });
  }

  updateField(field, value) {
    this.setState({ [field]: value });
  }

  getCategories() {
    this.props.data.getCategoriesFn(this.props.type === 'timeEntry' ? 'timeEntryCategory' : 'allowanceCategory').then((data) => {
      const categoryData = _.map(data, (category, index) => {
        if (this.props.type === 'timeEntry') {
          return { id: index, name: category };
        } else {
          return { id: index, name: category.name, unit: category.unit };
        }
      });

      this.setState({ categoryData, isLoading: false });
    });
  }

  submitForm(action) {
    if (!this.validateForm()) return;

    this.props.onClick(action, this.state);
  }

  validateForm() {
    let isValid = true;

    const category = document.getElementById('hidden-category');
    if (!category.value.toString().toLowerCase().includes('meal break')) {
      if (!this.state.comment) {
        isValid = false;
        this.addCommentError();
      }
    }

    const requiredInputs = document.getElementsByClassName('form-container')[0].querySelectorAll('[required]');
    requiredInputs.forEach((element) => {
      if (_.isEmpty(element.value)) {
        element.classList.add('input--invalid');
        isValid = false;
      } else {
        element.classList.remove('input--invalid');
      }
    });

    const requiredElements = document.getElementsByClassName('selected-text required');
    _.forEach(requiredElements, (element) => {
      if (_.isEmpty(element.getElementsByTagName('input')[0].value)) {
        element.classList.add('input--invalid');
        isValid = false;
      }
    });

    if (isValid && this.props.type === 'timeEntry') {
      const startTime = moment(this.state.startTime, 'hh:mm:ss');
      const endTime = moment(this.state.endTime, 'HH:mm:ss');

      const totalHours = moment.duration(endTime.diff(startTime));

      const hours = parseInt(totalHours.asHours());
      const minutes = parseInt(totalHours.asMinutes()) % 60;

      const startTimeElem = document.getElementById('startTime');
      const endTimeElem = document.getElementById('endTime');

      if (hours <= 0 && minutes <= 0) {
        isValid = false;

        startTimeElem.classList.add('input--invalid');
        endTimeElem.classList.add('input--invalid');
      } else {
        startTimeElem.classList.remove('input--invalid');
        endTimeElem.classList.remove('input--invalid');
      }
    }

    return isValid;
  }

  renderCategory() {
    return (
      <div className='form-element'>
        <label>Category</label>
        <CustomSelect
          placeholder={'Select'}
          defaultText={this.props.data.selectedEntry ? this.props.data.selectedEntry.category : ''}
          optionsList={this.state.categoryData}
          onClick={(value) => this.handleClickCategory(value)}
          id='category'
          required={true}
        />
      </div>
    );
  }

  renderQuantity() {
    return (
      <>
        {this.props.type === 'allowance' && (
          <div className='form-row'>
            <div className='form-element'>
              <label>Quantity {this.state.categoryId ? `(${this.props.data.allowanceCategories[this.state.categoryId].unit})` : ''}</label>
              <div className='input-container'>
                <input id={`qty`} type='text' name={'qty'} autoComplete='off' value={this.state.qty} onChange={this.handleChange} required />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  renderComment() {
    return (
      <div className='form-element'>
        <label>Comment</label>
        <textarea id='comment' name={'comment'} value={this.state.comment} onChange={this.handleChange} maxLength={500} />
        {this.state.commentErrorMessage && <label style={{ color: '#ed6c40' }}>{this.state.commentErrorMessage}</label>}
      </div>
    );
  }

  renderTotalHours() {
    let totalHours = this.props.data.calculateTotalHoursFn(this.state.startTime, this.state.endTime);

    return (
      <>
        {this.props.type === 'timeEntry' && (
          <div className='total'>
            <h3>
              <div>Total Hours</div>
              <div>{totalHours}</div>
            </h3>
          </div>
        )}
      </>
    );
  }

  renderButtons() {
    return (
      <div className='button-group'>
        {!this.props.data.selectedEntry && <Button classes='button button--med button--blue' onClick={() => this.submitForm('add')} text='Add' />}

        {this.props.data.selectedEntry && <Button classes='button button--med button--blue' onClick={() => this.submitForm('update')} text='Update' />}

        {this.props.data.selectedEntry && <Button classes='button button--destroy' onClick={() => this.props.onClick('delete', this.state)} text='Delete Entry' />}
      </div>
    );
  }
}
