import React from 'react';
import Zoom from './Zoom';
import Icon from './Icon';

class PhotoCard extends React.Component {
  render() {
    return (
      <div className='swiper-card'>
        <div className='card-body'>
          <div className='image-preview-container'>
            <Zoom src={this.props.photoUrl} />
          </div>
        </div>
        <div className='swiper-card-arrow arrow--prev' onClick={() => this.props.prevFn()}>
          <Icon name='chevron' />
        </div>
        <div className='swiper-card-arrow arrow--next' onClick={() => this.props.nextFn()}>
          <Icon name='chevron' />
        </div>
      </div>
    );
  }
}

export default PhotoCard;
