import React from 'react';
import _ from 'lodash';
import CustomSelect from '../CustomSelect';
import DatePickerSelect from '../DatePickerSelect';
import ButtonGroup from '../ButtonGroup';

class SearchCriteria extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: props.filters,
    };
  }
  async componentDidUpdate(prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.setState({
        filters: this.props.filters,
      });
    }
  }

  applyFilters() {
    this.props.onClick(this.state.filters);
  }

  clearFilters() {
    this.state.filters.forEach((filter) => {
      let elem;
      if (filter.type === 'text') {
        elem = document.getElementById('input-filter-' + filter.name);
        elem.value = '';
      } else if (filter.type === 'select') {
        elem = document.getElementById('default-select-text-filter-' + filter.name);

        if (elem) {
          elem.innerHTML = '';
        }
      }
    });

    this.props.onClick();
  }

  updateField(selectedFilter, val) {
    let filterIndex = this.props.filters.findIndex((filter) => filter.name === selectedFilter);
    let filters = this.props.filters;

    if (filters[filterIndex].multiselect) {
      if (filters[filterIndex].selection.includes(val)) {
        filters[filterIndex].selection = _.without(filters[filterIndex].selection, val);
      } else {
        filters[filterIndex].selection.push(val);
      }
    } else {
      filters[filterIndex].selection = val;
    }

    this.setState({
      filters: filters,
    });
  }

  renderElement(filter) {
    let element;
    switch (filter.type) {
      case 'select':
        let defaultText;

        // If multiselect, display selection as count if more than one selected, or just the one selection
        if (filter.multiselect) {
          if (!filter.selection.length) {
            defaultText = null;
          } else {
            defaultText = filter.selection.length > 1 ? `${filter.selection.length} options selected` : filter.options.find((option) => filter.selection[0] === option.id).name;
          }
        } else {
          defaultText = filter.selection ? filter.selection.name : null;
        }

        element = (
          <CustomSelect
            optionsList={filter.options}
            multiselect={filter.multiselect}
            selection={filter.selection}
            defaultText={defaultText}
            displayLabel={filter.displayLabel}
            onClick={(option) => this.updateField(filter.name, option)}
            id={'filter-' + filter.name}
            key={filter.name}
          />
        );
        break;
      case 'dateSelectRange':
        let selection = { startDate: filter.selection.start, endDate: filter.selection.end };
        element = (
          <DatePickerSelect
            displayLabel={filter.displayLabel}
            selectionText={selection.startDate ? selection.startDate.format('DD/MM/YY') + ' - ' + selection.endDate.format('DD/MM/YY') : ''}
            selection={selection}
            range={true}
            onClick={(startDate, endDate) => this.updateField(filter.name, { start: startDate, end: endDate })}
            id={filter.name}
            key={filter.name}
          />
        );
        break;
      case 'text':
        element = (
          <div className='input-container input--with-placeholder' key={filter.name}>
            <div className='placeholder'>{filter.displayLabel}</div>
            <input id={`input-filter-${filter.name}`} type='text' onChange={(e) => this.updateField(filter.name, e.target.value)} />
          </div>
        );
        break;
      default:
        break;
    }

    return element;
  }

  render() {
    return (
      <div className='search-criteria-container'>
        <div className='header'>Search Criteria</div>
        <div className='button-group'>
          {this.state.filters.map((filter) => {
            return this.renderElement(filter, this.state.clear);
          })}
        </div>
        <ButtonGroup
          items={[
            { type: 'button', classes: 'button--plain', text: 'Clear All', onClick: () => this.clearFilters() },
            { type: 'button', classes: 'button--blue', text: 'Search', onClick: () => this.applyFilters() },
          ]}
        />
      </div>
    );
  }
}

export default SearchCriteria;
