import React from 'react';
import Icon from './Icon';

class Button extends React.Component {
  handleClick() {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  render() {
    const classes = this.props.classes ? this.props.classes : '';
    const icon = this.props.icon;
    const text = this.props.text;
    const iconPosition = this.props.iconPosition;
    const iconClasses = this.props.iconClasses;

    return (
      <div className={`button ${classes}`} onClick={() => this.handleClick()}>
        {iconPosition === '1' && <Icon name={icon} classes={iconClasses} />}
        {text && <span>{text}</span>}
        {iconPosition === '2' && <Icon name={icon} />}
      </div>
    );
  }
}

export default Button;
