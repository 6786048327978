import React from 'react';

class ToggleButton extends React.Component {
  handleClick(value) {
    if (this.props.onClick) {
      this.props.onClick(value);
    }
  }

  render() {
    const classes = this.props.classes ? this.props.classes : '';
    const options = this.props.options;

    return (
      <div className={`button button--toggle ${classes}`}>
        {options.map((option, index) => {
          return (
            <div className={this.props.selection === option.value ? 'selected' : ''} key={index} onClick={() => this.handleClick(option.value)}>
              <span>{option.name}</span>
            </div>
          );
        })}
      </div>
    );
  }
}

export default ToggleButton;
