import React from 'react';
import Button from './Button';
import CustomSelect from './CustomSelect';
import TimeInput from './TimeInput';

class AddNewResult extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showSelectState: false,
      selection: {},
      options: {},
    };
  }

  async toggleSelectState(show) {
    show = show ? show : !this.state.showSelectState;
    let options = this.state.options;
    let selection = this.state.selection;

    if (show) {
      let promises = [];

      this.props.items.forEach((item) => {
        if (item.options) {
          options[item.id] = item.options;
        } else if (typeof item.getOptionsFn === 'function') {
          promises.push(item.getOptionsFn());
        }

        if (item.type === 'time') {
          selection[item.id] = '00:00';
        }
      });

      await Promise.all(promises).then((data) => {
        this.props.items.forEach((item, index) => {
          if (!item.options) {
            options[item.id] = data[index];
          }
        });
      });
    }

    this.setState({
      showSelectState: show,
      options: options,
      selection: show ? selection : {},
    });
  }

  updateSelection(item, option, timeType) {
    let selection = this.state.selection;

    if (item.type === 'timeRange') {
      if (!selection[item.id]) {
        selection[item.id] = {};
      }
      selection[item.id][timeType] = option;
    } else {
      selection[item.id] = option;
    }

    this.setState({
      selection: selection,
    });
  }

  async addResult() {
    this.props.updateFn(this.state.selection);
    this.toggleSelectState(false);
  }

  validateForm() {
    let isValid = true;
    this.props.items.forEach((item) => {
      if (item.type === 'timeRange') {
        if (!this.state.selection[item.id] || (!this.state.selection[item.id].startTime && !this.state.selection[item.id].endTime)) {
          isValid = false;
        }
      } else {
        if (!this.state.selection[item.id]) {
          isValid = false;
        }
      }
    });

    return isValid;
  }

  renderElement(item) {
    switch (item.type) {
      case 'text':
        return (
          <input
            id={`new-${item.id}-select`}
            type='text'
            key={item.id}
            autoComplete='off'
            pattern={item.pattern}
            placeholder={item.placeholder ? item.placeholder : 'Select'}
            onChange={(e) => this.updateSelection(item, e.target.value)}
          />
        );
      case 'number':
        return (
          <input
            id={`new-${item.id}-select`}
            type='number'
            key={item.id}
            autoComplete='off'
            pattern={item.pattern}
            placeholder={item.placeholder ? item.placeholder : 'Select'}
            onChange={(e) => this.updateSelection(item, e.target.value)}
          />
        );
      case 'time':
        return <TimeInput id={`new-${item.id}`} key={item.id} required={true} value={'00:00'} onChange={(value) => this.updateSelection(item, value)} />;
      case 'timeRange':
        return (
          <div key={`new-${item.id}`}>
            <TimeInput id={`new-${item.id}-startTime`} key={`${item.id}-startTime`} required={true} value={'00:00'} onChange={(value) => this.updateSelection(item, value, 'startTime')} />
            -
            <TimeInput id={`new-${item.id}-endTime`} key={`${item.id}-endTime`} required={true} value={'00:00'} onChange={(value) => this.updateSelection(item, value, 'endTime')} />
          </div>
        );
      default:
        return (
          <CustomSelect
            id={`new-${item.id}-select`}
            key={item.id}
            optionsList={this.state.options[item.id]}
            placeholder={item.placeholder ? item.placeholder : 'Select'}
            onClick={(option) => this.updateSelection(item, option)}
          />
        );
    }
  }

  render() {
    return (
      <div className={`add-new-result-container`} id={`add-new-${this.props.id}`}>
        {!this.state.showSelectState && <Button classes={'button--plain'} text={'+ Add New'} onClick={() => this.toggleSelectState()} />}
        {this.state.showSelectState && (
          <div>
            {this.props.items.map((item) => this.renderElement(item))}
            <div className={'button-group'}>
              <Button classes={`button--blue ${!this.validateForm() ? 'button--disabled' : ''}`} text={'Add'} onClick={() => this.addResult()} />
              <Button classes={'button--plain'} text={'Cancel'} onClick={() => this.toggleSelectState(false)} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default AddNewResult;
