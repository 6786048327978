import React from 'react';
import Button from '../Button';

class MatrixPartDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newInvoice: '',
      margin: props.data.part ? props.data.part.margin : '',
      SAPNo: props.data.part ? props.data.part.SAPNo : '',
      SAPCost: props.data.part ? props.data.part.SAPCost : '',
      SAPInvoice: props.data.part ? props.data.part.SAPInvoice : '',
    };
  }

  componentDidMount() {
    const element = document.getElementsByName('newInvoice');
    if (element) {
      element[0].addEventListener('focusout', () => this.updateMargin());
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data.part && prevProps.data.part.id !== this.props.data.part.id) {
      this.setState({
        newInvoice: '',
        margin: this.props.data.part ? this.props.data.part.margin : '',
        SAPNo: this.props.data.part ? this.props.data.part.SAPNo : '',
        SAPCost: this.props.data.part ? this.props.data.part.SAPCost : '',
        SAPInvoice: this.props.data.part ? this.props.data.part.SAPInvoice : '',
      });
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  updateMargin() {
    let newInvoice = this.state.newInvoice;
    let margin = this.props.data.part.margin;

    if (newInvoice !== '') {
      margin = ((newInvoice - this.state.SAPCost) / newInvoice) * 100;
      margin = isNaN(margin) ? '-' : Math.round((margin + Number.EPSILON) * 100) / 100;
    }

    this.setState({ margin: margin });
  }

  updateInvoiceRate() {
    this.setState({
      SAPInvoice: this.state.newInvoice,
    });
    this.props.data.updateInvoiceRate(this.state.newInvoice);
  }

  render() {
    const part = this.props.data.part;
    const updatePartCOG = this.props.data.updatePartCOG;

    return (
      <div className={`form-container`}>
        {part && (
          <div>
            <h3>{part.description}</h3>
            <div className='form-element'>
              <label>Current Invoice Rate</label>
              <div className='input-container input--dollar'>
                <div>$</div>
                <input type='text' className={part.invoice !== part.SAPInvoice ? 'input--invalid' : ''} autoComplete='off' disabled={true} value={part.invoice} />
              </div>
            </div>
            <div className='form-element'>
              <label>New Invoice Rate</label>
              <div className='input-container input--dollar'>
                <div>$</div>
                <input type='number' autoComplete='off' name='newInvoice' value={this.state.newInvoice} onChange={this.handleChange} />
              </div>
            </div>

            <div className='button-group'>
              <Button classes='button button--blue' text='Update' onClick={() => this.updateInvoiceRate()} />
            </div>

            <div className='form-element'>
              <label>SAP Part #</label>
              <div className='input-container'>
                <input
                  type='text'
                  className={this.state.SAPNo && this.state.SAPNo !== '-' ? '' : 'input--invalid'}
                  autoComplete='off'
                  name='SAPNo'
                  value={this.state.SAPNo}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className='form-element'>
              <label>SAP Cost</label>
              <div className='input-container input--dollar'>
                <div>$</div>
                <input type='text' className={this.state.SAPCost > 0 ? '' : 'input--invalid'} autoComplete='off' name='SAPCost' value={this.state.SAPCost} onChange={this.handleChange} />
              </div>
            </div>
            <div className='form-element'>
              <label>SAP Invoice</label>
              <div className='input-container input--dollar'>
                <div>$</div>
                <input
                  type='text'
                  className={part.invoice !== part.SAPInvoice ? 'input--invalid' : ''}
                  autoComplete='off'
                  name='SAPInvoice'
                  value={this.state.SAPInvoice}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className='form-element'>
              <label>Margin</label>
              <div className='input-container'>
                <input type='text' className={this.state.margin < 0 ? 'input--invalid' : ''} autoComplete='off' value={`${this.state.margin}%`} disabled={true} />
              </div>
            </div>
            <div className='button-group'>
              <Button classes='button button--blue' text='Change' onClick={() => updatePartCOG(this.state)} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default MatrixPartDetails;
