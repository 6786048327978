import Utils from '../utils/Utils';
import _ from 'lodash';

export default {
  getContracts: () => {
    return new Promise((resolve, reject) => {
      let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getContracts`;

      return fetch(apiUrl)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, {
              method: 'POST',
            });
            return res.json();
          }
        })
        .then((data) => {
          data = _.sortBy(data, 'name');
          resolve(data);
        })
        .catch(() => {});
    });
  },

  getAreasByContract(contractIds) {
    return new Promise((resolve) => {
      let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getAreasByContract?contractIds=${contractIds}`;
      fetch(apiUrl)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, {
              method: 'POST',
            });
            return res.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },

  getStates(selectedContract) {
    return new Promise((resolve) => {
      let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getStates?DatabaseServer=${selectedContract.dbServer}`;
      fetch(apiUrl)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, {
              method: 'POST',
            });
            return res.json();
          }
        })
        .then((data) => {
          let codeset = data.map((state) => {
            return {
              id: state.stateId,
              name: state.stateName,
            };
          });
          resolve(codeset);
        })
        .catch(() => {});
    });
  },

  getSuburbsByState(selectedContract, stateId) {
    return new Promise((resolve) => {
      if (stateId === undefined) {
        resolve([]);
        return;
      }

      let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getSuburbsByState?DatabaseServer=${selectedContract.dbServer}&stateId=${stateId}`;
      fetch(apiUrl)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, {
              method: 'POST',
            });
            return res.json();
          }
        })
        .then((data) => {
          let codeset = data.map((suburb) => {
            return {
              id: suburb.suburbId,
              name: suburb.suburbName,
            };
          });
          resolve(_.sortBy(_.uniqBy(codeset, 'id'), 'name'));
        })
        .catch(() => {});
    });
  },

  getStreetsBySuburb(selectedContract, suburbId) {
    return new Promise((resolve) => {
      if (suburbId === undefined) {
        resolve([]);
        return;
      }

      let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getStreetsBySuburb?DatabaseServer=${selectedContract.dbServer}&suburbId=${suburbId}`;
      fetch(apiUrl)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, {
              method: 'POST',
            });
            return res.json();
          }
        })
        .then((data) => {
          let codeset = data.map((street) => {
            return {
              id: street.streetId,
              name: street.streetName,
            };
          });
          resolve(_.sortBy(_.uniqBy(codeset, 'id'), 'name'));
        })
        .catch(() => {});
    });
  },

  getSupervisorsByContract: (contractId) => {
    return new Promise((resolve, reject) => {
      let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getSupervisorsByContract?contractId=${contractId}`;

      return fetch(apiUrl)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, {
              method: 'POST',
            });
            return res.json();
          }
        })
        .then((data) => {
          data = _.sortBy(data, 'name').map((result) => {
            return {
              id: result.id,
              name: Utils.titleize(result.name.replaceAll('.', ' ')),
            };
          });
          resolve(data);
        })
        .catch(() => {});
    });
  },

  getReaders: (selectedContract, workRouteId, scheduledDate) => {
    return new Promise((resolve, reject) => {
      let apiUrl;

      if (workRouteId) {
        // Get Readers by Work Route (and optionally Date)
        apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getreadersbyworkrouteid?DatabaseServer=${selectedContract.dbServer}&workrouteid=${workRouteId}`;

        if (scheduledDate) {
          apiUrl += `&scheduleddate=${scheduledDate.format('YYYY-MM-DD')}`;
        }
      } else {
        // Get Readers by Contract
        apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getreadersbycontractid?DatabaseServer=${selectedContract.dbServer}&contractid=${selectedContract.id}`;
      }

      return fetch(apiUrl)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, {
              method: 'POST',
            });
            return res.json();
          }
        })
        .then((data) => {
          let readerData = data
            .map((reader) => {
              return {
                id: reader.ReaderId,
                name: reader.ReaderName ? Utils.titleize(reader.ReaderName.replaceAll('.', ' ')) : '',
                equipment: reader.Equipments ? reader.Equipments.split('|') : [],
                workCapacity: reader.CapacityOnScheduledDate,
                incompleteJobs: reader.IncompletedJobsTime,
                ranking: reader.Ranking,
                recommendedReader: reader.SuggestedReader ? reader.SuggestedReader : 0,
              };
            })
            .filter((reader) => reader.name);

          readerData = _.sortBy(readerData, 'name');
          resolve(readerData);
        })
        .catch(() => {});
    });
  },

  getReaderCategories: () => {
    return new Promise((resolve, reject) => {
      let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getReaderCategories`;

      return fetch(apiUrl)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, {
              method: 'POST',
            });
            return res.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },

  getAgreementTypes: () => {
    return new Promise((resolve, reject) => {
      let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getAgreementTypes`;

      return fetch(apiUrl)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, {
              method: 'POST',
            });
            return res.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },

  getPayTypes: () => {
    return new Promise((resolve, reject) => {
      let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getPayTypes`;

      return fetch(apiUrl)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, {
              method: 'POST',
            });
            return res.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },

  /**
   * Get codesets for the following categories:
   * 1 - Trouble Code
   * 2 - Meter Type Code
   * 3 - Instruction Code
   * 4 - Skip Code
   * 5 - Location Code
   * 6 - Tap Test Code
   */
  getCodesets: (selectedContract, categoryIds) => {
    return new Promise((resolve) => {
      let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getcodesets?DatabaseServer=${selectedContract.dbServer}&contractid=${selectedContract.id}&categoryid=${categoryIds}`;
      fetch(apiUrl)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, {
              method: 'POST',
            });
            return res.json();
          }
        })
        .then((data) => {
          let codeset = data.results.map((code) => {
            return {
              id: code.CodeSetId,
              name: Utils.titleize(code.Name),
              categoryId: code.CategoryId,
            };
          });
          resolve(codeset);
        })
        .catch(() => {});
    });
  },

  getJobStatuses() {
    return new Promise((resolve) => {
      let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getstatuses`;
      fetch(apiUrl)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, {
              method: 'POST',
            });
            return res.json();
          }
        })
        .then((data) => {
          let statusData = data.results.map((status) => {
            return {
              id: status.StatusID,
              name: status.Name,
            };
          });
          resolve(statusData);
        })
        .catch(() => {});
    });
  },

  getReadFrequencies(selectedContract) {
    return new Promise((resolve) => {
      let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getReadFrequencies?DatabaseServer=${selectedContract.dbServer}`;
      fetch(apiUrl)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, {
              method: 'POST',
            });
            return res.json();
          }
        })
        .then((data) => {
          let codeset = data.map((frequency) => {
            return {
              id: frequency.readFrequencyId,
              name: frequency.name,
            };
          });
          resolve(codeset);
        })
        .catch(() => {});
    });
  },

  getRouteGrade(selectedContract) {
    return new Promise((resolve) => {
      let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getRouteGrades?DatabaseServer=${selectedContract.dbServer}`;
      fetch(apiUrl)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, {
              method: 'POST',
            });
            return res.json();
          }
        })
        .then((data) => {
          let codeset = data.map((grade) => {
            return {
              id: grade.RouteGradeId,
              name: grade.Name,
            };
          });
          resolve(codeset);
        })
        .catch(() => {});
    });
  },

  getReaderEquipment() {
    return new Promise((resolve) => {
      let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getEquipment`;
      fetch(apiUrl)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, {
              method: 'POST',
            });
            return res.json();
          }
        })
        .then((data) => {
          let codeset = data.map((equipment) => {
            return {
              id: equipment.id,
              name: equipment.name,
            };
          });
          resolve(codeset);
        })
        .catch(() => {});
    });
  },

  getRouteCategories() {
    return new Promise((resolve) => {
      let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getRouteCategories`;
      fetch(apiUrl)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, {
              method: 'POST',
            });
            return res.json();
          }
        })
        .then((data) => {
          let codeset = data.map((category) => {
            return {
              id: category.id,
              name: category.name,
            };
          });
          resolve(codeset);
        })
        .catch(() => {});
    });
  },

  getUserRoles() {
    return new Promise((resolve) => {
      let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getRoles`;
      fetch(apiUrl)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, {
              method: 'POST',
            });
            return res.json();
          }
        })
        .then((data) => {
          let codeset = data.map((role) => {
            return {
              id: role.id,
              name: role.name,
            };
          });
          resolve(codeset);
        })
        .catch(() => {});
    });
  },
};
