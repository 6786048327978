import React from 'react';
import Icon from './Icon';

class Modal extends React.Component {
  showModal(show) {
    this.props.showModalFn(show);
  }

  componentDidMount() {
    // Add Event Listner to handle the click that happens outside
    // the Custom Select Container
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('keydown', this.handleEscape);
  }

  componentWillUnmount() {
    // Remove the event listener on component unmounting
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('keydown', this.handleEscape);
  }

  // This method handles the click that happens outside the element
  handleClickOutside = (e) => {
    var element = document.getElementsByClassName('modal-overlay')[0];
    let modalElements = document.getElementsByClassName('modal-content');

    if (!this.props.required && this.props.showModal && element.contains(e.target)) {
      let closeModal = true;
      Array.prototype.forEach.call(modalElements, (modalElement) => {
        if (modalElement.contains(e.target)) {
          closeModal = false;
        }
      });

      if (closeModal) {
        this.props.showModalFn(false);
      }
    }
  };

  handleEscape = (e) => {
    if (e.key === 'Escape' && !this.props.required) {
      this.props.showModalFn(false);
    }
  };

  render() {
    return (
      <div className='modal-overlay'>
        <div className={`modal ${this.props.classes ? this.props.classes : ''}`}>
          {!this.props.required && (
            <div className='modal-close-button'>
              <Icon name='full-cross' />
            </div>
          )}
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Modal;
