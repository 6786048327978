import React from 'react';
import wave from '../../images/wave2.svg';
import Button from '../Button';
import logo from '../../images/skilltech-logo.png';
import uat_logo from '../../images/skilltech-mimtr-logo-uat-dark.png';
import Loader from 'react-loader-spinner';

/**
 * TEMPORARY LOGON UPDATE UNTIL AD LOGIN
 */
class UpdateUserLogon extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    document.title = `${process.env.REACT_APP_ENV ? `[${process.env.REACT_APP_ENV}] ` : ''}MiMtr Hybrid | Update User Logon`;

    let checkTokenApiUrl = `${process.env.REACT_APP_API_ENDPOINT}/check`;
    fetch(checkTokenApiUrl).then((res) => {
      if (res.status === 401) {
        this.props.logoutFn();
      } else {
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
      }
    });
  }

  getUsers() {
    let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getSapReferenceNumbers`;

    return fetch(apiUrl)
      .then((res) => {
        if (res.status === 401) {
          this.props.logoutFn();
        } else {
          fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
          return res.json();
        }
      })
      .then((data) => {
        return data;
      });
  }

  updateUser(user) {
    return new Promise((resolve, reject) => {
      let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/createuser`;

      let body = {
        username: user.UserName,
        password: user.SapReferenceNumber,
      };

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      };

      fetch(apiUrl, requestOptions)
        .then((res) => {
          console.log(res.status);
          if (res.status !== 200) {
            reject(user);
          } else {
            resolve('done');
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  runUpdate() {
    this.setState({
      isLoading: true,
    });

    this.getUsers().then((res) => {
      let users = res.results;
      let promises = [];

      users.forEach((user) => {
        promises.push(this.updateUser(user));
      });

      Promise.all(promises)
        .catch((data) => {
          return promises;
        })
        .then((data) => {
          this.setState({
            isLoading: false,
          });
        });
    });
  }

  render() {
    return (
      <div className='main-content log-ticket-page'>
        <div className='logo-container' />
        <header>
          <div>
            <h1>Update User Logons</h1>
          </div>
          <img className='logo-container' src={process.env.REACT_APP_ENV ? uat_logo : logo} alt='' />
        </header>
        <div className='content-container'>
          <div className='button-group'>
            {!this.state.isLoading && <Button classes='button button--blue' text='Run Update' onClick={() => this.runUpdate()} />}
            {this.state.isLoading && <Loader type='Bars' color='#008dce' height={30} width={30} visible={this.state.isLoading} />}
          </div>
          <div className='form-container'></div>
        </div>
        <img className='wave' src={wave} alt={''} />
      </div>
    );
  }
}

export default UpdateUserLogon;
