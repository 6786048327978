import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import ButtonBar from '../ButtonBar';
import CardCarousel from '../CardCarousel';
import ValidationCard from './ValidationCard';
import Loader from 'react-loader-spinner';
import wave from '../../images/wave.svg';
import logo from '../../images/skilltech-mimtr-logo-dark.png';
import uat_logo from '../../images/skilltech-mimtr-logo-uat-dark.png';
import TabContainer from '../TabContainer';
import './approvals-page.scss';
import Utils from '../../utils/Utils';
import Modal from '../Modal';
import MeterDetailModal from '../meter-detail-modal/MeterDetailModal';
import MarkForAuditModal from '../MarkForAuditModal';
import { Buffer } from 'buffer';
import Api from '../../api/Api';

class Approvals extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userData: props.userData,
      isLoading: true,
      isLoadingRoutes: true,
      isLoadingNextPhoto: false,
      buttonData: [],
      routeData: [],
      photoData: [],
      carouselData: { outOfTolerance: [], skip: [], adHoc: [], pole: [] },
      readerData: [],
      visiblePhotoIds: { outOfTolerance: [], skip: [], adHoc: [], pole: [] },
      selectedAreas: [],
      selectedAreaGroups: [],
      selectedRoute: null,
      selectedGroup: 'outOfTolerance',
      selectedRead: {},
      selectedSkipCode: null,
      filterActive: false,
      cardData: [],
      showModal: false,
      modalName: '',
    };
  }

  async componentDidMount() {
    this.getData();
    document.title = `${process.env.REACT_APP_ENV ? `[${process.env.REACT_APP_ENV}] ` : ''}MiMtr Hybrid | Approvals`;
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.selectedContract.id !== this.props.selectedContract.id) {
      this.setState({
        isLoading: true,
        isLoadingRoutes: true,
        buttonData: [],
        routeData: [],
        photoData: [],
        carouselData: { outOfTolerance: [], skip: [], adHoc: [], pole: [] },
        readerData: [],
        visiblePhotoIds: { outOfTolerance: [], skip: [], adHoc: [], pole: [] },
        selectedAreas: [],
        selectedAreaGroups: [],
        selectedRoute: null,
        selectedGroup: 'outOfTolerance',
        selectedRead: {},
        filterActive: false,
        cardData: [],
        showModal: false,
        modalName: '',
      });
      this.getData();
    }
  }

  async getData() {
    this.setState({ isLoading: true });
    let selectedContract = this.props.selectedContract;
    let selectedAreas = this.state.selectedAreas.length && selectedContract.id === this.state.selectedAreas[0].contractId ? this.state.selectedAreas : this.props.selectedAreas;

    let selectedAreaGroups = [];
    selectedAreas.forEach((area) => {
      if (area.contractId === selectedContract.id) {
        selectedAreaGroups.push(area.areaGroupId);
      }
    });

    selectedAreas = [
      ...new Set(
        selectedAreas.map((area) => {
          return area.id;
        })
      ),
    ];
    selectedAreaGroups = [...new Set(selectedAreaGroups)];

    let promises = [];
    promises.push(this.getRouteData(selectedAreas, selectedAreaGroups, this.state.selectedGroup));
    promises.push(Api.getCodesets(this.props.selectedContract, [1, 2, 3, 4, 5, 6]));

    Promise.all(promises).then((res) => {
      let clientRouteData = res[0];
      let troubleCodeData = res[1].filter((code) => code.categoryId === 1);
      let meterTypeCodeData = res[1].filter((code) => code.categoryId === 2);
      let instructionCodeData = res[1].filter((code) => code.categoryId === 3);
      let skipCodeData = res[1].filter((code) => code.categoryId === 4);
      let locationCodeData = res[1].filter((code) => code.categoryId === 5);
      let tapTestData = res[1].filter((code) => code.categoryId === 6);

      // Adding an 'All' option for filter
      skipCodeData.splice(0, 0, { id: 0, name: 'All' });

      this.setState({
        masterRouteData: clientRouteData,
        routeData: clientRouteData,
        troubleCodeData: troubleCodeData,
        meterTypeCodeData: meterTypeCodeData,
        instructionCodeData: instructionCodeData,
        skipCodeData: skipCodeData,
        locationCodeData: locationCodeData,
        tapTestData: tapTestData,
      });

      this.setState({
        buttonData: this.getButtonData(clientRouteData),
        selectedAreas: selectedAreas,
        selectedAreaGroups: selectedAreaGroups,
        isLoading: false,
        isLoadingRoutes: false,
        selectedGroup: 'outOfTolerance',
      });
    });
  }

  async getRouteData(selectedAreas, selectedAreaGroups, selectedGroup) {
    let contract = this.props.selectedContract;
    return new Promise((resolve, reject) => {
      let photoType = '';

      switch (selectedGroup) {
        case 'outOfTolerance':
          photoType = 1;
          break;
        case 'skip':
          photoType = 2;
          break;
        case 'adHoc':
          photoType = 5;
          break;
        case 'pole':
          photoType = 5;
          break;

        default:
          break;
      }

      let routeApiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getRoutesForValidation?DatabaseServer=${contract.dbServer}&photoType=${photoType}&contractid=${
        contract.id
      }&areaids=${selectedAreas}&areagroupids=${selectedAreaGroups}&minAdHocPhotos=${selectedGroup === 'pole' ? 3 : ''}`;
      fetch(routeApiUrl)
        .then((routesRes) => {
          if (routesRes.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
            return routesRes.json();
          }
        })
        .then((data) => {
          let routeData = _.groupBy(data, 'ClientRouteId');
          routeData = _.map(routeData, (route) => {
            return {
              areaGroupId: route[0].AreaGroupId,
              areaId: route[0].AreaId,
              contractId: route[0].ContractId,
              name: route[0].RouteDescription,
              status: route[0].RouteStatus,
              id: route[0].ClientRouteId,
              workRouteId: route.map((workRoute) => workRoute.WorkRouteId),
            };
          });

          resolve(routeData);
        })
        .catch((err) => {
          resolve([]);
        });
    });
  }

  async getPhotos(selectedAreas, selectedAreaGroups, selectedRoute, selectedSkipCode, selectedGroup, troubleCodeData) {
    return new Promise((resolve, reject) => {
      let photoData = [];
      this.getPhotoData(selectedAreas, selectedAreaGroups, selectedRoute, selectedSkipCode, selectedGroup, troubleCodeData).then((result1) => {
        photoData.push(result1);
        setTimeout(
          this.getPhotoData(selectedAreas, selectedAreaGroups, selectedRoute, selectedSkipCode, selectedGroup, troubleCodeData).then((result2) => {
            photoData.push(result2);
            this.getPhotoData(selectedAreas, selectedAreaGroups, selectedRoute, selectedSkipCode, selectedGroup, troubleCodeData).then((result3) => {
              photoData.push(result3);
              resolve(photoData);
            });
          }),
          3000
        );
      });
    });
  }

  getPhotoData(selectedAreas, selectedAreaGroups, selectedRoute, selectedSkipCode, selectedGroup, troubleCodeData) {
    let visiblePhotoIds = this.state.visiblePhotoIds;

    let contract = this.props.selectedContract;
    return new Promise((resolve, reject) => {
      let photoType = '';

      switch (selectedGroup) {
        case 'outOfTolerance':
          photoType = 1;
          break;
        case 'skip':
          photoType = 2;
          break;
        case 'adHoc':
          photoType = 5;
          break;
        case 'pole':
          photoType = 5;
          break;

        default:
          break;
      }

      let photoApiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getPhotosForValidation?DatabaseServer=${contract.dbServer}&photoType=${photoType}&contractid=${
        contract.id
      }&areaids=${selectedAreas}&areagroupids=${selectedAreaGroups}&userId=${this.state.userData.userId}&loadedPhotoIds=${visiblePhotoIds[selectedGroup]}&skipCode=${selectedSkipCode}&minAdHocPhotos=${
        selectedGroup === 'pole' ? 3 : ''
      }`;

      if (selectedRoute) {
        photoApiUrl += `&clientrouteid=${selectedRoute}`;
      }

      fetch(photoApiUrl)
        .then((photoRes) => {
          if (photoRes.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
            return photoRes.json();
          }
        })
        .then((data) => {
          if (!data.results || !data.results.length || !data.results[0]) {
            resolve();
            return;
          }

          data.results.forEach((photo) => {
            if (!visiblePhotoIds[selectedGroup].includes(photo.PictureId)) {
              console.log(visiblePhotoIds[selectedGroup]);
              console.log(`adding: ${photo.PictureId}`);
              visiblePhotoIds[selectedGroup].push(photo.PictureId);
              console.log(visiblePhotoIds[selectedGroup]);
            } else {
              // Remove later
              console.log(`new: ${photo.PictureId} meter: ${photo.MeterNumber}`);
              console.log('DUPLICATE?');
              resolve();
            }
          });

          this.setState({
            visiblePhotoIds: visiblePhotoIds,
          });

          let photoData = this.formatPhotoData(data.results, selectedGroup, troubleCodeData);

          resolve(photoData);
        })
        .finally((err) => {
          resolve();
        });
    });
  }

  formatPhotoData(photos, selectedGroup, troubleCodeData) {
    let initialPhoto = photos[0];

    let address = initialPhoto.Address.trim();
    address = Utils.titleize(address);
    address = address.replace('Nsw', 'NSW');
    address = address.replace('Qld', 'QLD');
    address = address.replace('Vic', 'VIC');
    address = address.replace('Sa', 'SA');
    address = address.replace('Nt', 'NT');
    address = address.replace('Act', 'ACT');
    address = address.replace('Wa', 'WA');
    address = address.replace('Tas', 'TAS');

    troubleCodeData = troubleCodeData ? troubleCodeData : this.state.troubleCodeData;
    let troubleCode = troubleCodeData.find((code) => code.id === initialPhoto.TroubleCode);

    let skipCode = this.state.skipCodeData ? this.state.skipCodeData.find((code) => code.id === initialPhoto.ReadReason_Code) : null;

    let photoData = {
      address: address,
      customerName: initialPhoto.CustomerName.trim().length ? initialPhoto.CustomerName : 'N/A',
      reader: initialPhoto.Reader ? initialPhoto.Reader : 'N/A',
      readerName: initialPhoto.ReaderName ? initialPhoto.ReaderName.replace('.', ' ') : 'N/A',
      readerId: initialPhoto.ReaderId ? initialPhoto.ReaderId : 'N/A',
      meterNumber: initialPhoto.NewMeterNumber ? initialPhoto.NewMeterNumber : initialPhoto.MeterNumber,
      meterId: initialPhoto.MeterId,
      workRouteNumber: initialPhoto.RouteNumber,
      workRouteId: initialPhoto.WorkRouteId,
      clientRouteNumber: initialPhoto.ClientRouteNumber.trim(),
      clientRouteId: initialPhoto.ClientRouteId,
      reading: initialPhoto.NewReading !== null && initialPhoto.NewReading !== undefined ? initialPhoto.NewReading : '',
      previousReading: initialPhoto.PreviousReading ? initialPhoto.PreviousReading : '',
      skipReason: initialPhoto.ReadReason_Code && skipCode ? skipCode.name : '',
      high: initialPhoto.HighValue,
      low: initialPhoto.LowValue,
      dials: initialPhoto.NumberOfDial,
      id: initialPhoto.PictureId,
      readDate: moment(initialPhoto.ReadTimestampUtc),
      register: initialPhoto.Register,
      utility: initialPhoto.Utility,
      troubleCode: initialPhoto.TroubleCode && troubleCode ? troubleCode.name : 'NULL',
    };

    if (['adHoc', 'pole'].includes(selectedGroup)) {
      photoData.photos = photos.map((photo) => {
        return {
          imageData: photo.ImageData,
          photoUrl: `data:image/jpeg;base64,${Buffer.from(photo.ImageData).toString('base64')}`,
          pictureId: photo.PictureId,
        };
      });
    } else {
      photoData.imageData = initialPhoto.ImageData;
      photoData.photoUrl = `data:image/jpeg;base64,${Buffer.from(initialPhoto.ImageData).toString('base64')}`;
    }

    return photoData;
  }

  getButtonData(routeData, selectedGroup) {
    selectedGroup = selectedGroup ? selectedGroup : this.state.selectedGroup;

    let selectedRoute = routeData.find((route) => route.id === this.state.selectedRoute);

    let buttonData = [
      {
        type: 'group',
        items: [
          {
            type: 'filter-button',
            text: 'Filter',
            icon: 'filter',
            iconPosition: '2',
            classes: this.state.filterActive ? 'button--active' : '',
            popover: {
              type: 'filter',
              onClick: (filterData) => this.filterResults(filterData),
              filters: [
                {
                  name: 'areaGroups',
                  displayLabel: 'Area Groups',
                  type: 'tags',
                  selection: [],
                  options: _.filter(this.props.userData.areaGroups, { contractId: this.props.selectedContract.id }),
                  selectCallback: (selectedAreaGroups, filters) => {
                    let areaIndex = filters.findIndex((filter) => filter.name === 'areas');
                    let contractAreas = _.filter(this.props.userData.areas, (area) => area.contractId === this.props.selectedContract.id);
                    filters[areaIndex].options = selectedAreaGroups ? _.filter(contractAreas, (area) => selectedAreaGroups.includes(area.areaGroupId)) : contractAreas;
                    return filters;
                  },
                },
                {
                  name: 'areas',
                  displayLabel: 'Areas',
                  type: 'select',
                  options: _.orderBy(_.filter(this.props.userData.areas, { contractId: this.props.selectedContract.id }), 'name'),
                },
                {
                  name: 'routeStatus',
                  displayLabel: 'Route Status',
                  type: 'select',
                  options: [
                    { name: 'All', id: 0 },
                    { name: 'Complete', id: 1 },
                  ],
                },
                {
                  name: 'skipCode',
                  displayLabel: 'Skip Code',
                  type: 'select',
                  hide: () => {
                    return this.state.selectedGroup === 'skip';
                  },
                  options: this.state.skipCodeData,
                },
              ],
              selections: { selectedAreaGroups: [], selectedArea: null },
            },
          },
          {
            type: 'select',
            name: 'route',
            defaultText: selectedRoute ? selectedRoute.name : 'Select A Route',
            options: routeData,
            updateFn: (route) => this.selectRoute(route),
          },
        ],
      },
    ];

    if (!['adHoc', 'pole'].includes(selectedGroup)) {
      buttonData.push({
        type: 'group',
        items: [
          {
            type: 'button',
            text: 'Mark This Route For Audit',
            classes: 'button--blue',
            icon: 'person',
            iconPosition: '1',
            onClick: () => {
              this.showModal(true, 'markForAudit');
            },
          },
        ],
      });
    }

    return buttonData;
  }

  filterResults(filterData) {
    let buttonData;

    this.setState({ isLoading: true, isLoadingRoutes: true, filterActive: _.some(filterData, 'selection') });

    if (!filterData) {
      buttonData = this.getButtonData(this.state.masterRouteData);

      const buttonGroupIndex = buttonData.findIndex((button) => button.type === 'group');
      const buttonGroupData = buttonData[buttonGroupIndex];
      const filterButtonIndex = buttonGroupData.items.findIndex((button) => button.type === 'filter-button');
      buttonGroupData.items[filterButtonIndex].classes = '';
      buttonData[buttonGroupIndex] = buttonGroupData;

      this.getPhotos(this.state.selectedAreas, this.state.selectedAreaGroups).then((photoData) => {
        let carouselData = this.state.carouselData;
        carouselData[this.state.selectedGroup] = photoData.filter((photo) => !!photo).map((photo) => this.getCarouselCard(photo));

        this.setState({
          buttonData: buttonData,
          carouselData: carouselData,
          selectedRoute: null,
          selectedSkipCode: null,
          isLoading: false,
          isLoadingRoutes: false,
        });
      });
    } else {
      const selectedAreaGroups = filterData[0].selection && filterData[0].selection.length > 0 ? filterData[0].selection : this.state.selectedAreaGroups;
      const routeStatus = filterData[2].selection;
      console.log('Filtered routestatus ' + routeStatus);
      const skipCode = filterData[3].selection ? filterData[3].selection.id : null;

      let selectedAreas = [];
      if (filterData[1].selection) {
        selectedAreas = [filterData[1].selection.id];
      } else if (filterData[0]) {
        selectedAreas = this.props.selectedAreas.filter((area) => selectedAreaGroups.includes(area.areaGroupId) && area.contractId === this.props.selectedContract.id).map((area) => area.id);
      }

      buttonData = this.state.buttonData;

      const buttonGroupIndex = buttonData.findIndex((button) => button.type === 'group');
      const buttonGroupData = buttonData[buttonGroupIndex];
      const filterButtonIndex = buttonGroupData.items.findIndex((button) => button.type === 'filter-button');
      const filterPopoverData = buttonGroupData.items[filterButtonIndex].popover;
      filterPopoverData.filters = filterData;

      const selectIndex = buttonGroupData.items.findIndex((button) => button.type === 'select');
      const selectData = buttonGroupData.items[selectIndex];

      selectData.options = this.state.masterRouteData.filter((route) => {
        return (
          (!selectedAreaGroups || !selectedAreaGroups.length || selectedAreaGroups.includes(route.areaGroupId)) &&
          (!selectedAreas || !selectedAreas.length || selectedAreas.includes(route.areaId)) &&
          (!routeStatus || !routeStatus.length || routeStatus.id === route.status)
        );
      });

      buttonGroupData.items[filterButtonIndex].popover = filterPopoverData;
      buttonGroupData.items[filterButtonIndex].classes = _.some(filterData, 'selection') ? 'button--active' : '';
      buttonGroupData.items[selectIndex] = selectData;
      buttonData[buttonGroupIndex] = buttonGroupData;

      this.getPhotos(selectedAreas, selectedAreaGroups, null, skipCode, this.state.selectedGroup).then((photoData) => {
        let carouselData = this.state.carouselData;
        carouselData[this.state.selectedGroup] = photoData.filter((photo) => !!photo).map((photo) => this.getCarouselCard(photo));

        this.setState({
          buttonData: buttonData,
          carouselData: carouselData,
          selectedRoute: null,
          selectedSkipCode: skipCode,
          isLoading: false,
          isLoadingRoutes: false,
        });
      });
    }
  }

  selectGroup(tabName) {
    this.setState({
      isLoading: true,
    });

    this.getRouteData(this.state.selectedAreas, this.state.selectedAreaGroups, tabName).then((routeData) => {
      let buttonData = this.getButtonData(this.state.routeData, tabName);
      const buttonGroupIndex = buttonData.findIndex((button) => button.type === 'group');
      const routeSelectIndex = buttonData[buttonGroupIndex].items.findIndex((button) => button.type === 'select');
      buttonData[buttonGroupIndex].items[routeSelectIndex].options = routeData;

      if (tabName === 'skip' && !this.state.carouselData[tabName].length) {
        this.getPhotos(this.state.selectedAreas, this.state.selectedAreaGroups, null, null, tabName, this.state.troubleCodeData).then((photoData) => {
          let carouselData = this.state.carouselData;
          carouselData[tabName] = photoData.filter((photo) => !!photo).map((photo) => this.getCarouselCard(photo, tabName));

          this.setState({
            selectedRead: photoData[0],
            selectedGroup: tabName,
            routeData: routeData,
            buttonData: buttonData,
            carouselData: carouselData,
            photoData: photoData,
            isLoading: false,
          });
        });
      } else {
        this.setState({
          selectedGroup: tabName,
          routeData: routeData,
          buttonData: buttonData,
          isLoading: false,
        });
      }
    });
  }

  selectRoute(route) {
    this.setState({ isLoading: true });
    let visiblePhotoIds = this.state.visiblePhotoIds;
    visiblePhotoIds[this.state.selectedGroup] = [];
    visiblePhotoIds.adHoc = [];
    visiblePhotoIds.pole = [];

    this.setState({ visiblePhotoIds: visiblePhotoIds });

    this.getPhotos(this.state.selectedAreas, this.state.selectedAreaGroups, route.id, null, this.state.selectedGroup).then((photoData) => {
      let carouselData = this.state.carouselData;
      carouselData[this.state.selectedGroup] = photoData.filter((photo) => !!photo).map((photo) => this.getCarouselCard(photo));

      this.setState({
        carouselData: carouselData,
        selectedRoute: route.id,
        isLoading: false,
      });
    });
  }

  getCarouselCard(reading, selectedGroup) {
    if (!reading) {
      return;
    }
    reading.photoType = selectedGroup ? selectedGroup : this.state.selectedGroup;
    return (
      <ValidationCard
        data={reading}
        key={reading.id}
        openDetailsFn={() => this.openMeterDetails(reading)}
        submitFn={(reading, validation) => this.submitValidation(reading, validation)}
        prevFn={() => this.slider.slickPrev()}
        nextFn={() => this.slider.slickNext()}
        isLoadingNextPhoto={this.state.isLoadingNextPhoto}
      />
    );
  }

  async onCardChange(current) {
    let carouselData = this.state.carouselData;
    let groupData = carouselData[this.state.selectedGroup];

    if (current >= groupData.length - 3) {
      this.setState({
        isLoadingNextPhoto: true,
      });
      await this.getPhotoData(this.state.selectedAreas, this.state.selectedAreaGroups, this.state.selectedRoute, this.state.selectedSkipCode, this.state.selectedGroup).then((data) => {
        if (!data) {
          setTimeout(
            this.getPhotoData(this.state.selectedAreas, this.state.selectedAreaGroups, this.state.selectedRoute, this.state.selectedSkipCode, this.state.selectedGroup).then((data2) => {
              data = data2;
            }),
            3000
          );
        }

        if (data) {
          groupData.push(this.getCarouselCard(data));
        }

        this.setState({
          carouselData: carouselData,
          isLoadingNextPhoto: false,
        });
      });
    }
  }

  submitValidation(reading, validStatus, dismissed) {
    let selectedGroup = this.state.selectedGroup;
    let clientRoute = this.state.routeData.find((route) => route.id === reading.clientRouteId);

    // If rejecting and it's an OOT or Skip, and the route is not completed (status 90), ask if want to mark for audit
    // {!this.state.isLoadingRoutes && <ButtonBar buttonData={this.state.buttonData}/>}
    if (!validStatus && !['adHoc', 'pole'].includes(selectedGroup) && clientRoute.status < 90 && !dismissed) {
      this.setState({
        selectedRead: reading,
      });

      this.showModal(true, 'markForAudit');
      return new Promise((resolve) => {
        resolve({});
      });
    }

    return new Promise((resolve, reject) => {
      let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/validatephoto`;

      let photoType;

      switch (selectedGroup) {
        case 'outOfTolerance':
          photoType = 1;
          break;
        case 'skip':
          photoType = 2;
          break;
        case 'adHoc':
          photoType = 5;
          break;
        case 'pole':
          photoType = 5;
          break;

        default:
          break;
      }

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          pictureid: reading.photos && reading.photos.length > 1 ? reading.photos.map((photo) => photo.pictureId).join(',') : reading.id,
          userid: this.state.userData.userId,
          contractid: this.props.selectedContract.id,
          DatabaseServer: this.props.selectedContract.dbServer,
          newreading: reading.newReading,
          photoType: photoType,
          validateresult: validStatus ? 1 : 0,
        }),
      };

      fetch(apiUrl, requestOptions)
        .then((response) => {
          if (response.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
            return response.json();
          }
        })
        .then((data) => {
          if (data.message === 'PASS') {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });

            this.setState({
              isLoadingRoutes: true,
            });

            let routeData = this.state.routeData;
            let matchingClientRoute = routeData.find((route) => route.id === reading.clientRouteId);

            // Remove the photos just validated from the client route photo count
            matchingClientRoute.name = matchingClientRoute.name.replace(/([^,]+$)/, (match) => {
              let photoCount = match.split(' ')[1];
              return ` ${parseInt(photoCount) - reading.photos.length} Photos.`;
            });

            this.setState({
              buttonData: this.getButtonData(routeData),
              routeData: routeData,
              isLoadingRoutes: false,
            });
            this.slider.slickNext();
          }
        });
      resolve();
    });
  }

  submitMarkForAudit(selectedRead, selectAll) {
    let reading = selectedRead;
    return new Promise((resolve, reject) => {
      let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/markForAudit`;
      let body = {
        DatabaseServer: this.props.selectedContract.dbServer,
      };

      if (selectAll) {
        body.clientRouteId = reading.clientRouteId;
        body.photoType = this.state.selectedGroup === 'skip' ? 2 : 1;
      } else {
        body.meterId = reading.meterId;
      }

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      };

      fetch(apiUrl, requestOptions)
        .then((response) => {
          if (response.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
            return response.json();
          }
        })
        .then((data) => {
          this.showModal(false);
          this.slider.slickNext();
        });
      resolve();
    });
  }

  async getMeterDetails(id) {
    let meterDetailsApi = `${process.env.REACT_APP_API_ENDPOINT}/getjobdetails?DatabaseServer=${this.props.selectedContract.dbServer}&meterid=${id}`;

    return new Promise((resolve) => {
      fetch(meterDetailsApi)
        .then((searchRes) => {
          if (searchRes.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
            return searchRes.json();
          }
        })
        .then((data) => {
          let results = {};
          results.readings = data.results[0].map((result) => {
            let instructionCodeData = this.state.instructionCodeData;
            let readInstruction1 = instructionCodeData.find((code) => code.id === result.NewReadInstruction1_Code);
            let readInstruction2 = instructionCodeData.find((code) => code.id === result.NewReadInstruction2_Code);

            let meterType = this.state.meterTypeCodeData.find((code) => code.id === result.NewMeterType);

            let troubleCodeData = this.state.troubleCodeData;
            let troubleCode1 = troubleCodeData.find((code) => code.id === result.NewTroubleCode1_Code);
            let troubleCode2 = troubleCodeData.find((code) => code.id === result.NewTroubleCode2_Code);

            let meterLocation = this.state.locationCodeData.find((code) => code.id === result.NewMeterLocation_Code);

            let tapTestResult = this.state.tapTestData.find((code) => code.id === result.TapTestResult_Code);

            return {
              meterId: result.MeterId,
              meterNumber: result.NewMeterNumber,
              troubleMessage: result.TroubleMessage,
              specialMessage: result.NewMeterSpecialMessage,
              meterInformation: result.NewMeterInformation,
              newMeterLocation: meterLocation ? meterLocation.name : '',
              newReadInstruction1: readInstruction1 ? readInstruction1.name : '',
              newReadInstruction2: readInstruction2 ? readInstruction2.name : '',
              meterType: meterType ? meterType.name : '',
              readerId: result.ReaderId,
              readTimestampUtc: result.ReadTimestampUtc,
              newReading: result.NewReading,
              longitude: result.Longitude,
              latitude: result.Latitude,
              newTroubleCode1: troubleCode1 ? troubleCode1.name : 'NULL',
              newTroubleCode2: troubleCode2 ? troubleCode2.name : '',
              readReasonCode: result.ReadReason_Code,
              tapTestResult: tapTestResult ? tapTestResult.name : '',
              textPrompt: result.TextPrompt.trim(),
              readingId: result.readingId,
            };
          });

          results.photos = data.results[1].map((result) => {
            return {
              pictureId: result.PictureId,
              imageData: result.ImageData,
              pictureType: result.PictureType,
              readingId: result.ReadId,
              reviewed: result.Reviewed,
              reviewedBy: result.ReviewedBy ? result.ReviewedBy.replace('.', ' ') : 'N/A',
              reviewedDate: result.LastUpdatedAtUtc ? moment(result.LastUpdatedAtUtc) : null,
            };
          });

          results.photos = _.groupBy(results.photos, (photo) => photo.pictureType);

          resolve(results);
        })
        .catch(() => {});
    });
  }

  async openMeterDetails(reading) {
    await this.getMeterDetails(reading.meterId).then((meterDetails) => {
      let registers = meterDetails.readings.map((register) => {
        return {
          textPrompt: register.textPrompt,
          newReading: register.newReading,
        };
      });

      meterDetails.readings = meterDetails.readings[0];
      meterDetails.readings.registers = registers;
      meterDetails.readings.dateCompleted = reading.readDate;
      meterDetails.readings.workRouteNumber = reading.workRouteNumber;
      meterDetails.readings.meterNumber = reading.meterNumber;
      meterDetails.readings.address = reading.address;
      meterDetails.readings.skipReason = reading.skipReason;
      meterDetails.readings.completedBy = reading.completedBy;
      meterDetails.readings.areaGroup = reading.areaGroup;
      meterDetails.readings.status = reading.status;
      meterDetails.readings.index = reading.index;

      this.setState({ cardData: meterDetails });
      this.showModal(true, 'meterDetails');
    });
  }

  showModal(show, modalType) {
    const showingModal = this.state.showModal;

    this.setState({
      showModal: show != null ? show : !showingModal,
      modalType: !showingModal ? modalType : '',
    });
  }

  render() {
    const groups = [];

    ['outOfTolerance', 'skip', 'adHoc'].forEach((groupName) => {
      let group = { name: groupName };
      groups.push(group);
    });

    if (this.props.userData.securityRoles.canApprovePolePhotos && this.props.selectedContract.name === 'Essential Energy') {
      groups.push({ name: 'pole' });
    }

    const settings = {
      dots: false,
      arrows: false,
      infinite: false,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      afterChange: (current) => this.onCardChange(current),
    };

    let carouselData = this.state.carouselData[this.state.selectedGroup];

    return (
      <div className={`main-content approvals-page`}>
        <header>
          <h1>Approvals</h1>
          <img className='logo-container' src={process.env.REACT_APP_ENV ? uat_logo : logo} alt='' />
        </header>
        <TabContainer
          id={'search'}
          groups={groups}
          selectedTab={this.state.selectedGroup}
          onClick={(name) => this.selectGroup(name)}
          isLoading={this.state.isLoading}
          ref={(c) => (this.tabContainer = c)}
        />

        {<ButtonBar buttonData={this.state.buttonData} />}
        {!this.state.isLoading && carouselData.length > 0 && (
          <CardCarousel settings={settings} data={carouselData} reference={(c) => (this.slider = c)} isLoadingNextPhoto={this.state.isLoadingNextPhoto} />
        )}
        {!this.state.isLoading && !this.state.selectedRoute && <div className='empty-state'>Please select a route to start approving.</div>}
        {!this.state.isLoading && this.state.selectedRoute && !carouselData.length && <div className='empty-state'>There are no photos to approve.</div>}

        {this.state.isLoading && (
          <div className='spinner-container'>
            <Loader type='Bars' color='#008dce' height={30} width={30} visible={this.state.isLoading} />
          </div>
        )}
        <img className='wave' src={wave} alt={''} />
        {this.state.showModal && (
          <Modal classes={this.state.modalType === 'markForAudit' ? 'popup' : ''} showModalFn={(show) => this.showModal(show)} showModal={this.state.showModal}>
            {this.state.modalType === 'meterDetails' && <MeterDetailModal data={this.state.cardData} />}
            {this.state.modalType === 'markForAudit' && (
              <MarkForAuditModal
                showModalFn={(show, dismissed) => {
                  if (dismissed) {
                    this.submitValidation(this.state.selectedRead, false, dismissed);
                  }
                  this.showModal(show);
                }}
                selectedReads={[this.state.selectedRead]}
                photoType={this.state.selectedGroup}
                submitFn={(selectAll) => this.submitMarkForAudit(this.state.selectedRead, selectAll)}
              />
            )}
          </Modal>
        )}
      </div>
    );
  }
}
export default Approvals;
