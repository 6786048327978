import React from 'react';
import TabContainer from '../TabContainer';
import wave from '../../images/wave.svg';
import logo from '../../images/skilltech-mimtr-logo-dark.png';
import uat_logo from '../../images/skilltech-mimtr-logo-uat-dark.png';
import Utils from '../../utils/Utils';
import _ from 'lodash';
import moment from 'moment';
import ButtonBar from '../ButtonBar';
import Icon from '../Icon';
import Loader from 'react-loader-spinner';
import Button from '../Button';
import ExportUtils from '../../utils/ExportUtils';
import Modal from '../Modal';
import ManualQuintiqExportModal from './ManualQuintiqExportModal';
import { ROLE } from '../../constants/UserRole';
import { TIMESHEET_STATUS } from '../../constants/TimesheetStatus';
import TimesheetApproverDetailsModal from './TimesheetApproverDetailsModal';

class ManageTimesheets extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      masterData: {},
      timesheetsData: {},
      buttonData: [],
      isLoading: true,
      bulkSelecting: false,
      selectAll: false,
      selectedGroup: 'pendingApproval',
      selectedColumn: null,
      categoryType: 'all',
      statusData: [],
      dateFrom: moment().startOf('week'),
      dateTo: moment().endOf('week'),
      showModal: false,
    };
  }

  async componentDidMount() {
    document.title = `${process.env.REACT_APP_ENV ? `[${process.env.REACT_APP_ENV}] ` : ''}MiMtr Hybrid | Manage Timesheets`;

    let manageTimesheetSession = JSON.parse(localStorage.getItem('manageTimesheetSession'));
    if (manageTimesheetSession) {
      this.setState(
        {
          dateFrom: moment(manageTimesheetSession.dateFrom),
          dateTo: moment(manageTimesheetSession.dateTo),
        },
        () => {
          this.getData();
        }
      );
    } else {
      this.getData();
    }
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.selectedContract.id !== this.props.selectedContract.id) {
      this.setState({
        bulkSelecting: false,
        selectAll: false,
        searchFn: null,
      });
      this.getData();
    }
  }

  async getData() {
    this.setState({ isLoading: true });
    await this.getTimesheetStatuses().then((statusData) => {
      statusData = statusData.filter((status) => {
        return ![TIMESHEET_STATUS.DRAFT].includes(status.id);
      });

      this.setState({
        statusData: statusData,
        selectedGroup: statusData[0].name,
      });

      this.getCategories('time').then((timeCategories) => {
        this.getCategories('allowance').then((allowanceCategories) => {
          this.getTimesheetsData(timeCategories, allowanceCategories).then((timesheetsData) => {
            this.getMissingTimesheets().then((missingTimesheetsData) => {
              timesheetsData.missing = missingTimesheetsData;
              this.setState({
                buttonData: this.getButtonData(),
                masterData: timesheetsData,
                timesheetsData: timesheetsData,
                timeCategoriesData: timeCategories,
                allowanceCategoriesData: allowanceCategories,
                isLoading: false,
              });
            });
          });
        });
      });
    });
  }

  async getTimesheetStatuses() {
    return new Promise((resolve, reject) => {
      let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getTimesheetStatuses`;

      fetch(apiUrl)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
            return res.json();
          }
        })
        .then((data) => {
          let statusData = data.results.map((code) => {
            return {
              id: code.timesheetStatusId,
              name: Utils.camelize(code.timesheetStatusName),
            };
          });

          statusData.push({ id: -1, name: 'missing' });

          resolve(statusData);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  async getCategories(type) {
    return new Promise((resolve, reject) => {
      let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/${type === 'time' ? 'getTimeEntryCategories' : 'getAllowanceCategories'}`;
      fetch(apiUrl)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
            return res.json();
          }
        })
        .then((data) => {
          let categoryData;

          if (type === 'time') {
            categoryData = data.results.map((category) => {
              return {
                id: category.timeCategoryId,
                name: category.timeCategoryName,
              };
            });
          } else {
            categoryData = data.results.map((category) => {
              return {
                id: category.allowanceTypeId,
                name: category.allowanceName,
                unitsName: category.unitsName,
              };
            });
          }
          resolve(categoryData);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  async getTimesheetsData(timeCategories, allowanceCategories) {
    return new Promise((resolve, reject) => {
      let dateFrom = this.state.dateFrom.format('YYYY-MM-DD');
      let dateTo = this.state.dateTo.format('YYYY-MM-DD');

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          dateFrom: dateFrom,
          dateTo: dateTo,
          contractId: this.props.selectedContract.id,
          approverId: this.props.userData.userId,
        }),
      };

      let manageTimesheetsApiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getReaderTimesheets`;
      fetch(manageTimesheetsApiUrl, requestOptions)
        .then((timesheetsRes) => {
          return timesheetsRes.json();
        })
        .then((data) => {
          let timesheetsData = {};

          // Group timesheets by status
          let groupedData = _.groupBy(data.results, (timesheet) => {
            switch (timesheet.TimesheetStatusId) {
              case TIMESHEET_STATUS.AMENDED_BY_PROVISIONAL_APPROVER:
                return TIMESHEET_STATUS.AMENDED;
              case TIMESHEET_STATUS.RETURNED_BY_PROVISIONAL_APPROVER:
                return TIMESHEET_STATUS.RETURNED;
              default:
                return timesheet.TimesheetStatusId;
            }
          });

          timeCategories = timeCategories ? timeCategories : this.state.timeCategoriesData;
          allowanceCategories = allowanceCategories ? allowanceCategories : this.state.allowanceCategoriesData;
          let categories = [...timeCategories, ...allowanceCategories];

          _.forEach(groupedData, (group, statusId) => {
            // Group data by readers, in groups of dates
            let employeeDates = _.groupBy(group, 'ReaderName');

            // Employee Timesheets
            let employeeTimesheetData = _.map(employeeDates, (employee, name) => {
              let employeeTotals = {};
              categories.forEach((category) => {
                let totalTime = _.sumBy(employee, category.name);

                if (totalTime) {
                  if (timeCategories.map((category) => category.name).includes(category.name)) {
                    totalTime = Utils.toHoursAndMinutes(totalTime);
                  } else if (allowanceCategories.map((category) => category.name).includes(category.name)) {
                    totalTime = totalTime.toFixed(2);
                  }
                }

                employeeTotals[category.name] = totalTime;
              });

              let dateTimesheetData = employeeDates[name].map((date) => {
                let dateTotals = {};
                _.forEach(date, (totalTime, category) => {
                  if (totalTime) {
                    if (timeCategories.map((category) => category.name).includes(category)) {
                      dateTotals[category] = Utils.toHoursAndMinutes(totalTime);
                    } else if (allowanceCategories.map((category) => category.name).includes(category)) {
                      dateTotals[category] = totalTime.toFixed(2);
                    }
                  } else {
                    dateTotals[category] = totalTime;
                  }
                });

                return {
                  id: date.TimesheetId,
                  name: moment(date.EventDate).format('DD/MM/YYYY'),
                  date: date.EventDate,
                  totals: dateTotals,
                  timesheetStatusId: date.TimesheetStatusId,
                };
              });

              return {
                id: employee[0].ReaderId,
                name: name,
                totals: employeeTotals,
                dates: _.sortBy(dateTimesheetData, 'date'),
                count: dateTimesheetData.length,
              };
            });

            let contractTotals = {};
            categories.forEach((category) => {
              let totalTime = _.sumBy(group, category.name);

              if (totalTime) {
                if (timeCategories.map((category) => category.name).includes(category.name)) {
                  totalTime = Utils.toHoursAndMinutes(totalTime);
                } else if (allowanceCategories.map((category) => category.name).includes(category.name)) {
                  totalTime = totalTime.toFixed(2);
                }
              }

              contractTotals[category.name] = totalTime;
            });

            let contractTimesheetData = {
              id: this.props.selectedContract.id,
              name: this.props.selectedContract.name,
              selected: false,
              totals: contractTotals,
              employees: _.sortBy(employeeTimesheetData, 'name'),
              count: _.sumBy(employeeTimesheetData, 'count'),
              expanded: true,
            };

            let matchingStatus = this.state.statusData.find((status) => parseInt(status.id) === parseInt(statusId));
            timesheetsData[matchingStatus.name] = contractTimesheetData;
          });

          resolve(timesheetsData);
        })
        .catch((err) => {});
    });
  }

  async getMissingTimesheets() {
    return new Promise((resolve, reject) => {
      let dateFrom = this.state.dateFrom.format('YYYY-MM-DD');
      let dateTo = this.state.dateTo.format('YYYY-MM-DD');

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          dateFrom: dateFrom,
          dateTo: dateTo,
          contractId: this.props.selectedContract.id,
          approverId: this.props.userData.userId,
        }),
      };

      let missingTimesheetsApiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getMissingTimesheets`;
      fetch(missingTimesheetsApiUrl, requestOptions)
        .then((missingTimesheetsRes) => {
          return missingTimesheetsRes.json();
        })
        .then((data) => {
          if (data.results) {
            data = data.results.map((timesheet) => {
              return {
                employeeId: timesheet.EmployeeId,
                readerName: timesheet.ReaderName,
                readerId: timesheet.ReaderId,
                productive: Utils.toHoursAndMinutes(timesheet.Productive),
                date: moment(timesheet.EventDate).format('DD/MM/YYYY'),
              };
            });

            data = _.sortBy(data, ['readerName', 'date']);
          } else {
            data = [];
          }

          resolve(data);
        });
    });
  }

  getButtonData(selectedGroup) {
    selectedGroup = selectedGroup ? selectedGroup : this.state.selectedGroup;
    let buttonData = [];
    if (selectedGroup === 'pendingApproval') {
      buttonData.push({
        type: 'bulk-select-button',
        classes: 'button--plain',
        text: 'Bulk Select',
        icon: 'checkbox',
        iconPosition: '1',
        actionItems: [{ text: 'Update All', onClick: (ids, status) => this.bulkUpdateTimesheets(ids, status), getPopover: () => this.getBulkUpdatePopover() }],
        selectAll: () => this.selectRow('all', null, null, null, true),
        toggleBulkSelectFn: () => this.toggleBulkSelecting(),
        count: 0,
      });
    } else if (selectedGroup === 'provisionallyApproved') {
      buttonData.push({
        type: 'bulk-select-button',
        classes: 'button--plain',
        text: 'Bulk Select',
        icon: 'checkbox',
        iconPosition: '1',
        actionItems: [{ text: 'Update All', onClick: (ids, status) => this.bulkUpdateTimesheets(ids, status), getPopover: () => this.getBulkUpdatePopoverProv() }],
        selectAll: () => this.selectRow('all', null, null, null, true),
        toggleBulkSelectFn: () => this.toggleBulkSelecting(),
        count: 0,
      });
    } else {
      buttonData.push({ type: 'text' });
    }

    buttonData.push({
      type: 'group',
      items: [
        {
          type: 'toggle-button',
          name: 'categoryType',
          selection: this.state.categoryType,
          options: [
            { name: 'All', value: 'all' },
            { name: 'Time', value: 'time' },
            { name: 'Allowances', value: 'allowances' },
          ],
          onClick: (value) => this.updateToggle('categoryType', value),
        },
        {
          type: 'date-picker-select',
          name: 'dateRange',
          displayLabel: 'Date Range',
          range: true,
          selection: { start: this.state.dateFrom, end: this.state.dateTo },
          updateFn: (name, dateRange) => this.changeDate(dateRange),
        },
        {
          type: 'filter-button',
          text: 'Filter',
          icon: 'filter',
          iconPosition: '2',
          popover: {
            type: 'filter',
            onClick: (filterData) => this.filterResults(filterData),
            exclusiveFilterSelection: true,
            filters: [
              {
                name: 'minAvgFieldDutiesHours',
                displayLabel: 'Avg. Field Duties Hours >',
                type: 'number',
                selection: '',
              },
              {
                name: 'minSystemDiscrepancy',
                displayLabel: 'System Discrepancy >',
                type: 'select',
                selection: null,
                options: [
                  { name: '15 min', value: 15 },
                  { name: '30 min', value: 30 },
                  { name: '60 min', value: 60 },
                  { name: '> 60 min', value: 61 },
                ],
              },
              {
                name: 'maxSystemDiscrepancy',
                displayLabel: 'System Discrepancy <=',
                type: 'number',
                selection: '',
              },
              {
                name: 'maxMealBreakMins',
                displayLabel: 'Meal Break Mins <',
                type: 'number',
                selection: '',
              },
            ],
          },
          showCondition: () => {
            return this.state.selectedGroup !== 'missing';
          },
        },
        { type: 'search-input', onChange: (key) => this.searchResults(key), placeholder: 'Search Employee' },
      ],
    });

    return buttonData;
  }

  selectStatusGroup(tabName) {
    if (this.state.bulkSelecting) {
      this.toggleBulkSelecting(false);
    }

    if (tabName === 'missing') {
      this.filterResults();
    }

    this.setState({
      selectedGroup: tabName,
      buttonData: this.getButtonData(tabName),
    });
  }

  changeDate(dateRange) {
    this.setState(
      {
        dateTo: dateRange.end,
        dateFrom: dateRange.start,
        isLoading: true,
      },
      () => {
        let manageTimesheetSession = {
          dateTo: dateRange.end.format('YYYY/MM/DD'),
          dateFrom: dateRange.start.format('YYYY/MM/DD'),
        };

        localStorage.setItem('manageTimesheetSession', JSON.stringify(manageTimesheetSession));

        this.getTimesheetsData().then((timesheetsData) => {
          this.getMissingTimesheets().then((missingTimesheetsData) => {
            timesheetsData.missing = missingTimesheetsData;

            let buttonData = this.state.buttonData;
            // const buttonGroupIndex = buttonData.findIndex(button => button.type === 'group');
            const buttonGroup = buttonData.find((button) => button.type === 'group');
            const dateRangeButton = buttonGroup.items.find((button) => button.name === 'dateRange');
            dateRangeButton.selection = { start: dateRange.start, end: dateRange.end };

            this.setState({
              masterData: timesheetsData,
              timesheetsData: timesheetsData,
              buttonData: buttonData,
              isLoading: false,
            });
          });
        });
      }
    );
  }

  searchResults(searchTerm) {
    let data = _.cloneDeep(this.state.masterData);
    let searchFn = null;

    if (searchTerm) {
      searchFn = function (result, group) {
        if (group === 'missing') {
          return result.readerName.toLowerCase().includes(searchTerm.toLowerCase());
        } else {
          return result.name.toLowerCase().includes(searchTerm.toLowerCase());
        }
      };
    }

    let timeCategories = this.state.timeCategoriesData;
    let allowanceCategories = this.state.allowanceCategoriesData;
    let categories = [...timeCategories, ...allowanceCategories];

    _.forEach(data, (group, key) => {
      if (searchTerm) {
        if (key !== 'missing') {
          data[key].employees = group.employees.filter((result) => searchFn(result, key));
          data[key].count = _.sumBy(data[key].employees, (employee) => employee.count);

          if (this.state.filterFn) {
            data[key].employees = data[key].employees.filter((result) => this.state.filterFn(result, key));
          }

          categories.forEach((category) => {
            let isTime = timeCategories.map((category) => category.name).includes(category.name);
            let isAllowance = allowanceCategories.map((category) => category.name).includes(category.name);
            let total = _.sumBy(data[key].employees, (employee) => {
              if (employee.totals[category.name] === undefined) {
                return 0;
              }

              if (isTime) {
                return Utils.toMinutes(employee.totals[category.name]);
              } else if (isAllowance) {
                return parseFloat(employee.totals[category.name]);
              }
            });

            if (total) {
              if (isTime) {
                total = Utils.toHoursAndMinutes(total);
              } else if (isAllowance) {
                total = total.toFixed(2);
              }
            }

            data[key].totals[category.name] = total;
          });
        } else {
          data[key] = group.filter((result) => searchFn(result, key));
        }
      }
    });

    this.setState({
      timesheetsData: data,
      searchFn: searchFn,
    });
  }

  filterResults(filterData) {
    let filterFn;
    let buttonData;
    let data = _.cloneDeep(this.state.masterData);

    if (!filterData) {
      filterFn = (result) => {
        return result;
      };
      buttonData = this.getButtonData();

      if (this.state.searchFn) {
        _.forEach(data, (group, key) => {
          if (key !== 'missing') {
            data[key].employees = data[key].employees.filter((result) => this.state.searchFn(result, key));
          } else {
            data[key] = data[key].filter((result) => this.state.searchFn(result, key));
          }
        });
      }
    } else {
      const selectedAvgFieldDutiesHours = filterData[0].selection;
      const selectedMinSystemDiscrepancy = filterData[1].selection;
      const selectedMaxSystemDiscrepancy = filterData[2].selection;
      const selectedMealBreakMins = filterData[3].selection;

      filterFn = (employee) => {
        let avgFieldDuties = 0;

        // Only show employees who have more than the selected average field duties hours
        if (selectedAvgFieldDutiesHours !== '') {
          // Get the employee's total Field Duties time divided by the number of dates
          avgFieldDuties = Utils.toMinutes(employee.totals['Field Duties']) / employee.dates.length / 60;
        }

        // Only show dates that have more than the selected system discrepancy minutes
        if (selectedMinSystemDiscrepancy) {
          // For each date compare System Productive to actual Field Duties
          employee.dates = employee.dates.filter((date) => {
            let totalFieldDuties = Utils.toMinutes(date.totals['Field Duties']);
            let totalSystemProductive = Utils.toMinutes(date.totals['Productive (System)']);
            return Math.abs(totalFieldDuties - totalSystemProductive) >= selectedMinSystemDiscrepancy.value;
          });
        }

        // Only show dates that have less than or equal to the selected system discrepancy minutes
        if (selectedMaxSystemDiscrepancy) {
          // For each date compare System Productive to actual Field Duties
          employee.dates = employee.dates.filter((date) => {
            let totalFieldDuties = Utils.toMinutes(date.totals['Field Duties']);
            let totalSystemProductive = Utils.toMinutes(date.totals['Productive (System)']);

            return Math.abs(totalFieldDuties - totalSystemProductive) <= selectedMaxSystemDiscrepancy;
          });
        }

        // Only show dates that have less than the selected meal break minutes
        if (selectedMealBreakMins !== '') {
          employee.dates = employee.dates.filter((date) => {
            let totalMealBreak = Utils.toMinutes(date.totals['Meal Break']);
            return totalMealBreak <= parseFloat(selectedMealBreakMins);
          });
        }

        return (
          (selectedAvgFieldDutiesHours === '' || avgFieldDuties >= parseFloat(selectedAvgFieldDutiesHours)) &&
          (!selectedMinSystemDiscrepancy || employee.dates.length > 0) &&
          (!selectedMaxSystemDiscrepancy || employee.dates.length > 0) &&
          (selectedMealBreakMins === '' || employee.dates.length > 0)
        );
      };

      buttonData = this.state.buttonData;

      const buttonGroupIndex = buttonData.findIndex((button) => button.type === 'group');
      const buttonGroupData = buttonData[buttonGroupIndex];
      const filterButtonIndex = buttonGroupData.items.findIndex((button) => button.type === 'filter-button');
      const filterPopoverData = buttonGroupData.items[filterButtonIndex].popover;
      filterPopoverData.filters = filterData;

      buttonGroupData.items[filterButtonIndex].classes = _.some(
        _.filter(filterData, (filter) => !filter.hide || (typeof filter.hide === 'function' && !filter.hide())),
        (filter) => filter.selection !== '' && filter.selection !== null
      )
        ? 'button--active'
        : '';
      buttonGroupData.items[filterButtonIndex].popover = filterPopoverData;
      buttonData[buttonGroupIndex] = buttonGroupData;

      let timeCategories = this.state.timeCategoriesData;
      let allowanceCategories = this.state.allowanceCategoriesData;
      let categories = [...timeCategories, ...allowanceCategories];

      _.forEach(data, (group, key) => {
        if (key !== 'missing') {
          // Run the filter function
          data[key].employees = group.employees.filter((result) => filterFn(result, key));

          // Run search function if necessary
          if (this.state.searchFn) {
            data[key].employees = data[key].employees.filter((result) => this.state.searchFn(result, key));
          }

          // Recount the tab counts
          data[key].count = _.sumBy(data[key].employees, (employee) => employee.count);

          // Recalculate the category totals
          categories.forEach((category) => {
            let isTime = timeCategories.map((category) => category.name).includes(category.name);

            // Recalculate employee total from employee date totals
            data[key].employees.forEach((employee) => {
              if (employee.totals[category.name]) {
                let employeeTotal = _.sumBy(employee.dates, (date) => (isTime ? Utils.toMinutes(date.totals[category.name]) : parseFloat(date.totals[category.name])));
                employee.totals[category.name] = employeeTotal ? (isTime ? Utils.toHoursAndMinutes(employeeTotal) : employeeTotal.toFixed(2)) : 0;
              }
            });

            // Recalculate contract total from employee totals
            if (data[key].totals[category.name]) {
              let contractTotal = _.sumBy(data[key].employees, (employee) => (isTime ? Utils.toMinutes(employee.totals[category.name]) : parseFloat(employee.totals[category.name])));
              data[key].totals[category.name] = contractTotal ? (isTime ? Utils.toHoursAndMinutes(contractTotal) : contractTotal.toFixed(2)) : 0;
            }
          });
        } else if (this.state.searchFn) {
          data[key] = data[key].filter((result) => this.state.searchFn(result, key));
        }
      });
    }

    this.setState({
      buttonData: buttonData,
      timesheetsData: data,
      filterFn: filterFn ? filterFn : null,
    });
  }

  toggleBulkSelecting(toggle) {
    // Switch to the opposite state
    const bulkSelecting = toggle !== undefined ? toggle : !this.state.bulkSelecting;

    // Clear changes when toggling bulk select
    const bulkButton = this.state.buttonData.find((button) => button.type === 'bulk-select-button');
    if (bulkButton) {
      bulkButton.count = 0;
    }

    if (!bulkSelecting) {
      this.selectRow('all', null, null, null, true, false);
    }

    this.setState({
      bulkSelecting: bulkSelecting,
      selectAll: false,
    });
  }

  updateToggle(field, value) {
    let buttonData = this.state.buttonData;

    const groupIndex = buttonData.findIndex((button) => button.type === 'group');
    const toggleIndex = buttonData[groupIndex].items.findIndex((button) => button.name === field);
    buttonData[groupIndex].items[toggleIndex].selection = value;

    this.setState({
      buttonData: buttonData,
      [field]: value,
    });
  }

  async selectRow(type, contractData, employeeData, dateData, bulkSelecting, toggle) {
    let timesheetsData = this.state.timesheetsData;
    let groupData = timesheetsData[this.state.selectedGroup];
    let matchingEmployeeIndex = employeeData ? groupData.employees.findIndex((employee) => employeeData === employee) : '';

    if (bulkSelecting) {
      let matchingEmployee;
      const bulkButton = _.find(this.state.buttonData, { type: 'bulk-select-button' });
      let bulkSelectCount = bulkButton.count;

      switch (type) {
        case 'all':
        case 'contract':
          toggle = toggle !== undefined ? toggle : !groupData.selected;
          groupData.selected = toggle;
          groupData.employees.forEach((employee) => {
            employee.selected = toggle;
            employee.dates.forEach((date) => {
              date.selected = toggle;

              if (toggle) {
                bulkSelectCount++;
              } else {
                bulkSelectCount = 0;
              }
            });
          });
          break;
        case 'employee':
          matchingEmployee = groupData.employees[matchingEmployeeIndex];
          toggle = !matchingEmployee.selected;
          matchingEmployee.selected = toggle;
          matchingEmployee.dates.forEach((date) => {
            date.selected = toggle;
          });

          if (toggle) {
            groupData.selected = !_.some(groupData.employees, { selected: false });
            bulkSelectCount = bulkSelectCount + matchingEmployee.dates.length;
          } else {
            groupData.selected = false;
            bulkSelectCount = bulkSelectCount - matchingEmployee.dates.length;
          }
          break;
        default:
          matchingEmployee = groupData.employees[matchingEmployeeIndex];
          let matchingDateIndex = matchingEmployee.dates.findIndex((date) => date.id === dateData.id);
          toggle = !matchingEmployee.dates[matchingDateIndex].selected;
          matchingEmployee.dates[matchingDateIndex].selected = toggle;

          if (toggle) {
            matchingEmployee.selected = !_.some(matchingEmployee.dates, { selected: false });
            groupData.selected = !_.some(groupData.employees, { selected: false });
            bulkSelectCount++;
          } else {
            matchingEmployee.selected = false;
            groupData.selected = false;
            bulkSelectCount = bulkSelectCount - 1;
          }

          break;
      }

      bulkButton.count = bulkSelectCount;

      this.setState({
        timesheetsData: timesheetsData,
      });
    } else {
      if (type === 'contract') {
        groupData.expanded = !groupData.expanded;
      } else if (type === 'employee') {
        groupData.employees[matchingEmployeeIndex].expanded = !groupData.employees[matchingEmployeeIndex].expanded;
      } else {
        this.props.selectEmployeeTimesheetFn(groupData.employees[matchingEmployeeIndex], dateData.date);
      }

      this.setState({
        timesheetsData: timesheetsData,
      });
    }
  }

  selectColumn(column) {
    let data = _.cloneDeep(this.state.masterData);
    let columnName = null;

    if (column.name !== this.state.selectedColumn) {
      columnName = column.name;
      data[this.state.selectedGroup].employees = data[this.state.selectedGroup].employees
        .filter((employee) => {
          return employee.totals[columnName] !== 0;
        })
        .map((employee) => {
          employee.totals = { [columnName]: employee.totals[columnName] };
          return employee;
        });

      data[this.state.selectedGroup].totals = { [columnName]: data[this.state.selectedGroup].totals[columnName] };

      data[this.state.selectedGroup].employees = _.orderBy(
        data[this.state.selectedGroup].employees,
        (employee) => {
          return Utils.toMinutes(employee.totals[columnName]);
        },
        'desc'
      );
    }

    this.setState({
      timesheetsData: data,
      selectedColumn: columnName,
    });
  }

  getBulkUpdatePopover() {
    let isProvisionalApprover = this.props.selectedContract.roles.includes(ROLE.PROVISIONAL_APPROVER);
    return (
      <ul>
        {!isProvisionalApprover && (
          <li className='accept' onClick={() => this.bulkUpdateTimesheets(TIMESHEET_STATUS.APPROVED)}>
            Accept Timesheets
          </li>
        )}
        {isProvisionalApprover && (
          <li className='accept' onClick={() => this.bulkUpdateTimesheets(TIMESHEET_STATUS.PROVISIONALLY_APPROVED)}>
            Provisionally Approve Timesheets
          </li>
        )}
        {!isProvisionalApprover && (
          <li className='reject' onClick={() => this.openRejectTimesheetsModal(TIMESHEET_STATUS.REJECTED)}>
            Reject Timesheets
          </li>
        )}
        {isProvisionalApprover && (
          <li className='reject' onClick={() => this.openRejectTimesheetsModal(TIMESHEET_STATUS.REJECTED)}>
            {' '}
            Reject Timesheets
          </li>
        )}
      </ul>
    );
  }

  getBulkUpdatePopoverProv() {
    let isProvisionalApprover = this.props.selectedContract.roles.includes(ROLE.PROVISIONAL_APPROVER);
    return (
      <ul>
        {!isProvisionalApprover && (
          <li className='accept' onClick={() => this.bulkUpdateTimesheets(TIMESHEET_STATUS.APPROVED)}>
            Accept Timesheets
          </li>
        )}
        {isProvisionalApprover && (
          <li className='accept' onClick={() => this.bulkUpdateTimesheets(TIMESHEET_STATUS.PROVISIONALLY_APPROVED)}>
            {' '}
            Approve Timesheets
          </li>
        )}
      </ul>
    );
  }

  async bulkUpdateTimesheets(status, rejectionReason) {
    let selectedTimesheetIds = [];
    this.state.timesheetsData[this.state.selectedGroup].employees.forEach((employee) => {
      let selectedDates = employee.dates.filter((date) => date.selected).map((timesheet) => timesheet.id);
      selectedTimesheetIds.push(...selectedDates);
    });

    let body = {
      timesheetIds: Array.isArray(selectedTimesheetIds) ? selectedTimesheetIds.join(',') : selectedTimesheetIds,
      approverId: this.props.userData.userId,
      timesheetStatusId: status,
    };

    if (rejectionReason) {
      body.rejectionReason = rejectionReason;
    }

    return new Promise((resolve, reject) => {
      let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/updateReaderTimesheets`;

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      };

      fetch(apiUrl, requestOptions)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
            return res.json();
          }
        })
        .then((data) => {
          if (data.message === 'PASS') {
            this.toggleBulkSelecting(false);

            this.getTimesheetsData().then((timesheetsData) => {
              this.setState({
                masterData: timesheetsData,
                timesheetsData: timesheetsData,
              });
            });
          }
        });
      resolve();
    });
  }

  bulkRejectTimesheets(rejectionStatus, rejectionReason) {
    this.showModal(false);

    this.bulkUpdateTimesheets(rejectionStatus, rejectionReason);

    this.setState({
      newStatusId: null,
    });
  }

  openRejectTimesheetsModal(rejectionStatus) {
    this.setState({
      newStatusId: rejectionStatus,
    });

    this.showModal(true);
  }

  showModal(show) {
    const showingModal = this.state.showModal;

    this.setState({
      showModal: show != null ? show : !showingModal,
    });
  }

  mrpTimesExport() {
    let dateFrom = this.state.dateFrom.format('YYYY-MM-DD');
    let dateTo = this.state.dateTo.format('YYYY-MM-DD');

    let contract = this.props.selectedContract;

    let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getMRPTimesData?dateFrom=${dateFrom}&dateTo=${dateTo}&contractId=${contract.id}`;

    fetch(apiUrl)
      .then((res) => {
        if (res.status === 401) {
          this.props.logoutFn();
        } else {
          fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
          return res.json();
        }
      })
      .then((data) => {
        let excelData = data.results.length > 0 ? data.results : [{ 'There is no data for these params:': `Contract: ${contract.name}, Date Range: ${dateFrom}-${dateTo}` }];
        ExportUtils.exportToExcel(excelData, `MRP-Times-Export_${contract.name}_${dateFrom}-${dateTo}.xlsx`);
      });
  }

  manualQuintiqExport(params) {
    let dateFrom = this.state.dateFrom.format('YYYY-MM-DD');
    let dateTo = this.state.dateTo.format('YYYY-MM-DD');

    let contract = this.props.selectedContract;

    let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/getApprovedPayrollData?dateFrom=${dateFrom}&dateTo=${dateTo}&contractId=${contract.id}`;
    if (params.sapReference) {
      apiUrl += `&sapReference=${params.sapReference}`;
    }

    if (params.reExport) {
      apiUrl += `&reExport=${params.reExport}`;
    }

    fetch(apiUrl)
      .then((res) => {
        if (res.status === 401) {
          this.props.logoutFn();
        } else {
          fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
          return res.json();
        }
      })
      .then((data) => {
        let excelData =
          data.results.length > 0 ? data.results : [{ 'There is no data for these params:': `Contract: ${contract.name}, Date Range: ${dateFrom}-${dateTo}, SAP Reference: ${params.sapReference}` }];
        ExportUtils.exportToExcel(excelData, `Quintiq-Manual-Export_${contract.name}_${dateFrom}-${dateTo}.xlsx`);
      });
  }

  exportMissingTimesheets() {
    let excelData =
      this.state.timesheetsData.missing === 0
        ? [{ 'There are no missing timesheets for these params:': `Contract: ${this.props.selectedContract.name}, Date Range: ${dateFrom}-${dateTo}` }]
        : this.state.timesheetsData.missing.map((timesheet) => {
            return {
              Name: timesheet.readerName,
              Date: timesheet.date,
              'Field Duties': timesheet.productive,
            };
          });

    let dateFrom = this.state.dateFrom.format('DD-MM-YYYY');
    let dateTo = this.state.dateTo.format('DD-MM-YYYY');

    ExportUtils.exportToExcel(excelData, `Missing-Timesheets-${this.props.selectedContract.name}_${dateFrom}-${dateTo}.xlsx`);
  }

  render() {
    let groups = [];

    let data = this.state.timesheetsData;

    this.state.statusData.forEach((status) => {
      let displayName = status.name;
      if ([TIMESHEET_STATUS.RETURNED_BY_PROVISIONAL_APPROVER, TIMESHEET_STATUS.AMENDED_BY_PROVISIONAL_APPROVER].includes(status.id)) return;

      if (status.id === TIMESHEET_STATUS.POSTED) {
        displayName = 'exported';
      }

      let group = {
        name: status.name,
        count: status.name === 'missing' && data[status.name] ? data[status.name].length : data[status.name] ? data[status.name].count : 0,
        displayName: displayName,
      };

      groups.push(group);
    });

    let contract = this.state.timesheetsData[this.state.selectedGroup];

    let categories = [];
    if (!this.state.isLoading && contract && this.state.selectedGroup !== 'missing') {
      let timeCategories = this.state.timeCategoriesData.filter((category) => {
        return (!this.state.selectedColumn && category.name === 'Meal Break') || !!contract.totals[category.name];
      });

      if (timeCategories.length === 1 && timeCategories[0].name === 'Meal Break' && this.state.selectedColumn !== 'Meal Break') {
        timeCategories = [];
      }

      let allowanceCategories = this.state.allowanceCategoriesData.filter((category) => {
        return !!contract.totals[category.name];
      });

      switch (this.state.categoryType) {
        case 'all':
          categories.push(...timeCategories);
          categories.push(...allowanceCategories);
          break;
        case 'time':
          categories.push(...timeCategories);
          break;
        case 'allowances':
          categories.push(...allowanceCategories);
          break;
        default:
          break;
      }
    }

    // 94	Urban Utilities - Meter Replacement
    // 95	Gold Coast - Meter Replacement
    // 96	Sydney Water - Meter Replacement
    const canViewMRPTimesExport = ['94', '95', '96'].includes(this.props.selectedContract.id);

    return (
      <div className={'main-content manage-timesheets-page'}>
        <header>
          <h1>Manage Timesheets</h1>
          <img className='logo-container' src={process.env.REACT_APP_ENV ? uat_logo : logo} alt='' />
        </header>
        {!this.state.isLoading && <TabContainer id={'manage-timesheets'} groups={groups} selectedTab={this.state.selectedGroup} onClick={(name) => this.selectStatusGroup(name)} />}
        {!this.state.isLoading && <ButtonBar bulkSelecting={this.state.bulkSelecting} buttonData={this.state.buttonData} />}
        {!this.state.isLoading && this.state.selectedGroup !== 'missing' && (
          <div className={`pivot-table-container ${this.state.bulkSelecting ? 'bulk-selecting' : ''}`}>
            {contract && (
              <div className={'pivot-table'} id={'timesheet-pivot-table'}>
                <div className={'pivot-table-column-headers'}>
                  <div className={'pivot-table-header pivot-table-cell'} />
                  {categories.map((category) => {
                    return (
                      <div className={'pivot-table-header pivot-table-cell'} key={`${category.name}-${category.id}`} onClick={() => this.selectColumn(category)}>
                        <div className='u--multiline-ellipsis' title={category.name}>
                          {category.name}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className={'pivot-row-header-border'} />
                <div key={contract.id}>
                  <div className={'pivot-table-row'}>
                    <div className={'pivot-table-row-header'}>
                      {this.state.bulkSelecting && (
                        <div className='checkbox-container'>
                          <input type='checkbox' id={'checkbox-contract-' + contract.id} checked={contract.selected} onChange={() => {}} />
                          <label className='checkbox-label' htmlFor={'checkbox-contract-' + contract.id} onClick={() => this.selectRow('contract', contract, null, null, true)} />
                        </div>
                      )}
                      <div
                        className={`pivot-table-header pivot-table-cell ${contract.selected ? 'selected' : ''} ${contract.expanded ? 'expanded' : ''}`}
                        onClick={() => this.selectRow('contract', contract)}>
                        <Icon name='chevron' />
                        <div className='u--ellipsis' title={contract.name}>
                          {contract.name}
                        </div>
                      </div>
                    </div>
                    <div className={'pivot-table-body'}>
                      <div className={'pivot-table-row-body--gap'}>
                        {categories.map((category) => {
                          return <div className={'pivot-table-gap'} key={category.name} />;
                        })}
                      </div>
                      <div className={`pivot-table-row-body ${contract.selected ? 'selected' : ''} ${contract.expanded ? 'expanded' : ''}`} key={contract.id}>
                        {categories.map((category) => {
                          return (
                            <div className={'pivot-table-cell'} key={category.name}>
                              {contract.totals[category.name]}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  {contract.expanded &&
                    contract.employees.map((employee) => {
                      return (
                        <div key={employee.id}>
                          <div className={'pivot-table-row'}>
                            <div className={'pivot-table-row-header pivot-table-row-header--nested'}>
                              {this.state.bulkSelecting && (
                                <div className='checkbox-container'>
                                  <input type='checkbox' id={'checkbox-employee-' + employee.id} checked={employee.selected} onChange={() => {}} />
                                  <label className='checkbox-label' htmlFor={'checkbox-employee-' + employee.id} onClick={() => this.selectRow('employee', contract, employee, null, true)} />
                                </div>
                              )}
                              <div
                                className={`pivot-table-header pivot-table-cell ${employee.selected ? 'selected' : ''} ${employee.expanded ? 'expanded' : ''}`}
                                onClick={() => this.selectRow('employee', contract, employee)}>
                                <Icon name='chevron' />
                                <div className='u--ellipsis' title={Utils.titleize(employee.name.replace('.', ' '))}>
                                  {Utils.titleize(employee.name.replace('.', ' '))}
                                </div>
                              </div>
                            </div>
                            <div className={'pivot-table-body'}>
                              <div className={'pivot-table-row-body--gap'}>
                                {categories.map((category) => {
                                  return <div className={'pivot-table-gap'} key={category.name} />;
                                })}
                              </div>
                              <div className={`pivot-table-row-body ${employee.selected ? 'selected' : ''} ${employee.expanded ? 'expanded' : ''}`}>
                                {categories.map((category) => {
                                  return (
                                    <div className={'pivot-table-cell'} key={`${category.name}`}>
                                      {employee.totals[category.name]}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                          {employee.expanded &&
                            employee.dates.map((date) => {
                              let isProvisionalStatus = [TIMESHEET_STATUS.RETURNED_BY_PROVISIONAL_APPROVER, TIMESHEET_STATUS.AMENDED_BY_PROVISIONAL_APPROVER].includes(date.timesheetStatusId);
                              return (
                                <div key={date.id}>
                                  <div className={'pivot-table-row'}>
                                    <div className={'pivot-table-row-header pivot-table-row-header--nested-2'}>
                                      {this.state.bulkSelecting && (
                                        <div className='checkbox-container'>
                                          <input type='checkbox' id={'checkbox-date-' + date.id} checked={date.selected} onChange={() => {}} />
                                          <label className='checkbox-label' htmlFor={'checkbox-date-' + date.id} onClick={() => this.selectRow('date', contract, employee, date, true)} />
                                        </div>
                                      )}
                                      <div className={`pivot-table-header pivot-table-cell ${date.selected ? 'selected' : ''}`} onClick={() => this.selectRow('date', contract, employee, date)}>
                                        <div className='u--ellipsis f--link' title={date.name}>
                                          {date.name}
                                        </div>
                                        {isProvisionalStatus && (
                                          <span className={'provisional-tag'} title={'Actioned by Provisional Approver'}>
                                            (P)
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <div className={'pivot-table-body'}>
                                      <div className={'pivot-table-row-body--gap'}>
                                        {categories.map((category) => {
                                          return <div className={'pivot-table-gap'} key={category.name} />;
                                        })}
                                      </div>
                                      <div className={`pivot-table-row-body ${date.selected ? 'selected' : ''}`} key={date.id}>
                                        {categories.map((category) => {
                                          return (
                                            <div className={'pivot-table-cell'} key={category.name}>
                                              {date.totals[category.name]}
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
            {!this.state.isLoading && !contract && (
              <div>
                <div className='empty-state'>There are no {Utils.humanize(this.state.selectedGroup)} timesheets.</div>
              </div>
            )}
          </div>
        )}
        {!this.state.isLoading && this.state.selectedGroup === 'pendingApproval' && (
          <div>
            {this.state.showModal && (
              <Modal showModalFn={(show) => this.showModal(show)} showModal={this.state.showModal}>
                <TimesheetApproverDetailsModal
                  showModalFn={(show) => this.showModal(show)}
                  showModal={this.state.showModal}
                  data={{ timesheet: { statusId: TIMESHEET_STATUS.PENDING_APPROVAL }, newStatusId: this.state.newStatusId }}
                  onClick={(status, timesheet) => this.bulkRejectTimesheets(status, timesheet.rejectionReason)}
                />
              </Modal>
            )}
          </div>
        )}
        {!this.state.isLoading && this.state.selectedGroup === 'approved' && (
          <div className={'button-group'}>
            {canViewMRPTimesExport && <Button classes={'button--blue'} text={'MRP Times Export'} onClick={() => this.mrpTimesExport()} />}
            <Button classes={'button--blue'} text={'Manual Quintiq Export'} onClick={() => this.showModal()} />
          </div>
        )}
        {!this.state.isLoading && this.state.selectedGroup === 'approved' && (
          <div>
            {this.state.showModal && (
              <Modal showModalFn={(show) => this.showModal(show)} showModal={this.state.showModal}>
                <ManualQuintiqExportModal showModalFn={(show) => this.showModal(show)} showModal={this.state.showModal} onClick={(params) => this.manualQuintiqExport(params)} />
              </Modal>
            )}
          </div>
        )}
        {!this.state.isLoading && this.state.selectedGroup === 'missing' && (
          <div className={`pivot-table-container`}>
            {contract.length > 0 && (
              <div className={'pivot-table'} id={'timesheet-pivot-table'}>
                <div className={'pivot-table-column-headers'}>
                  <div className={'pivot-table-header pivot-table-cell'} />
                  <div className={'pivot-table-header pivot-table-cell'}>
                    <div title={'Date'}>Date</div>
                  </div>
                  <div className={'pivot-table-header pivot-table-cell'}>
                    <div title={'Field Duties'}>Field Duties</div>
                  </div>
                </div>
                <div className={'pivot-row-header-border'} />
                <div>
                  {contract.map((timesheet) => {
                    return (
                      <div key={`${JSON.stringify(timesheet)}`}>
                        <div className={'pivot-table-row'}>
                          <div className={'pivot-table-row-header'}>
                            <div className={`pivot-table-header pivot-table-cell`}>
                              <div className='u--ellipsis' title={Utils.titleize(timesheet.readerName.replace('.', ' '))}>
                                {Utils.titleize(timesheet.readerName.replace('.', ' '))}
                              </div>
                            </div>
                          </div>
                          <div className={'pivot-table-body'}>
                            <div className={'pivot-table-row-body--gap'}>
                              <div className={'pivot-table-gap'} />
                              <div className={'pivot-table-gap'} />
                            </div>
                            <div className={`pivot-table-row-body`}>
                              <div className={'pivot-table-cell'}>{timesheet.date}</div>
                              <div className={'pivot-table-cell'}>{timesheet.productive}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {!contract.length && <div className='empty-state'>There are no Missing timesheets.</div>}
          </div>
        )}
        {!this.state.isLoading && this.state.selectedGroup === 'missing' && (
          <div className={'button-group'}>
            <Button classes={'button--blue'} text={'Export Missing Timesheets'} onClick={() => this.exportMissingTimesheets()} />
          </div>
        )}
        {this.state.isLoading && (
          <div className='spinner-container'>
            <Loader type='Bars' color='#008dce' height={30} width={30} visible={this.state.isLoading} />
          </div>
        )}
        <img className='wave' src={wave} alt={''} />
      </div>
    );
  }
}

export default ManageTimesheets;
