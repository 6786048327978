import React from 'react';
import ResultsContainer from '../ResultsContainer';
import _ from 'lodash';
import moment from 'moment';
import wave from '../../images/wave.svg';
import logo from '../../images/skilltech-mimtr-logo-dark.png';
import uat_logo from '../../images/skilltech-mimtr-logo-uat-dark.png';
import Modal from '../Modal';
import './audits-page.scss';
import AuditReallocationModal from './AuditReallocationModal';
import WorkRouteResult from '../search/result-item/WorkRouteResult';
import Api from '../../api/Api';
import Utils from '../../utils/Utils';
import Button from '../Button';

class Audits extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      masterData: [],
      routeData: [],
      readerData: [],
      buttonData: [],
      userData: props.userData,
      isLoading: true,
      bulkSelecting: false,
      selectAll: false,
      sortKey: 'name',
      searchFn: null,
      filterFn: null,
      resultData: [],
      showModal: false,
      modalType: '',
    };
  }

  async componentDidMount() {
    document.title = `${process.env.REACT_APP_ENV ? `[${process.env.REACT_APP_ENV}] ` : ''}MiMtr Hybrid | Audits`;

    this.getData();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.selectedContract.id !== this.props.selectedContract.id) {
      this.setState({
        bulkSelecting: false,
        selectAll: false,
        sortKey: 'name',
        searchFn: null,
        filterFn: null,
      });
      this.getData();
    }
  }

  async getData() {
    this.setState({ isLoading: true });
    await this.getAuditRouteData().then((routeData) => {
      Api.getReaders(this.props.selectedContract).then((readerData) => {
        this.setState({
          masterData: routeData,
          routeData: routeData,
          readerData: readerData,
          buttonData: this.getButtonData(readerData, routeData),
          isLoading: false,
        });
      });
    });
  }

  async getAuditRouteData() {
    let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/jobsearch?DatabaseServer=${this.props.selectedContract.dbServer}&contractid=${this.props.selectedContract.id}&searchtype=audit`;

    return new Promise((resolve, reject) => {
      return fetch(apiUrl)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
            reject();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
            return res.json();
          }
        })
        .then((data) => {
          if (!data.results || !data.results.length || !data.results[0]) {
            resolve([]);
            return;
          }

          let masterData = data.results.map((result) => {
            return {
              id: result.WorkRouteId,
              workRouteNumber: result.WorkRouteNumber,
              name: result.RouteDescription,
              status: result.Status,
              readerName: Utils.titleize(result.ReaderName.replace('.', ' ').replace('.', ' ')),
              readerId: result.ReaderId,
              scheduledDate: moment(result.ScheduledDate),
              totalMeters: result.TotalMeters,
              completed: result.Completed,
              skipped: result.Skipped,
              incomplete: result.Incomplete,
              metersForAudit: result.MetersForAudit,
            };
          });
          resolve(masterData);
        });
    });
  }

  getButtonData(readerData, masterData) {
    return [
      {
        type: 'bulk-select-button',
        classes: 'button--plain',
        text: 'Bulk Select',
        actionItems: [
          { text: 'Reallocate All', onClick: () => this.showModal(true, 'reallocation') },
          { text: 'Remove Audit For All', onClick: () => this.showModal(true, 'remove') },
        ],
        icon: 'checkbox',
        iconPosition: '1',
        selectAll: () => this.selectResult(null, true),
        toggleBulkSelectFn: () => this.toggleBulkSelecting(),
        count: 0,
      },
      {
        type: 'group',
        items: [
          {
            type: 'filter-button',
            text: 'Filter',
            icon: 'filter',
            iconPosition: '2',
            popover: {
              type: 'filter',
              onClick: (filterData) => this.filterResults(filterData),
              filters: [
                {
                  name: 'reader',
                  displayLabel: 'Assigned Reader',
                  type: 'select',
                  multiselect: true,
                  selection: [],
                  options: _.sortBy(
                    _.uniqBy(masterData, 'readerId')
                      .filter((route) => route.readerId)
                      .map((route) => {
                        return { id: route.readerId, name: route.readerName };
                      }),
                    'name'
                  ),
                },
              ],
            },
          },
          {
            type: 'sort-button',
            classes: 'button--active',
            text: 'Sort By',
            icon: 'sort',
            iconPosition: '2',
            popover: {
              type: 'select',
              onClick: (key) => this.sortResults(key),
              options: [
                { name: 'Route No.', value: 'routeNo' },
                { name: 'Route Name', value: 'name' },
                { name: 'Scheduled Date', value: 'scheduledDate' },
                { name: 'Assigned Reader', value: 'readerName' },
              ],
            },
            selectedSort: 'name',
            sortAsc: true,
          },
          { type: 'search-input', onChange: (key) => this.searchResults(key) },
        ],
      },
    ];
  }

  toggleBulkSelecting(toggle) {
    // Switch to the opposite state
    const bulkSelecting = toggle ? toggle : !this.state.bulkSelecting;

    // Clear changes when toggling bulk select
    const bulkButton = this.state.buttonData.find((button) => button.type === 'bulk-select-button');
    bulkButton.count = 0;
    let selectedRoutes = [];

    let routeData = this.state.masterData;
    if (!bulkSelecting) {
      routeData.forEach((route) => {
        route.selected = false;
      });
    }

    this.setState({
      bulkSelecting: bulkSelecting,
      selectAll: false,
      routeData: routeData,
      selectedRoutes: selectedRoutes,
    });
  }

  async selectResult(selectedItem, toggleAll) {
    let masterData = this.state.masterData;

    const bulkSelecting = this.state.bulkSelecting;
    let selectAll = this.state.selectAll;

    // If bulk selecting and toggling all, switch according to the selectAll state
    if (bulkSelecting && toggleAll) {
      selectAll = !selectAll;
    }

    if (bulkSelecting) {
      masterData.forEach((item) => {
        if (toggleAll) {
          item.selected = selectAll;
        } else if (item.id === selectedItem.id) {
          item.selected = !item.selected;
        }
      });

      // Add to the bulk select count
      const count = _.filter(masterData, { selected: true }).length;
      const bulkButton = _.find(this.state.buttonData, { type: 'bulk-select-button' });
      bulkButton.count = count;

      let resultData = this.getResultItems(masterData, bulkSelecting);

      this.setState({
        resultData: resultData,
        selectAll: selectAll,
      });
    } else {
      window.open(`/hybrid/search?type=meter&workRouteNumber=${selectedItem.workRouteNumber}&audit=1`);
    }
  }

  filterResults(filterData) {
    let filterFn;
    let buttonData;

    if (!filterData) {
      filterFn = (route) => {
        return route;
      };
      buttonData = this.getButtonData(this.state.readerData, this.state.masterData);
    } else {
      const selectedAreaGroups = filterData[0].selection;
      const selectedArea = filterData[1].selection;
      const selectedDateRangeStart = filterData[2].selection.start;
      const selectedDateRangeEnd = filterData[2].selection.end;
      const selectedReader = filterData[3].selection;
      const selectedCategory = filterData[4].selection;
      const selectedFrequency = filterData[5].selection;

      filterFn = function (route) {
        return (
          (!selectedAreaGroups.length || selectedAreaGroups.includes(route.areaGroupId)) &&
          (!selectedDateRangeStart || !selectedDateRangeEnd || (selectedDateRangeStart.isSameOrBefore(route.initialDate, 'day') && selectedDateRangeEnd.isSameOrAfter(route.endDate, 'day'))) &&
          (!selectedArea.length > 0 || selectedArea.includes(route.areaId)) &&
          (!selectedReader.length > 0 || selectedReader.includes(route.readerId)) &&
          (!selectedCategory.length > 0 || selectedCategory.includes(route.category)) &&
          (!selectedFrequency.length > 0 || selectedFrequency.includes(route.readFrequency))
        );
      };

      buttonData = this.state.buttonData;

      const buttonGroupIndex = buttonData.findIndex((button) => button.type === 'group');
      const buttonGroupData = buttonData[buttonGroupIndex];
      const filterButtonIndex = buttonGroupData.items.findIndex((button) => button.type === 'filter-button');
      const filterPopoverData = buttonGroupData.items[filterButtonIndex].popover;
      filterPopoverData.filters = filterData;

      buttonGroupData.items[filterButtonIndex].classes = _.some(filterData, 'selection') ? 'button--active' : '';
      buttonGroupData.items[filterButtonIndex].popover = filterPopoverData;
      buttonData[buttonGroupIndex] = buttonGroupData;
    }

    let data = this.getGroupedData(this.state.masterData, null, filterFn, null);

    this.setState({
      buttonData: buttonData,
      routeData: data,
      filterFn: filterFn ? filterFn : null,
    });
  }

  sortResults(sortKey) {
    let data = this.getGroupedData(this.state.masterData, sortKey);
    let currentSortKey, sortAsc;

    // Find the sort button and check if the sort key was already selected
    let buttonData = this.state.buttonData;
    const buttonGroup = buttonData.find((button) => button.type === 'group');
    buttonGroup.items.forEach((button) => {
      if (button.type === 'sort-button') {
        currentSortKey = button.selectedSort;

        // If the sort key was reselected, toggle asc/desc order
        if (currentSortKey === sortKey) {
          button.sortAsc = !button.sortAsc;
        } else {
          button.sortAsc = true;
        }

        button.selectedSort = sortKey;
        sortAsc = button.sortAsc;
      }
    });

    // Order each route group by the sorting key
    _.forEach(data, (group, key) => {
      data[key] = _.orderBy(data[key], sortKey, sortAsc ? 'asc' : 'desc');
    });

    this.setState({
      routeData: data,
      buttonData: buttonData,
      sortKey: sortKey,
    });
  }

  searchResults(searchTerm) {
    let data = _.cloneDeep(this.state.masterData);
    let searchFn = null;

    if (searchTerm) {
      searchFn = (route) => {
        return route.workRouteNumber.includes(searchTerm) || route.name.toLowerCase().includes(searchTerm.toLowerCase());
      };

      data = data.filter(searchFn);
    }

    if (this.state.filterFn) {
      data = data.filter((result) => this.state.filterFn(result));
    }

    this.setState({
      routeData: data,
      searchFn: searchFn,
    });
  }

  showModal(show, modalType) {
    const showingModal = this.state.showModal;

    this.setState({
      showModal: show != null ? show : !showingModal,
      modalType: !showingModal ? modalType : '',
    });
  }

  async bulkReallocateRoute(reader) {
    let selectedItems = this.state.masterData.filter((data) => data.selected);

    const promises = selectedItems.map(async (route) => {
      route.newReaderId = reader.id;
      route.newReaderName = reader.readerName;
      return await this.reallocateRoute(route);
    });

    await Promise.all(promises).then(() => {
      this.toggleBulkSelecting(false);
      this.setState({
        showModal: false,
      });
      this.updateRouteData(selectedItems);
    });
  }

  reallocateRoute(updatedRoute) {
    return new Promise((resolve, reject) => {
      let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/reallocateRoute`;

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          DatabaseServer: this.props.selectedContract.dbServer,
          userId: this.props.userData.userId,
          readerId: updatedRoute.newReaderId,
          workRouteId: updatedRoute.id,
        }),
      };

      fetch(apiUrl, requestOptions)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
            return res.json();
          }
        })
        .then((data) => {
          fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
          this.showModal(false);
        });
      resolve();
    });
  }

  async bulkUnmarkRoute() {
    let selectedItems = this.state.masterData.filter((data) => data.selected);

    const promises = selectedItems.map(async (route) => {
      return await this.unmarkForAudit(route);
    });

    await Promise.all(promises).then(() => {
      this.toggleBulkSelecting(false);
      this.showModal(false);
    });
  }

  unmarkForAudit(route) {
    return new Promise((resolve, reject) => {
      let apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/unmarkRouteForAudit`;

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          DatabaseServer: this.props.selectedContract.dbServer,
          workRouteId: route.id,
        }),
      };

      fetch(apiUrl, requestOptions)
        .then((res) => {
          if (res.status === 401) {
            this.props.logoutFn();
          } else {
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
            return res.json();
          }
        })
        .then((data) => {
          fetch(`${process.env.REACT_APP_API_ENDPOINT}/refresh`, { method: 'POST' });
          let masterData = this.state.masterData;

          let matchingRoute = masterData.findIndex((masterRoute) => masterRoute.id === route.id);
          masterData.splice(matchingRoute, 1);

          this.setState({
            masterData: masterData,
          });
        })
        .catch((err) => {});
      resolve();
    });
  }

  updateRouteData(selectedRoutes) {
    selectedRoutes.forEach((route) => {
      let selectedRoute = this.state.masterData.find((data) => route.id === data.id);
      selectedRoute.status = 'Assigned';
      selectedRoute.readerName = route.newReaderName;
    });
  }

  getResultItems(bulkSelecting) {
    bulkSelecting = bulkSelecting != null ? bulkSelecting : this.state.bulkSelecting;

    return this.state.routeData.map((route, index) => {
      return <WorkRouteResult data={route} key={index} selectFn={() => this.selectResult(route)} bulkSelecting={bulkSelecting} />;
    });
  }

  render() {
    let data = { resultData: this.getResultItems() };

    return (
      <div className={'main-content audits-page'}>
        <header>
          <h1>Audits</h1>
          <img className='logo-container' src={process.env.REACT_APP_ENV ? uat_logo : logo} alt='' />
        </header>
        <ResultsContainer
          data={data}
          buttonData={this.state.buttonData}
          dataClass={'route'}
          emptyStateText={`There are no routes marked for audit.`}
          isLoading={this.state.isLoading}
          bulkSelecting={this.state.bulkSelecting}
        />
        {this.state.showModal && (
          <Modal classes='popup' showModalFn={(show) => this.showModal(show)} showModal={this.state.showModal}>
            {this.state.modalType === 'reallocation' && (
              <AuditReallocationModal
                readerData={this.state.readerData}
                selectedRoutes={this.state.masterData.filter((data) => data.selected)}
                showModalFn={(show) => this.showModal(show)}
                submitFn={(reader) => this.bulkReallocateRoute(reader)}
              />
            )}
            {this.state.modalType === 'remove' && (
              <div id={'reallocation-modal'} className='modal-content'>
                <div className='heading'>Removing Routes From Audit</div>
                {!this.state.isLoading && (
                  <div>
                    <div className='form-container'>
                      <p>Are you sure you want to remove {this.state.masterData.filter((data) => data.selected).length > 1 ? 'these routes' : 'this route'} ?</p>
                    </div>
                    <div className='button-group t--align-right'>
                      <Button classes={`button button--plain`} onClick={() => this.showModal(false)} text='Cancel' />
                      <Button classes={`button button--med button--orange`} onClick={() => this.bulkUnmarkRoute()} text='Remove' />
                    </div>
                  </div>
                )}
              </div>
            )}
          </Modal>
        )}
        <img className='wave' src={wave} alt={''} />
      </div>
    );
  }
}

export default Audits;
