import React from 'react';
import Utils from '../utils/Utils';

/**
 * Custom control to allow time input in 24 hour format.
 */
class TimeInput extends React.Component {
  constructor(props) {
    super(props);

    let defaultValue = '00:00';
    let [validatedTime] = this.validateTimeAndCursor(props.value, defaultValue);

    this.state = {
      value: validatedTime,
      defaultValue: defaultValue,
      colon: ':',
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      const [validatedTime] = this.validateTimeAndCursor(this.props.value, this.state.defaultValue);
      this.setState({
        value: validatedTime,
      });
    }
  }

  formatTimeItem(value) {
    return `${value || ''}00`.substr(0, 2);
  }

  /**
   *
   * @param value The changed time
   * @param defaultValue The original value
   * @param cursorPosition The current position of the cursor within the string value after input
   * @returns [value, cursorPosition]
   */
  validateTimeAndCursor(value, defaultValue, cursorPosition) {
    const colon = this.state ? this.state.colon : ':';
    const [oldH, oldM] = defaultValue.split(colon);

    let newCursorPosition = cursorPosition ? Number(cursorPosition) : 0;
    let [newH, newM] = String(value).split(colon);

    newH = this.formatTimeItem(newH);
    if (Number(newH[0]) > 2) {
      newH = `0${newH[0]}`;
      newCursorPosition += 1;
    } else if (Number(newH[0]) === 2) {
      if (Number(oldH[0]) === 2 && Number(newH[1]) > 3) {
        newH = `2${oldH[1]}`;
        newCursorPosition -= 2;
      } else if (Number(newH[1]) > 3) {
        newH = '23';
      }
    }

    newM = this.formatTimeItem(newM);
    if (Number(newM[0]) > 5) {
      newM = oldM;
      newCursorPosition -= 1;
    }

    const validatedValue = `${newH}${colon}${newM}`;

    return [validatedValue, newCursorPosition];
  }

  /**
   * Controls the input and logic response.
   * @param e - The change event
   */
  handleChange(e) {
    const oldValue = this.state.value;
    const inputEl = e.target;
    const inputValue = inputEl.value;
    const position = inputEl.selectionEnd || 0;
    const isTyped = inputValue.length > oldValue.length;
    const cursorCharacter = inputValue[position - 1];
    const addedCharacter = isTyped ? cursorCharacter : null;
    const removedCharacter = isTyped ? null : oldValue[position];
    const replacedSingleCharacter = inputValue.length === oldValue.length ? oldValue[position - 1] : null;
    const colon = this.state.colon;

    let newValue = oldValue;
    let newPosition = position;

    if (addedCharacter !== null) {
      if ((position === 3 || position === 6) && addedCharacter === colon) {
        newValue = `${inputValue.substr(0, position - 1)}${colon}${inputValue.substr(position + 1)}`;
      } else if ((position === 3 || position === 6) && Utils.isNumber(addedCharacter)) {
        newValue = `${inputValue.substr(0, position - 1)}${colon}${addedCharacter}${inputValue.substr(position + 2)}`;
        newPosition = position + 1;
      } else if (Utils.isNumber(addedCharacter)) {
        // user typed a number
        newValue = inputValue.substr(0, position - 1) + addedCharacter + inputValue.substr(position + 1);
        if (position === 2 || position === 5) {
          newPosition = position + 1;
        }
      } else {
        // if user typed NOT a number, then keep old value & position
        newPosition = position - 1;
      }
    } else if (replacedSingleCharacter !== null) {
      // user replaced only a single character
      if (Utils.isNumber(cursorCharacter)) {
        if (position - 1 === 2 || position - 1 === 5) {
          newValue = `${inputValue.substr(0, position - 1)}${colon}${inputValue.substr(position)}`;
        } else {
          newValue = inputValue;
        }
      } else {
        // user replaced a number on some non-number character
        newValue = oldValue;
        newPosition = position - 1;
      }
    } else if (typeof cursorCharacter !== 'undefined' && cursorCharacter !== colon && !Utils.isNumber(cursorCharacter)) {
      // set of characters replaced by non-number
      newValue = oldValue;
      newPosition = position - 1;
    } else if (removedCharacter !== null) {
      if ((position === 2 || position === 5) && removedCharacter === colon) {
        newValue = `${inputValue.substr(0, position - 1)}0${colon}${inputValue.substr(position)}`;
        newPosition = position - 1;
      } else {
        // user removed a number
        newValue = `${inputValue.substr(0, position)}0${inputValue.substr(position)}`;
      }
    }
    const [validatedTime, validatedCursorPosition] = this.validateTimeAndCursor(newValue, oldValue, newPosition);

    this.setState({ value: validatedTime }, () => {
      inputEl.selectionStart = validatedCursorPosition;
      inputEl.selectionEnd = validatedCursorPosition;
    });

    this.props.onChange(validatedTime);
  }

  render() {
    const { value } = this.state;

    return <input type='text' id={this.props.id} value={value} required={this.props.required} onChange={(e) => this.handleChange(e)} />;
  }
}

export default TimeInput;

/**
 * Credit to antonfisher/react-simple-timefield
 */
