import React from 'react';
import Button from './Button';
import Popover from './Popover';

class PopoverButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopover: false,
    };
  }

  togglePopover() {
    const show = this.state.showPopover;
    this.setState({
      showPopover: !show,
    });
  }

  componentDidMount() {
    // Add Event Listner to handle the click that happens outside
    // the Custom Select Container
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    // Remove the event listener on component unmounting
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  // This method handles the click that happens outside the element
  handleClickOutside = (e) => {
    var element = document.getElementById(this.props.data.popover.type + '-container');

    if (e.target !== element && !element.contains(e.target) && this.state.showPopover) {
      this.setState({
        showPopover: false,
      });
    }
  };

  handleClick(args) {
    if (this.props.data.popover.onClick) {
      this.props.data.popover.onClick(args);
    }

    this.setState({
      showPopover: false,
    });
  }

  render() {
    const item = this.props.data;
    const selected = this.state.showPopover;

    return (
      <div className='button-container' id={item.popover.type + '-container'}>
        <Button classes={`${selected ? 'selected' : ''} ${item.classes ? item.classes : ''}`} icon={item.icon} iconPosition={item.iconPosition} text={item.text} onClick={() => this.togglePopover()} />
        {selected && <Popover item={item} onClick={(...args) => this.handleClick(...args)} />}
      </div>
    );
  }
}

export default PopoverButton;
