import React from 'react';

class ResultsList extends React.Component {
  render() {
    const data = this.props.data.resultData;

    return (
      <div id={this.props.group ? this.props.group + '-content' : ''} className='results-list'>
        {data.map((item) => {
          return item;
        })}
      </div>
    );
  }
}

export default ResultsList;
