import React from 'react';
import Utils from '../../utils/Utils';
import Button from '../Button';
import TabContainer from '../TabContainer';
import _ from 'lodash';
import CardCarousel from '../CardCarousel';
import PhotoCard from '../PhotoCard';
import { Buffer } from 'buffer';
import './meter-detail-modal.scss';

class MeterDetailModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFullImage: false,
      selectedGroup: 'readingDetails',
      selectedPhotos: [],
      masterPhotos: props.data.photos,
    };
  }

  componentDidMount() {
    let allPhotos = [];
    _.forEach(this.state.masterPhotos, (photoGroup) => {
      allPhotos.push(photoGroup);
    });

    allPhotos = allPhotos.flat();

    let carouselPhotos = allPhotos.map((photo, index) => {
      photo.index = index;
      return this.getCarouselCard(photo);
    });

    this.setState({
      carouselData: carouselPhotos,
      allPhotoData: allPhotos,
    });
  }

  selectStatusGroup(tabName) {
    this.setState({
      selectedGroup: tabName,
      showFullImage: false,
    });
  }

  validateUpdate(field, value) {
    switch (field) {
      case 'coordinates':
        let coords = value.split(',');
        return parseFloat(coords[0]) && parseFloat(coords[1]);
      default:
        return true;
    }
  }

  updateField(field, value) {
    let jobData = this.state.jobData;
    switch (field) {
      case 'coordinates':
        let coords = value.split(',');
        jobData.latitude = parseFloat(coords[0]);
        jobData.longitude = parseFloat(coords[1]);
        break;

      default:
        jobData[field] = value;
        break;
    }

    this.updateJob(jobData);
  }

  updateJob(jobData) {
    //api call .then((result) => {})

    this.setState({
      jobData: jobData,
    });

    this.props.updateFn(jobData);
  }

  toggleFullImage(show, photo) {
    this.setState(
      {
        showFullImage: show,
        selectedImage: photo,
      },
      () => {
        if (photo) {
          this.slider.slickGoTo(photo.index);
        }
      }
    );
  }

  getCarouselCard(photo) {
    let photoUrl = photo.imageData ? `data:image/jpeg;base64,${Buffer.from(photo.imageData.data).toString('base64')}` : '';
    return <PhotoCard photoUrl={photoUrl} key={photo.pictureId} prevFn={() => this.slider.slickPrev()} nextFn={() => this.slider.slickNext()} />;
  }

  onCardChange(current) {
    this.setState({
      selectedImage: this.state.allPhotoData[current],
    });
  }

  selectPhoto(photo) {
    let selectedPhotos = this.state.selectedPhotos;
    if (selectedPhotos.includes(photo.pictureId)) {
      selectedPhotos = selectedPhotos.filter((photoId) => photoId !== photo.pictureId);
    } else {
      selectedPhotos.push(photo.pictureId);
    }

    this.setState({
      selectedPhotos: selectedPhotos,
    });
  }

  downloadImage(photo) {
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);

    downloadLink.href = photo.imageData ? `data:image/jpeg;base64,${Buffer.from(photo.imageData.data).toString('base64')}` : '';
    downloadLink.target = '_self';
    downloadLink.download = `${photo.pictureId}.jpeg`;
    downloadLink.click();
  }

  bulkDownload() {
    let selectedPhotos = this.state.allPhotoData.filter((photo) => this.state.selectedPhotos.includes(photo.pictureId));
    selectedPhotos.forEach((photo) => this.downloadImage(photo));
  }

  render() {
    const data = this.props.data;
    const groups = ['readingDetails', 'meterDetails', 'readingPhotos'].map((status) => {
      return {
        name: status,
      };
    });

    const settings = {
      dots: true,
      arrows: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      afterChange: (current) => this.onCardChange(current),
    };

    let OOTPhoto, skipPhoto;
    if (this.state.allPhotoData) {
      OOTPhoto = this.state.allPhotoData.filter((photo) => photo.pictureType === 1)[0];
      skipPhoto = this.state.allPhotoData.filter((photo) => photo.pictureType === 2)[0];
    }

    return (
      <div id={'meter-detail-modal'} className='modal-content meter-detail-modal'>
        <div className='sub-heading'>Meter No.</div>
        <div className='heading'>
          <div>
            <div className='heading'>
              {/*<InlineEditable type="input"*/}
              {/*                field="meterNumber"*/}
              {/*                data={data.meterNumber}*/}
              {/*                maxLength="9"*/}
              {/*                updateFn={(meterNumber) => this.updateField('meterNumber', meterNumber)}*/}
              {/*                icon={true}/>*/}
              <div className='read-only'>{data.readings.meterNumber}</div>
            </div>
          </div>
        </div>
        <TabContainer id={'meter-detail'} groups={groups} selectedTab={this.state.selectedGroup} containerId={'meter-detail-modal'} onClick={(name) => this.selectStatusGroup(name)} />
        <div className={'scrollable-container'}>
          {this.state.selectedGroup === 'readingDetails' && !this.state.showFullImage && (
            <div className='form-container'>
              <div className='form-column'>
                {data.readings.registers.map((register, index) => {
                  let readingValue = register.newReading ? register.newReading.toString() : 0;

                  return (
                    <div className='form-element' key={register.textPrompt}>
                      <div className='input-container'>
                        <label>Reading {data.readings.registers.length > 1 ? `(${register.textPrompt})` : ''}</label>
                        <input
                          className={`char-box char-box--${5} read-only`}
                          type='number'
                          pattern='[0-9]*'
                          onChange={(e) => this.updateField('reading', e.target.value)}
                          value={readingValue ? readingValue.padStart(5, '0') : ''}
                        />
                        <div className='boxes'>
                          {Array.from({ length: 5 }, (_, index) => (
                            <div className='box' key={index} />
                          ))}
                        </div>
                      </div>
                      {data.readings.registers.length - 1 === index && !data.skipReason && OOTPhoto && (
                        <Button classes={'button button--plain'} onClick={() => this.toggleFullImage(true, OOTPhoto)} text={'View OOT Image'} />
                      )}
                    </div>
                  );
                })}

                <div className='form-element'>
                  <label>Reader</label>
                  <div className='read-only'>{data.readings.readerId ? data.readings.readerId : '—'}</div>
                </div>
                <div className='form-element'>
                  <label>Trouble Code</label>
                  <div className='read-only'>{data.readings.newTroubleCode1 ? data.readings.newTroubleCode1 + ' ' + data.readings.newTroubleCode2 : '—'}</div>
                  {/*<InlineEditable type="select"*/}
                  {/*                field="troubleCode1"*/}
                  {/*                data={data.readings.newTroubleCode1}*/}
                  {/*                optionList={[]}*/}
                  {/*                updateFn={(id) => this.updateField('newTroubleCode1', id)}/>*/}
                  {/*<InlineEditable type="select"*/}
                  {/*                field="troubleCode2"*/}
                  {/*                data={data.readings.newTroubleCode2}*/}
                  {/*                optionList={[]}*/}
                  {/*                updateFn={(id) => this.updateField('newTroubleCode2', id)}/>*/}
                </div>
                <div className='form-element'>
                  <label>OOT Field Test Result</label>
                  <div className='read-only'>{data.readings.ootTestResult ? data.readings.ootTestResult : '—'}</div>
                  {/*<InlineEditable type="select"*/}
                  {/*                field="tapTestResult"*/}
                  {/*                data={data.readings.tapTestResult}*/}
                  {/*                optionList={[]}*/}
                  {/*                updateFn={(id) => this.updateField('tapTestResult', id)}/>*/}
                </div>
                <div className='form-element'>
                  <label>Low Tolerance</label>
                  <div className='read-only'>{data.readings.lowTolerance ? data.readings.lowTolerance : '—'}</div>
                  {/*<InlineEditable type="select"*/}
                  {/*                field="tapTestResult"*/}
                  {/*                data={data.readings.tapTestResult}*/}
                  {/*                optionList={[]}*/}
                  {/*                updateFn={(id) => this.updateField('tapTestResult', id)}/>*/}
                </div>
                <div className='form-element'>
                  <label>Previous Reading</label>
                  <div className='read-only'>{data.readings.prevReading ? data.readings.prevReading : '—'}</div>
                  {/*<InlineEditable type="select"*/}
                  {/*                field="tapTestResult"*/}
                  {/*                data={data.readings.tapTestResult}*/}
                  {/*                optionList={[]}*/}
                  {/*                updateFn={(id) => this.updateField('tapTestResult', id)}/>*/}
                </div>
              </div>
              <div className='form-column'>
                <div className='form-element'>
                  <label>Skip Reason</label>
                  <div className='read-only'>{data.readings.skipReason ? Utils.titleize(data.readings.skipReason) : '—'}</div>
                  {/*<InlineEditable type="select"*/}
                  {/*                field="skipReason"*/}
                  {/*                data={data.readings.skipReason ? Utils.titleize(data.readings.skipReason) : null}*/}
                  {/*                optionList={[]}*/}
                  {/*                updateFn={(id) => this.updateField('skipReason', id)}/>*/}
                  {data.readings.skipReason && skipPhoto && <Button classes={'button button--plain'} onClick={() => this.toggleFullImage(true, skipPhoto)} text={'View Skip Image'} />}
                </div>
                <div className='form-element'>
                  <label>Read Date</label>
                  <div className='read-only'>{data.readings.dateCompleted ? data.readings.dateCompleted.format('DD/MM/YYYY hh:mma') : '—'}</div>
                  {/*<InlineEditable field="dateCompleted"*/}
                  {/*                data={data.readings.dateCompleted ? data.readings.dateCompleted.format("DD/MM/YYYY") : null}*/}
                  {/*                updateFn={(date) => this.updateField('dateCompleted', date)}/>*/}
                </div>
                <div className='form-element'>
                  <label>Trouble Message</label>
                  <div className='read-only'>{data.readings.troubleMessage ? data.readings.troubleMessage : '—'}</div>
                  {/*<InlineEditable type="input"*/}
                  {/*                field="troubleMessage"*/}
                  {/*                data={data.readings.troubleMessage}*/}
                  {/*                maxLength={50}*/}
                  {/*                updateFn={(troubleMessage) => this.updateField('troubleMessage', troubleMessage)}/>*/}
                </div>
                <div className='form-element'>
                  <label>Tap Test Result</label>
                  <div className='read-only'>{data.readings.tapTestResult ? data.readings.tapTestResult : '—'}</div>
                  {/*<InlineEditable type="select"*/}
                  {/*                field="tapTestResult"*/}
                  {/*                data={data.readings.tapTestResult}*/}
                  {/*                optionList={[]}*/}
                  {/*                updateFn={(id) => this.updateField('tapTestResult', id)}/>*/}
                </div>
                <div className='form-element'>
                  <label>High Tolerance</label>
                  <div className='read-only'>{data.readings.highTolerance ? data.readings.highTolerance : '—'}</div>
                  {/*<InlineEditable type="select"*/}
                  {/*                field="tapTestResult"*/}
                  {/*                data={data.readings.tapTestResult}*/}
                  {/*                optionList={[]}*/}
                  {/*                updateFn={(id) => this.updateField('tapTestResult', id)}/>*/}
                </div>
                <div className='form-element'>
                  <label>Read Instruction</label>
                  <div className='read-only'>{data.readings.newReadInstruction1 ? data.readings.newReadInstruction1 + ' ' + data.readings.newReadInstruction2 : '—'}</div>
                  {/*<InlineEditable type="select"*/}
                  {/*                field="readInstruction1"*/}
                  {/*                data={data.readings.newReadInstruction1}*/}
                  {/*                optionList={[]}*/}
                  {/*                updateFn={(id) => this.updateField('newReadInstruction1', id)}/>*/}
                  {/*<InlineEditable type="select"*/}
                  {/*                field="readInstruction2"*/}
                  {/*                data={data.readings.newReadInstruction2}*/}
                  {/*                optionList={[]}*/}
                  {/*                updateFn={(id) => this.updateField('newReadInstruction2', id)}/>*/}
                </div>
              </div>
            </div>
          )}
          {this.state.selectedGroup === 'meterDetails' && !this.state.showFullImage && (
            <div className='form-container'>
              <div className='form-column'>
                <div className='form-element'>
                  <label>Previous Meter No</label>
                  <div className='read-only'>{data.readings.prevMeterNumber ? data.readings.prevMeterNumber : '—'}</div>
                  {/*<InlineEditable type="input"*/}
                  {/*                field="meterInformation"*/}
                  {/*                data={data.readings.meterInformation}*/}
                  {/*                updateFn={(meterInformation) => this.updateField('meterInformation', meterInformation)}/>*/}
                </div>
                <div className='form-element'>
                  <label>Work Route</label>
                  <div className='read-only'>{data.readings.workRouteNumber}</div>
                </div>
                <div className='form-element'>
                  <label>Customer Name</label>
                  <div className='read-only'>{data.readings.customerName ? data.readings.customerName : '—'}</div>
                  {/*<InlineEditable type="select"*/}
                  {/*                field="meterLocation"*/}
                  {/*                data={data.readings.newMeterLocation}*/}
                  {/*                optionList={[]}*/}
                  {/*                updateFn={(id) => this.updateField('meterLocation', id)}/>*/}
                </div>
                <div className='form-element'>
                  <label>Meter Information</label>
                  <div className='read-only'>{data.readings.meterInformation ? data.readings.meterInformation : '—'}</div>
                  {/*<InlineEditable type="input"*/}
                  {/*                field="meterInformation"*/}
                  {/*                data={data.readings.meterInformation}*/}
                  {/*                updateFn={(meterInformation) => this.updateField('meterInformation', meterInformation)}/>*/}
                </div>
                <div className='form-element'>
                  <label>Special Message</label>
                  <div className='read-only'>{data.readings.specialMessage ? data.readings.specialMessage : '—'}</div>
                  {/*<InlineEditable type="input"*/}
                  {/*                field="specialMessage"*/}
                  {/*                data={data.readings.specialMessage}*/}
                  {/*                maxLength={50}*/}
                  {/*                updateFn={(specialMessage) => this.updateField('specialMessage', specialMessage)}/>*/}
                </div>
              </div>
              <div className='form-column'>
                <div className='form-element'>
                  <label>Address</label>
                  <div className='read-only'>{data.readings.address}</div>
                </div>
                <div className='form-element'>
                  <label>Coordinates (Longitude, Latitude)</label>
                  <div className='read-only'>
                    {data.readings.latitude != null ? (
                      <Button
                        classes={'button button--plain'}
                        onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${data.readings.latitude}, ${data.readings.longitude}`)}
                        text={`${data.readings.latitude}, ${data.readings.longitude}`}
                      />
                    ) : (
                      '—'
                    )}
                  </div>
                  {/*<InlineEditable type="input"*/}
                  {/*                field="coords"*/}
                  {/*                validationFn={(coords) => this.validateUpdate('coordinates', coords)}*/}
                  {/*                data={data.readings.latitude ? `${data.readings.latitude}, ${data.readings.longitude}` : null}*/}
                  {/*                updateFn={(coords) => this.updateField('coordinates', coords)}/>*/}
                </div>
                <div className='form-element'>
                  <label>Meter Location</label>
                  <div className='read-only'>{data.readings.newMeterLocation ? data.readings.newMeterLocation : '—'}</div>
                  {/*<InlineEditable type="select"*/}
                  {/*                field="meterLocation"*/}
                  {/*                data={data.readings.newMeterLocation}*/}
                  {/*                optionList={[]}*/}
                  {/*                updateFn={(id) => this.updateField('meterLocation', id)}/>*/}
                </div>
                <div className='form-element'>
                  <label>Meter Type</label>
                  <div className='read-only'>{data.readings.meterType ? data.readings.meterType : '—'}</div>
                  {/*<InlineEditable type="select"*/}
                  {/*                field="meterType"*/}
                  {/*                data={data.readings.meterType}*/}
                  {/*                optionList={[]}*/}
                  {/*                updateFn={(id) => this.updateField('meterType', id)}/>*/}
                </div>
                <div className='form-element'>
                  <label>Special Message Changed</label>
                  <div className='read-only'>{data.readings.specialMessageChanged ? 'Yes' : 'No'}</div>
                  {/*<InlineEditable type="input"*/}
                  {/*                field="specialMessage"*/}
                  {/*                data={data.readings.specialMessage}*/}
                  {/*                maxLength={50}*/}
                  {/*                updateFn={(specialMessage) => this.updateField('specialMessage', specialMessage)}/>*/}
                </div>
              </div>
            </div>
          )}
          {this.state.selectedGroup === 'readingPhotos' && !this.state.showFullImage && (
            <div className={'form-container'}>
              {this.state.allPhotoData.length > 0 && (
                <div>
                  <div className={'button-group'}>
                    <Button
                      classes={`button button--blue ${this.state.selectedPhotos.length > 0 ? '' : 'button--disabled'}`}
                      onClick={() => this.bulkDownload()}
                      text={`Download Selected (${this.state.selectedPhotos.length})`}
                      icon={'download'}
                      iconPosition={'1'}
                    />
                  </div>
                  {_.map(this.state.masterPhotos, (photoGroup, index) => {
                    let pictureType = '';
                    switch (parseInt(index)) {
                      case 1:
                        pictureType = 'OOT';
                        break;
                      case 2:
                        pictureType = 'Skip';
                        break;
                      case 3:
                        pictureType = 'Trouble';
                        break;
                      case 4:
                        pictureType = 'Survey';
                        break;
                      case 5:
                        pictureType = 'Ad Hoc';
                        break;
                      default:
                        break;
                    }

                    return (
                      <div className={'form-element'} key={index}>
                        <label>
                          {pictureType} Photos ({photoGroup.length})
                        </label>

                        {photoGroup.map((photo) => {
                          let photoUrl = photo.imageData ? `data:image/jpeg;base64,${Buffer.from(photo.imageData.data).toString('base64')}` : '';
                          return (
                            <div className='image-thumbnail' key={photo.pictureId}>
                              <div className={'image-preview-container'} title={'Click to Enlarge'} onClick={() => this.toggleFullImage(true, photo)}>
                                <img src={photoUrl} alt={''} />
                              </div>
                              <div className={'image-thumbnail-footer'}>
                                <div className={'checkbox-container'}>
                                  <input type='checkbox' id={'checkbox-' + photo.pictureId} checked={this.state.selectedPhotos.includes(photo.pictureId)} onChange={() => this.selectPhoto(photo)} />
                                  <label className='checkbox-label' htmlFor={'checkbox-' + photo.pictureId} />
                                </div>
                                <div className={'u--float-right'}>
                                  <span>{photo.reviewed === null ? 'Not Yet Validated' : photo.reviewed ? 'Accepted' : 'Rejected'}</span>
                                  <div className='separator' />
                                  <Button classes={'button button--plain'} title={'Download Image'} icon={'download'} iconPosition={'2'} onClick={() => this.downloadImage(photo, photoUrl)} />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              )}

              {!this.state.allPhotoData.length && <div className={'empty-state'}>There are no photos for this meter.</div>}
            </div>
          )}

          {this.state.showFullImage && (
            <div className='form-container'>
              <div className={'image-full-view'}>
                <div className={'button-group'}>
                  <Button classes={'button button--plain'} onClick={() => this.toggleFullImage(false)} text={'Back'} icon={'chevron'} iconPosition={'1'} iconClasses={'back'} />
                  <Button classes={'button button--blue'} onClick={() => this.downloadImage(this.state.selectedImage)} text={'Download Photo'} icon={'download'} iconPosition={'2'} />
                </div>
                <div className='form-element'>
                  <label>Validation Status</label>
                  <div className='read-only'>
                    {this.state.selectedImage.reviewed === null
                      ? 'Not Yet Validated'
                      : `${this.state.selectedImage.reviewed ? 'Accepted' : 'Rejected'} by ${this.state.selectedImage.reviewedBy} on ${this.state.selectedImage.reviewedDate.format(
                          'DD/MM/YYYY hh:mma'
                        )}`}
                  </div>
                </div>
                <CardCarousel settings={settings} data={this.state.carouselData} reference={(c) => (this.slider = c)} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default MeterDetailModal;
