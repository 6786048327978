import React from 'react';

class Utils extends React.Component {
  static humanize = (str) => {
    if (!str) {
      return;
    }

    return str[0].toUpperCase() + str.substring(1).replace(/([a-z])(?=[A-Z])/g, '$1 ');
  };

  static titleize = (str) => {
    if (!str) {
      return;
    }

    return str.replace(/(^|[\s-])\S/g, function (match) {
      return match.toUpperCase();
    });
  };

  static camelize = (str) => {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
      if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
  };

  static upperSnakeize = (str) => {
    if (!str) {
      return;
    }

    return str.replace(' ', '_').toUpperCase();
  };

  static isNumber = (value) => {
    const number = Number(value);
    return !isNaN(number) && String(value) === String(number);
  };

  static toHoursAndMinutes = (totalMin) => {
    let hours = Math.floor(totalMin / 60);
    let min = totalMin % 60;
    if (min.toString().length < 2) min = '0' + min;

    return `${hours}:${min}`;
  };

  static toMinutes = (totalHoursAndMins) => {
    if (!totalHoursAndMins) {
      return 0;
    }

    const [hours, minutes] = totalHoursAndMins.split(':');
    const totalMinutes = +hours * 60 + +minutes;

    return totalMinutes;
  };
}
export default Utils;
