import React from 'react';
import Icon from '../../Icon';

class ImportFileResult extends React.Component {
  render() {
    const data = this.props.data;

    return (
      <div className={`result-item import-file-item`}>
        <div id={'importFile-' + data.id} className={`item-card`}>
          <div className='item-row'>
            {/* Imported Date */}
            <div className='item-detail' title='Imported Date'>
              <Icon name='importDate' />
              <span>{data.importedDate.format('DD/MM/YYYY hh:mma')}</span>
            </div>
            {/* File Name */}
            <div className='item-detail f--bold' title='File Name'>
              <span>{data.fileName}</span>
            </div>
            {/* Status */}
            <div className='item-detail' title='Status'>
              <Icon name='status' />
              <span>{data.status}</span>
            </div>
            {/* Meters */}
            <div className='item-detail' title='Meters'>
              <Icon name='meter' />
              <span>
                {data.meters} {data.meters === 1 ? 'meter' : 'meters'}
              </span>
            </div>
            {/* Jobs */}
            <div className='item-detail' title='Jobs'>
              <Icon name='job' />
              <span>
                {data.jobs} {data.jobs === 1 ? 'job' : 'jobs'}
              </span>
            </div>
            {/* Cycles */}
            <div className='item-detail' title='Cycles'>
              <Icon name='repeat' />
              <span>
                {data.cycles} {data.cycles === 1 ? 'cycle' : 'cycles'}
              </span>
            </div>
            {/* Client Routes */}
            <div className='item-detail' title='Client Routes'>
              <Icon name='route' />
              <span>
                {data.clientRoutes} {data.clientRoutes === 1 ? 'client route' : 'client routes'}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ImportFileResult;
