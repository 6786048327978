import React from 'react';
import Icon from '../Icon';
import Utils from '../../utils/Utils';

class RouteTemplateResult extends React.Component {
  handleClick = (e) => {
    const routeTemplateId = this.props.data.routeId;
    const routeTemplateElem = document.getElementById('routeTemplate-' + routeTemplateId);

    if (routeTemplateElem.contains(e.target)) {
      const editableElem = document.getElementById('edit-button-' + routeTemplateId);

      if (!editableElem || !editableElem.contains(e.target)) {
        this.props.selectFn();
      }
    }
  };

  componentDidMount() {
    const element = document.getElementById('routeTemplate-' + this.props.data.routeId);
    element.addEventListener('mousedown', this.handleClick);
  }

  componentWillUnmount() {
    // Remove the event listener on component unmounting
    const element = document.getElementById('routeTemplate-' + this.props.data.routeId);
    element.removeEventListener('mousedown', this.handleClick);
  }

  render() {
    const routeTemplate = this.props.data;

    return (
      <div className={`result-item ${routeTemplate.selected ? 'selected' : ''}`}>
        <div id={'routeTemplate-' + routeTemplate.routeId} className={`item-card`}>
          <div id={'edit-button-' + routeTemplate.routeId} className={'edit-button'} title={'Click to Edit'} onClick={() => this.props.editFn()}>
            <Icon name='edit' />
          </div>
          <div className='item-row'>
            {/* Route No. */}
            <div className='item-detail f--bold' title='Route No.'>
              <span>{routeTemplate.routeNumber}</span>
            </div>
            {/* Description. */}
            <div className='item-detail' title='Description'>
              <Icon name='label' />
              <span className='u--ellipsis'>{routeTemplate.description}</span>
            </div>
            {/* Meters */}
            <div className='item-detail' title='Total Meters'>
              <Icon name='meter' />
              <span>{routeTemplate.totalMeters} meters</span>
            </div>
            {/* Route Category */}
            {
              <div>
                <div className='item-detail' title='Route Category'>
                  <Icon name='house' />
                  <span>{routeTemplate.routeCategoryName}</span>
                </div>
              </div>
            }
            {/* Preferred Reader */}
            {
              <div className='item-detail' title='Preferred Reader'>
                <Icon name='star-person' />
                <span className='u--ellipsis' title={routeTemplate.preferredReaderName ? Utils.titleize(routeTemplate.preferredReaderName.replace('.', ' ')) : 'N/A'}>
                  {routeTemplate.preferredReaderName ? Utils.titleize(routeTemplate.preferredReaderName.replace('.', ' ')) : 'N/A'}
                </span>
              </div>
            }
            {/* Requested */}
            {
              <div className='item-detail'>
                <span className={'f--bold'} title={routeTemplate.isRequested ? 'Requested' : ''}>
                  {routeTemplate.isRequested ? 'Requested' : ''}
                </span>
              </div>
            }
          </div>
          <div className='item-row'>
            {/* Route Name */}
            <div className='item-detail' title='Route Name'>
              <Icon name='pin' />
              <span className='u--ellipsis' title={routeTemplate.routeName}>
                {routeTemplate.routeName}
              </span>
            </div>
            {/* Area */}
            <div className='item-detail' title='Area'>
              <Icon name='map' />
              <span className='u--ellipsis'>{routeTemplate.areaName}</span>
            </div>
            {/* Requirements */}
            {
              <div className='item-detail' title='Requirements'>
                <Icon name='clipboard' />
                <span className={'u--ellipsis'} title={routeTemplate.requirements.length > 0 ? 'Requires ' + routeTemplate.requirements.join(', ') : 'No Requirements'}>
                  {routeTemplate.requirements.length > 0 ? 'Requires ' + routeTemplate.requirements.join(', ') : 'No Requirements'}
                </span>
              </div>
            }
            {/* Read Frequency */}
            {
              <div>
                <div className='item-detail' title='Read Frequency'>
                  <Icon name='repeat' />
                  <span className='u--ellipsis' title={routeTemplate.readFrequencyName}>
                    {routeTemplate.readFrequencyName}
                  </span>
                </div>
              </div>
            }
            {/* Last Reader */}
            {
              <div className='item-detail' title='Last Reader'>
                <Icon name='history' />
                <span className='u--ellipsis' title={routeTemplate.lastReaderName ? Utils.titleize(routeTemplate.lastReaderName.replace('.', ' ')) : 'N/A'}>
                  {routeTemplate.lastReaderName ? Utils.titleize(routeTemplate.lastReaderName.replace('.', ' ')) : 'N/A'}
                </span>
              </div>
            }
            {/* Requested */}
            {
              <div className='item-detail' title='Requested Reader'>
                <span className='u--ellipsis' title={routeTemplate.requestedBy ? `By` : `By ${Utils.titleize(routeTemplate.requestedBy.replace('.', ' '))}`}>
                  {routeTemplate.requestedBy ? `By ${Utils.titleize(routeTemplate.requestedBy.replace('.', ' '))}` : ''}
                </span>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default RouteTemplateResult;
